import {DocumentData, DocumentReference, QueryDocumentSnapshot, SnapshotOptions} from "firebase/firestore";

export type UserRightType = {
    id?: string;
    ref?: DocumentReference;
    group: string;
    item: string;
    path: string;
}

export const UserRightConverter = {
    toFirestore(right: UserRightType): DocumentData {
        return {
            group: right.group,
            item: right.item,
            path: right.path,
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): UserRightType {
        const right = snapshot.data(options)!;
        return {
            id: snapshot.id,
            ref: snapshot.ref,
            group: right.group,
            item: right.item,
            path: right.path,
        } as UserRightType;
    }
};
