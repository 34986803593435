import {
    CustomerOfferProductionAttributesType,
} from "../../../../../../../fb-converters/cutomerOffer-converter";
import React from "react";
import OfferAttrPdfTableHeader from "./OfferAttrPdfTableHeader";
import OfferAttrPdfTableRow from "./OfferAttrPdfTableRow";
import {View} from "@react-pdf/renderer";
import OfferAttrPdfTableTotal from "./OfferAttrPdfTableTotal";
import {CurrencyNameType} from "../../../../../../../fb-converters/currency-converter";

interface OfferPdfTableProps {
    attributes: CustomerOfferProductionAttributesType,
    productQty: number,
    total: number,
    euroExchangeRate: number,
    currency?: CurrencyNameType
}

const OfferProductPdfTable = ({attributes, productQty, total, euroExchangeRate, currency}: OfferPdfTableProps) => {
    return (<View wrap={false}>
        <OfferAttrPdfTableHeader currency={currency} />
        <OfferAttrPdfTableRow attributes={attributes} productQty={productQty} euroExchangeRate={euroExchangeRate}/>
        <OfferAttrPdfTableTotal total={total} euroExchangeRate={euroExchangeRate}/>
    </View>);
};

export default OfferProductPdfTable;
