import {
    Button,
    Col,
    Form, FormGroup,
    Modal, ModalBody,
    ModalTitle, Row, Spinner, Tab, Tabs,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import React, {useEffect, useState} from "react";
import {
    addDoc,
    collection,
    DocumentData,
    DocumentReference,
    query,
    updateDoc,
    where
} from "firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faLocation, faSave} from "@fortawesome/free-solid-svg-icons";
import ModalBackground from "../../../../common/ModalBackground";
import DialogCloseButton from "../../../../common/DialogCloseButton";
import {RegionsDialog} from "../../regions/RegionsDialog";
import SelectValueFromDialog from "../../../../common/SelectValueFromDialog";
import {dateToString, firebaseDateToDate, removeEmptyFields, showErrorsListInToast} from "../../../../common";
import {UsersDialog} from "../../users/UsersDialog";
import {fbDb,} from "../../../../App";
import {useCollection} from "react-firebase-hooks/firestore";
import ReactDatePicker from "react-datepicker";
import {ClientLocationType} from "../../../../fb-converters/location-converter";
import I18Label from "../../../../i18/i18label";
import useUserRights from "../../../../hooks/useUserRights";

export interface ClientLocationDialogProps {
    zIndex: number;
    client: DocumentData;
    location: ClientLocationType | null;
    onDone: () => any;
}

export type ClientLocationCrmSettingsType = {
    campaignId: string;
    campaignRef: DocumentReference;
    campaignName: string;
    campaignPeriod: string;
    inCampaign: boolean;
}

export function ClientLocationDialog({zIndex, client, location, onDone}: ClientLocationDialogProps) {
    const {canUserEdit} = useUserRights();
    const [saving, setSaving] = useState(false);
    const [country, setCountry] = useState<string>(location?.country || "");
    const [region, setRegion] = useState<string>(location?.region || "");
    const [salesRepresentative, setsalesRepresentative] = useState<string>(location?.salesRepresentative || "");
    const [startDate, setStartDate] = useState<Date | undefined>(firebaseDateToDate(location?.startDate));
    const [crmSettings, setCrmSettings] = useState<ClientLocationCrmSettingsType[]>([]);

    const [campaigns, loadingCampaigns, errorCampaigns] = useCollection(
        query(collection(fbDb, "campaigns"), where("isActive", "==", "Да")
        ));

    const regionsDialog = <RegionsDialog show={true} zIndex={zIndex || 1010} onClose={() => {
    }}/>
    const tradersDialog = <UsersDialog show={true} zIndex={zIndex || 1010} onClose={() => {
    }}/>


    useEffect(() => {
        const _crmSettings: ClientLocationCrmSettingsType[] = [];
        if (campaigns && !campaigns.empty) {
            campaigns.docs.forEach(
                d => {
                    const data = d.data();
                    if (data.isActive === "Да") {
                        const c: ClientLocationCrmSettingsType = {
                            campaignId: d.id,
                            campaignRef: d.ref,
                            campaignName: data.name,
                            campaignPeriod: dateToString(firebaseDateToDate(data.fromDate)) + " - " +
                                dateToString(firebaseDateToDate(data.toDate)),
                            inCampaign: data.clients.includes(location?.id),
                        }
                        _crmSettings.push(c);
                    }
                }
            );
        }
        setCrmSettings(_crmSettings);
    }, [campaigns]);

    const save = async (data: any) => {
        const errors: string[] = [];
        if (!data.region || !data.country) {
            errors.push("Моля, изберете държава и регион.");

        }
        if (!data.name) {
            errors.push("Моля, попълнете името на клиента.")

        }
        if (!data.sizeGroup) {
            errors.push("Моля, изберете клиентска група.")

        }
        if (errors.length > 0) {
            showErrorsListInToast("Грешка", errors);
            return;

        }

        try {
            setSaving(true);
            let _data = removeEmptyFields(data);
            _data.startDate = startDate || null;
            if (location && location.ref) {
                await updateDoc(location.ref, _data);
                _data.id = location.id;
                _data.ref = location.ref;
            } else {
                _data = await addDoc(collection(fbDb, "clientLocations"), _data);
            }

            crmSettings.forEach(
                s => {
                    const c = campaigns?.docs.find(d => d.id === s.campaignId);
                    if (c) {
                        const clients = c.data().clients;
                        const isInList = clients.includes(_data.id);

                        if (s.inCampaign && !isInList) {
                            clients.push(_data.id);
                            updateDoc(c.ref, {clients: clients});
                        }
                        if (!s.inCampaign && isInList) {
                            const newList = clients.filter((item: string) => item !== _data.id);
                            updateDoc(c.ref, {clients: newList});
                        }
                    }
                }
            )

            onDone();
        } catch (e: any) {
            console.error(e);
            showErrorsListInToast("Грешка", errors);
        } finally {
            setSaving(false);
        }
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const data: any = {
            type: 'location',
            clientId: client.id,
            clientName: client.name,
            name: event.target.elements.name.value || client.name,
            sizeGroup: client.sizeGroup.name,
            address: event.target.elements.address.value || '',
            email: event.target.elements.email.value || '',
            mol: event.target.elements.mol.value || '',
            phone: event.target.elements.phone.value || '',
            religion: event.target.elements.religion.value || '',
            machinesCnt: event.target.elements.machinesCnt.value || '',
            machines: event.target.elements.machines.value || '',
            country: country || '',
            region: region || '',
            startDate: startDate,
        };
        save(removeEmptyFields(data));
    }

    function updateCrmSettings(setting: ClientLocationCrmSettingsType) {
        const newSettings = [...crmSettings];
        const idx = newSettings.findIndex(
            value => value.campaignId === setting.campaignId
        );
        if (idx > -1) {
            newSettings[idx] = setting;
            setCrmSettings(newSettings);
        }
    }

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={true} size={"xl"} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faLocation}/> <I18Label
                        label={`${location ? "Корекция" : "Добавяне"} на локация`}/>
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onDone()}/>
                </ModalHeader>

                <ModalBody>
                    <Form className={"p-2 pt-0"} onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Tabs defaultActiveKey={"general"}>
                                <Tab title={<I18Label label={"Основни данни"}/>} eventKey={"general"}>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-2">
                                                <Form.Label><I18Label label={"Наименование"}/></Form.Label>
                                                <Form.Control type="text" name="name"
                                                              defaultValue={location?.name}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <SelectValueFromDialog label={<I18Label label={"Регион"}/>}
                                                                   value={region}
                                                                   onChange={(region) => {
                                                                       setCountry(region?.country || "");
                                                                       setRegion(region?.name || "");
                                                                   }}
                                                                   dialog={regionsDialog}/>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Form.Label><I18Label label={"Търговски представител"}/></Form.Label>
                                                <Form.Control value={salesRepresentative} readOnly/>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-2">
                                                <Form.Label><I18Label label={"Адрес"}/></Form.Label>
                                                <Form.Control type="text" name="address"
                                                              defaultValue={location?.address}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-2">
                                                <Form.Label><I18Label label={"Отговорно лице"}/></Form.Label>
                                                <Form.Control type="text" name="mol"
                                                              defaultValue={location?.mol}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-2">
                                                <Form.Label><I18Label label={"Данни за контакт"}/></Form.Label>
                                                <Form.Control type="text" name="phone"
                                                              defaultValue={location?.phone}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-2">
                                                <Form.Label><I18Label label={"Отговорно лице e-mail"}/></Form.Label>
                                                <Form.Control type="text" name="email"
                                                              defaultValue={location?.email}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label><I18Label label={"Отговорно лице религия"}/></Form.Label>
                                                <Form.Control type="text" name="religion"
                                                              defaultValue={location?.religion}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-2">
                                                <Form.Label><I18Label
                                                    label={"Брой на притежаваните машини"}/></Form.Label>
                                                <Form.Control type="text" name="machinesCnt"
                                                              defaultValue={location?.machinesCnt}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-2">
                                                <Form.Label><I18Label label={"Притежавани машини"}/></Form.Label>
                                                <Form.Control type="text" name="machines"
                                                              defaultValue={location?.machines}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab title={<I18Label label={"CRM кампани"}/>} eventKey={"crm"}>
                                    <Row className={"mt-3"}>
                                        <Col xs={12} className={"bg-secondary-light p-2 mb-2"}>
                                            <Row className={"lh-2em"}>
                                                <Col xs={"auto"}><I18Label label={"Дата на включване"}/>:</Col>
                                                <Col xs={"auto"}>
                                                    <ReactDatePicker
                                                        dateFormat="dd.MM.yyyy"
                                                        selected={startDate}
                                                        className={"w-75px"}
                                                        isClearable={true}
                                                        onChange={date => setStartDate(date || undefined)}/>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {
                                            crmSettings.map(
                                                c => <Col xs={12} md={6} key={c.campaignId} className={"p-2 border"}
                                                          onClick={(event: any) => updateCrmSettings({
                                                              ...c,
                                                              inCampaign: !c.inCampaign
                                                          })}>
                                                    <Row>
                                                        <Col>
                                                            <Form.Check>
                                                                <Form.Check.Input type={"checkbox"}
                                                                                  checked={c.inCampaign}

                                                                />
                                                                <Form.Check.Label> {c.campaignName} </Form.Check.Label>
                                                            </Form.Check>
                                                        </Col>
                                                        <Col xs={"auto"}> <strong>{c.campaignPeriod}</strong></Col>
                                                    </Row>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Tab>
                            </Tabs>
                        </Modal.Body>
                        <Modal.Footer>
                            <Row className={"w-100"}>
                                {
                                    saving && <Col xs={12} className={"text-center"}>
                                        <Spinner animation={"border"}/>
                                    </Col>
                                }

                                {
                                    !saving &&
                                    <>
                                        <Col xs={6}>
                                            <Button variant={"outline-secondary"} onClick={() => {
                                                onDone()
                                            }}>
                                                <FontAwesomeIcon icon={faArrowLeft}/> <I18Label label={"Към списъка"}/>
                                            </Button>
                                        </Col>
                                        {
                                            canUserEdit('Номенклатури-Клиенти') &&
                                            <Col xs={6} className={"text-end"}>
                                                <Button type={"submit"}>
                                                    <FontAwesomeIcon icon={faSave}/> <I18Label label={"Запиши"}/>
                                                </Button>
                                            </Col>
                                        }
                                    </>
                                }
                            </Row>
                        </Modal.Footer>
                    </Form>
                </ModalBody>
            </Modal>
        </ModalBackground>
    )
}
