import React, {useState} from "react";

import {Button, Col, Container, Modal, Row, Spinner,} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {
    customerOfferConverter,
    CustomerOfferType
} from "../../../../fb-converters/cutomerOffer-converter";
import {addDoc, collection, getCountFromServer, updateDoc} from "firebase/firestore";
import {fbDb} from "../../../../App";
import {CustomerInquiryType} from "../../../../fb-converters/cutomerInquiry-converter";
import {
    removeEmptyFields, showErrorsListInToast,
} from "../../../../common";
import CustomerOfferDetailsTabOffer from "./CustomerOfferDetailsTabOffer";
import I18Label from "../../../../i18/i18label";
import {API} from "../../../../api";
import {DocumentModificationType} from "../../../../fb-converters/DocumentModificationsType";
import useUserRights from "../../../../hooks/useUserRights";


export interface Props {
    zIndex?: number,
    inquiry: CustomerInquiryType,
    offer: CustomerOfferType,
    onDone: () => any,
    disabled?: boolean
}


export function CustomerOfferDetails(props: Props) {
    const {canUserEdit} = useUserRights();
    const [working, setWorking] = useState(false);

    const offersRef = collection(fbDb, `customerInquiries/${props.offer.inquiryId}/offers`).withConverter(customerOfferConverter);
    const [offer, setOffer] = useState<CustomerOfferType>(props.offer);

    async function saveOffer() {
        try {
            setWorking(true);

            offer.products.forEach(
                p => {
                    if(!p.scrapWeight) {
                        p.scrapWeight = 0;
                    }
                    const price = (p.singlePrice || 0);
                    const discount = p.discount || 0;
                    const finalPrice = price - (price * discount / 100);
                    p.finalSinglePrice = finalPrice - (p.skrubPrice || 0);
                    const attributesPrice = p.attributes.reduce(
                        (acc, a) => acc + a.finalPrice, 0
                    ) ?? 0;
                    p.finalSingleTotalPrice = p.finalSinglePrice + attributesPrice;
                }
            )

            offer.authorId = API.user?.id || null;
            offer.authorName = API.user?.name || null;
            const _offer = removeEmptyFields(offer)!;

            _offer.products = _offer.products.map(
                p => {
                    p = removeEmptyFields(p)!
                    p.product = removeEmptyFields(p.product)!;
                    return p;
                }
            );

            if (offer.ref) {
                await updateDoc(offer.ref, _offer)
            } else {
                const offersCount = await getCountFromServer(offersRef);
                let currentCnt = offersCount.data().count + 1;
                _offer.offerNumber = currentCnt;
                const newDocRef = await addDoc(offersRef, _offer);
                await updateDoc(newDocRef, {id: newDocRef.id, ref: newDocRef});
            }

            props.onDone();
        } catch (e: any) {
            console.error(e);
            showErrorsListInToast("Грешка", [`Възникна грешка при опит за запис:\n${e.message}`]);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            <Modal.Body>
                <Container fluid className={"pt-3"}>
                    <CustomerOfferDetailsTabOffer
                        zIndex={props.zIndex}
                        inquiry={props.inquiry}
                        offer={offer}
                        onChange={props.disabled ? undefined : newOffer => setOffer(newOffer)}
                    />
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        working ? <Col className={"text-center"}><Spinner animation={"border"}/></Col> :
                            <>
                                <Col xs={6}>
                                    <Button variant={"outline-secondary"} onClick={() => {
                                        props.onDone()
                                    }}>
                                        <FontAwesomeIcon icon={faArrowLeft}/> <I18Label label={"Към списъка"}/>
                                    </Button>
                                </Col>
                                {
                                    !props.disabled &&
                                    canUserEdit('CRM-Запитвания и оферти') &&
                                    <Col xs={6} className={"text-end"}>
                                        <Button type={"submit"} onClick={saveOffer}>
                                            <FontAwesomeIcon icon={faSave}/> <I18Label label={"Запиши"}/>
                                        </Button>
                                    </Col>
                                }
                            </>
                    }
                </Row>
            </Modal.Footer>
        </>
    )
}
