import {dateToString, zeroToEmptyStringAsNumber} from "../../../../common";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import {WarehouseOutType} from "../../hooks/useWarehouseOut";


export interface WarehouseOutTableRowProps {
    data: WarehouseOutType;
    onEdit?: () => void;
    onDelete?: () => void;
}

export default function WarehouseOutTableRow(props: WarehouseOutTableRowProps) {
    return <tr>
        <td> {dateToString(props.data.transactionDate)} </td>
        {/*<td> {props.data.transactionNumber} </td>*/}
        <td> {props.data.client?.bulstat} </td>
        <td> {props.data.client?.name} </td>
        <td className={"text-end w-100px"}> {zeroToEmptyStringAsNumber(props.data.price * props.data.currencyRate, " " + props.data.currency)} </td>
        <td className={"text-end"}>
            {
                props.onEdit &&
                <Button size={"sm"} variant={"secondary"} onClick={() => props.onEdit!()}>
                    <FontAwesomeIcon icon={faPencil} />
                </Button>
            }&nbsp;
            {
                props.onDelete &&
                <Button size={"sm"} variant={"danger"} onClick={() => props.onDelete!()}>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            }
        </td>
    </tr>;
}
