import {Button, Col, Form, ModalBody, ModalFooter, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCircleArrowDown, faEdit, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import LoadDataFromServerError from "../../../common/LoadDataFromServerError";
import {dateToString, dateWithEndDayTime, zeroToEmptyString} from "../../../common";
import RightAlignedCell from "../../../common/RightAlignedCell";
import I18Label from "../../../i18/i18label";
import {CurrencyNameType, CurrencyType, useCurrency} from "../../../fb-converters/currency-converter";
import ReactDatePicker from "react-datepicker";
import useUserRights from "../../../hooks/useUserRights";

export interface CurrenciesListProps {
    currency: CurrencyNameType;
    isVisible: boolean;
    onAdd: () => any;
    onEdit: (item: CurrencyType) => any;
    onDelete: (item: CurrencyType) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
}

export function CurrenciesList({currency, isVisible, onAdd, onEdit, onDelete, onClose, onSelect}: CurrenciesListProps) {
    const {canUserEdit} = useUserRights();
    const toDay = dateWithEndDayTime(new Date())!;
    const weekAgo = new Date(toDay);
    weekAgo.setDate(toDay.getDate() - 7);

    const [fromDate, setFromDate] = useState(weekAgo);
    const [toDate, setToDate] = useState(toDay);
    const {getCurrencyHistory, loading, error} = useCurrency(null);
    const [history, setHistory] = useState<CurrencyType[]>([]);

    useEffect(() => {
        if (currency && fromDate && toDate && isVisible) {
            getCurrencyHistory(currency, fromDate, toDate)
                .then((data) => {
                    setHistory(data);
                })
        } else {
            setHistory([]);
        }
    }, [fromDate, toDate, currency, isVisible]);

    return (
        <>
            {
                isVisible &&
                <Row className={"mt-3"}>
                    <Col>
                        <Form.Group>
                            <Form.Label>От дата: </Form.Label>
                            <ReactDatePicker
                                selected={fromDate}
                                onChange={date => setFromDate(date as Date)}
                                dateFormat={"dd.MM.yyyy"}
                                className={"form-control w-120px"}
                                showIcon={true}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>До дата: </Form.Label>
                            <ReactDatePicker
                                selected={toDate}
                                onChange={date => setToDate(date as Date)}
                                dateFormat={"dd.MM.yyyy"}
                                showIcon={true}
                                className={"form-control w-120px"}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            }
            {loading && <Spinner animation={"border"}/>}

            {
                !loading && error && <LoadDataFromServerError/>
            }

            {
                !loading && !error && isVisible && history.length === 0 &&
                <Row className={"m-5"}>
                    <Col className={"text-center fw-bold"}>Няма въведени валутни курсове</Col>
                </Row>
            }


            {
                !loading && !error && isVisible && history.length > 0 &&
                <>
                    <ModalBody>
                        <div className={"max-h-60vh scrollable"}>
                            <Table size={"sm"} borderless hover>
                                <thead style={{backgroundColor: "white"}}>
                                <tr>
                                    <th style={{width: onSelect ? 120 : 90}}></th>
                                    <th>Дата</th>
                                    <RightAlignedCell header>Курс</RightAlignedCell>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    history.length > 0 && history.map(
                                        d =>
                                            <tr key={d.id} className={"tr-bordered"}>
                                                <td>
                                                    {
                                                        canUserEdit('Номенклатури-Валутни курсове') && <>
                                                            <Button size={"sm"} variant={"outline-secondary"}
                                                                    onClick={() => onEdit(d)}
                                                            >
                                                                <FontAwesomeIcon icon={faEdit}/>
                                                            </Button>&nbsp;
                                                            <Button size={"sm"} variant={"outline-danger"}
                                                                    onClick={() => {
                                                                        onDelete(d)
                                                                    }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash}/>
                                                            </Button>&nbsp;
                                                        </>
                                                    }
                                                    {
                                                        onSelect &&
                                                        <Button size={"sm"} variant={"outline-success"}
                                                                onClick={() => {
                                                                    onSelect(d)
                                                                }}
                                                        >
                                                            <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                        </Button>
                                                    }
                                                </td>
                                                <td>
                                                    {dateToString(d.date)}
                                                </td>
                                                <RightAlignedCell>
                                                    {d.rate.toFixed(3)}
                                                </RightAlignedCell>
                                            </tr>
                                    )
                                }
                                </tbody>

                            </Table>
                        </div>
                    </ModalBody>
                </>
            }

            {
                isVisible &&
                <ModalFooter>
                    {
                        canUserEdit('Номенклатури-Валутни курсове') &&
                        <Button onClick={() => onAdd()}>
                            <FontAwesomeIcon icon={faPlus}/> <I18Label label={"Добави нов"}/>
                        </Button>
                    }
                    <Button variant={"outline-secondary"} onClick={() => {
                        onClose(null)
                    }}>
                        <FontAwesomeIcon icon={faCancel}/> <I18Label label={"Затвори"}/>
                    </Button>
                </ModalFooter>
            }
        </>
    )
}
