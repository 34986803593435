import {Card, Col, Row} from "react-bootstrap";
import React from "react";
import {CustomerInvoiceType} from "../../../fb-converters/cutomerInvoice-converter";

export default function CustomersInvoicesPage(props: {
    inModal?: boolean,
    openMode: "add" | "edit" | "list",
    editInvoice?: CustomerInvoiceType,
    onDone?: VoidFunction,
}) {
    return <>
        <Row>
            {!props.inModal && <Col/>}
            <Col xs={props.inModal ? 12 : 8}>
                <Card>
                    <Card.Header>
                        <Card.Title>CRM - Фактуриране</Card.Title>
                    </Card.Header>


                    <Card.Body className={"bg-secondary-light"}>
                        <strong>Тук ще се показват всички издадени фактури...</strong>
                    </Card.Body>

                    <Card.Footer className={"text-end"}>
                    </Card.Footer>
                </Card>
            </Col>
            {!props.inModal && <Col/>}
        </Row>
    </>
}
