import { CustomerOfferType
} from "../../../../../../../fb-converters/cutomerOffer-converter";
import {TableRow} from "../product-table/OfferProductPdfTableHeader";
import React from "react";
import {View, Text, StyleSheet} from "@react-pdf/renderer";
import {ProductSummaryType} from "../../../../CustomerOfferDetailsSummary";
import RightAlignedCell from "../../../../../../../common/RightAlignedCell";
import {numberWithCommas, numberWithCommasToCurrency} from "../../../../../../../common";


const styles = StyleSheet.create({
    col1: { width: 20, textAlign: "center", padding: 2 },
    col2: { width: 320, textAlign: "left", padding: 2 },
    col3: { width: 300, textAlign: "center", padding: 2 },
    col4: { width: 300, textAlign: "center", padding: 2 },
    col5: { width: 80, textAlign: "center", padding: 2 },
});

const OfferInvestmentPdfTableRow = ({offer, summary}:
                                        {
                                            offer: CustomerOfferType,
                                            summary?: ProductSummaryType[] | undefined
                                        }) => {

    return (
        <View>
            {
                summary?.map(
                    (s, idx) => <tr key={idx}>

                        <RightAlignedCell>  </RightAlignedCell>
                        <RightAlignedCell>  </RightAlignedCell>
                        <RightAlignedCell>  </RightAlignedCell>
                        <RightAlignedCell>  </RightAlignedCell>
                        <RightAlignedCell>  </RightAlignedCell>
                        <RightAlignedCell>  </RightAlignedCell>
                    </tr>
                )
            }
            {
                summary?.map((s, idx) => (
                    <TableRow cols={[
                        <View style={styles.col1}>
                            <Text>{ idx+1 }</Text>
                        </View>,
                        <View style={styles.col2}>
                            <Text>{ s.product }</Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>{ numberWithCommasToCurrency(s.warranty) }</Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>{ numberWithCommasToCurrency(s.priceWithoutServices * offer.eurExchangeRate) }</Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>{ numberWithCommasToCurrency(s.extendedWarranty) }</Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>{ numberWithCommasToCurrency(s.priceWithExtWarranty * offer.eurExchangeRate) }</Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>{ numberWithCommasToCurrency(s.warranty) }</Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>{ numberWithCommasToCurrency(s.monthPriceWithoutServices * offer.eurExchangeRate) }</Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>{ numberWithCommasToCurrency(s.extendedWarranty) }</Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>{ numberWithCommasToCurrency(s.monthPriceWithExtWarranty * offer.eurExchangeRate) }</Text>
                        </View>,
                    ]}/>
                ))
            }
        </View>
    );
};

export default OfferInvestmentPdfTableRow;
