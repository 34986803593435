import {
    DocumentReference,
    FirestoreError,
    QueryConstraint,
    QueryDocumentSnapshot,
    where
} from "firebase/firestore";
import {useEffect, useState} from "react";
import useFirebaseCollection from "../../../hooks/useFirebaseCollection";
import {firebaseDateToDate} from "../../../common";
import {ClientType} from "../../nomenclatures/clients/hooks/useClients";
import {WarehouseOutItemsType} from "./useWarehouseOutItems";
import {CurrencyNameType, CurrencyType, EUR_CURRENCY_NAME} from "../../../fb-converters/currency-converter";


export interface WarehouseOutType {
    id?: string;
    ref?: DocumentReference;
    invoiceId?: string;
    ownCompanyId?: string;
    warehouseId: string;
    transactionNumber: number;
    transactionDate: Date;
    clientId?: string;
    client?: ClientType;
    items?: WarehouseOutItemsType;
    productIds: string[];
    price: number;
    currency: CurrencyNameType;
    currencyRate: number;
}

export type WarehouseOutsType = WarehouseOutType[];


export function convertWarehouseOutData(_objects: QueryDocumentSnapshot[]): WarehouseOutsType {
    if(_objects) {
        return _objects.map(
            d => {
                return {
                    id: d.id,
                    ref: d.ref,
                    invoiceId: d.get('invoiceId'),
                    transactionNumber: d.get('transactionNumber'),
                    transactionDate: firebaseDateToDate(d.get('transactionDate')),
                    client: d.get('client'),
                    items: d.get('items'),
                    productIds: d.get('productIds') ?? [],
                    ownCompanyId: d.get('ownCompanyId') ?? "",
                    warehouseId: d.get('warehouseId') ?? "",
                    price: d.get('price') || 0,
                    currency: d.get('currency') || EUR_CURRENCY_NAME,
                    currencyRate: d.get('currencyRate') || 1,
                } as WarehouseOutType;
            }
        );
    } else return [];
}



export default function useWarehouseOut(warehouseId: string | undefined, constraints?: QueryConstraint[]): [WarehouseOutsType, boolean, FirestoreError | undefined] {

    const finalConstraints = constraints ? [...constraints] : [];
    finalConstraints.push(where("warehouseId", "==", warehouseId ?? ""));

    const [documents, loading, error] = useFirebaseCollection("warehouseOut", finalConstraints);
    const [convertedData, setConvertedData] = useState<WarehouseOutsType>([]);

    useEffect(() => {
        if(documents) {
            setConvertedData(convertWarehouseOutData(documents.docs));
        }
    }, [documents]);

    return [convertedData, loading, error];
}
