import {Button, CloseButton, Col, Container, Modal, Row, Spinner, Table} from "react-bootstrap";
import {
    capitalizeFirstLetter,
    dateToString,
    zeroToEmptyStringAsCurrencyWithCommas,
    zeroToEmptyStringAsNumber
} from "../../../../../common";
import ModalBackground from "../../../../../common/ModalBackground";
import {CustomerInvoiceType} from "../../../../../fb-converters/cutomerInvoice-converter";
import CenterAlignedCell from "../../../../../common/CenterAlignedCell";
import RightAlignedCell from "../../../../../common/RightAlignedCell";
import {priceToText} from "../../../../../priceToText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf, faPrint} from "@fortawesome/free-solid-svg-icons";
import {useReactToPrint} from "react-to-print";
import React, {useEffect, useMemo, useRef, useState} from "react";
import "./ConfirmationPrintPreview.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CustomPrintSendEmail from "../../../../../common/custom-print-elements/CustomPrintSendEmail";
import {API} from "../../../../../api";
import {CustomerInquiryType} from "../../../../../fb-converters/cutomerInquiry-converter";
import I18Label, {getI18Text, getProductI18Name} from "../../../../../i18/i18label";
import appDb from "../../../../../global-state/global-db";
import {useCurrency} from "../../../../../fb-converters/currency-converter";

interface Props {
    zIndex?: number;
    confirmation: CustomerInvoiceType;
    inquiry: CustomerInquiryType;
    onDismiss: () => void;
}


export default function ConfirmationPrintPreview({zIndex, confirmation, inquiry, onDismiss}: Props) {
    const _zIndex = zIndex || 1000;
    const componentRef = useRef(null);
    let invoiceTotal = 0;
    let invoiceVat = 0;
    const invoice = confirmation;
    const [working, setWorking] = useState(false);

    const {currencyRates, loadingRates, getCurrency} = useCurrency(invoice.invoiceDate);
    const exchangeRate = useMemo(
        () => getCurrency(invoice.currency).rate,
        [invoice.invoiceDate, currencyRates]
    )

    const lng = appDb.language.value;

    const emailSubject = lng === "ro" ?
        "Confirmare a comenzii de la Industrial Batteries SRL" :
        "Потвърждение на поръчка от Индустриални батерии АД";

    const emailHTML = lng === "ro" ?
        "<div>Bună ziua,</div>" +
        "<div>&nbsp;</div>" +
        "<div>Atașat aveți documentul de confirmare a comenzii. " + getI18Text(confirmation.documentType).toLowerCase() + ".</div>" +
        "<div>&nbsp;</div>" +
        "<div>&nbsp;</div>" +
        "<div>Cu respect,</div>" +
        "<div>&nbsp;</div>" +
        "<div>" + API.user?.name + "</div>" +
        "<div>" + API.user?.email + "</div>" :
        "<div>Здравейте,</div>" +
        "<div>&nbsp;</div>" +
        "<div>Прикачен е файл с " + confirmation.documentType.toLowerCase() + ".</div>" +
        "<div>&nbsp;</div>" +
        "<div>&nbsp;</div>" +
        "<div>Поздрави,</div>" +
        "<div>&nbsp;</div>" +
        "<div>" + API.user?.name + "</div>" +
        "<div>" + API.user?.email + "</div>";

    const printContent = (
        <Container fluid ref={componentRef} className={"p-5"}>
            <div className={"d-none d-print-block m-5"}>&nbsp;</div>
            <Row className={"text-center mb-5"}>
                <h1 className={"text-uppercase"}><I18Label label={invoice.documentType} /></h1>
            </Row>
            <Row className={"text-end"}>
                <Col>
                    <h4><I18Label label={"Номер"} />:</h4>
                </Col>
                <Col xs={3} className={"text-end fw-bold"}>
                    <h4><b>{invoice.invoiceNumber.toString().padStart(10, '0')}</b></h4>
                </Col>
            </Row>
            <Row className={"text-end"}>
                <Col>
                    <h4><I18Label label={"Дата"} />:</h4>
                </Col>
                <Col xs={3} className={"text-end"}>
                    <h4><b>{dateToString(invoice.invoiceDate)}</b></h4>
                </Col>
            </Row>

            <Row className={"text-end"}>
                <Col>
                    <h4><I18Label label={"Оферта"} />:</h4>
                </Col>
                <Col xs={3} className={"text-end"}>
                    <h4 className={"text-nowrap"}>
                        <b>{`${invoice.offerNumber.toString().padStart(6, '0')} / ${dateToString(invoice.offerDate)} `}</b>
                    </h4>
                </Col>
            </Row>

            <Row className={"text-end"}>
                <Col>
                    <h4><I18Label label={"Запитване"} />:</h4>
                </Col>
                <Col xs={3} className={"text-end"}>
                    <h4 className={"text-nowrap"}>
                        <b>{`${inquiry.inquiryNumber.toString().padStart(6, '0')} / ${dateToString(inquiry.inquiryDate)} `}</b>
                    </h4>
                </Col>
            </Row>

            <Row>
                <Col className={"border border-2 border-dark rounded pt-2 m-3 ms-0"}>
                    <Table className={"no-border"}>
                        <tbody>
                        <tr>
                            <td><I18Label label={"Получател"} />:</td>
                            <td className={"fw-bold"}>{invoice.receiver.name}</td>
                        </tr>
                        <tr>
                            <td><I18Label label={"ДДС. №:"} /></td>
                            <td className={"fw-bold"}>{invoice.receiver.ddsNumber}</td>
                        </tr>
                        <tr>
                            <td><I18Label label={"Идент. №:"} /></td>
                            <td className={"fw-bold"}>{invoice.receiver.bulstat}</td>
                        </tr>
                        <tr>
                            <td><I18Label label={"Град"} />:</td>
                            <td className={"fw-bold"}>{invoice.receiver.city}</td>
                        </tr>
                        <tr>
                            <td><I18Label label={"Адрес"} />:</td>
                            <td className={"fw-bold"}>{invoice.receiver.address}</td>
                        </tr>
                        <tr>
                            <td><I18Label label={"МОЛ"} />:</td>
                            <td className={"fw-bold"}>{invoice.receiver.mol}</td>
                        </tr>
                        <tr>
                            <td><I18Label label={"Телефон"} />:</td>
                            <td className={"fw-bold"}>{invoice.receiver.phone}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col className={"border border-2 border-dark rounded pt-2 m-3 me-0"}>
                    <Table className={"no-border"}>
                        <tbody>
                        <tr>
                            <td><I18Label label={"Доставчик"} />:</td>
                            <td className={"fw-bold"}>{invoice.sender.name}</td>
                        </tr>
                        <tr>
                            <td><I18Label label={"ДДС. №:"} /></td>
                            <td className={"fw-bold"}>{invoice.sender.ddsNumber}</td>
                        </tr>
                        <tr>
                            <td><I18Label label={"Идент. №:"} /></td>
                            <td className={"fw-bold"}>{invoice.sender.bulstat}</td>
                        </tr>
                        <tr>
                            <td><I18Label label={"Град"} />:</td>
                            <td className={"fw-bold"}>{invoice.sender.city}</td>
                        </tr>
                        <tr>
                            <td><I18Label label={"Адрес"} />:</td>
                            <td className={"fw-bold"}>{invoice.sender.address}</td>
                        </tr>
                        <tr>
                            <td><I18Label label={"МОЛ"} />:</td>
                            <td className={"fw-bold"}>{invoice.sender.mol}</td>
                        </tr>
                        <tr>
                            <td><I18Label label={"Телефон"} />:</td>
                            <td className={"fw-bold"}>{invoice.sender.phone}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row className={"mt-5 mb-5"}>
                <Table>
                    <thead>
                    <tr>
                        <CenterAlignedCell header>№</CenterAlignedCell>
                        <CenterAlignedCell header><I18Label label={"Код"} /></CenterAlignedCell>
                        <CenterAlignedCell header><I18Label label={"Наименование на стоката/услугата"} /></CenterAlignedCell>
                        <CenterAlignedCell header><I18Label label={"Мярка"} /></CenterAlignedCell>
                        <RightAlignedCell header><I18Label label={"Количество"} /></RightAlignedCell>
                        <RightAlignedCell header><I18Label label={"Тегло"} /></RightAlignedCell>
                        {/*{*/}
                        {/*    invoice.documentType !== 'потвърждение на поръчка' && <>*/}
                        {/*        <RightAlignedCell header>Цена</RightAlignedCell>*/}
                        {/*        <RightAlignedCell header>ТО%</RightAlignedCell>*/}
                        {/*    </>*/}
                        {/*}*/}
                        <RightAlignedCell header><I18Label label={"Сума"} /> ({invoice.currency})</RightAlignedCell>
                    </tr>
                    </thead>
                    <tbody>
                    {invoice.items.map((item, index) => {
                            invoiceTotal += item.total;
                            invoiceVat = invoiceTotal * 0.2;

                            return (
                                <tr key={index}>
                                    <CenterAlignedCell>{index + 1}</CenterAlignedCell>
                                    <CenterAlignedCell>{item.code}</CenterAlignedCell>
                                    <td>{getProductI18Name(item.name)}</td>
                                    <CenterAlignedCell><I18Label label={item.dimension} /></CenterAlignedCell>
                                    <RightAlignedCell>{zeroToEmptyStringAsNumber(item.qty)}</RightAlignedCell>
                                    <RightAlignedCell>{zeroToEmptyStringAsNumber(item.weight)}</RightAlignedCell>
                                    {/*{*/}
                                    {/*    invoice.documentType !== 'потвърждение на поръчка' && <>*/}
                                    {/*        <RightAlignedCell>{zeroToEmptyStringAsCurrencyWithCommas(item.price)}</RightAlignedCell>*/}
                                    {/*        <RightAlignedCell>{zeroToEmptyStringAsCurrencyWithCommas(item.discount)}</RightAlignedCell>*/}
                                    {/*    </>*/}
                                    {/*}*/}
                                    <RightAlignedCell>{zeroToEmptyStringAsCurrencyWithCommas(item.total * exchangeRate)}</RightAlignedCell>
                                </tr>
                            )
                        }
                    )}
                    </tbody>
                </Table>
            </Row>

            <Row className={"text-end"}>
                <Col>
                    <h5><I18Label label={"Данъчна основа"} /> 20%:</h5>
                </Col>
                <Col xs={2} className={"text-end"}>
                    <h5>{zeroToEmptyStringAsCurrencyWithCommas(invoiceTotal * exchangeRate)} {invoice.currency}</h5>
                </Col>
            </Row>

            <Row className={"text-end"}>
                <Col>
                    <h5><I18Label label={"ДДС"} /> 20%:</h5>
                </Col>
                <Col xs={2} className={"text-end"}>
                    <h5>{zeroToEmptyStringAsCurrencyWithCommas(invoiceVat * exchangeRate)} {invoice.currency}</h5>
                </Col>
            </Row>

            <Row className={"text-end"}>
                <Col>
                    <h5><b><I18Label label={"Сума за плащане"} />:</b></h5>
                </Col>
                <Col xs={2} className={"text-end"}>
                    <h5><b>{zeroToEmptyStringAsCurrencyWithCommas((invoiceTotal + invoiceVat) * exchangeRate)} {invoice.currency}</b></h5>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h5><b><I18Label label={"Словом"} />: </b> {capitalizeFirstLetter(priceToText((invoiceTotal + invoiceVat) * exchangeRate, lng, invoice.currency))} </h5>
                </Col>
            </Row>

        </Container>
    );

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function handleClose() {
        onDismiss();
    }

    const handleExport = async () => {
        if (componentRef.current) {
            const pdf = new jsPDF("p", "pt", "a4");
            const canvas = await html2canvas(componentRef.current);
            const img = canvas.toDataURL("image/png");
            const imgProperties = pdf.getImageProperties(img);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight =
                (imgProperties.height * pdfWidth) / imgProperties.width;
            pdf.addImage(
                img,
                "PNG",
                0,
                0,
                pdfWidth - 5,
                pdfHeight,
                "page1",
                "FAST",
            );
            return pdf;
        }
    };

    const handleGerenatePdf = async () => {
        setWorking(true);
        const pdf = await handleExport();
        if(pdf) {
            pdf.save(`confirmation-${invoice.invoiceNumber}.pdf`);
        }
        setWorking(false);
    };

    const [pdfFile, setPdfFile] = useState<string | undefined>(undefined);
    useEffect(
        () => {
            const fetchData = async () => {
                const pdf = await handleExport();
                if(pdf) {
                    const content = pdf.output('arraybuffer');
                    var base64 = btoa(
                        new Uint8Array(content)
                            .reduce((data, byte) => data + String.fromCharCode(byte), ''));
                    setPdfFile(base64);
                }
            };
            fetchData();
        },
        [componentRef.current]
    );

    return (
        <ModalBackground zIndex={_zIndex}>
            <Modal show style={{zIndex: _zIndex + 1}} size={"xl"}>
                <Modal.Header>
                    <Modal.Title><I18Label label={"Печат на документ"} /></Modal.Title>
                    <CloseButton onClick={() => onDismiss()}/>
                </Modal.Header>
                <Modal.Body className={"p-5"}>
                    {printContent}
                </Modal.Body>
                <Modal.Footer>
                    {
                        working ? <Col className={"text-center"}><Spinner animation={"border"}/></Col> :
                            <>
                                <Col>
                                    <Button variant="secondary" onClick={handleClose}>
                                        <I18Label label={"Затвори"} />
                                    </Button>
                                </Col>
                                <Col>
                                    {
                                        pdfFile &&
                                        <CustomPrintSendEmail
                                            email={emailHTML}
                                            subject={emailSubject}
                                            // templateName={"email_with_attached_document"}
                                            attachments={{
                                                filename: `confirmation-${invoice.invoiceNumber}.pdf`,
                                                blob:  pdfFile,
                                            }}
                                            initialSendToEmail={inquiry.location?.email ?? confirmation.receiver.email}
                                            documentId={confirmation.id ?? ""}
                                            documentType={"customer-confirmation"}
                                        />
                                    }
                                </Col>
                                <Col xs={"auto"}>
                                    <Button onClick={handleGerenatePdf} variant={"info"} className={"text-light"}>
                                        <FontAwesomeIcon icon={faFilePdf}/> <I18Label label={"Запази като PDF"} />
                                    </Button>&nbsp;
                                    <Button variant="primary" onClick={handlePrint}>
                                        <FontAwesomeIcon icon={faPrint} className={"me-2"}/>
                                        <I18Label label={"Печат"} />
                                    </Button>
                                </Col>
                            </>
                    }
                </Modal.Footer>
            </Modal>
        </ModalBackground>
    );
}
