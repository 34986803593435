import {
    CustomerOfferProductionType,
    CustomerOfferType
} from "../../../../../../../fb-converters/cutomerOffer-converter";
import OfferProductPdfTableHeader from "./OfferProductPdfTableHeader";
import React from "react";
import OfferProductPdfTableRow from "./OfferProductPdfTableRow";
import { View } from "@react-pdf/renderer";

interface OfferPdfTableProps {
    offer: CustomerOfferType;
    productIndex: number;
    lng: string;
    i18: string[]
}

const OfferProductPdfTable = ({productIndex, offer, lng, i18}: OfferPdfTableProps) => {
    const product: CustomerOfferProductionType = offer.products[productIndex];
    let savedPrice = product.singlePrice > 0  ?(product.singlePrice - product.finalSinglePrice) * product.qty
        : 0;

    if (product.services?.length > 0) {
        product.services.forEach(service => {
            savedPrice += (service.singlePrice - service.finalPrice) * service.qty * product.qty;
        });
    }

    if (product.attributes?.length > 0) {
        product.attributes.forEach(attribute => {
            savedPrice += (attribute.singlePrice - attribute.finalPrice) * product.qty;
        });
    }

    const price = (product.singlePrice || 0);
    const discount = product.discount || 0;
    const finalPrice = price - (price * discount / 100);
    const isStarred = productIndex === offer.bestProductPriceIdx;

    return (<View wrap={false}>
        <OfferProductPdfTableHeader savedPrice={savedPrice}
                                    isStarred={isStarred}
                                    currency={offer.currency}
                                    euroExchangeRate={offer.eurExchangeRate}/>
        <OfferProductPdfTableRow productIndex={productIndex}
                                 product={product}
                                 finalPrice={finalPrice}
                                 eurExchangeRate={offer.eurExchangeRate} />
    </View>);
};

export default OfferProductPdfTable;
