import {CurrencyIndexType} from "./CurrencyIndexType";
import {DocumentReference} from "firebase/firestore";

export type GlobalStateType = {
    id?: string;
    ref?: DocumentReference;
    currencyIndex: CurrencyIndexType;
    ecoTaxPerKgPrice: number;
    customerInquiryNumber: number;
    customerInvoiceNumber: number;
    customerInvoiceNumberIb: number;
    customerInvoiceNumberEnergia: number;
    productsNumber: number;
    customerProformaInvoiceNumber: number;
    customerOrderConfirmationNumber: number;
    productionCardNumber: number;
    productionCardNumberEnergia: number;
    warehouseRequestsNumber: number;
};

export const globalStateDefault: GlobalStateType = {
    currencyIndex: {
        EUR: 0,
        LEU: 0,
    },
    ecoTaxPerKgPrice: 0,
    customerInquiryNumber: 0,
    customerInvoiceNumber: 0,
    customerInvoiceNumberIb: 0,
    customerInvoiceNumberEnergia: 0,
    productsNumber: 0,
    customerProformaInvoiceNumber: 0,
    customerOrderConfirmationNumber: 0,
    productionCardNumber: 0,
    productionCardNumberEnergia: 0,
    warehouseRequestsNumber: 0,
}
