// function convertPriceToText(price, lng) {
//     let lv = "eur";
//     let st = "cents";
//     let and = "and";
//     let _thousands = "thousands";
//
//     // Речник с текстовете за числата от 0 до 19
//     var numbers = [
//         "нула",
//         "едно",
//         "две",
//         "три",
//         "четири",
//         "пет",
//         "шест",
//         "седем",
//         "осем",
//         "девет",
//         "десет",
//         "единадесет",
//         "дванадесет",
//         "тринадесет",
//         "четиринадесет",
//         "петнадесет",
//         "шестнадесет",
//         "седемнадесет",
//         "осемнадесет",
//         "деветнадесет"
//     ];
//
//     // Речник с текстовете за десетиците
//     var tens = [
//         "",
//         "",
//         "двадесет",
//         "тридесет",
//         "четиридесет",
//         "петдесет",
//         "шестдесет",
//         "седемдесет",
//         "осемдесет",
//         "деветдесет"
//     ];
//
//     // Речник с текстовете за стотиците
//     var hundreds = [
//         "",
//         "сто",
//         "двеста",
//         "триста",
//         "четиристотин",
//         "петстотин",
//         "шестстотин",
//         "седемстотин",
//         "осемстотин",
//         "деветстотин"
//     ];
//
//     // Речник с текстовете за хилядите
//     var thousands = [
//         "",
//         "хиляда",
//         "две хиляди",
//         "три хиляди",
//         "четири хиляди",
//         "пет хиляди",
//         "шест хиляди",
//         "седем хиляди",
//         "осем хиляди",
//         "девет хиляди"
//     ];
//
//     // Речник с текстовете за милионите
//     var millions = [
//         "",
//         "един милион",
//         "два милиона",
//         "три милиона",
//         "четири милиона",
//         "пет милиона",
//         "шест милиона",
//         "седем милиона",
//         "осем милиона",
//         "девет милиона"
//     ];
//
//
//     switch (lng) {
//         case "bg":
//             lv = "лева";
//             st = "стотинки";
//             and = "и";
//             _thousands = "хиляди";
//             break;
//         case "ro":
//             lv = "lei";
//             st = "bani";
//             and = "și";
//             _thousands = "mii";
//             break;
//     }
//
//
//     // Проверка за нула
//     if (price === 0) {
//         return numbers[0];
//     }
//
//     // Преобразуване на числото в текст
//     var result = "";
//
//     // Разделяне на числото на отделни части
//     var integerPart = Math.floor(price);
//     var fractionalPart = Math.round((price - integerPart) * 100);
//
//     // Обработка на цялата част
//     if (integerPart >= 1000000) {
//         var millionsPart = Math.floor(integerPart / 1000000);
//         integerPart %= 1000000;
//         result += millions[millionsPart] + " ";
//     }
//
//     if (integerPart >= 1000) {
//         var thousandsPart = Math.floor(integerPart / 1000);
//         integerPart %= 1000;
//         if(thousandsPart > 9) {
//             result += convertPriceToText(thousandsPart) + " " + _thousands + " ";
//         } else {
//             result += thousands[thousandsPart] + " ";
//         }
//     }
//
//     if (integerPart >= 100) {
//         var hundredsPart = Math.floor(integerPart / 100);
//         integerPart %= 100;
//         result += hundreds[hundredsPart] + " ";
//     }
//
//     if (integerPart >= 20) {
//         var tensPart = Math.floor(integerPart / 10);
//         integerPart %= 10;
//         result += tens[tensPart] + " ";
//     }
//
//     if (integerPart > 0) {
//         result += (result ? " " + and + " " : "") + numbers[integerPart] + " ";
//     }
//
//     // Обработка на дробната част
//     if (fractionalPart > 0) {
//         result += lv + " " + and + " " + fractionalPart + " " + st;
//     }
//
//     return result.trim();
// }

// export function priceToText(price) {
//   return convertPriceToText(price).trim();
// }

import {BGN_CURRENCY_NAME, EUR_CURRENCY_NAME, LEU_CURRENCY_NAME} from "./fb-converters/currency-converter";

const numberToWords = (num, lang) => {
    const ones = {
        bg: ["", "едно", "две", "три", "четири", "пет", "шест", "седем", "осем", "девет"],
        ro: ["", "unu", "doi", "trei", "patru", "cinci", "șase", "șapte", "opt", "nouă"],
        en: ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"]
    };

    const tens = {
        bg: ["", "десет", "двадесет", "тридесет", "четиридесет", "петдесет", "шестдесет", "седемдесет", "осемдесет", "деветдесет"],
        ro: ["", "zece", "douăzeci", "treizeci", "patruzeci", "cincizeci", "șaizeci", "șaptezeci", "optzeci", "nouăzeci"],
        en: ["", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"]
    };

    const teens = {
        bg: ["десет", "единадесет", "дванадесет", "тринадесет", "четиринадесет", "петнадесет", "шестнадесет", "седемнадесет", "осемнадесет", "деветнадесет"],
        ro: ["zece", "unsprezece", "doisprezece", "treisprezece", "paisprezece", "cincisprezece", "șaisprezece", "șaptesprezece", "optsprezece", "nouăsprezece"],
        en: ["ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"]
    };

    const thousands = {
        bg: ["", "хиляда", "хиляди", "хиляди"],
        ro: ["", "mie", "mii", "mii"],
        en: ["", "thousand", "thousand", "thousand"]
    };

    if (num < 10) return ones[lang][num];
    if (num < 20) return teens[lang][num - 10];
    if (num < 100) return tens[lang][Math.floor(num / 10)] + (num % 10 !== 0 ? " " + ones[lang][num % 10] : "");
    if (num < 1000) return ones[lang][Math.floor(num / 100)] + (lang === "bg" ? " стотин " : " ") + (num % 100 !== 0 ? numberToWords(num % 100, lang) : "");
    if (num < 1000000) return numberToWords(Math.floor(num / 1000), lang) + " " + thousands[lang][num < 2000 ? 1 : 2] + (num % 1000 !== 0 ? " " + numberToWords(num % 1000, lang) : "");

    return "Number too large";
};

const convertAmountToWords = (amount, lang, currency) => {
    let euros = Math.floor(amount);
    let cents = Math.round((amount - euros) * 100);

    let moneyText = "";
    let oneText = "";
    let centsText = "";
    let oneCentText = "";
    if(lang === "bg") {
        switch (currency) {
            case BGN_CURRENCY_NAME:
                oneText = "лев";
                moneyText = "лева";
                centsText = "ст.";
                oneCentText = "ст.";
                break;
            case EUR_CURRENCY_NAME:
                oneText = "евро";
                moneyText = "евро";
                centsText = "цента";
                oneCentText = "цент";
                break;
            case LEU_CURRENCY_NAME:
                oneText = "лея";
                moneyText = "леи";
                centsText = "банути";
                oneCentText = "банут";
                break;
            default:
                oneText = "лев";
                moneyText = "лева";
                centsText = "ст.";
                oneCentText = "ст.";
        }
    }

    if(lang === "ro") {
        switch (currency) {
            case BGN_CURRENCY_NAME:
                oneText = "lev";
                moneyText = "levi";
                centsText = "st.";
                oneCentText = "st.";
                break;
            case EUR_CURRENCY_NAME:
                oneText = "euro";
                moneyText = "euro";
                centsText = "centi";
                oneCentText = "cent";
                break;
            case LEU_CURRENCY_NAME:
                oneText = "leu";
                moneyText = "lei";
                centsText = "bani";
                oneCentText = "ban";
                break;
            default:
                oneText = "leu";
                moneyText = "lei";
                centsText = "bani";
                oneCentText = "ban";
        }
    }

    let euroText = numberToWords(euros, lang) + " " + (euros === 1 ? oneText : moneyText);
    let centText = cents > 0 ?
        numberToWords(cents, lang) + " " + (cents === 1 ? oneCentText : centsText) : "";

    let conjunction = { bg: " и ", ro: " si ", en: " and " }[lang];

    return euroText + (cents > 0 ? conjunction + centText : "");
};

export function priceToText(price, lang, currency) {
  return convertAmountToWords(price, lang, currency).trim();
}
