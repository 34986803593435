import {CustomerInvoiceType} from "../../../fb-converters/cutomerInvoice-converter";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, orderBy, query, where} from "firebase/firestore";
import {fbDb} from "../../../App";
import {Modal, Spinner, Table} from "react-bootstrap";
import {dateTimeToString, dateToString} from "../../../common";
import I18Label from "../../../i18/i18label";
import DialogCloseButton from "../../../common/DialogCloseButton";
import ModalBackground from "../../../common/ModalBackground";
import ViewIconButton from "../../../common/icon-buttons/ViewIconButton";
import {useState} from "react";
import {customerOfferConverter, CustomerOfferType} from "../../../fb-converters/cutomerOffer-converter";
import {CustomerOfferDetails} from "./details/CustomerOfferDetails";
import {CustomerInquiryType} from "../../../fb-converters/cutomerInquiry-converter";

interface CustomerOfferHistoryProps {
    zIndex?: number;
    inquiry: CustomerInquiryType;
    offer: CustomerOfferType;
    onClose: () => void;
}

const CustomerOfferHistory = ({zIndex = 1200, inquiry, offer, onClose}: CustomerOfferHistoryProps) => {
    const [documentToView, setDocumentToView] = useState<CustomerOfferType | null>(null);
    const [offers, loading, error] = useCollectionData(
        query(
            collection(fbDb, "customerOffersHistory"),
            where("inquiryNumber", "==", offer.inquiryNumber),
            where("offerNumber", "==", offer.offerNumber),
            orderBy("createdAt", "desc")
        ).withConverter(customerOfferConverter)
    );

    if (error) {
        console.error(error);
    }

    return (
        <>{
              !documentToView &&
              <ModalBackground zIndex={zIndex}>
                  <Modal show={!documentToView} size={"lg"} style={{zIndex: zIndex + 1}}>
                      <Modal.Header>
                          <Modal.Title>
                            <span className={"text-uppercase"}> <I18Label
                                label={"История за оферта"}/> #{offer.offerNumber} </span>
                              <span
                                  className={"ms-3 text-primary"}><I18Label
                                  label={"към запитване"}/>: {offer.inquiryNumber} / {dateToString(offer.inquiryDate)}</span>
                          </Modal.Title>
                          <DialogCloseButton onClose={onClose}/>
                      </Modal.Header>

                      <Modal.Body>
                          <div>
                              {loading && <div className={"mt-5 text-center"}><Spinner animation={"border"}/></div>}
                              {error && <div className={"mt-5 text-center text-danger fw-bold"}>
                                  {error.message}
                              </div>}
                              {offers && offers.length === 0 && <div className={"m-5 text-center text-danger fw-bold"}>
                                  <I18Label label={"Няма намерени записи"}/>
                              </div>}
                              <Table size={"sm"} striped bordered hover responsive>
                                  <thead>
                                  <tr>
                                      <th>Дата</th>
                                      <th>Автор</th>
                                      <th></th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {offers && offers.map((offer: CustomerOfferType) => (
                                      <tr>
                                          <td>{dateTimeToString(offer.createdAt)}</td>
                                          <td>{offer.changerName}</td>
                                          <td className={"text-end w-50px"}>
                                              <ViewIconButton size={"sm"} onClick={() => {
                                                  setDocumentToView(offer);
                                              }}/>
                                          </td>
                                      </tr>
                                  ))}
                                  </tbody>
                              </Table>
                          </div>
                      </Modal.Body>
                      <Modal.Footer/>
                  </Modal>
              </ModalBackground>
          }
            {
                documentToView &&
                <CustomerOfferDetails
                    zIndex={zIndex + 10}
                    inquiry={inquiry}
                    offer={documentToView}
                    onDone={
                        () => setDocumentToView(null)
                    }
                    disabled={true}
                />
            }
        </>
    )
}

export default CustomerOfferHistory;
