import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, orderBy, query, where} from "firebase/firestore";
import {fbDb} from "../../../App";
import {Modal, Spinner, Table} from "react-bootstrap";
import {dateTimeToString, dateToString} from "../../../common";
import I18Label from "../../../i18/i18label";
import DialogCloseButton from "../../../common/DialogCloseButton";
import ModalBackground from "../../../common/ModalBackground";
import ViewIconButton from "../../../common/icon-buttons/ViewIconButton";
import {useState} from "react";
import {productionCardConverter, ProductionCardType} from "../types/ProductionCardType";
import {ProductionPlanHistoryDetails} from "./ProductionPlanHistoryDetails";

interface ProductionPlanHistoryProps {
    zIndex?: number;
    inquiryNumber: number;
    onClose: () => void;
}

const ProductionPlanHistory = ({zIndex = 1200, inquiryNumber, onClose}: ProductionPlanHistoryProps) => {
    const [documentToView, setDocumentToView] = useState<ProductionCardType | null>(null);
    const [cards, loading, error] = useCollectionData(
        query(
            collection(fbDb, "productionCardsHistory"),
            where("inquiryNumber", "==", inquiryNumber),
            orderBy("createdAt", "desc")
        ).withConverter(productionCardConverter)
    );

    if (error) {
        console.error(error);
    }

    if (documentToView) return (
        <ProductionPlanHistoryDetails card={documentToView}
                                      zIndex={zIndex + 100}
                                      onDone={() => setDocumentToView(null)}
        />);

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={true} size={"lg"} style={{zIndex: zIndex + 1}}>
                <Modal.Header>
                    <Modal.Title>
                        <span className={"text-uppercase"}> <I18Label
                            label={"История на производствените карти за запитване "}/> #{inquiryNumber} </span>
                    </Modal.Title>
                    <DialogCloseButton onClose={onClose}/>
                </Modal.Header>

                <Modal.Body>

                    <div>
                        {loading && <div className={"mt-5 text-center"}><Spinner animation={"border"}/></div>}
                        {error && <div className={"mt-5 text-center text-danger fw-bold"}>
                            {error.message}
                        </div>}
                        {cards && cards.length === 0 && <div className={"m-5 text-center text-danger fw-bold"}>
                            <I18Label label={"Няма намерени записи"}/>
                        </div>}
                        <Table size={"sm"} striped bordered hover responsive>
                            <thead>
                            <tr>
                                <th>Дата</th>
                                <th>Автор</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {cards && cards.map((card: ProductionCardType) => (
                                <tr>
                                    <td>{dateTimeToString(card.createdAt)}</td>
                                    <td>{card.changerName}</td>
                                    <td className={"text-end w-50px"}>
                                        <ViewIconButton size={"sm"} onClick={() => {
                                            setDocumentToView(card);
                                        }}/>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer/>
            </Modal>
        </ModalBackground>
    )
}

export default ProductionPlanHistory;
