import {Form} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import React, {FC, useEffect, useState} from "react";
import FilterIconButton from "../../../common/icon-buttons/FilterIconButton";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";
import {dateToString} from "../../../common";
import ProductionPlanDateEditCell from "./ProductionPlanDateEditCell";


export type ProductionPlanFilterType = {
    cardNumber: string;
    inquiryNumber: string;
    clientName: string;
    clientId: string;
    regarding: string;
    productionPlace: string;
    deadlineDate?: Date;
    productionDate?: Date;
    expeditionDate?: Date;
    planExpeditionDate?: Date;
    planProductionDate?: Date;
}

export const initialProductionPlanFilter: ProductionPlanFilterType = {
    cardNumber: "",
    inquiryNumber: "",
    clientName: "",
    clientId: "",
    regarding: "",
    productionPlace: "",
}

interface ProductionPlanFilterProps {
    hasSelect: boolean;
    filter: ProductionPlanFilterType;
    setFilter: (filter: ProductionPlanFilterType) => void;
}

const ProductionPlanFilter: FC<ProductionPlanFilterProps> = ({hasSelect, filter, setFilter}) => {
    const _clients = useHookstate(appDb.clients);
    const clients = _clients.get().map(client => ({...client})).sort((a, b) => a.name.localeCompare(b.name));

    const [newFilter, setNewFilter] = useState({...filter});
    useEffect(
        () => {
            setNewFilter({...filter})
        }, [filter]
    );

    return (
        <tr>
            {
                hasSelect &&
                <td />
            }
            <td/>
            <td>
                <Form.Control type="text" size={"sm"}
                              onChange={(e) => setNewFilter({...newFilter, cardNumber: e.target.value})}
                              value={newFilter.cardNumber}
                />
            </td>
            <td>
                <Form.Control type="text" size={"sm"}
                              onChange={(e) => setNewFilter({...newFilter, inquiryNumber: e.target.value})}
                              value={newFilter.inquiryNumber}
                />
            </td>
            <td>
                <Form.Select size={"sm"}
                             onChange={(e) => {
                                 setNewFilter({...newFilter, clientId: e.target.value})
                             }}
                             value={newFilter.clientId}>
                    <option value="-1"></option>
                    {
                        clients.map(client => (
                            <option key={client.id} value={client.id}>{client.name}</option>
                        ))
                    }
                </Form.Select>
            </td>
            {/*<td>*/}
            {/*    <Form.Select size={"sm"} onChange={*/}
            {/*        (e) => {*/}
            {/*            setNewFilter({...newFilter, regarding: e.target.value})*/}
            {/*        }*/}
            {/*    } value={newFilter.regarding}>*/}
            {/*        <option value={""}></option>*/}
            {/*        <option value={"Доставка"}>Доставка</option>*/}
            {/*        <option value={"Ремонт"}>Ремонт</option>*/}
            {/*        <option value={"Производство"}>Производство</option>*/}
            {/*        <option value={"Продажба"}>Продажба</option>*/}
            {/*    </Form.Select>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*    <Form.Select size={"sm"}*/}
            {/*                 onChange={(e) => {*/}
            {/*                     setNewFilter({...newFilter, productionPlace: e.target.value})*/}
            {/*                 }}*/}
            {/*                 value={newFilter.productionPlace}>*/}
            {/*        <option value={""}></option>*/}
            {/*        <option value={"В сервиза"}>В сервиза</option>*/}
            {/*        <option value={"При клиент"}>При клиент</option>*/}
            {/*        <option value={"От доставчик"}>От доставчик</option>*/}
            {/*    </Form.Select>*/}
            {/*</td>*/}
            <ProductionPlanDateEditCell setDate={(date) => setNewFilter({...newFilter, deadlineDate: date})}
                                        date={newFilter.deadlineDate}/>
            <ProductionPlanDateEditCell setDate={(date) => setNewFilter({...newFilter, planProductionDate: date})}
                                        date={newFilter.planProductionDate}/>
            <ProductionPlanDateEditCell date={newFilter.planExpeditionDate}
                                        setDate={(date) => setNewFilter({...newFilter, planExpeditionDate: date})}/>
            <ProductionPlanDateEditCell setDate={(date) => setNewFilter({...newFilter, productionDate: date})}
                                        date={newFilter.productionDate}/>
            <td/>
            <td></td>
            <td></td>
            <td className={"text-center"}>
                <FilterIconButton onClick={() => {
                    newFilter.clientName = clients.find(client => client.id === newFilter.clientId)?.name || "";
                    setFilter(newFilter);
                }}
                                  title={"Филтрирай"}/>
            </td>
        </tr>
    );
}

export default ProductionPlanFilter;
