import {Button, Card, Col, Container, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import React, {useEffect, useMemo, useState} from "react";
import CRMSummaryReportFilter, {CRMSummaryReportFilterType} from "./CRMSummaryReportFilter";
import {fbDb} from "../../../../App";
import {
    collection,
    doc, getDoc,
    getDocs,
    query,
    QueryConstraint,
    updateDoc,
    where,
    writeBatch
} from "firebase/firestore";
import {customerInquiryConverter, CustomerInquiryType} from "../../../../fb-converters/cutomerInquiry-converter";
import {
    customerInvoiceConverter,
    CustomerInvoiceItemType,
    CustomerInvoiceType
} from "../../../../fb-converters/cutomerInvoice-converter";
import {customerOfferConverter, CustomerOfferType} from "../../../../fb-converters/cutomerOffer-converter";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {dateToString} from "../../../../common";
import I18Label from "../../../../i18/i18label";
import {productionCardConverter, ProductionCardType} from "../../../production/types/ProductionCardType";
import NotesIconButton from "../../../../common/icon-buttons/NotesIconButton";
import SaveIconButton from "../../../../common/icon-buttons/SaveIconButton";
import {expeditionConverter} from "../../../../fb-converters/expedition-converter";
import CustomerInquiryDetails from "../../customers-inquiries/CustomerInquiryDetails";
import appDb from "../../../../global-state/global-db";
import {useHookstate} from "@hookstate/core";
import useOwnCompaniesRepo from "../../../warehouse/hooks/useOwnCompaniesRepo";
import {CustomerOfferDetails} from "../../customers-offers/details/CustomerOfferDetails";
import ModalBackground from "../../../../common/ModalBackground";
import CustomerInvoiceDetails from "../../customers-invoices/CustomerInvoiceDetails";
import ProductionPlanDetailsPopup from "../../../production/production-plan/ProductionPlanDetailsPopup";
import * as ExcelJS from "exceljs";
import ExportExcelIconButton from "../../../../common/icon-buttons/ExportExcelIconButton";
import {BGN_CURRENCY_NAME, EUR_CURRENCY_NAME, LEU_CURRENCY_NAME} from "../../../../fb-converters/currency-converter";

type reportItemType = {
    comments: string | null;
    randId: string;
    clientId: string;
    clientName: string;

    inquiryId: string;
    inquiryNumber: string;
    inquiryDate: string;

    offerId: string;
    offerNumber: string;
    offerDate: string;
    offerAuthor: string;

    confirmationId: string;
    confirmationNumber: string;
    confirmationDate: string;

    proformaId: string[];
    proformaNumber: string[];
    proformaDate: string[];
    proformaTotal: number[];
    proformaCurrency: string[];

    invoiceId: string[];
    invoiceNumber: string[];
    invoiceDate: string[];
    invoiceTotal: number[];
    invoiceCurrency: string[];

    productionCardId: string[];
    productionCardNumber: string[];
    productionCardDate: string[];
    productionCardStatus: string[];
}

export default function CRMSummaryReportPage() {
    const {ownCompanies} = useOwnCompaniesRepo();

    const [filter, setFilter] = useState<CRMSummaryReportFilterType | null>(null);
    const [editInquiry, setEditInquiry] = useState<CustomerInquiryType | undefined>(undefined);
    const [editOffer, setEditOffer] = useState<CustomerOfferType | undefined>(undefined);
    const [editOfferInquiry, setEditOfferInquiry] = useState<CustomerInquiryType | undefined>(undefined);
    const [editInquiryId, setEditInquiryId] = useState<string | undefined>(undefined);
    const [editInquiryComments, setEditInquiryComments] = useState<string | undefined>(undefined);
    const [editInvoice, setEditInvoice] = useState<CustomerInvoiceType | undefined>(undefined);
    const [editInvoiceInquiry, setEditInvoiceInquiry] = useState<CustomerInquiryType | undefined>(undefined);
    const [editInvoiceOffer, setEditInvoiceOffer] = useState<CustomerOfferType | undefined>(undefined);
    const [editProductionCardItem, setEditProductionCardItem] = useState<CustomerInvoiceItemType | undefined>(undefined);
    const [editProductionCardInquiry, setEditProductionCardInquiry] = useState<CustomerInquiryType | undefined>(undefined);
    const [editProductionCardInvoice, setEditProductionCardInvoice] = useState<CustomerInvoiceType | undefined>(undefined);

    const filterControl = useMemo(
        () => <CRMSummaryReportFilter onChange={setFilter}/>,
        []
    );

    const invoiceQuery = useMemo(
        () => {
            const hasFilter = filter !== null && filter.fromDate && filter.toDate;
            if (!hasFilter) return null;
            const invoicesCollection = collection(fbDb, 'customerInvoices').withConverter(customerInvoiceConverter);
            const constraints: QueryConstraint[] = [];
            if (filter.fromDate) {
                constraints.push(where('inquiryDate', '>=', filter.fromDate));
            }
            if (filter.toDate) {
                constraints.push(where('inquiryDate', '<=', filter.toDate));
            }
            if (filter.client) {
                constraints.push(where('receiver.clientId', '==', filter.client.id));
            }

            return query(invoicesCollection, ...constraints);
        },
        [filter]
    );

    const inquiryQuery = useMemo(
        () => {
            const hasFilter = filter !== null && filter.fromDate && filter.toDate;
            if (!hasFilter) return null;
            const inquiriesCollection = collection(fbDb, 'customerInquiries').withConverter(customerInquiryConverter);
            const constraints: QueryConstraint[] = [];
            if (filter.fromDate) {
                constraints.push(where('inquiryDate', '>=', filter.fromDate));
            }
            if (filter.toDate) {
                constraints.push(where('inquiryDate', '<=', filter.toDate));
            }
            if (filter.client) {
                constraints.push(where('client.id', '==', filter.client.id));
            }

            return query(inquiriesCollection, ...constraints);
        },
        [filter]
    );


    const productionCardQuery = useMemo(
        () => {
            const hasFilter = filter !== null && filter.fromDate && filter.toDate;
            if (!hasFilter) return null;
            const productionCardCollection = collection(fbDb, 'productionCards').withConverter(productionCardConverter);
            const constraints: QueryConstraint[] = [];
            if (filter.fromDate) {
                constraints.push(where('inquiryDate', '>=', filter.fromDate));
            }
            if (filter.toDate) {
                constraints.push(where('inquiryDate', '<=', filter.toDate));
            }

            return query(productionCardCollection, ...constraints);
        },
        [filter]
    );

    const expeditionQuery = useMemo(
        () => {
            const hasFilter = filter !== null && filter.fromDate && filter.toDate;
            if (!hasFilter) return null;
            const expeditionCollection = collection(fbDb, 'expedition').withConverter(expeditionConverter);
            const constraints: QueryConstraint[] = [];
            constraints.push(where('status', '==', 'ПРИКЛЮЧЕНА'));
            if (filter.fromDate) {
                constraints.push(where('inquiry.inquiryDate', '>=', filter.fromDate));
            }
            if (filter.toDate) {
                constraints.push(where('inquiry.inquiryDate', '<=', filter.toDate));
            }

            return query(expeditionCollection, ...constraints);
        },
        [filter]
    );

    const [inquiryData, inquiryLoading, inquiryError] = useCollectionData<CustomerInquiryType>(inquiryQuery);
    const [invoiceData, invoiceLoading, invoiceError] = useCollectionData<CustomerInvoiceType>(invoiceQuery);
    const [productionCardData, productionCardLoading, productionCardError] = useCollectionData(productionCardQuery);
    const [expeditionData, expeditionLoading, expeditionError] = useCollectionData(expeditionQuery);

    const updateInquiryOffers = async (inquiryId: string) => {
        const inquiryAllOffersData = await getDocs(
            query(
                collection(fbDb, `customerInquiries/${inquiryId}/offers`).withConverter(customerOfferConverter)
            )
        );
        const inquiryAllOffers = inquiryAllOffersData.docs.map(d => d.data());

        await updateDoc(
            doc(fbDb, `customerInquiries/${inquiryId}`),
            {
                offers: inquiryAllOffers.map(o => ({
                    offerId: o.id,
                    offerDate: o.offerDate,
                    offerNumber: o.offerNumber
                }))
            }
        );
    }

    useEffect(() => {
        if (invoiceLoading || inquiryError) return;

        const batch = writeBatch(fbDb);
        inquiryData?.map(
            i => {
                if ((!i.offers || i.offers.length === 0) && i.id) {
                    updateInquiryOffers(i.id);
                }
            }
        );

    }, [inquiryData]);

    const reportData = useMemo(() => {
        if (inquiryData === undefined ||
            invoiceData === undefined ||
            productionCardData === undefined ||
            expeditionData === undefined
        ) return null;

        const result: reportItemType[] = [];
        for (const i of inquiryData) {
            const inquiryId = i.id ?? "";
            const inquiryNumber = i.inquiryNumber?.toString().padStart(6, "0") ?? "";
            const inquiryDate = dateToString(i.inquiryDate) ?? "";
            const clientId = i.client?.id ?? "";
            const clientName = i.client?.name ?? "";

            for (const o of i.offers) {
                const offerId = o.offerId ?? "";
                const offerNumber = o.offerNumber?.toString().padStart(6, "0") ?? "";
                const offerDate = dateToString(o.offerDate) ?? "";
                const offerAuthor = o.authorName ?? "";

                const confirmation = invoiceData.find(i => i.offerId === offerId && i.documentType === "потвърждение на поръчка");

                const confirmationId = confirmation?.id ?? "";
                const confirmationNumber = confirmation?.invoiceNumber?.toString().padStart(6, "0") ?? "";
                const confirmationDate = dateToString(confirmation?.invoiceDate) ?? "";

                const proforma = invoiceData.filter(i => i.offerId === offerId && i.documentType === "проформа фактура");
                const proformaId = proforma.map(p => p.id ?? "");
                const proformaNumber = proforma.map(p => p.invoiceNumber?.toString().padStart(6, "0") ?? "");
                const proformaDate = proforma.map(p => dateToString(p.invoiceDate) ?? "");
                const proformaTotal = proforma.map(
                    p =>
                        p.items.reduce((acc, val) =>
                            acc + parseFloat((val.total * p.currencyRate * (1 + p.dds / 100)).toFixed(2)), 0)
                );
                const proformaCurrency = proforma.map(p => p.currency ?? EUR_CURRENCY_NAME);

                const invoice = invoiceData.filter(i => i.offerId === offerId && i.documentType === "фактура");
                const invoiceId = invoice.map(p => p.id ?? "");
                const invoiceNumber = invoice.map(p => p.invoiceNumber?.toString().padStart(6, "0") ?? "");
                const invoiceDate = invoice.map(p => dateToString(p.invoiceDate) ?? "");
                const invoiceTotal = invoice.map(
                    p =>
                        p.items.reduce((acc, val) =>
                            acc + parseFloat((val.total * p.currencyRate * (1 + p.dds / 100)).toFixed(2)), 0)
                );
                const invoiceCurrency = invoice.map(p => p.currency ?? EUR_CURRENCY_NAME);

                const productionCard = productionCardData.filter(i => i.inquiryId === inquiryId && i.offerId === offerId);
                const productionCardId = productionCard.map(p => p.id ?? "");
                const productionCardNumber = productionCard.map(p => p.cardNumber?.toString().padStart(6, "0") ?? "");
                const productionCardDate = productionCard.map(p => dateToString(p.cardDate) ?? "");
                const productionCardStatus = productionCard.map(p => p.productionStatus ?? "");

                result.push({
                    randId: Math.random().toString(),
                    clientId,
                    clientName,
                    inquiryId,
                    inquiryNumber,
                    inquiryDate,
                    offerId,
                    offerNumber,
                    offerDate,
                    offerAuthor,
                    confirmationId,
                    confirmationNumber,
                    confirmationDate,
                    proformaId,
                    proformaNumber,
                    proformaDate,
                    proformaTotal,
                    proformaCurrency,
                    invoiceId,
                    invoiceNumber,
                    invoiceDate,
                    invoiceTotal,
                    invoiceCurrency,
                    productionCardId,
                    productionCardNumber,
                    productionCardDate,
                    productionCardStatus,
                    comments: i.comments
                });
            }
        }
        return result;
    }, [inquiryData, invoiceData, productionCardData, expeditionData]);

    async function saveInquiryComments(inquiryId: string, editInquiryComments: string | undefined) {
        await updateDoc(
            doc(fbDb, `customerInquiries/${inquiryId}`),
            {
                comments: editInquiryComments
            }
        );
    }

    function showInquiryDetails(inquiryId: string) {
        const inquiry = inquiryData?.find(i => i.id === inquiryId);
        if (inquiry) {
            setEditInquiry(inquiry);
        }
    }

    async function showOfferDetails(inquiryId: string, offerId: string) {
        const inquiry = inquiryData?.find(i => i.id === inquiryId);
        if (!inquiry) return;

        const offer = await getDoc(
            doc(fbDb, `customerInquiries/${inquiryId}/offers/${offerId}`).withConverter(customerOfferConverter)
        );

        if (offer.exists()) {
            setEditOffer(offer.data());
            setEditOfferInquiry(inquiry);
        }
    }

    async function showInvoiceDetails(inquiryId: string, offerId: string, invoiceId: string) {
        const inquiry = inquiryData?.find(i => i.id === inquiryId);
        if (!inquiry) return;

        const offer = await getDoc(
            doc(fbDb, `customerInquiries/${inquiryId}/offers/${offerId}`).withConverter(customerOfferConverter)
        );

        if (offer.exists()) {
            setEditInvoiceOffer(offer.data());
        }

        const invoice = await getDoc(
            doc(fbDb, `customerInvoices/${invoiceId}`).withConverter(customerInvoiceConverter)
        );

        if (invoice.exists()) {
            setEditInvoice(invoice.data());
            setEditInvoiceInquiry(inquiry);
        }
    }

    async function showProductionCardDetails(productionCardId: string) {
        const productionCard = await getDoc(
            doc(fbDb, `productionCards/${productionCardId}`).withConverter(productionCardConverter)
        );

        if (!productionCard.exists()) return;

        const inquiryId = productionCard.data()?.inquiryId;
        if (!inquiryId) return;

        const inquiry = inquiryData?.find(i => i.id === inquiryId);
        if (!inquiry) return;

        const offer = await getDoc(
            doc(fbDb, `customerInquiries/${inquiryId}/offers/${productionCard.data()?.offerId}`).withConverter(customerOfferConverter)
        );
        if (!offer.exists()) return;

        const cardProductId = productionCard.data()?.productId;
        if (!cardProductId) return;

        const invoiceId = productionCard.data()?.invoiceId;
        if (!invoiceId) return;

        const invoice = invoiceData?.find(i => i.id === invoiceId);
        if (!invoice) return;

        const product = invoice.items.find(i => i.productId === cardProductId);
        if (!product) return;

        setEditProductionCardItem(product);
        setEditProductionCardInquiry(inquiry);
        setEditProductionCardInvoice(invoice);
        setEditOffer(offer.data());
    }


    async function exportDataToExcel() {
        if (!filter) return;

        const headerStyle = {
            font: {bold: true, size: 16},
            border: {
                top: {style: "medium"},
                left: {style: "medium"},
                bottom: {style: "medium"},
                right: {style: "medium"}
            },
            alignment: {vertical: "middle", horizontal: "center"} as ExcelJS.Alignment,
        } as ExcelJS.Style;

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('СПРАВКА', {
            views: [{showGridLines: false}],
        });
        worksheet.addRow([`ОБОБЩЕНА СПРАВКА за период: ${dateToString(filter?.fromDate)} - ${dateToString(filter?.toDate)}`]);
        worksheet.mergeCells("A1:P1");
        worksheet.getRow(1).eachCell((cell) => {
            cell.style = headerStyle;
        });
        worksheet.addRow([]);
        worksheet.addRow(["Клиент", "Запитване", "", "Оферта", "", "", "Потв.поръчка", "", "Карта за възлагане", "", "", "", "Проф.фактура", "", "Фактура", ""]);
        worksheet.mergeCells("B3:C3");
        worksheet.mergeCells("D3:F3");
        worksheet.mergeCells("G3:H3");
        worksheet.mergeCells("I3:L3");
        worksheet.mergeCells("M3:N3");
        worksheet.mergeCells("O3:P3");
        worksheet.getRow(3).eachCell((cell) => {
            cell.style = {...headerStyle, font: {bold: true, size: 12}};
        });


        for (const item of reportData ?? []) {
            const row = [
                item.clientName ?? "",
                item.inquiryNumber ?? "",
                item.inquiryDate ?? "",
                item.offerNumber ?? "",
                item.offerDate ?? "",
                item.offerAuthor ?? "",
                item.confirmationNumber ?? "",
                item.confirmationDate ?? "",
                item.productionCardNumber[0] ?? "",
                item.productionCardDate[0] ?? "",
                item.productionCardStatus[0] ?? "",
                expeditionData?.filter(e => e.cardId === item.productionCardId[0])
                    .map(e => `${dateToString(e.expeditionDate)} - ${e.client?.name}`).join("\n") ?? "",
                item.proformaNumber[0] ?? "",
                item.proformaDate[0] ?? "",
                item.invoiceNumber[0] ?? "",
                item.invoiceDate[0] ?? "",
            ];

            const worksheetRow = worksheet.addRow(row);
            worksheetRow.eachCell((cell) => {
                cell.style = {
                    border: {
                        top: {style: "thin"},
                        left: {style: "thin"},
                        bottom: {style: "thin"},
                        right: {style: "thin"}
                    }
                };
            });

            if (item.productionCardId.length > 1) {
                item.productionCardId.forEach((_, idx) => {
                    const row = [
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        item.productionCardNumber[idx] ?? "",
                        item.productionCardDate[idx] ?? "",
                        item.productionCardStatus[idx] ?? "",
                        "",
                        item.proformaNumber[idx] ?? "",
                        item.proformaDate[idx] ?? "",
                        item.invoiceNumber[idx] ?? "",
                        item.invoiceDate[idx] ?? "",
                    ];

                    const worksheetRow = worksheet.addRow(row);
                    worksheetRow.eachCell((cell) => {
                        cell.style = {
                            border: {
                                top: {style: "thin"},
                                left: {style: "thin"},
                                bottom: {style: "thin"},
                                right: {style: "thin"}
                            }
                        };
                    });
                });
            }
        }

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `Справка за ${dateToString(filter.fromDate)} - ${dateToString(filter.toDate)}.xlsx`;
            a.click();
        });
    }

    console.log(reportData);
    return <Container>
        <Card>
            <Card.Header>
                <Card.Title><I18Label label={"CRM - обобщена справка"}/></Card.Title>
            </Card.Header>

            <Card.Body>
                <Row>
                    <Col>
                        {filterControl}
                    </Col>
                    <Col xs={"auto"} style={{paddingTop: "2.5rem"}}>
                        <ExportExcelIconButton onClick={exportDataToExcel}/>
                    </Col>
                </Row>

                {
                    !reportData && <Row>
                        <Col className={"text-center"}>
                            <I18Label label={"Моля, изчакайте..."}/>
                        </Col>
                    </Row>
                }

                {
                    inquiryError && <Row className={"text-center"}>
                        <Col>
                            <div className={"text-danger"}>{inquiryError.message}</div>
                        </Col>
                    </Row>
                }
                {
                    invoiceError && <Row className={"text-center"}>
                        <Col>
                            <div className={"text-danger"}>{invoiceError.message}</div>
                        </Col>
                    </Row>
                }

                {
                    (inquiryLoading || invoiceLoading) && <Row className={"text-center"}>
                        <Col>
                            <Spinner animation={"border"}/> Зареждане ...
                        </Col>
                    </Row>
                }

                {
                    reportData && <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th rowSpan={2}><I18Label label={"Клиент"}/></th>
                            <th colSpan={2}><I18Label label={"Запитване"}/></th>
                            <th colSpan={3}><I18Label label={"Оферта"}/></th>
                            <th colSpan={2}><I18Label label={"Потв.поръчка"}/></th>
                            <th colSpan={4}><I18Label label={"Карта за възлагане"}/></th>
                            <th colSpan={2}><I18Label label={"Проф.фактура"}/></th>
                            <th colSpan={2}><I18Label label={"Фактура"}/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            reportData.map(
                                i => {
                                    // debugger
                                    const itemCnt = Math.max(i.proformaId.length, i.invoiceId.length, i.productionCardId.length);
                                    const rowSpans = itemCnt > 0 ? itemCnt : 1;

                                    const rows = [
                                        (<tr key={i.randId}>
                                            <td rowSpan={rowSpans}>
                                                <Row>
                                                    <Col>{i.clientName}</Col>
                                                    <Col xs={"auto"}>
                                                        <NotesIconButton onClick={() => {
                                                            if (editInquiryId && editInquiryId === i.inquiryId) {
                                                                setEditInquiryId(undefined);
                                                                setEditInquiryComments(undefined);
                                                                return;
                                                            }
                                                            setEditInquiryId(i.inquiryId);
                                                            setEditInquiryComments(i.comments ?? "");
                                                        }} note={i.comments ?? undefined}/>
                                                    </Col>
                                                </Row>

                                            </td>
                                            <td rowSpan={rowSpans}>
                                                <Button variant={"link"}
                                                        onClick={() => showInquiryDetails(i.inquiryId)}>{i.inquiryNumber}</Button>
                                            </td>
                                            <td rowSpan={rowSpans}>{i.inquiryDate}</td>
                                            <td rowSpan={rowSpans}>
                                                <Button variant={"link"}
                                                        onClick={() => showOfferDetails(i.inquiryId, i.offerId)}>{i.offerNumber}</Button>
                                            </td>
                                            <td rowSpan={rowSpans}>{i.offerDate}</td>
                                            <td rowSpan={rowSpans}>{i.offerAuthor}</td>
                                            <td rowSpan={rowSpans}>
                                                {
                                                    i.confirmationNumber &&
                                                    <Button variant={"link"}
                                                            onClick={() => showInvoiceDetails(i.inquiryId, i.offerId, i.confirmationId)}>{i.confirmationNumber}</Button>
                                                }
                                            </td>
                                            <td rowSpan={rowSpans}>{i.confirmationDate}</td>
                                            <td>{
                                                itemCnt > 0 &&
                                                <Button variant={"link"}
                                                        onClick={() => showProductionCardDetails(i.productionCardId[0])}>{i.productionCardNumber[0]}</Button>
                                            }</td>
                                            <td>{itemCnt > 0 ? i.productionCardDate[0] : ""}</td>
                                            <td>{itemCnt > 0 ? i.productionCardStatus[0] : ""}</td>
                                            <td>{
                                                expeditionData?.filter(
                                                    e => e.cardId === i.productionCardId[0]
                                                ).map(
                                                    e => <div>
                                                        {dateToString(e.expeditionDate)}<br/>
                                                        <small>
                                                            {e.driver?.name} / {e.car?.name} ({e.car?.regPlate})
                                                        </small>
                                                    </div>
                                                )
                                            }</td>

                                            <td>
                                                {
                                                    itemCnt > 0 &&
                                                    <Button variant={"link"}
                                                            onClick={() => showInvoiceDetails(i.inquiryId, i.offerId, i.proformaId[0])}>{i.proformaNumber[0]}</Button>
                                                }
                                            </td>
                                            <td>{itemCnt > 0 ? i.proformaDate[0] : ""}</td>
                                            <td>
                                                {
                                                    itemCnt > 0 &&
                                                    <Button variant={"link"}
                                                            onClick={() => showInvoiceDetails(i.inquiryId, i.offerId, i.invoiceId[0])}>{i.invoiceNumber[0]}</Button>
                                                }
                                            </td>
                                            <td>{itemCnt > 0 ? i.invoiceDate[0] : ""}</td>
                                        </tr>)
                                    ];

                                    if (itemCnt > 1) {
                                        for (let idx = 1; idx < itemCnt; idx++) {
                                            rows.push(
                                                <tr key={i.randId + `_${idx}`}>
                                                    <td>
                                                        {
                                                            idx < i.productionCardNumber.length &&
                                                            <Button variant={"link"}
                                                                    onClick={() => showProductionCardDetails(i.productionCardId[idx])}>{i.productionCardNumber[idx]}</Button>
                                                        }
                                                    </td>
                                                    <td>{idx < i.productionCardDate.length ? i.productionCardDate[idx] : ""}</td>
                                                    <td>{idx < i.productionCardStatus.length ? i.productionCardStatus[idx] : ""}</td>
                                                    <td>{idx < i.proformaNumber.length ? i.proformaNumber[idx] : ""}</td>
                                                    <td>{idx < i.proformaDate.length ? i.proformaDate[idx] : ""}</td>
                                                    <td>{idx < i.invoiceNumber.length ? i.invoiceNumber[idx] : ""}</td>
                                                    <td>{idx < i.invoiceDate.length ? i.invoiceDate[idx] : ""}</td>
                                                </tr>
                                            );
                                        }
                                    }

                                    if (editInquiryId && editInquiryId === i.inquiryId) {
                                        rows.push(
                                            <tr>
                                                <td>
                                                    <Row>
                                                        <Col>Бележки:</Col>
                                                        <Col xs={"auto"}>
                                                            <SaveIconButton onClick={() => {
                                                                saveInquiryComments(i.inquiryId, editInquiryComments);
                                                                setEditInquiryId(undefined);
                                                                setEditInquiryComments(undefined);
                                                            }}/>
                                                        </Col>
                                                    </Row></td>
                                                <td colSpan={12}>
                                                    <Form.Control
                                                        as={"textarea"} value={editInquiryComments}
                                                        onChange={(e) => {
                                                            const newComments = e.target.value;
                                                            setEditInquiryComments(newComments);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    }

                                    return rows;
                                }
                            )
                        }
                        <tr>
                            <td colSpan={15} className={"text-end bg-body-secondary"}><I18Label label={"Общо"}/> {EUR_CURRENCY_NAME}: </td>
                            <td className={"text-end fw-bold"}>
                                {
                                    reportData.reduce((acc, item) =>
                                        acc + item.invoiceTotal.reduce((acc, val, idx) =>
                                            acc + (item.invoiceCurrency[idx] === EUR_CURRENCY_NAME ? val : 0), 0), 0
                                    ).toFixed(2)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={15} className={"text-end bg-body-secondary"}><I18Label label={"Общо"}/> {BGN_CURRENCY_NAME}: </td>
                            <td className={"text-end fw-bold"}>
                                {
                                    reportData.reduce((acc, item) =>
                                        acc + item.invoiceTotal.reduce((acc, val, idx) =>
                                            acc + (item.invoiceCurrency[idx] === BGN_CURRENCY_NAME ? val : 0), 0), 0
                                    ).toFixed(2)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={15} className={"text-end bg-body-secondary"}><I18Label label={"Общо"}/> {LEU_CURRENCY_NAME}: </td>
                            <td className={"text-end fw-bold"}>
                                {
                                    reportData.reduce((acc, item) =>
                                        acc + item.invoiceTotal.reduce((acc, val, idx) =>
                                            acc + (item.invoiceCurrency[idx] === LEU_CURRENCY_NAME ? val : 0), 0
                                        ), 0
                                    ).toFixed(2)
                                }
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                }

            </Card.Body>

        </Card>

        {
            editInquiry &&
            <CustomerInquiryDetails inquiry={editInquiry} ownCompanies={ownCompanies ?? []}
                                    onClose={() => setEditInquiry(undefined)}
            />
        }

        {
            editOffer && editOfferInquiry &&
            <ModalBackground zIndex={1100}>
                <Modal show={true} style={{zIndex: 1100}} className={"modal-90"}
                       onHide={() => {
                           setEditOffer(undefined);
                           setEditOfferInquiry(undefined);
                       }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Детайли на оферта</Modal.Title>
                    </Modal.Header>
                    <CustomerOfferDetails inquiry={editOfferInquiry}
                                          offer={editOffer}
                                          zIndex={1100}
                                          onDone={() => {
                                              setEditOffer(undefined);
                                              setEditOfferInquiry(undefined);
                                          }}/>
                </Modal>
            </ModalBackground>
        }

        {
            editInvoice && editInvoiceInquiry && editInvoiceOffer &&
            <CustomerInvoiceDetails
                inquiry={editInvoiceInquiry}
                offer={editInvoiceOffer}
                offerInvoices={[]}
                availableItems={[]}
                invoice={editInvoice}
                zIndex={1100}
                ownCompanies={ownCompanies ?? []}
                onClose={() => {
                    setEditInvoice(undefined);
                    setEditInvoiceInquiry(undefined);
                    setEditInvoiceOffer(undefined);
                }}
            />
        }

        {
            editProductionCardItem && editProductionCardInquiry && editProductionCardInvoice && editOffer &&
            <ProductionPlanDetailsPopup
                product={editProductionCardItem}
                inquiry={editProductionCardInquiry}
                offer={editOffer}
                invoice={editProductionCardInvoice}
                zIndex={1100} show={true}
                onClose={() => {
                    setEditProductionCardItem(undefined);
                    setEditProductionCardInquiry(undefined);
                    setEditProductionCardInvoice(undefined);
                }}
            />
        }
    </Container>
}
