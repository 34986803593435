import AppVersionControl from "./AppVersionControl";

export function FooterComponent(props: any) {
    return (
        <>
            <div className={"w-100 text-center"}>
                <AppVersionControl />
            </div>
        </>
    )
}
