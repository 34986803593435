import {Button, Col, Form, ModalBody, ModalFooter, Pagination, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowsV,
    faCancel, faChartLine,
    faCircleArrowDown, faCopy,
    faEdit, faEuro, faLeaf,
    faMoneyBillTrendUp,
    faPlus,
    faSearch,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useMemo, useState} from "react";
import useDebounce from "../../../hooks/UseDebounce";
import UsePaginatorPages from "../../../hooks/UsePaginatorPages";
import {textToInt, zeroToEmptyString, zeroToEmptyStringAsCurrency} from "../../../common";
import BulkPriceUpdateModal from "./BulkPriceUpdateModal";
import ProductPriceHistoryModal from "./ProductPriceHistoryModal";
import {useHookstate} from "@hookstate/core";
import appState from "../../../global-state/global-state";
import CurrencyIndexUpdateModal from "./CurrencyIndexUpdateModal";
import EcoTaxUpdateModal from "./EcoTaxUpdateModal";
import appDb from "../../../global-state/global-db";
import {ProductType} from "./hooks/useProductsOnce";
import {updateDoc, writeBatch} from "firebase/firestore";
import {fbDb} from "../../../App";
import globalState from "../../../global-state/global-state";
import ProductsExpImpModal from "./ProductsExpImpModal";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import CancelIconButton from "../../../common/icon-buttons/CancelIconButton";
import ProductAvailabilityOnDemand from "../../../common/ProductAvailabilityOnDemand";
import ProductAvailability from "../../../common/ProductAvailability";
import {ProductGroup} from "../product-groups/ProductGroupsList";
import I18Label from "../../../i18/i18label";
import useUserRights from "../../../hooks/useUserRights";


export interface ProductsListProps {
    isVisible: boolean;
    onAdd: (copyProduct?: ProductType) => any;
    onEdit: (item: ProductType) => any;
    onDelete: (item: ProductType) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
    onMultiSelect?: (result: any[]) => any;
}

export function ProductsList({
                                 isVisible,
                                 onAdd,
                                 onEdit,
                                 onDelete,
                                 onClose,
                                 onSelect,
                                 onMultiSelect
                             }: ProductsListProps) {
    const PAGE_SIZE = 100;
    const {canUserEdit} = useUserRights();
    const currencyIndexEUR = useHookstate(appState.currencyIndex.EUR).value;
    const currencyIndexLEU = useHookstate(appState.currencyIndex.LEU).value;
    const ecoTaxPrice = useHookstate(appState.ecoTaxPerKgPrice).value;
    const globalState = useHookstate(appState).get();

    const [showBulkPriceUpdate, setShowBulkPriceUpdate] = useState(false);
    const [showCurrencyIndexUpdate, setShowCurrencyIndexUpdate] = useState<"EUR" | "LEU" | undefined>(undefined);
    const [showEcoTaxUpdate, setShowEcoTaxUpdate] = useState(false);
    const [showExpImp, setShowExpImp] = useState(false);
    const [showProductHistory, setShowProductHistory] = useState<string | null>(null);
    const [filterName, setFilterName] = useState('');
    const [filterNameRo, setFilterNameRo] = useState('');
    const [filterSku, setFilterSku] = useState('');
    const [filterProductGroup, setFilterProductGroup] = useState<ProductGroup | undefined>();
    const [filterDescription, setFilterDescription] = useState('');
    const [filterWidth, setFilterWidth] = useState('');
    const [filterHeight1, setFilterHeight1] = useState('');
    const [filterHeight2, setFilterHeight2] = useState('');
    const [filterLength, setFilterLength] = useState('');

    const _products = useHookstate(appDb.products);
    const products = useMemo(() => {
        return _products.value.map(v => {
            return {...v} as ProductType
        })
    }, [_products]);


    const productsGroups = useMemo(
        () => {
            const result: ProductGroup[] = [];
            for (const product of products) {
                if (product.productGroup) {
                    const group = result.find(r => r.id === product.productGroup?.id);
                    if (!group) {
                        result.push({
                            id: product.productGroup.id,
                            ref: product.productGroup.ref,
                            name: product.productGroup.name,
                            percent: product.productGroup.percent,
                            variant: product.productGroup.variant
                        });
                    }
                }
            }
            return result;
        }, [products]
    );

    const [filtered, setFilteredProducts] = useState<any[]>([])
    const [activePage, setActivePage] = useState(0);
    const pages = UsePaginatorPages(filtered?.length || 0, PAGE_SIZE, activePage, setActivePage);
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

    useDebounce(() => {
        if (products) {

            const filterLengthParts = filterLength.split("-");
            const lengthFrom = textToInt(filterLengthParts[0], 0) || 0;
            const lengthTo = textToInt(filterLengthParts[1], 0) || lengthFrom;

            const filterWidthParts = filterWidth.split("-");
            const widthFrom = textToInt(filterWidthParts[0], 0) || 0;
            const widthTo = textToInt(filterWidthParts[1], 0) || widthFrom;

            const filterHeight1Parts = filterHeight1.split("-");
            const height1From = textToInt(filterHeight1Parts[0], 0) || 0;
            const height1To = textToInt(filterHeight1Parts[1], 0) || height1From;

            const filterHeight2Parts = filterHeight2.split("-");
            const height2From = textToInt(filterHeight2Parts[0], 0) || 0;
            const height2To = textToInt(filterHeight2Parts[1], 0) || height2From;

            const newList = products.filter(
                r => (!filterName || filterName.length === 0 || r.name?.toLowerCase().indexOf(filterName.toLowerCase()) > -1) &&
                    (!filterSku || filterSku.length === 0 || (r!.sku || "").toLowerCase().indexOf(filterSku.toLowerCase()) > -1) &&
                    (!filterNameRo || filterNameRo.length === 0 || (r.descriptionForClient ?? "").toLowerCase().indexOf(filterNameRo.toLowerCase()) > -1) &&
                    (!filterProductGroup || r.productGroup?.id === filterProductGroup.id) &&
                    (!filterDescription || filterDescription.length === 0 || (r.description || "").toLowerCase().indexOf(filterDescription.toLowerCase()) > -1) &&
                    (!filterWidth || filterWidth.length === 0 || ((r.width ?? 0) >= widthFrom && (r.width ?? 0) <= widthTo)) &&
                    (!filterHeight1 || filterHeight1.length === 0 || ((r.height1 ?? 0) >= height1From && (r.height1 ?? 0) <= height1To)) &&
                    (!filterHeight2 || filterHeight2.length === 0 || ((r.height2 ?? 0) >= height2From && (r.height2 ?? 0) <= height2To)) &&
                    (!filterLength || filterLength.length === 0 || ((r._length ?? 0) >= lengthFrom && (r._length ?? 0) <= lengthTo))
            );

            if (newList.length !== filtered.length) {
                setActivePage(0);
            }

            newList.sort(
                (a, b) => a.name > b.name ? -1 :
                    (a.name < b.name ? 1 : 0)
            )
            setFilteredProducts(newList);
        } else setFilteredProducts([]);
    }, [
        products, filterName, filterNameRo, filterSku, filterProductGroup, filterDescription,
        filterWidth, filterHeight1, filterHeight2, filterLength
    ], 24);

    async function setSKUNumbers() {
        let nextNumber = globalState.productsNumber;

        let batch = writeBatch(fbDb);
        let batchCnt = 0;
        try {
            for (const p of products) {
                if (!p.sku || p.sku.trim() === "") {
                    batch.update(p.ref, {sku: nextNumber.toString()});
                    batchCnt++;
                    nextNumber++;
                    batch.update(globalState.ref!, {productsNumber: nextNumber, autoNumber: true});
                    batchCnt++;

                    if (batchCnt > 250) {
                        await batch.commit();
                        batch = writeBatch(fbDb);
                        batchCnt = 0;
                    }

                    console.log(p.name, nextNumber);
                }
            }
            await batch.commit();
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        let maxNumber = 0;
        for (const p of products) {
            const n = textToInt(p.sku || "", 0)!;
            if (n > maxNumber) {
                maxNumber = n;
            }
        }

        if (maxNumber > globalState.productsNumber) {
            updateDoc(globalState.ref!, {productsNumber: maxNumber});
        }
    }, [products]);

    const toggleSelection = (id: string) => {
        const idx = selectedProducts.indexOf(id);
        if (idx > -1) {
            setSelectedProducts(selectedProducts.filter(r => r !== id));
        } else {
            setSelectedProducts([...selectedProducts, id]);
        }
    }

    return (
        <>
            {
                isVisible &&
                <>
                    <ModalBody>
                        <div className={"max-h-60vh scrollable"}>
                            <Table size={"sm"} borderless hover>
                                <thead style={{backgroundColor: "white"}}>
                                <tr>
                                    <th style={{width: onSelect ? 165 : 120}}></th>
                                    <th>SKU</th>
                                    <th><I18Label label={"Име"}/></th>
                                    <th><I18Label label={"Име (English)"}/></th>
                                    <th className={"text-end"}><I18Label label={"Дължина"}/>, <I18Label label={"мм"}/>
                                    </th>
                                    <th className={"text-end"}><I18Label label={"Широчина"}/>, <I18Label label={"мм"}/>
                                    </th>
                                    <th className={"text-end"}><I18Label label={"Височина"}/> (h1), <I18Label
                                        label={"мм"}/></th>
                                    <th className={"text-end"}><I18Label label={"Височина"}/> (h2), <I18Label
                                        label={"мм"}/></th>
                                    <th><I18Label label={"Описание на продукт"}/></th>
                                    <th><I18Label label={"Група и %"}/></th>
                                    <th className={"text-center"}><I18Label label={"Наличност"}/></th>
                                </tr>
                                <tr>
                                    <td className={"text-start text-dark"}>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterSku}
                                                      onChange={(e) => setFilterSku(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterName}
                                                      onChange={(e) => setFilterName(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterNameRo}
                                                      onChange={(e) => setFilterNameRo(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterLength}
                                                      onChange={(e) => setFilterLength(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterWidth}
                                                      onChange={(e) => setFilterWidth(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterHeight1}
                                                      onChange={(e) => setFilterHeight1(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterHeight2}
                                                      onChange={(e) => setFilterHeight2(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterDescription}
                                                      onChange={(e) => setFilterDescription(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Select size={"sm"} value={filterProductGroup?.id || ''}
                                                     onChange={(e) => {
                                                         const id = e.target.value;
                                                         setFilterProductGroup(productsGroups.find(r => r.id === id));
                                                     }}
                                        >
                                            <option value={''}><I18Label label={"Всички"}/></option>
                                            {
                                                productsGroups.map(r =>
                                                    <option key={r.id} value={r.id}>{r.name}</option>
                                                )
                                            }
                                        </Form.Select>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} disabled={true}/>
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    filtered && filtered.length === 0 &&
                                    <tr>
                                        <td colSpan={10} className={"text-center fw-bold"}><I18Label
                                            label={"Няма продукти"}/></td>
                                    </tr>
                                }
                                {

                                    filtered && filtered.length > 0 &&
                                    filtered.slice(activePage * PAGE_SIZE, activePage * PAGE_SIZE + PAGE_SIZE).map(
                                        r =>
                                            <tr key={r.id} className={"tr-bordered"}>
                                                <td>
                                                    {
                                                        onMultiSelect &&
                                                        <FormCheckInput checked={
                                                            selectedProducts.indexOf(r.id) > -1
                                                        } style={{marginRight: "5px"}}
                                                                        onChange={() => toggleSelection(r.id)}
                                                        />
                                                    }
                                                    <Button size={"sm"} variant={"outline-secondary"}
                                                            onClick={() => onEdit(r)}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit}/>
                                                    </Button>&nbsp;
                                                    <Button size={"sm"} variant={"outline-info"}
                                                            title={"Покажи историята на цената."}
                                                            onClick={() => setShowProductHistory(r.id)}
                                                    >
                                                        <FontAwesomeIcon icon={faChartLine}/>
                                                    </Button>&nbsp;
                                                    {
                                                        canUserEdit('Номенклатури-Продукти') && <>
                                                            <Button size={"sm"} variant={"outline-danger"}
                                                                    onClick={() => {
                                                                        onDelete(r)
                                                                    }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash}/>
                                                            </Button>&nbsp;
                                                            <Button size={"sm"} variant={"outline-info"}
                                                                    onClick={() => {
                                                                        onAdd({...r, id: null, ref: null})
                                                                    }}
                                                            >
                                                                <FontAwesomeIcon icon={faCopy}/>
                                                            </Button>&nbsp;
                                                        </>
                                                    }
                                                    {
                                                        onSelect &&
                                                        <Button size={"sm"} variant={"outline-success"}
                                                                onClick={() => {
                                                                    onSelect({...r, id: r.id, ref: r.ref})
                                                                }}
                                                        >
                                                            <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                        </Button>
                                                    }
                                                </td>
                                                <td>
                                                    {r.sku}
                                                </td>
                                                <td>
                                                    {r.name}
                                                </td>
                                                <td>
                                                    {r.descriptionForClient}
                                                </td>
                                                <td className={"text-end"}>
                                                    {zeroToEmptyString(r._length)}
                                                </td>
                                                <td className={"text-end"}>
                                                    {zeroToEmptyString(r.width)}
                                                </td>
                                                <td className={"text-end"}>
                                                    {zeroToEmptyString(r.height1)}
                                                </td>
                                                <td className={"text-end"}>
                                                    {zeroToEmptyString(r.height2)}
                                                </td>
                                                <td>
                                                    {r.description}
                                                </td>
                                                <td>
                                                    {r.productGroup && r.productGroup.name ?
                                                        (r.productGroup.name || "") +
                                                        ` ( ${r.productGroup.percent || 0}% )` : ''}
                                                </td>
                                                <td className={"text-center"}>
                                                    {
                                                        selectedProducts.indexOf(r.id) > -1 ?
                                                            <ProductAvailability
                                                                productId={r.id}
                                                            />
                                                            :
                                                            <ProductAvailabilityOnDemand productId={r.id}/>
                                                    }
                                                </td>
                                            </tr>
                                    )
                                }
                                </tbody>

                            </Table>
                        </div>
                        <div className={"m-0 mt-4"}>
                            <Pagination className="justify-content-center">
                                {pages}
                            </Pagination>
                        </div>
                    </ModalBody>

                    {
                        canUserEdit('Номенклатури-Продукти') &&
                        <ModalFooter>
                            <Col xs={"auto"}>
                                {/*<Button onClick={updateFlag}>.t.e.s.t.</Button>*/}
                                <Button onClick={() => setShowBulkPriceUpdate(true)}>
                                    <FontAwesomeIcon icon={faMoneyBillTrendUp}/> <I18Label
                                    label={"Промяна на цените за група"}/>
                                </Button>&nbsp;
                                <Button variant={"outline-secondary"} onClick={() => setShowCurrencyIndexUpdate("EUR")}>
                                    <FontAwesomeIcon icon={faEuro}/> <I18Label label={"Курс на"}/>
                                    EUR: <strong>{currencyIndexEUR.toFixed(4)} <I18Label label={"лв"}/></strong>
                                </Button>&nbsp;
                                <Button variant={"outline-secondary"} onClick={() => setShowCurrencyIndexUpdate("LEU")}>
                                    <FontAwesomeIcon icon={faEuro}/> <I18Label label={"Курс на"}/>
                                    LEU: <strong>{currencyIndexLEU.toFixed(4)} <I18Label label={"лв"}/></strong>
                                </Button>&nbsp;
                                <Button variant={"outline-secondary"} onClick={() => setShowEcoTaxUpdate(true)}>
                                    <FontAwesomeIcon icon={faLeaf}/> <I18Label
                                    label={"Eко такса на кг"}/>: <strong>{zeroToEmptyStringAsCurrency(ecoTaxPrice.toString(), ' EUR')}</strong>
                                </Button>&nbsp;
                                <Button variant={"outline-secondary"} onClick={() => setShowExpImp(true)}>
                                    <FontAwesomeIcon icon={faArrowsV}/> <I18Label label={"Експ/Импорт"}/>
                                </Button>&nbsp;
                                {/*<Button variant={"outline-secondary"} onClick={() => setSKUNumbers()}>*/}
                                {/*    <FontAwesomeIcon icon={faLeaf}/> Генерирай SKU номера*/}
                                {/*</Button>&nbsp;*/}
                            </Col>
                            <Col>
                                <span><I18Label label={"Брой"}/>: {filtered.length} / {products.length}</span>
                            </Col>
                            {
                                onMultiSelect &&
                                <Col>
                                    <span><I18Label label={"Избрани"}/>: {selectedProducts.length} <I18Label
                                        label={"продукта"}/></span>&nbsp;
                                    <Button size={"sm"} variant={"outline-success"}
                                            onClick={() => {
                                                onMultiSelect(selectedProducts.map(r => {
                                                    const p = products.find(r1 => r1.id === r);
                                                    return {...p, id: p?.id, ref: p?.ref};
                                                }));
                                            }}
                                    >
                                        <FontAwesomeIcon icon={faCircleArrowDown}/>
                                    </Button>
                                    <CancelIconButton onClick={() => setSelectedProducts([])}/>

                                </Col>
                            }
                            <Col className={"text-end"}>
                                <Button onClick={() => onAdd()}>
                                    <FontAwesomeIcon icon={faPlus}/> <I18Label label={"Добави нов"}/>
                                </Button>&nbsp;
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onClose(null)
                                }}>
                                    <FontAwesomeIcon icon={faCancel}/> <I18Label label={"Затвори"}/>
                                </Button>
                            </Col>
                        </ModalFooter>
                    }
                </>
            }

            {
                showEcoTaxUpdate &&
                <EcoTaxUpdateModal
                    onClose={() => setShowEcoTaxUpdate(false)} show={true}
                    zIndex={1500}
                />
            }
            {
                showCurrencyIndexUpdate &&
                <CurrencyIndexUpdateModal
                    currency={showCurrencyIndexUpdate}
                    onClose={() => setShowCurrencyIndexUpdate(undefined)} show={true}
                    zIndex={1500}
                />
            }
            {
                showBulkPriceUpdate &&
                <BulkPriceUpdateModal
                    onClose={() => setShowBulkPriceUpdate(false)} show={true}
                    zIndex={1500}
                />
            }
            {
                showProductHistory &&
                <ProductPriceHistoryModal
                    productId={showProductHistory}
                    onClose={() => setShowProductHistory(null)} show={true}
                    zIndex={1500}
                />
            }
            {
                showExpImp &&
                <ProductsExpImpModal
                    onClose={() => setShowExpImp(false)} show={true}
                    zIndex={1500}
                />
            }
        </>
    )
}
