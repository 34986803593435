import {FC, useEffect} from "react";
import {Form} from "react-bootstrap";
import useWarehouseObjectsRepo from "../../warehouse/hooks/useWarehouseObjectsRepo";

interface WarehouseObjectsDropdownProps {
    ownCompanyId?: string,
    selectedWarehouseObjectId?: string,
    onChange: (selectedWarehouseObjectId: string) => void,
    readOnly?: boolean
}

const WarehouseObjectsDropdown: FC<WarehouseObjectsDropdownProps> = ({
                                                                         ownCompanyId,
                                                                         selectedWarehouseObjectId,
                                                                         onChange,
                                                                         readOnly
                                                                     }) => {
    const {ownCompanyWarehouseObjects} = useWarehouseObjectsRepo(ownCompanyId);

    useEffect(() => {
        if(selectedWarehouseObjectId !== "" &&
            ownCompanyWarehouseObjects.find(warehouseObject =>
                warehouseObject.id === selectedWarehouseObjectId) === undefined) {
            if(ownCompanyWarehouseObjects.length > 0) {
                onChange(ownCompanyWarehouseObjects[0].id);
            } else {
                onChange("");
            }
        }
    }, [selectedWarehouseObjectId, ownCompanyWarehouseObjects]);

    return (
        <Form.Select
            disabled={readOnly}
            onChange={(e) => onChange(e.target.value)}
            value={selectedWarehouseObjectId ?? ""}
        >
            {
                ownCompanyWarehouseObjects.map(warehouseObject => (
                    <option key={warehouseObject.id} value={warehouseObject.id}>
                        {warehouseObject.name ?? ""}
                    </option>
                ))
            }
        </Form.Select>
    )
}

export default WarehouseObjectsDropdown;
