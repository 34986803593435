import {Form, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {
    EMPTY_PRODUCTION_CARD_MATERIAL,
    ProductionCardMaterialsType,
    ProductionCardMaterialType
} from "../types/ProductionCardMaterialType";
import ReactDatePicker from "react-datepicker";
import {textToFloat} from "../../../common";
import {ProductsDialog} from "../../nomenclatures/products/ProductsDialog";
import SelectValueFromDialog from "../../../common/SelectValueFromDialog";
import EditableTableCell from "../../../common/EditableTableCell";
import RightAlignedCell from "../../../common/RightAlignedCell";
import PlusIconButton from "../../../common/icon-buttons/PlusIconButton";
import DeleteConfirmIconButton from "../../../common/icon-buttons/DeleteConfirmIconButton";
import I18Label from "../../../i18/i18label";
import {WarehouseObjectType} from "../../warehouse/hooks/useWarehouseObjects";
import useWarehouseObjectsRepo from "../../warehouse/hooks/useWarehouseObjectsRepo";
import ProductAvailabilityOnDemand from "../../../common/ProductAvailabilityOnDemand";


interface EditableProductionPlanMaterialRowProps {
    zIndex?: number,
    item: ProductionCardMaterialType,
    onSave: (item: ProductionCardMaterialType) => void,
    onCancel: VoidFunction,
    availableWarehouses?: WarehouseObjectType[] | undefined
}

const EditableProductionPlanMaterialRow: React.FC<EditableProductionPlanMaterialRowProps> = (props: EditableProductionPlanMaterialRowProps) => {
    const [item, setItem] = useState({...props.item});
    const [editCell, setEditCell] = useState("");

    useEffect(() => {
        if (!props.item.warehouseId) {
            props.item.warehouseId = props.availableWarehouses?.[0]?.id ?? "";
        }
        setItem({...props.item});
        setEditCell("");
    }, [props.item]);

    const productDialog = <ProductsDialog show={true} zIndex={props.zIndex || 2000} onClose={() => {
    }}/>

    function saveItem() {
        props.onSave(item);
    }

    return <tr>
        <td>
            <Form.Select size={"sm"}
                         value={item.warehouseId ?? ""}
                         onChange={e => {
                             setItem(item => {
                                 return {...item, warehouseId: e.target.value};
                             })
                         }}
            >
                {
                    props.availableWarehouses?.map(
                        warehouse => <option key={warehouse.id} value={warehouse.id}>
                            {warehouse.name}
                        </option>
                    )
                }
            </Form.Select>
        </td>
        <td> {item.productCode} </td>
        <td>
            <SelectValueFromDialog value={item.productName} className={"mb-0"} size={"sm"}
                                   onChange={(product) => {
                                       console.log(product);
                                       setItem({
                                           ...item,
                                           productId: product.id,
                                           productCode: product.sku,
                                           productName: product.name,
                                           productQty: 1,
                                           productDimension: product.dimens.trim(),
                                           productSize: {
                                               l: product._length || 0,
                                               h1: product.height1 || 0,
                                               h2: product.height2 || 0,
                                               b: product.width || 0,
                                           },
                                       })
                                   }
                                   }
                                   dialog={productDialog}/>
        </td>
        <td>
            {
                item.productSize.l + " / " + item.productSize.b + " / " + item.productSize.h1 + " / " + item.productSize.h2
            }
        </td>
        <EditableTableCell inEditMode={true}
                           isWorking={false}
                           value={item.productQty.toString()}
                           onEdit={() => {
                           }}
                           onBlur={
                               (newValue) => {
                                   item.productQty = textToFloat(newValue) || 0;
                               }
                           }
                           onDone={() => {
                           }}
        />
        {/*<td>*/}
        {/*    <Form.Control size={"sm"}*/}
        {/*                  value={item.productDimension}*/}
        {/*                  onChange={e => {*/}
        {/*                      setItem(item => {*/}
        {/*                          return {...item, productDimension: e.target.value};*/}
        {/*                      })*/}
        {/*                  }}/>*/}
        {/*</td>*/}
        <td>
            <Form.Control size={"sm"}
                          value={item.supplier}
                          onChange={e => {
                              setItem(item => {
                                  return {...item, supplier: e.target.value};
                              })
                          }}
            />
        </td>
        {/*<td>*/}
        {/*    <ReactDatePicker onChange={date => {*/}
        {/*        setItem(item => {*/}
        {/*            return {...item, supplyDate: date};*/}
        {/*        })*/}
        {/*    }} selected={item.supplyDate} dateFormat={"dd.MM.yyyy"}*/}
        {/*                     className={"form-control form-control-sm"} isClearable*/}
        {/*    />*/}
        {/*</td>*/}
        {/*<td>*/}
        {/*    <Form.Control size={"sm"}*/}
        {/*                  value={item.supplyAddress}*/}
        {/*                  onChange={e => {*/}
        {/*                      setItem(item => {*/}
        {/*                          return {...item, supplyAddress: e.target.value};*/}
        {/*                      })*/}
        {/*                  }}/>*/}
        {/*</td>*/}
        <RightAlignedCell className={"w-30px"}>
            <PlusIconButton size={"sm"} onClick={saveItem}
                            disabled={editCell !== "" ||
                                !item.productId ||
                                item.productQty <= 0 ||
                                !item.warehouseId}
            />
        </RightAlignedCell>
    </tr>
}


export default function ProductionPlanMaterialsTable(props: {
    onChange?: (items: ProductionCardMaterialsType) => void,
    items: ProductionCardMaterialsType,
    availableWarehouses?: WarehouseObjectType[]
}) {
    const {getWarehouseObjectName} = useWarehouseObjectsRepo();
    const [newItem, setNewItem] =
        useState<ProductionCardMaterialType>({...EMPTY_PRODUCTION_CARD_MATERIAL});

    function addNewItem(item: ProductionCardMaterialType) {
        props.onChange && props.onChange([...props.items, item]);
        setNewItem({...EMPTY_PRODUCTION_CARD_MATERIAL});
    }

    useEffect(() => {
        let needUpdate = false;
        for (let item of props.items) {
            if (!item.warehouseId) {
                item.warehouseId = props.availableWarehouses?.[0]?.id ?? "";
                needUpdate = true;
            }
        }
        if (needUpdate) {
            props.onChange && props.onChange([...props.items]);
        }
    }, [props.items]);

    return (
        <Table>
            <thead>
            <tr>
                <th>Склад</th>
                <th>SKU</th>
                <th><I18Label label={"Наименование"}/></th>
                <th className={"w-120px text-center"}><I18Label label={"Размери"}/> (L/B/H1/H2) mm</th>
                <th className={"w-60px"}><I18Label label={"К-во"}/></th>
                {/*<th className={"w-100px"}><I18Label label={"Ед."}/></th>*/}
                <th><I18Label label={"Доставчик"}/></th>
                {/*<th className={"w-100px"}><I18Label label={"Дата на доставка"}/></th>*/}
                {/*<th><I18Label label={"Адрес на доставка"}/></th>*/}
                <th className={"w-30px text-end"}/>
            </tr>
            </thead>
            <tbody>
            {
                props.items.sort(
                    (a, b) => a.orderIndex - b.orderIndex
                ).map(
                    (item, index) => {
                        return (
                            <tr key={`${item.productCode}_${index}`}>
                                <td>
                                    <Form.Select size={"sm"}
                                                 value={item.warehouseId ?? ""}
                                                 disabled={!props.onChange}
                                                 onChange={e => {
                                                     item.warehouseId = e.target.value;
                                                     props.onChange && props.onChange(props.items);
                                                 }}
                                    >
                                        {
                                            props.availableWarehouses?.map(
                                                warehouse => <option key={warehouse.id} value={warehouse.id}>
                                                    {getWarehouseObjectName(warehouse.id)}
                                                </option>
                                            )
                                        }
                                    </Form.Select>
                                </td>
                                <td>{item.productCode}</td>
                                <td>{item.productName}</td>
                                <td className={"text-center"}>
                                    {item.productSize.l} / {item.productSize.b} / {item.productSize.h1} / {item.productSize.h2}
                                </td>

                                <EditableTableCell inEditMode={true}
                                                   isWorking={false}
                                                   disabled={!props.onChange}
                                                   value={item.productQty.toString()}
                                                   onEdit={() => {
                                                   }}
                                                   onBlur={(newValue) => {
                                                       item.productQty = textToFloat(newValue) ?? 0;
                                                       props.onChange && props.onChange(props.items);
                                                   }}
                                                   onDone={() => {
                                                   }}
                                />

                                {/*<td>*/}
                                {/*    <Form.Control size={"sm"}*/}
                                {/*                  disabled={!props.onChange}*/}
                                {/*                  value={item.productDimension}*/}
                                {/*                  onChange={e => {*/}
                                {/*                      item.productDimension = e.target.value;*/}
                                {/*                      props.onChange && props.onChange(props.items);*/}
                                {/*                  }}*/}
                                {/*    />*/}
                                {/*</td>*/}

                                <td>
                                    <Form.Control size={"sm"}
                                                  value={item.supplier}
                                                  disabled={!props.onChange}
                                                  onChange={e => {
                                                      item.supplier = e.target.value;
                                                      props.onChange && props.onChange(props.items);
                                                  }}
                                    />
                                </td>
                                {/*<td>*/}
                                {/*    <ReactDatePicker onChange={date => {*/}
                                {/*        item.supplyDate = date;*/}
                                {/*        props.onChange && props.onChange(props.items);*/}
                                {/*    }}*/}
                                {/*                     selected={item.supplyDate} dateFormat={"dd.MM.yyyy"}*/}
                                {/*                     className={"form-control form-control-sm"} isClearable*/}
                                {/*                     disabled={!props.onChange}*/}
                                {/*    />*/}
                                {/*</td>*/}
                                {/*<td>*/}
                                {/*    <Form.Control size={"sm"}*/}
                                {/*                  value={item.supplyAddress}*/}
                                {/*                  disabled={!props.onChange}*/}
                                {/*                  onChange={e => {*/}
                                {/*                      item.supplyAddress = e.target.value;*/}
                                {/*                      props.onChange && props.onChange(props.items);*/}
                                {/*                  }}/>*/}
                                {/*</td>*/}
                                <RightAlignedCell className={"w-30px text-nowrap"}>
                                    <ProductAvailabilityOnDemand productId={item.productId}/>&nbsp;
                                    {
                                        props.onChange &&
                                        <DeleteConfirmIconButton onClick={
                                            () => {
                                                props.onChange && props.onChange(props.items.filter(i => i !== item));
                                            }
                                        } size={"sm"}/>
                                    }
                                </RightAlignedCell>
                            </tr>
                        )
                    }
                )
            }
            {
                props.onChange &&
                <EditableProductionPlanMaterialRow
                    availableWarehouses={props.availableWarehouses}
                    item={newItem}
                    onSave={item => addNewItem(item)}
                    onCancel={() => setNewItem({...EMPTY_PRODUCTION_CARD_MATERIAL})}/>
            }
            </tbody>
        </Table>
    )
        ;
}
