import {useState} from "react";
import {CrmPeriod, CrmPeriodType, IBVoidFunction, IBVoidFunctionWithUserId} from "../../types";
import {Col, Row} from "react-bootstrap";
import CrmPeriodSelector from "./CrmPeriodSelector";
import CrmPeriodViewer from "./CrmPeriodViewer";
import moment from "moment/moment";
import useUserRights from "../../hooks/useUserRights";


export interface CrmPeriodProps {
    period: CrmPeriod;
    periodType: CrmPeriodType;
    statistics: any;
    changePeriodType: (newPeriodType: CrmPeriodType, newPeriod: CrmPeriod) => any;
    createActivity?: () => any;
    onMoveActivitiesCallback?: IBVoidFunctionWithUserId;
    onSetMoveActivitiesCallback?: (cb: IBVoidFunctionWithUserId | null ) => void;
}

export interface CrmCalendarProps {
    period: CrmPeriod;
    onChangePeriod: (newPeriod: CrmPeriod) => any;
}

export default function CrmCalendar({period, onChangePeriod}: CrmCalendarProps) {
    const [periodType, setPeriodType] = useState<CrmPeriodType>("activity");
    const [moveFunction, setMoveFunction] = useState<IBVoidFunctionWithUserId | null>(null);

    function changePeriod(newPeriodType: CrmPeriodType, newPeriod: CrmPeriod) {
        setPeriodType(newPeriodType);
        onChangePeriod(newPeriod);
    }

    function createActivity() {
        const periodDay = moment().year(period.year).month(period.month || 0).date(period.day || 1);
        const _date = periodDay.isValid() ? periodDay.toDate() : new Date();

        setPeriodType("activity");
        onChangePeriod({...period, activity: {date: _date, activityType: "задача", region: null, clientId: null, clientName: null}});
    }

    const onSetMoveCallback = function (cb: IBVoidFunctionWithUserId | null ) {
        const _cb = () => cb;
        setMoveFunction(_cb);
    }

    return <>
        <Row>
            <Col>
                <CrmPeriodSelector period={period} periodType={periodType} changePeriodType={changePeriod}
                    createActivity={createActivity} statistics={null} onMoveActivitiesCallback={moveFunction || undefined}
                />
            </Col>
        </Row>
        <Row>
            <Col >
                <CrmPeriodViewer period={period} periodType={periodType} changePeriodType={changePeriod}
                                 statistics={null} onSetMoveActivitiesCallback={onSetMoveCallback}/>
            </Col>
        </Row>
    </>
}
