import {useEffect, useState} from "react";
import {Account} from "../types";
import {API} from "../api";
import {Simulate} from "react-dom/test-utils";
import cut = Simulate.cut;

const useUserRights = () => {
    const currentUser = API.user;
    const [user, setUser] = useState<Account | undefined>(undefined);
    const [canView, setCanView] = useState<string[]>([]);
    const [canEdit, setCanEdit] = useState<string[]>([]);

    useEffect(
        () => {
            if (currentUser) {
                setUser(currentUser);
                setCanView(currentUser.canView);
                setCanEdit(currentUser.canEdit);
            } else {
                setUser(undefined);
                setCanView([]);
                setCanEdit([]);
            }
        },
        [currentUser]
    );

    const canUserView = (module: string) => {
        return user?.canView.length === 0 ||
            user?.canView?.includes(module) ||
            user?.canView?.includes('Всичко') ||
            user?.isAdmin;
    }

    const canUserEdit = (module: string) => {
        return user?.canEdit.length === 0 ||
            user?.canEdit?.includes(module) ||
            user?.canEdit?.includes('Всичко') ||
            user?.isAdmin;
    }

    return {
        user,
        canView,
        canEdit,
        canUserView,
        canUserEdit,
    }
}

export default useUserRights;
