import {Col, Form, Row, Spinner, Table} from "react-bootstrap";
import {dateToString, dateWithZeroTime, showErrorsListInToast} from "../../../common";
import React, {FC, useEffect, useState} from "react";
import CenterAlignedCell from "../../../common/CenterAlignedCell";
import {expeditionConverter, ExpeditionStatusType, ExpeditionType} from "../../../fb-converters/expedition-converter";
import {ExpeditionPlanFilterType} from "./ExpeditionPlanFilter";
import useFirebaseCollection from "../../../hooks/useFirebaseCollection";
import ExpeditionCourse, {ExpeditionCourseType} from "./ExpeditionCourse";
import {deleteDoc, doc, QueryConstraint, updateDoc, where} from "firebase/firestore";
import ExpeditionPlanPrintPreview from "../print/expedition/ExpeditionPlanPrintPreview";
import I18Label from "../../../i18/i18label";
import ReactDatePicker from "react-datepicker";
import SelectValueFromDialog from "../../../common/SelectValueFromDialog";
import {UsersDialog} from "../../nomenclatures/users/UsersDialog";
import {CarsDialog} from "../../nomenclatures/cars/CarsDialog";
import CancelIconButton from "../../../common/icon-buttons/CancelIconButton";
import CheckIconButton from "../../../common/icon-buttons/CheckIconButton";
import {fbDb} from "../../../App";
import DeleteConfirmIconButton from "../../../common/icon-buttons/DeleteConfirmIconButton";
import useUserRights from "../../../hooks/useUserRights";

type ExpeditionPlanTableProps = {
    zIndex: number,
    filter: ExpeditionPlanFilterType,
    viewMode?: "active" | "completed"
}

const ExpeditionPlanTable: FC<ExpeditionPlanTableProps> = ({zIndex, filter, viewMode}) => {
    const [selectedExpeditons, setSelectedExpeditons] = useState<ExpeditionType[]>([]);
    const onSelect = false;

    const expeditionConstraints: QueryConstraint[] = [];
    if (filter.fromDate) {
        expeditionConstraints.push(where("expeditionDate", ">=", dateWithZeroTime(filter.fromDate)!));
    }
    if (filter.toDate) {
        const toDate = dateWithZeroTime(filter.toDate)!;
        toDate.setDate(toDate.getDate() + 1);
        expeditionConstraints.push(where("expeditionDate", "<=", toDate));
    }
    if (filter.client) {
        expeditionConstraints.push(where("client.id", "==", filter.client.id));
    }
    if (filter.driver) {
        expeditionConstraints.push(where("driver.id", "==", filter.driver.id));
    }
    if (filter.car) {
        expeditionConstraints.push(where("car.id", "==", filter.car.id));
    }

    const [expeditionsData, loadExpeditions, errorExpeditions] =
        useFirebaseCollection("expedition", expeditionConstraints, expeditionConverter);


    const [expeditions, setExpeditions] = useState<ExpeditionType[] | undefined>(undefined);
    const [courses, setCourses] = useState<ExpeditionCourseType[] | undefined>();
    const [filteredCourses, setFilteredCourses] = useState<ExpeditionCourseType[] | undefined>();
    const [printCourse, setPrintCourse] = useState<ExpeditionCourseType | undefined>();

    const [selectedCar, setSelectedCar] = useState<{
        id: string;
        name: string;
        regPlate: string;
        maxWeight: number;
    } | undefined>();
    const [selectedDriver, setSelectedDriver] = useState<{
        id: string,
        name: string
    } | undefined>();
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedCourseNumber, setSelectedCourseNumber] = useState<string | undefined>();
    const [selectedStatus, setSelectedStatus] = useState<string | undefined>();
    const [updatingSelected, setUpdatingSelected] = useState(false);
    const [deletingSelected, setDeletingSelected] = useState(false);

    const usersDialog = <UsersDialog show={true} zIndex={1060} onClose={() => {
    }}/>;
    const cardsDialog = <CarsDialog show={true} zIndex={1060} onClose={() => {
    }}/>;

    function isExpeditionActive(exp: ExpeditionType) {
        return exp.status === "ПЛАНИРАНА" || exp.status === "ИЗПЪЛНЯВА СЕ";
    }

    useEffect(
        () => {
            setExpeditions(undefined);
            setCourses(undefined);

            if (expeditionsData) {
                const corses: ExpeditionCourseType[] = [];
                const exps: ExpeditionType[] = [];
                expeditionsData.docs.forEach(d => {
                    const exp = d.data() as ExpeditionType;
                    exps.push(exp);
                    const course: ExpeditionCourseType = {
                        id: exp.id!,
                        expeditionDate: exp.expeditionDate!,
                        driverId: exp.driver?.id ?? "",
                        driverName: exp.driver?.name ?? "",
                        carId: exp.car?.id ?? "",
                        carName: exp.car?.name ?? "",
                        courseNumber: exp.courseNumber,
                        carMaxWeight: exp.car?.maxWeight ?? 0,
                        totalQuantity: exp.productQty || 0,
                        totalParcels: exp.weights?.leaving?.parcels || 0,
                        totalPallets: exp.weights?.leaving?.pallets || 0,
                        totalScrap: exp.weights?.arriving?.scrap || 0,
                        totalDelivery: exp.weights?.arriving?.delivery || 0,
                        totalPaletsLeaving: exp.palets?.leaving || 0,
                        totalPaletsArriving: exp.palets?.arriving || 0,
                        viewStatus: isExpeditionActive(exp) ? "active" : "completed",
                    }
                    const expeditonDate = dateToString(exp.expeditionDate);
                    const existingCourse = corses.find(c =>
                        dateToString(c.expeditionDate) === expeditonDate &&
                        (c.driverId ?? "") === (course.driverId ?? "") &&
                        (c.carId ?? "") === (course.carId ?? "") &&
                        c.courseNumber === course.courseNumber);
                    if (!existingCourse) {
                        corses.push(course);
                    } else {
                        existingCourse.totalParcels += course.totalParcels;
                        existingCourse.totalPallets += course.totalPallets;
                        existingCourse.totalScrap += course.totalScrap;
                        existingCourse.totalDelivery += course.totalDelivery;
                        existingCourse.totalPaletsLeaving += course.totalPaletsLeaving;
                        existingCourse.totalPaletsArriving += course.totalPaletsArriving;
                        const isCourseActive = isExpeditionActive(exp);
                        if (isCourseActive) {
                            existingCourse.viewStatus = "active";
                        }
                    }
                });

                setExpeditions(exps);
                setCourses(corses);
            }
        }, [expeditionsData]
    );

    useEffect(() => {
        if (courses) {
            setFilteredCourses(courses.filter(c =>
                (viewMode === "active" && c.viewStatus === "active") ||
                (viewMode === "completed" && c.viewStatus === "completed")
            ));
        } else {
            setFilteredCourses(undefined);
        }
    }, [courses, viewMode]);

    function getCourseExpeditions(printCourse: ExpeditionCourseType, expeditionTypes: ExpeditionType[]) {
        return expeditionTypes.filter(e =>
            dateToString(e.expeditionDate) === dateToString(printCourse.expeditionDate) &&
            e.driver?.id === printCourse.driverId &&
            e.car?.id === printCourse.carId &&
            e.courseNumber === printCourse.courseNumber);
    }

    function onSelectExpeditons(expeditions: ExpeditionType[]) {
        const newList = [...selectedExpeditons];
        expeditions.forEach(
            selected => {
                const index = selectedExpeditons.findIndex(e => e.id === selected.id);
                if (index === -1) {
                    newList.push(selected);
                } else {
                    newList.splice(index, 1);
                }
            }
        );

        setSelectedExpeditons(newList)
    }

    async function updateSelected() {
        try {
            setUpdatingSelected(true);

            for (const exp of selectedExpeditons) {
                const updated: ExpeditionType = {...exp,};
                if (selectedDate) {
                    updated.expeditionDate = selectedDate;
                }
                if (selectedDriver) {
                    updated.driver = selectedDriver;
                }
                if (selectedCar) {
                    updated.car = selectedCar;
                }
                if (selectedCourseNumber) {
                    updated.courseNumber = selectedCourseNumber;
                }
                if (selectedStatus) {
                    updated.status = selectedStatus as ExpeditionStatusType;
                }

                // update
                await updateDoc(doc(fbDb, "expedition", updated.id!), updated);

            }

            setSelectedDate(null);
            setSelectedDriver(undefined);
            setSelectedCar(undefined);
            setSelectedCourseNumber(undefined);
            setSelectedStatus(undefined);
            setSelectedExpeditons([]);
        } catch (e) {
            console.error(e);
            showErrorsListInToast(
                "Грешка при обновяване на експедиции",
                ["Неуспешно обновяване на експедиции"]
            );
        } finally {
            setUpdatingSelected(false);
        }
    }

    async function removeSelected() {
        try {
            setUpdatingSelected(true);
            for (const exp of selectedExpeditons) {
                await deleteDoc(doc(fbDb, "expedition", exp.id!));
            }
            setSelectedExpeditons([]);
        } catch (e) {
            console.error(e);
            showErrorsListInToast(
                "Грешка при изтриване на експедиции",
                ["Неуспешно изтриване на експедиции"]
            );
        } finally {
            setUpdatingSelected(false);
        }
    }

    const {canUserEdit} = useUserRights();
    return (
        <>
            {
                selectedExpeditons.length > 1 &&
                <div className={"p-2 bg-light border-bottom"}>
                    <Row>
                        <Col xs={"auto"}>
                            <Form.Group>
                                <Form.Label>
                                    Дата на експедиция:
                                </Form.Label><br/>
                                <ReactDatePicker
                                    className={"form-control"}
                                    selected={selectedDate}
                                    dateFormat={"dd.MM.yyyy"}
                                    isClearable={true}
                                    onChange={
                                        date => setSelectedDate(date)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Шофьор:
                                </Form.Label>
                                <SelectValueFromDialog
                                    value={selectedDriver?.name || ""}
                                    dialog={usersDialog}
                                    onChange={(selected) => {
                                        setSelectedDriver(selected)
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Превозно средство:
                                </Form.Label>
                                <SelectValueFromDialog
                                    value={`${selectedCar?.name || ""} ${selectedCar?.regPlate ? `[${selectedCar?.regPlate}]` : ""}`}
                                    dialog={cardsDialog}
                                    onChange={(selected) => {
                                        setSelectedCar(selected)
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <Form.Group>
                                <Form.Label>
                                    Курс:
                                </Form.Label>
                                <Form.Select
                                    value={selectedCourseNumber}
                                    onChange={
                                        (e) =>
                                            setSelectedCourseNumber(e.target.value === "" ? undefined : e.target.value)
                                    }
                                >
                                    <option value={""}></option>
                                    <option value={"Първи"}>Първи</option>
                                    <option value={"Втори"}>Втори</option>
                                    <option value={"Трети"}>Трети</option>
                                    <option value={"Четвърти"}>Четвърти</option>
                                    <option value={"Пети"}>Пети</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <Form.Group>
                                <Form.Label>
                                    Статус:
                                </Form.Label>
                                <Form.Select
                                    value={selectedStatus}
                                    disabled={!canUserEdit("Производство и експедиция-Дневен план за експедиция")}
                                    onChange={e => setSelectedStatus(e.target.value === "" ? undefined : e.target.value)}
                                >
                                    <option value={""}></option>
                                    <option value={"ПЛАНИРАНА"}>Планирана</option>
                                    <option value={"ПРИКЛЮЧЕНА"}>Приключена</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs={"auto"} className={"pt-4 mt-3"}>
                            {
                                updatingSelected &&
                                <Spinner animation={"border"}/>

                            }
                            {
                                !deletingSelected && <>
                                    <CheckIconButton onClick={updateSelected}/>&nbsp;
                                    <CancelIconButton onClick={
                                        () => {
                                            setSelectedDate(null);
                                            setSelectedDriver(undefined);
                                            setSelectedCar(undefined);
                                            setSelectedCourseNumber(undefined);
                                            setSelectedStatus(undefined);
                                            setSelectedExpeditons([]);
                                        }}
                                    />
                                </>
                            }
                            &nbsp;
                            <DeleteConfirmIconButton
                                size={"sm"}
                                modeChange={(inConfirmMode) => setDeletingSelected(inConfirmMode)}
                                onClick={removeSelected}
                            />
                        </Col>
                    </Row>
                </div>
            }
            <Table size={"sm"} hover bordered>
                <thead style={{backgroundColor: "white"}}>
                <tr>
                    <CenterAlignedCell header style={{width: onSelect ? 120 : 90}} rowSpan={3}></CenterAlignedCell>
                    <CenterAlignedCell header className={"w-100px"} rowSpan={3}>Дата</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Шофьор</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Автомобил</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Курс</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Запитване</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}><I18Label label={"Клиент"}/></CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Посока</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Адрес</CenterAlignedCell>
                    <CenterAlignedCell header colSpan={2} rowSpan={2}>Лице за контакт</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Допълнителна информация</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Кол.</CenterAlignedCell>
                    <CenterAlignedCell header colSpan={5} style={{width: "320px"}}>Тегло, кг</CenterAlignedCell>
                    <CenterAlignedCell header colSpan={2} rowSpan={2} style={{width: "160px"}}>Палети,
                        бр</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Статус</CenterAlignedCell>
                </tr>
                <tr>
                    <CenterAlignedCell header colSpan={2} style={{width: "160px"}}>Заминаване</CenterAlignedCell>
                    <CenterAlignedCell header colSpan={3} style={{width: "160px"}}>Връщане</CenterAlignedCell>
                </tr>
                <tr>
                    <CenterAlignedCell header>Име</CenterAlignedCell>
                    <CenterAlignedCell header>Телефон</CenterAlignedCell>
                    <CenterAlignedCell header style={{width: "80px"}}>Пратка</CenterAlignedCell>
                    {/*<CenterAlignedCell header style={{width: "80px"}}>Палет</CenterAlignedCell>*/}
                    <CenterAlignedCell header style={{width: "80px"}}>Натов.</CenterAlignedCell>

                    <CenterAlignedCell header style={{width: "80px"}}>Скрап</CenterAlignedCell>
                    <CenterAlignedCell header style={{width: "80px"}}>Доставка</CenterAlignedCell>
                    <CenterAlignedCell header style={{width: "80px"}}>Натов.</CenterAlignedCell>

                    <CenterAlignedCell header style={{width: "80px"}}>Замин.</CenterAlignedCell>
                    <CenterAlignedCell header style={{width: "80px"}}>Връщане</CenterAlignedCell>
                </tr>
                </thead>
                <tbody>
                {
                    loadExpeditions &&
                    <tr>
                        <td colSpan={6} className={"text-center fw-bold"}>
                            <Spinner animation={"border"}/>
                        </td>
                    </tr>
                }
                {
                    filteredCourses && filteredCourses.length === 0 &&
                    <tr>
                        <td colSpan={99} className={"text-center fw-bold"}>Няма планирани курсове</td>
                    </tr>
                }
                {
                    filteredCourses && filteredCourses.length > 0 &&
                    filteredCourses.map(
                        course => <>
                            <ExpeditionCourse
                                zIndex={zIndex + 1}
                                key={course.id}
                                course={course}
                                expeditions={expeditions ?? []}
                                onPrint={() => setPrintCourse(course)}
                                onSelect={onSelectExpeditons}
                                selectedExpeditons={selectedExpeditons}
                            />
                        </>
                    )
                }
                </tbody>
            </Table>
            {
                printCourse &&
                <ExpeditionPlanPrintPreview
                    zIndex={zIndex + 2}
                    expeditionCourse={printCourse}
                    expeditions={getCourseExpeditions(printCourse, expeditions ?? [])}
                    onDismiss={() => setPrintCourse(undefined)
                    }
                />
            }
        </>
    );
}
export default ExpeditionPlanTable;
