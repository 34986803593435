import React, {useState} from "react";
import {Link} from "react-router-dom";
import {API} from "../api";
import {UserNameComponent} from "./UserNameComponent";
import {Col, Nav, Navbar, NavDropdown, Row} from "react-bootstrap";
import {RegionsDialog} from "../pages/nomenclatures/regions/RegionsDialog";
import {ClientsDialog} from "../pages/nomenclatures/clients/ClientsDialog";
import {UsersDialog} from "../pages/nomenclatures/users/UsersDialog";
import {ProducersDialog} from "../pages/nomenclatures/producers/ProducersDialog";
import {ProvidersDialog} from "../pages/nomenclatures/providers/ProvidersDialog";
import {ProductsDialog} from "../pages/nomenclatures/products/ProductsDialog";
import {ServicesDialog} from "../pages/nomenclatures/services/ServicesDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressBook, faBank,
    faBatteryCar, faBook, faBuildingCircleCheck, faCalendar, faChartLine, faClipboardCheck,
    faCogs, faComments, faDollar, faDolly, faFileInvoice,
    faGlobe,
    faHandshake, faHome,
    faIndustry, faList,
    faPersonDigging, faRobot,
    faTruck, faUsers, faUserTie, faWarehouse
} from "@fortawesome/free-solid-svg-icons";
import {StandartsDialog} from "../pages/nomenclatures/standarts/StandartsDialog";
import {PositionsDialog} from "../pages/nomenclatures/positions/PositionsDialog";
import {CampaignsDialog} from "../pages/nomenclatures/campaigns/CampaignsDialog";
import {WarehouseObjectsDialog} from "../pages/nomenclatures/warehouse-objects/WarehouseObjectsDialog";
import {VirtualProductsDialog} from "../pages/nomenclatures/virtual-products/VirtualProductsDialog";
import {ProductionDialog} from "../pages/nomenclatures/production/ProductionDialog";
import {BankAccountsDialog} from "../pages/nomenclatures/bank-accounts/BankAccountsDialog";
import {CarsDialog} from "../pages/nomenclatures/cars/CarsDialog";
import I18Label from "../i18/i18label";
import {DictionaryDialog} from "../pages/nomenclatures/dictionary/DictionaryDialog";
import {OwnCompaniesDialog} from "../pages/nomenclatures/own-companies/OwnCompaniesDialog";
import {CurrenciesDialog} from "../pages/nomenclatures/currencies/CurrenciesDialog";
import useUserRights from "../hooks/useUserRights";

export function HeaderComponent(props: any) {
    const [selectedNav, setSelectedNav] = useState<string | null>(null);
    const {canUserView} = useUserRights();

    return (
        <>
            <Nav className="navbar navbar-light bg-light page-header"
                 onSelect={(selectedItem) => setSelectedNav(selectedItem)}
            >
                <Navbar.Brand className={"w-160px"}>
                    <Link to={"/"} className={"text-dark text-nowrap"}>
                        <img src={require('../assets/images/logo.png')} style={{height: "2em"}}/>
                    </Link>
                </Navbar.Brand>
                {
                    API.user &&
                    <>
                        <Row>
                            {
                                canUserView('CRM') &&
                                <Col>
                                    <NavDropdown title="CRM" id="nav-dropdown" className={"fw-bold"}>
                                        {
                                            canUserView('CRM-Календар') &&
                                            <NavDropdown.Item>
                                                <Link to={"/crm"}
                                                      className={"text-dark text-nowrap text-decoration-none"}>
                                                    <FontAwesomeIcon icon={faAddressBook}/>&nbsp;&nbsp;<I18Label
                                                    label={"Календар"}/>
                                                </Link>
                                            </NavDropdown.Item>
                                        }
                                        {
                                            canUserView('CRM-Справка за активности') &&
                                            <NavDropdown.Item>
                                                <Link to={"/crm/reports/activities"}
                                                      className={"text-dark text-nowrap text-decoration-none"}>
                                                    <FontAwesomeIcon icon={faClipboardCheck}/>&nbsp;&nbsp;<I18Label
                                                    label={"Справка за активности"}/>
                                                </Link>
                                            </NavDropdown.Item>
                                        }
                                        {
                                            canUserView('CRM-Запитвания и оферти') &&
                                            <NavDropdown.Item eventKey="Customer Inquiries">
                                                <Link to={"/crm/customers-inquiries"}
                                                      className={"text-dark text-nowrap text-decoration-none"}>
                                                    <FontAwesomeIcon icon={faComments}/>&nbsp;&nbsp;<I18Label
                                                    label={"Запитвания и оферти"}/>
                                                </Link>
                                            </NavDropdown.Item>
                                        }
                                        {
                                            canUserView('CRM-Обобщена справка') &&
                                            <NavDropdown.Item eventKey="Summary Report">
                                                <Link to={"/crm/reports/summary"}
                                                      className={"text-dark text-nowrap text-decoration-none"}>
                                                    <FontAwesomeIcon icon={faChartLine}/>&nbsp;&nbsp;<I18Label
                                                    label={"Обобщена справка"}/>
                                                </Link>
                                            </NavDropdown.Item>
                                        }
                                    </NavDropdown>
                                </Col>
                            }

                            {
                                canUserView('Склад') &&
                                <Col>
                                    <NavDropdown title={<I18Label label={"Склад"}/>} id="nav-dropdown"
                                                 className={"fw-bold"}>
                                        {
                                            canUserView('Склад-Склад - продажби') &&
                                            <NavDropdown.Item eventKey="WAREHOUSESOUT">
                                                <Link to={"/warehouses/out-transactions"}
                                                      className={"text-dark text-nowrap text-decoration-none"}>
                                                    <FontAwesomeIcon icon={faDolly}/>&nbsp;&nbsp;<I18Label
                                                    label={"Склад - продажби"}/>
                                                </Link>
                                            </NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Склад-Склад - доставки') &&
                                            <NavDropdown.Item eventKey="WAREHOUSESIN">
                                                <Link to={"/warehouses/in-transactions"}
                                                      className={"text-dark text-nowrap text-decoration-none"}>
                                                    <FontAwesomeIcon icon={faDolly}/>&nbsp;&nbsp;<I18Label
                                                    label={"Склад - доставки"}/>
                                                </Link>
                                            </NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Склад-Склад - заявки') &&
                                            <NavDropdown.Item eventKey="WAREHOUSES_REQUESTS">
                                                <Link to={"/warehouses/requests"}
                                                      className={"text-dark text-nowrap text-decoration-none"}>
                                                    <FontAwesomeIcon icon={faDolly}/>&nbsp;&nbsp;<I18Label
                                                    label={"Склад - заявки"}/>
                                                </Link>
                                            </NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Склад-Справки') &&
                                            <NavDropdown.Item eventKey="WAREHOUSESREPORT">
                                                <Link to={"/warehouses/reports"}
                                                      className={"text-dark text-nowrap text-decoration-none"}>
                                                    <FontAwesomeIcon icon={faChartLine}/>&nbsp;&nbsp;<I18Label
                                                    label={"Справки"}/>
                                                </Link>
                                            </NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Склад-Производствена номенклатура') &&
                                            <NavDropdown.Item eventKey="VIRTUAL_PRODUCTS">
                                                <FontAwesomeIcon icon={faList}/>&nbsp;&nbsp;
                                                <I18Label label={"Производствена номенклатура"}/></NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Склад-Собствено производство') &&
                                            <NavDropdown.Item eventKey="PRODUCTION">
                                                <FontAwesomeIcon icon={faRobot}/>&nbsp;&nbsp;
                                                <I18Label label={"Собствено производство"}/></NavDropdown.Item>
                                        }
                                        <NavDropdown.Item eventKey="Corporate Pricing"><I18Label
                                            label={"Интрастат"}/></NavDropdown.Item>
                                        {
                                            canUserView('Склад-Складово стопанство') &&
                                            <NavDropdown.Item eventKey="WAREHOUSES">
                                                <Link to={"/warehouses"}
                                                      className={"text-dark text-nowrap text-decoration-none"}>
                                                    <FontAwesomeIcon icon={faWarehouse}/>&nbsp;&nbsp;<I18Label
                                                    label={"Складово стопанство"}/>
                                                </Link>
                                            </NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Склад-Складови ревизии') &&
                                            <NavDropdown.Item eventKey="WAREHOUSEREVISION">
                                                <Link to={"/warehouses-revisions"}
                                                      className={"text-dark text-nowrap text-decoration-none"}>
                                                    <FontAwesomeIcon icon={faBuildingCircleCheck}/>&nbsp;&nbsp;<I18Label
                                                    label={"Складови ревизии"}/>
                                                </Link>
                                            </NavDropdown.Item>
                                        }
                                    </NavDropdown>
                                </Col>
                            }

                            {
                                canUserView('Производство и експедиция') &&
                                <Col>
                                    <NavDropdown title={<I18Label label={"Производство и експедиция"}/>}
                                                 id="nav-dropdown"
                                                 className={"fw-bold"}>
                                        {
                                            canUserView('Производство и експедиция-Производствен план') &&
                                            <NavDropdown.Item eventKey="Production Plan">
                                                <Link to={"/production/plan"}
                                                      className={"text-dark text-nowrap text-decoration-none"}>
                                                    <FontAwesomeIcon icon={faCalendar}/>&nbsp;&nbsp;<I18Label
                                                    label={"Производствен план"}/>
                                                </Link>
                                            </NavDropdown.Item>
                                        }
                                        <NavDropdown.Item eventKey="Corporate Pricing"><I18Label
                                            label={"Дневен капацитет"}/></NavDropdown.Item>
                                        <NavDropdown.Item eventKey="Corporate Pricing">
                                            <I18Label label={"Отчитане на производство"}/></NavDropdown.Item>
                                        <NavDropdown.Item eventKey="Corporate Pricing">
                                            <I18Label label={"Натовареност на производство"}/></NavDropdown.Item>
                                        {
                                            canUserView('Производство и експедиция-Дневен план за експедиция') &&
                                            <NavDropdown.Item eventKey="Expedition Plan">
                                                <Link to={"/expedition/plan"}
                                                      className={"text-dark text-nowrap text-decoration-none"}>
                                                    <FontAwesomeIcon icon={faCalendar}/>&nbsp;&nbsp;<I18Label
                                                    label={"Дневен план за експедиция"}/>
                                                </Link>
                                            </NavDropdown.Item>
                                        }
                                        <NavDropdown.Item eventKey="Corporate Pricing">
                                            <I18Label label={"Документи за експедиция"}/></NavDropdown.Item>
                                    </NavDropdown>
                                </Col>
                            }

                            <Col>
                                <NavDropdown title={<I18Label label={"Търговци"}/>} id="nav-dropdown"
                                             className={"fw-bold"}>
                                    <NavDropdown.Item eventKey="Basic Pricing"><I18Label
                                        label={"Планиране на работен ден"}/></NavDropdown.Item>
                                    <NavDropdown.Item eventKey="Corporate Pricing"><I18Label
                                        label={"Командировки"}/></NavDropdown.Item>
                                    <NavDropdown.Item eventKey="Corporate Pricing"><I18Label
                                        label={"Отчитане"}/></NavDropdown.Item>
                                    <NavDropdown.Item eventKey="Corporate Pricing"><I18Label
                                        label={"Бонусна система"}/></NavDropdown.Item>
                                </NavDropdown>
                            </Col>

                            <Col>
                                <Nav.Item>
                                    <Nav.Link href="/home" className={"fw-bold"}><I18Label
                                        label={"Поддръжка"}/></Nav.Link>
                                </Nav.Item>
                            </Col>

                            <Col>
                                <Nav.Item>
                                    <Nav.Link href="/home" className={"fw-bold text-nowrap"}>
                                        <I18Label label={"Присъствен график"}/></Nav.Link>
                                </Nav.Item>
                            </Col>

                            {
                                canUserView('Номенклатури') &&
                                <Col>
                                    <NavDropdown title={<I18Label label={"Номенклатури"}/>} id="nav-dropdown"
                                                 className={"fw-bold"}
                                                 color={"#000"} style={{color: "#000 !important"}}
                                    >
                                        {
                                            canUserView('Номенклатури-Държави и региони') &&
                                            <NavDropdown.Item eventKey="REGIONS">
                                                <FontAwesomeIcon icon={faGlobe}/>&nbsp;&nbsp;<I18Label
                                                label={"Държави и региони"}/></NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Номенклатури-Клиенти') &&
                                            <NavDropdown.Item eventKey="CLIENTS">
                                                <FontAwesomeIcon icon={faHandshake}/>&nbsp;&nbsp;<I18Label
                                                label={"Клиенти"}/></NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Номенклатури-Производители') &&
                                            <NavDropdown.Item eventKey="PRODUCERS">
                                                <FontAwesomeIcon icon={faIndustry}/>&nbsp;&nbsp;<I18Label
                                                label={"Производители"}/></NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Номенклатури-Доставчици') &&
                                            <NavDropdown.Item eventKey="PROVIDERS">
                                                <FontAwesomeIcon icon={faTruck}/>&nbsp;&nbsp;<I18Label
                                                label={"Доставчици"}/></NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Номенклатури-Продукти') &&
                                            <NavDropdown.Item eventKey="PRODUCTS">
                                                <FontAwesomeIcon icon={faBatteryCar}/>&nbsp;&nbsp;<I18Label
                                                label={"Продукти"}/></NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Номенклатури-Услуги') &&
                                            <NavDropdown.Item eventKey="SERVICES">
                                                <FontAwesomeIcon icon={faPersonDigging}/>&nbsp;&nbsp;<I18Label
                                                label={"Услуги"}/></NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Номенклатури-Стандарти') &&
                                            <NavDropdown.Item eventKey="STANDARTS">
                                                <FontAwesomeIcon icon={faCogs}/>&nbsp;&nbsp;<I18Label
                                                label={"Стандарти"}/>
                                            </NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Номенклатури-Превозни средства') &&
                                            <NavDropdown.Item eventKey="CARS">
                                                <FontAwesomeIcon icon={faTruck}/>&nbsp;&nbsp;<I18Label
                                                label={"Превозни средства"}/>
                                            </NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Номенклатури-Валутни курсове') &&
                                            <NavDropdown.Item eventKey="CURRENCIES">
                                                <FontAwesomeIcon icon={faDollar}/>&nbsp;&nbsp;<I18Label
                                                label={"Валутни курсове"}/>
                                            </NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Номенклатури-Фирми') &&
                                            <NavDropdown.Item eventKey={"OWN_COMPANIES"}>
                                                <FontAwesomeIcon icon={faHome}/>&nbsp;&nbsp;<I18Label
                                                label={"Фирми"}/>
                                            </NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Номенклатури-Складови обекти') &&
                                            <NavDropdown.Item eventKey="WAREHOUSE_OBJECTS">
                                                <FontAwesomeIcon icon={faWarehouse}/>&nbsp;&nbsp;<I18Label
                                                label={"Складови обекти"}/>
                                            </NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Номенклатури-Банкови сметки') &&
                                            <NavDropdown.Item eventKey="BANK_ACCOUNTS">
                                                <FontAwesomeIcon icon={faBank}/>&nbsp;&nbsp;<I18Label
                                                label={"Банкови сметки"}/>
                                            </NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Номенклатури-Потребители') &&
                                            <NavDropdown.Item eventKey="USERS">
                                                <FontAwesomeIcon
                                                    icon={faUsers}/>&nbsp;&nbsp;<I18Label
                                                label={"Потребители"}/></NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Номенклатури-Позиции') &&
                                            <NavDropdown.Item eventKey="USER_POSITIONS">
                                                <FontAwesomeIcon
                                                    icon={faUserTie}/>&nbsp;&nbsp;<I18Label
                                                label={"Позиции"}/></NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Номенклатури-Речник') &&
                                            <NavDropdown.Item eventKey="DICTIONARY">
                                                <FontAwesomeIcon
                                                    icon={faBook}/>&nbsp;&nbsp;<I18Label
                                                label={"Речник"}/></NavDropdown.Item>
                                        }
                                        {
                                            canUserView('Номенклатури-Кампании') &&
                                            <NavDropdown.Item eventKey="CAMPAIGN">
                                                <FontAwesomeIcon
                                                    icon={faAddressBook}/>&nbsp;&nbsp;<I18Label
                                                label={"Кампании"}/></NavDropdown.Item>
                                        }
                                    </NavDropdown>
                                </Col>
                            }

                        </Row>


                        <Navbar.Text className={"col-auto"}>
                            <UserNameComponent/>
                        </Navbar.Text>
                    </>
                }
            </Nav>

            {
                (selectedNav === "REGIONS") &&
                <RegionsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "CLIENTS") &&
                <ClientsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "USERS") && <UsersDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "PRODUCERS") &&
                <ProducersDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "PROVIDERS") &&
                <ProvidersDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "PRODUCTS") &&
                <ProductsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "SERVICES") &&
                <ServicesDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "STANDARTS") &&
                <StandartsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "OWN_COMPANIES") &&
                <OwnCompaniesDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "WAREHOUSE_OBJECTS") &&
                <WarehouseObjectsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "BANK_ACCOUNTS") &&
                <BankAccountsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "CARS") &&
                <CarsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "CURRENCIES") &&
                <CurrenciesDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "USER_POSITIONS") &&
                <PositionsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "DICTIONARY") &&
                <DictionaryDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "CAMPAIGN") &&
                <CampaignsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "VIRTUAL_PRODUCTS") &&
                <VirtualProductsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
            {
                (selectedNav === "PRODUCTION") &&
                <ProductionDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>
            }
        </>
    )
}
