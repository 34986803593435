import {
    Modal, ModalBody,
    ModalTitle, Tab, Tabs,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import React, {useEffect, useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalBackground from "../../../common/ModalBackground";
import DialogCloseButton from "../../../common/DialogCloseButton";
import {CurrenciesList} from "./CurrenciesList";
import {faDollarSign} from "@fortawesome/free-solid-svg-icons";
import {CurrenyDetails} from "./CurrenyDetails";
import {
    CurrencyNameType,
    CurrencyType,
    EMPTY_CURRENCY,
    LEU_CURRENCY_NAME
} from "../../../fb-converters/currency-converter";
import {CurrencyDelete} from "./CurrencyDelete";

export function CurrenciesDialog({zIndex, show, onClose, onSelect}: DIALOG_OPTIONS) {
    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyNameType>("LEU");
    const [viewMode, setViewMode] = useState<"VIEW_LIST" | "VIEW_ADD" | "VIEW_EDIT" | "VIEW_DELETE">('VIEW_LIST');
    const [editDocument, setEditDocument] = useState<CurrencyType | null>(null);
    const [deleteDocument, setDeleteDocument] = useState<CurrencyType | null>(null);
    const [addDocument, setAddDocument] = useState(false);

    useEffect(() => {
        setViewMode(editDocument ? "VIEW_EDIT" : "VIEW_LIST");
    }, [editDocument])

    useEffect(() => {
        setViewMode(deleteDocument ? "VIEW_DELETE" : "VIEW_LIST");
    }, [deleteDocument])

    useEffect(() => {
        setViewMode(addDocument ? "VIEW_ADD" : "VIEW_LIST");
    }, [addDocument])

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faDollarSign}/> Валутни курсове
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(null)}/>
                </ModalHeader>
                <ModalBody>
                    <Tabs activeKey={selectedCurrency}
                          onSelect={(k: any) => setSelectedCurrency(k)}>
                        <Tab title={LEU_CURRENCY_NAME} eventKey={LEU_CURRENCY_NAME}/>
                    </Tabs>
                    <CurrenciesList onClose={onClose}
                                    isVisible={viewMode === "VIEW_LIST"}
                                    currency={selectedCurrency}
                                    onSelect={onSelect}
                                    onAdd={() => setAddDocument(true)}
                                    onEdit={doc => {
                                        setEditDocument(doc);
                                    }}
                                    onDelete={doc => {
                                        setDeleteDocument(doc);
                                    }}
                    />

                    {
                        viewMode === "VIEW_EDIT" && editDocument &&
                        <CurrenyDetails currency={editDocument}
                                        zIndex={(zIndex || 1100) + 10}
                                        onDone={() => {
                                            setEditDocument(null)
                                        }}/>
                    }
                    {
                        viewMode === "VIEW_DELETE" && deleteDocument &&
                        <CurrencyDelete currency={deleteDocument} onDone={() => {
                            setDeleteDocument(null)
                        }}/>
                    }
                    {
                        viewMode === "VIEW_ADD" && addDocument &&
                        <CurrenyDetails currency={{...EMPTY_CURRENCY, name: selectedCurrency}}
                                        zIndex={(zIndex || 1100) + 10}
                                        onDone={() => {
                                            setAddDocument(false)
                                        }}/>
                    }
                </ModalBody>
            </Modal>
        </ModalBackground>
    )
}
