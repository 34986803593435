import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from "firebase/firestore";
import {dateWithZeroTime} from "../common";

export type WarehouseRevisionIndexItemType = {
    id: string | null;
    revisionNumber: number;
    revisionDate: Date;
    revisionAmount: number;
    createdBy: null | string;
    receivedBy: null | string;
    ownCompanyId: string | null;
    warehouseId: string | null;
}

export const EMPTY_WAREHOUSE_REVISION_INDEX_ITEM: WarehouseRevisionIndexItemType = {
    id: null,
    revisionNumber: 0,
    revisionDate: new Date(),
    revisionAmount: 0,
    createdBy: null,
    receivedBy: null,
    ownCompanyId: null,
    warehouseId: null
}

export type WarehouseRevisionIndexType = {
    items: WarehouseRevisionIndexItemType[];
}

export type WarehouseRevisionItemType = {
    productId: string | null;
    productIsOwnProduction: boolean;
    productCode: string | null;
    productName: string | null;
    productDimension: string | null;
    productSinglePrice: number;
    expectedQty: number;
    actualQty: number;
}

export type WarehouseRevisionItemsType = {
    revisionDate: Date;
    items: WarehouseRevisionItemType[];
    productIds: string[];
    ownCompanyId: string | null;
    warehouseId: string | null;
}

export const EMPTY_WAREHOUSE_REVISION_ITEM: WarehouseRevisionItemType = {
    productId: null,
    productIsOwnProduction: false,
    productCode: null,
    productName: null,
    productDimension: null,
    productSinglePrice: 0,
    expectedQty: 0,
    actualQty: 0
}

export const warehouseRevisionIndexConverter = {
    toFirestore(index: WarehouseRevisionIndexType): DocumentData {
        return {
            items: index.items.map(item => {
                return {
                    id: item.id,
                    revisionNumber: item.revisionNumber,
                    revisionDate: item.revisionDate,
                    revisionAmount: item.revisionAmount,
                    createdBy: item.createdBy,
                    receivedBy: item.receivedBy,
                    ownCompanyId: item.ownCompanyId,
                    warehouseId: item.warehouseId
                }
            })
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): WarehouseRevisionIndexType {
        const data = snapshot.data(options)!;
        return {
            items: data.items.map((item: any) => {
                return {
                    id: item.id,
                    revisionNumber: item.revisionNumber,
                    revisionDate: item.revisionDate.toDate(),
                    revisionAmount: item.revisionAmount,
                    createdBy: item.createdBy,
                    receivedBy: item.receivedBy,
                    ownCompanyId: item.ownCompanyId ?? "",
                    warehouseId: item.warehouseId ?? ""
                }
            })
        };
    }
};


export const warehouseRevisionItemConverter = {
    toFirestore(item: WarehouseRevisionItemsType): DocumentData {
        return {
            revisionDate: dateWithZeroTime(item.revisionDate),
            productIds: item.items.map(item => {
                return item.productId + ":" + (item.productIsOwnProduction ? "1" : "0");
            }) ?? [],
            items: item.items.map(item => {
                return {
                    productId: item.productId,
                    productIsOwnProduction: item.productIsOwnProduction ?? false,
                    productCode: item.productCode,
                    productName: item.productName,
                    productDimension: item.productDimension,
                    productSinglePrice: item.productSinglePrice,
                    expectedQty: item.expectedQty,
                    actualQty: item.actualQty
                }
            }),
            ownCompanyId: item.ownCompanyId,
            warehouseId: item.warehouseId
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): WarehouseRevisionItemsType {
        const data = snapshot.data(options)!;
        return {
            revisionDate: data.revisionDate?.toDate() ?? new Date(),
            productIds: data.productIds ?? [],
            items: data.items.map((item: any) => {
                return {
                    productId: item.productId,
                    productIsOwnProduction: item.productIsOwnProduction ?? false,
                    productCode: item.productCode,
                    productName: item.productName,
                    productDimension: item.productDimension,
                    productSinglePrice: item.productSinglePrice,
                    expectedQty: item.expectedQty,
                    actualQty: item.actualQty
                }
            }),
            ownCompanyId: data.ownCompanyId ?? "",
            warehouseId: data.warehouseId ?? ""
        };
    }
};
