import React, {useEffect, useMemo, useState} from "react";

import {
    addDoc,
    collection, deleteDoc, doc,
    doc as getDocRef, getDocs, query, runTransaction, updateDoc, where
} from "firebase/firestore";
import {Button, Col, Form, Modal, ModalBody, ModalTitle, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faFile, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb} from "../../../App";
import {
    dateTimeToString,
    dateToString, removeEmptyFields, showErrorsListInToast, showMsgInToast,
    zeroToEmptyString,
} from "../../../common";
import ReactDatePicker from "react-datepicker";
import {useDocument} from "react-firebase-hooks/firestore";
import {productionCardConverter, ProductionCardType} from "../types/ProductionCardType";
import {CustomerInvoiceItemType, CustomerInvoiceType} from "../../../fb-converters/cutomerInvoice-converter";
import ProductionPlanSpecificationTable from "./ProductionPlanSpecificationTable";
import ProductionPlanLoader from "./ProductionPlanLoader";
import {ProductionCardProductsType} from "../types/ProductionCardProductType";
import ProductionPlanMaterialsTable from "./ProductionPlanMaterialsTable";
import globalState from "../../../global-state/global-state";
import {CustomerInquiryType} from "../../../fb-converters/cutomerInquiry-converter";
import DeleteConfirmIconButton from "../../../common/icon-buttons/DeleteConfirmIconButton";
import I18Label, {getI18Text} from "../../../i18/i18label";
import {GlobalStateType} from "../../../global-state/types/globalState";
import useOwnCompaniesRepo from "../../warehouse/hooks/useOwnCompaniesRepo";
import {OwnCompanyDropdown} from "../../nomenclatures/own-companies/OwnCompanySelector";
import useWarehouseObjectsRepo from "../../warehouse/hooks/useWarehouseObjectsRepo";
import {WarehouseItemType} from "../../warehouse/hooks/useWarehouseInItems";
import HisotryIconButton from "../../../common/icon-buttons/HisotryIconButton";
import ModalHeader from "react-bootstrap/ModalHeader";
import DialogCloseButton from "../../../common/DialogCloseButton";
import ModalBackground from "../../../common/ModalBackground";


interface Props {
    zIndex?: number,
    card: ProductionCardType,
    onDone: () => void
}

export function ProductionPlanHistoryDetails({
                                                 zIndex,
                                                 card,
                                                 onDone,
                                             }: Props) {
    const {ownCompanyWarehouseObjects} = useWarehouseObjectsRepo(card?.ownCompanyId);

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={true} size={"xl"} style={{zIndex: ((zIndex ?? 1200) + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faFile}/> <I18Label label={"Карта за възлагане към сервиза"}/>
                        {
                            card && card.changerName && card.createdAt &&
                            <Row className={"small fst-italic"}>
                                <Col>
                                    <span className={"small text-muted"}><I18Label
                                        label={"Автор: "}/> {card.changerName} / {dateTimeToString(card.createdAt)}</span>
                                </Col>
                            </Row>
                        }
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onDone()}/>
                </ModalHeader>
                <Modal.Body>
                    <Row>
                        <Col>
                            <h3>{card.specification[0].productName}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={"auto"}>
                            <Form.Group className="w-200px">
                                <Form.Label><I18Label label={"Фирма"}/>:</Form.Label>
                                <OwnCompanyDropdown
                                    readOnly={true}
                                    selectedOwnCompanyId={card.ownCompanyId}
                                    onChange={(selectedOwnCompanyId) => {
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Group className="w-100px">
                                <Form.Label><I18Label label={"Карта номер"}/>:</Form.Label>
                                <Form.Control value={card.cardNumber} readOnly={true} disabled={true}/>
                            </Form.Group>
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Group className="w-100px">
                                <Form.Label><I18Label label={"Дата"}/>:</Form.Label>
                                <Form.Control value={dateToString(card.cardDate)} disabled readOnly/>
                            </Form.Group>
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Group className="w-140px">
                                <Form.Label><I18Label label={"Запитване номер/дата"}/>:</Form.Label>
                                <Form.Control value={
                                    `${card.inquiryNumber ?? ""} / ${dateToString(card.inquiryDate)}`} disabled/>
                            </Form.Group>
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Group className="w-140px">
                                <Form.Label><I18Label label={"Поръчка номер/дата"}/>:</Form.Label>
                                <Form.Control value={
                                    `${card.invoiceNumber} / ${dateToString(card.invoiceDate)}`} disabled/>
                            </Form.Group>
                        </Col>
                        {/*<Col>*/}
                        {/*    <Form.Group>*/}
                        {/*        <Form.Label><I18Label label={"Относно"}/>:</Form.Label>*/}
                        {/*        <Form.Select value={card.regarding} disabled={true}>*/}
                        {/*            <option value={""}></option>*/}
                        {/*            <option value={"Доставка"}>{getI18Text("Доставка")}</option>*/}
                        {/*            <option value={"Ремонт"}>{getI18Text("Ремонт")}</option>*/}
                        {/*            <option value={"Производство"}>{getI18Text("Производство")}</option>*/}
                        {/*            <option value={"Продажба"}>{getI18Text("Продажба")}</option>*/}
                        {/*        </Form.Select>*/}
                        {/*    </Form.Group>*/}
                        {/*</Col>*/}
                        {/*<Col>*/}
                        {/*    <Form.Group>*/}
                        {/*        <Form.Label><I18Label label={"Място на извършване"}/>:</Form.Label>*/}
                        {/*        <Form.Select value={card.productionPlace} disabled={true}>*/}
                        {/*            <option value={""}></option>*/}
                        {/*            <option value={"В сервиза"}>{getI18Text("В сервиза")}</option>*/}
                        {/*            <option value={"При клиент"}>{getI18Text("При клиент")}</option>*/}
                        {/*            <option value={"От доставчик"}>{getI18Text("От доставчик")}</option>*/}
                        {/*        </Form.Select>*/}
                        {/*    </Form.Group>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row>
                        <h5 className={"mt-5"}>
                            <I18Label label={"Спецификация"}/>:
                        </h5>
                    </Row>
                    <Row>
                        <Col>
                            <ProductionPlanSpecificationTable items={card.specification}/>
                        </Col>
                    </Row>
                    <Row className={"mt-3"}>
                        <Col xs={"auto"}>
                            <h5>
                                <I18Label label={"Допълнителни изисквания"}/>:
                            </h5>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Control as={"textarea"} value={card.notes} disabled={true}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mt-3"}>
                        <Col>
                            <h5>
                                <I18Label label={"Необходими материали"}/>:
                            </h5>
                        </Col>
                    </Row>
                    <Row className={"mb-3"}>
                        <Col>
                            <ProductionPlanMaterialsTable
                                items={card.materials}
                                availableWarehouses={ownCompanyWarehouseObjects}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5>
                                <I18Label label={"Условия на доставка към клиент"}/>:</h5>
                        </Col>
                    </Row>
                    <Row className={"mb-3"}>
                        <Col>
                            <Form.Group>
                                <Form.Label><I18Label label={"Транспорт"}/>:</Form.Label>
                                <Form.Select value={card.transport || ""} disabled={true}>
                                    <option value={""}></option>
                                    <option value={"Собствен"}>{getI18Text("Собствен")}</option>
                                    <option value={"Нает"}>{getI18Text("Нает")}</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label><I18Label label={"Адрес за доставка"}/>:</Form.Label>
                                <Form.Control value={card.address || ""} disabled={true}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-3"}>
                        <Col>
                            <Form.Group>
                                <Form.Label><I18Label label={"Лице за контакти"}/>:</Form.Label>
                                <Form.Control value={card.personForContact || ""} disabled={true}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label><I18Label label={"Телефон за контакти"}/>:</Form.Label>
                                <Form.Control value={card.phone || ""} disabled={true}/>
                            </Form.Group>
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Group>
                                <Form.Label><I18Label label={"Краен срок за доставка"}/>:</Form.Label>
                                <div className={"w-100"}>
                                    <ReactDatePicker
                                        className={"form-control"} isClearable
                                        dateFormat="dd.MM.yyyy"
                                        selected={card.deliveryDate}
                                        disabled={true}
                                        onChange={() => {
                                        }}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Row className={"w-100"}>
                        <Col xs={6}>
                            <Button variant={"outline-secondary"} onClick={() => {
                                onDone()
                            }}>
                                <FontAwesomeIcon icon={faArrowLeft}/> <I18Label label={"Към списъка"}/>
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </ModalBackground>
    )
}
