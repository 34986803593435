import {Modal, Spinner, Table} from "react-bootstrap";
import ModalBackground from "../ModalBackground";
import {dateTimeToString, dateToString} from "../../common";
import I18Label from "../../i18/i18label";
import DialogCloseButton from "../DialogCloseButton";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, doc, getDoc, orderBy, query, where} from "firebase/firestore";
import {fbDb} from "../../App";
import ViewIconButton from "../icon-buttons/ViewIconButton";
import {saveAs} from "file-saver";
import {useEffect, useState} from "react";
import { Buffer } from "buffer";

interface CustomPrintHistoryProps {
    collectionName: string;
    documentId: string;
    onClose: () => void;
}

const CustomPrintHistory = ({collectionName, documentId, onClose}: CustomPrintHistoryProps) => {
    const [history, loading, error] = useCollectionData(
        query(
            collection(fbDb, collectionName),
            where("documentId", "==", documentId),
            orderBy("createdAt", "desc")
        )
    );

    if (error) {
        console.error(error);
    }

    function viewAttachment(mail: any) {
        if(!mail.message.attachments || mail.message.attachments.length === 0) {
            return;
        }
        const byteCharacters = atob(mail.message.attachments[0].content);
        const byteNumbers = new Array(byteCharacters.length)
            .fill(null)
            .map((_, i) => byteCharacters.charCodeAt(i));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        saveAs(blob, "attachment.pdf");
    }

    return (
        <>
            <ModalBackground zIndex={1400}>
                <Modal show={true} size={"lg"} style={{zIndex: 1401}}>
                    <Modal.Header>
                        <Modal.Title>
                            <span className={"text-uppercase"}> <I18Label
                                label={"История на кореспонденцията"}/> </span>
                        </Modal.Title>
                        <DialogCloseButton onClose={onClose}/>
                    </Modal.Header>

                    <Modal.Body>
                        <div>
                            {loading && <div className={"mt-5 text-center"}><Spinner animation={"border"}/></div>}
                            {error && <div className={"mt-5 text-center text-danger fw-bold"}>
                                {error.message}
                            </div>}
                            {history && history.length === 0 && <div className={"m-5 text-center text-danger fw-bold"}>
                                <I18Label label={"Няма намерени записи"}/>
                            </div>}
                            {
                                history && history.length > 0 &&
                                <Table size={"sm"} striped bordered hover responsive>
                                    <thead>
                                    <tr>
                                        <th>Дата</th>
                                        <th>Потребител</th>
                                        <th>От</th>
                                        <th>До</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {history && history.map((h: any) => {
                                            return (
                                                <tr>
                                                    <td>{dateTimeToString(h.createdAt.toDate())}</td>
                                                    <td>{h.authName}</td>
                                                    <td>{h.from}</td>
                                                    <td>{h.to}</td>
                                                    <td className={"text-end"}>{
                                                        <ViewIconButton onClick={() => viewAttachment(h)}/>
                                                    }</td>
                                                </tr>
                                            );
                                        }
                                    )}
                                    </tbody>
                                </Table>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </ModalBackground>
        </>
    );
}

export default CustomPrintHistory;
