import {Button, Col, Row, Table} from "react-bootstrap";
import RightAlignedCell from "../../../common/RightAlignedCell";
import CenterAlignedCell from "../../../common/CenterAlignedCell";
import EditableTableCell from "../../../common/EditableTableCell";
import {ProductType} from "../../nomenclatures/products/hooks/useProductsOnce";
import {textToFloat, zeroToEmptyStringAsCurrency, zeroToEmptyStringAsCurrencyWithCommas} from "../../../common";
import CheckIconButton from "../../../common/icon-buttons/CheckIconButton";
import CancelIconButton from "../../../common/icon-buttons/CancelIconButton";
import TrashIconButton from "../../../common/icon-buttons/TrashIconButton";
import ListIconButton from "../../../common/icon-buttons/ListIconButton";
import React, {useState} from "react";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";
import {
    CustomerOfferType, productToOfferMapper
} from "../../../fb-converters/cutomerOffer-converter";
import {ProductsDialog} from "../../nomenclatures/products/ProductsDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import ProductAvailability from "../../../common/ProductAvailability";
import I18Label, {getProductI18Name, getI18Price, getI18PriceInLv, getI18Text} from "../../../i18/i18label";

interface Props {
    zIndex?: number,
    discount_price: number,
    offer: CustomerOfferType,
    setOffer: (offer: CustomerOfferType) => void,
    bestProductPriceIdx: number | null,
    setBestProductPriceIdx: (idx: number | null) => void,
    disabled?: boolean
}


export function ProductDimens(props: { product: ProductType }) {
    let text = "";
    if (props.product._length) text += props.product._length + " / ";
    if (props.product.width) text += props.product.width + " / ";
    if (props.product.height1) text += props.product.height1 + " / ";
    if (props.product.height2) text += props.product.height2;

    return text ? <>{text}</> : null;
}

export default function CustomerOfferDetailsProducts({
                                                         zIndex,
                                                         discount_price,
                                                         offer,
                                                         setOffer,
                                                         bestProductPriceIdx,
                                                         setBestProductPriceIdx,
                                                         disabled
                                                     }: Props) {
    const _products = useHookstate(appDb.products);
    const lng = appDb.language.value;
    const [editCell, setEditCell] = useState("");
    const [showProductsDialog, setShowProductsDialog] = useState(false);

    return (
        <>
            <Row>
                <Col xs={"12"}><I18Label label={"Продукти"}/></Col>
                < Col xs={"12"}>
                    <Table>
                        <thead>
                        <tr>
                            <RightAlignedCell header rowSpan={2}>No:</RightAlignedCell>
                            <th rowSpan={2}><I18Label label={"Тип"}/></th>
                            < RightAlignedCell header className={"w-120px"} rowSpan={2}>
                                <I18Label label={"Срок дост"}/><br/>
                                (<I18Label label={"раб.дни"}/>)
                            </RightAlignedCell>
                            <CenterAlignedCell header colSpan={4}><br/><I18Label
                                label={"Габаритни размери"}/></CenterAlignedCell>
                            <RightAlignedCell header rowSpan={2}><I18Label label={"Гаранция"}/><br/>
                                (<I18Label label={"месеци"}/>)</RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}><I18Label label={"Живот"}/><br/>(<I18Label
                                label={"цикли"}/>)</RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}><I18Label
                                label={"Ед.цена"}/><br/>({offer.currency})</RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}><I18Label
                                label={"Отстъпка"}/><br/>(%)</RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}><I18Label label={"Цена след"}/><br/>
                                <I18Label label={"отстъпка"}/>, ({offer.currency}):</RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}><I18Label label={"Скрап"}/>, (<I18Label
                                label={"кг"}/>)</RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}><I18Label label={"Цена за"}/><br/><I18Label
                                label={"скрап"}/>, ({offer.currency})</RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}><I18Label label={"Крайна цена"}/><br/>({offer.currency})</RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}><I18Label label={"Кол."}/></RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}><I18Label
                                label={"Цена"}/><br/>({offer.currency})</RightAlignedCell>
                            <RightAlignedCell header className={"text-end w-100px"} rowSpan={2}/>
                        </tr>
                        <tr>
                            <CenterAlignedCell header className={"w-40px"}>L</CenterAlignedCell>
                            <CenterAlignedCell header className={"w-40px"}>B</CenterAlignedCell>
                            <CenterAlignedCell header className={"w-40px"}>H1</CenterAlignedCell>
                            <CenterAlignedCell header className={"w-40px"}>H2</CenterAlignedCell>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            offer?.products?.map(
                                (p, idx) => {
                                    const _product = _products.find(value => value.value.id === p.product.id);
                                    let price = (p.singlePrice || 0);
                                    const discount = p.discount || 0;
                                    const finalPrice = price - (price * discount / 100);

                                    return <tr key={`${p.product.id}_${idx}`}>
                                        <td> {idx + 1} </td>
                                        <td> {getProductI18Name(p.product.name)} <br/> <ProductAvailability
                                            productId={p.product.id}/></td>
                                        <EditableTableCell inEditMode={true} // editCell === `${idx}-deliveryDays`}
                                                           disabled={disabled}
                                                           className={"w-120px text-end"}
                                                           isWorking={false}
                                                           value={p.deliveryDays}
                                                           onEdit={() => setEditCell(`${idx}-deliveryDays`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].deliveryDays = newValue;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>

                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-length`}
                                                           className={"w-60px text-end"}
                                                           disabled={disabled}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.product._length?.toString())}
                                                           onEdit={() => setEditCell(`${idx}-length`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].product._length = textToFloat(newValue) || 0;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>
                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-width`}
                                                           className={"w-60px text-end"}
                                                           disabled={disabled}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.product.width?.toString())}
                                                           onEdit={() => setEditCell(`${idx}-width`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].product.width = textToFloat(newValue) || 0;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>
                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-height1`}
                                                           className={"w-60px text-end"}
                                                           disabled={disabled}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.product.height1?.toString())}
                                                           onEdit={() => setEditCell(`${idx}-height1`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].product.height1 = textToFloat(newValue) || 0;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>

                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-height2`}
                                                           className={"w-60px text-end"}
                                                           disabled={disabled}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.product.height2?.toString())}
                                                           onEdit={() => setEditCell(`${idx}-height2`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].product.height2 = textToFloat(newValue) || 0;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>


                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-warranty`}
                                                           className={"w-60px text-end"}
                                                           disabled={disabled}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.product.warranty?.toString())}
                                                           onEdit={() => setEditCell(`${idx}-warranty`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].product.warranty = newValue;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>

                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-liveCycles`}
                                                           className={"w-60px text-end"}
                                                           disabled={disabled}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.product.liveCycles?.toString())}
                                                           onEdit={() => setEditCell(`${idx}-liveCycles`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].product.liveCycles = newValue;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>

                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-price`}
                                                           className={"w-120px text-end"}
                                                           disabled={disabled}
                                                           isWorking={false}
                                            // value={zeroToEmptyStringAsCurrency(getI18Price(p.singlePrice, offer.eurExchangeRate).toString())}
                                                           value={zeroToEmptyStringAsCurrency(p.singlePrice * offer.eurExchangeRate)}
                                                           onEdit={() => setEditCell(`${idx}-price`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               let price = textToFloat(newValue) ?? 0;
                                                               _offer.products[idx].singlePrice = price / offer.eurExchangeRate;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>

                                        <EditableTableCell inEditMode={true}//editCell === `${idx}-discount`}
                                                           className={"w-120px text-end"}
                                                           disabled={disabled}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.discount.toString())}
                                                           onEdit={() => setEditCell(`${idx}-discount`)}
                                                           onValidate={(newValue: string) => {
                                                               let discount = textToFloat(newValue) || 0;
                                                               if (discount < 0 || discount > 100 || isNaN(discount)) {
                                                                   discount = p.discount;
                                                               }

                                                               return zeroToEmptyStringAsCurrency(discount);
                                                           }}
                                                           onBlur={newValue => {
                                                               let discount = textToFloat(newValue) || 0;
                                                               if (discount < 0 || discount > 100 || isNaN(discount)) {
                                                                   discount = 0;
                                                               }
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].discount = discount;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>


                                        <RightAlignedCell> {zeroToEmptyStringAsCurrencyWithCommas(finalPrice * offer.eurExchangeRate)} </RightAlignedCell>

                                        <EditableTableCell
                                            inEditMode={true} //editCell === `${idx}-scrap`}
                                                           className={"w-120px text-end"}
                                                           disabled={disabled}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.scrapWeight?.toString())}
                                                           onEdit={() => setEditCell(`${idx}-scrap`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].scrapWeight = textToFloat(newValue) || 0;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}
                                        />

                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-skrubprice`}
                                                           className={"w-120px text-end"}
                                                           disabled={disabled}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.skrubPrice * offer.eurExchangeRate)}
                                                           onEdit={() => setEditCell(`${idx}-skrubprice`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].skrubPrice = (textToFloat(newValue) ?? 0) / offer.eurExchangeRate;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>

                                        <RightAlignedCell> {
                                            zeroToEmptyStringAsCurrencyWithCommas((finalPrice - p.skrubPrice) * offer.eurExchangeRate)
                                        } </RightAlignedCell>

                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-qty`}
                                                           className={"w-60px text-end"}
                                                           disabled={disabled}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.qty?.toString())}
                                                           onEdit={() => setEditCell(`${idx}-qty`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].qty = textToFloat(newValue) || 0;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>

                                        <RightAlignedCell> {
                                            zeroToEmptyStringAsCurrencyWithCommas((finalPrice - p.skrubPrice) * p.qty * offer.eurExchangeRate)
                                        } </RightAlignedCell>

                                        {!disabled &&
                                            <RightAlignedCell>
                                                {
                                                    editCell === `${idx}-remove` ?
                                                        <>
                                                            <CheckIconButton onClick={() => {
                                                                const _offer = {
                                                                    ...offer,
                                                                    products: [...offer.products]
                                                                };
                                                                _offer.products.splice(idx, 1);
                                                                setEditCell("");
                                                                setOffer(_offer);
                                                            }}/>
                                                            <CancelIconButton onClick={() => setEditCell("")}/>
                                                        </> :
                                                        <>
                                                            <Button
                                                                variant={(bestProductPriceIdx === idx) ? "warning" : "secondary"}
                                                                title={"Продукт с най-добра цена"} size={"sm"}
                                                                onClick={() => {
                                                                    setBestProductPriceIdx(idx === bestProductPriceIdx ? null : idx);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faStar}/>
                                                            </Button>&nbsp;
                                                            <TrashIconButton size={"sm"}
                                                                             onClick={() => setEditCell(`${idx}-remove`)}/>
                                                        </>
                                                }
                                            </RightAlignedCell>
                                        }
                                    </tr>
                                }
                            )
                        }
                        {!disabled &&
                            <tr>
                                <RightAlignedCell colSpan={17}>
                                    <ListIconButton onClick={() => setShowProductsDialog(true)} variant={"primary"}/>
                                </RightAlignedCell>
                            </tr>
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <ProductsDialog show={showProductsDialog} zIndex={(zIndex || 1060) + 10}
                            onClose={() => setShowProductsDialog(false)}
                            onSelect={result => {
                                if (result) {
                                    const product = {...result} as ProductType;
                                    const products = [...offer.products];
                                    const productOffer = productToOfferMapper(product, discount_price, 1);
                                    if (productOffer) {
                                        products.push(productOffer);
                                    }
                                    setOffer({...offer, products: products});
                                    setShowProductsDialog(false);
                                }
                            }}
                            onMultiSelect={results => {
                                const products = [...offer.products];
                                results.forEach((result: any) => {
                                    const product = {...result} as ProductType;
                                    const productOffer = productToOfferMapper(product, discount_price, 1);
                                    if (productOffer) {
                                        products.push(productOffer);
                                    }
                                });
                                setOffer({...offer, products: products});
                                setShowProductsDialog(false);
                            }}
            />
        </>
    )
}
