import {DocumentData, DocumentReference, QueryDocumentSnapshot, SnapshotOptions} from "firebase/firestore";

export type ExpeditionStatusType = "ПЛАНИРАНА" | "ИЗПЪЛНЯВА СЕ" | "ПРИКЛЮЧЕНА" | "ОТКАЗАНА";

export type ExpeditionType = {
    id?: string;
    ref?: DocumentReference;
    expeditionDate: Date | null;
    cardId: string | null;
    cardNumber: string | null;
    cardDate: Date | null;
    invoiceClientName: string | null;
    inquiry: {
        id: string | null;
        inquiryDate: Date | null;
        inquiryNumber: string | null;
    } | null;
    client: {
        id: string | null;
        name: string | null;
        region: string | null;
        address: string | null;
        contactPerson: string | null;
        phone: string | null;
    } | null;
    notes: string | null;
    productId: string | null;
    productName: string | null;
    productQty: number | null;
    productSku: string | null;
    palets: {
        leaving: number | null;
        arriving: number | null;
    } | null;
    weights: {
        leaving: {
            parcels: number | null;
            pallets: number | null;
        } | null;
        arriving: {
            scrap: number | null;
            delivery: number | null;
        } | null;
    } | null;
    driver: {
        id: string;
        name: string;
    } | null;
    car: {
        id: string;
        name: string;
        regPlate: string;
        maxWeight: number;
    } | null;
    courseNumber: string;
    status: ExpeditionStatusType | null;
}

export const EMPTY_EXPEDITION: ExpeditionType = {
    expeditionDate: null,
    cardId: null,
    cardNumber: null,
    cardDate: null,
    invoiceClientName: null,
    inquiry: null,
    client: null,
    notes: null,
    palets: null,
    weights: null,
    driver: null,
    car: null,
    productId: null,
    productName: null,
    productQty: null,
    productSku: null,
    courseNumber: 'Първи',
    status: "ПЛАНИРАНА"
};

export const expeditionConverter = {
    toFirestore(expedition: ExpeditionType): DocumentData {
        return {
            expeditionDate: expedition.expeditionDate,
            cardId: expedition.cardId,
            cardNumber: expedition.cardNumber,
            cardDate: expedition.cardDate,
            invoiceClientName: expedition.invoiceClientName,
            inquiry: {
                id: expedition.inquiry?.id,
                inquiryDate: expedition.inquiry?.inquiryDate,
                inquiryNumber: expedition.inquiry?.inquiryNumber,
            },
            client: {
                id: expedition.client?.id,
                name: expedition.client?.name,
                region: expedition.client?.region,
                address: expedition.client?.address,
                contactPerson: expedition.client?.contactPerson,
                phone: expedition.client?.phone,
            },
            notes: expedition.notes,
            productId: expedition.productId,
            productName: expedition.productName,
            productQty: expedition.productQty,
            productCode: expedition.productSku,
            palets: {
                leaving: expedition.palets ?.leaving,
                arriving: expedition.palets ?.arriving,
            },
            weights : {
                leaving: {
                    parcels: expedition.weights ?.leaving?.parcels,
                    pallets: expedition.weights ?.leaving?.pallets,
                },
                arriving: {
                    scrap: expedition.weights ?.arriving?.scrap,
                    delivery: expedition.weights ?.arriving?.delivery,
                },
                driver: {
                    id: expedition.driver?.id,
                    name: expedition.driver?.name,
                },
                car: {
                    id: expedition.car?.id,
                    name: expedition.car?.name,
                    regPlate: expedition.car?.regPlate,
                    maxWeight: expedition.car?.maxWeight,
                },
            },
            status: expedition.status,
            courseNumber: expedition.courseNumber ?? 'Първи',
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): ExpeditionType {
        const expedition = snapshot.data(options)!;
        return {
            id: snapshot.id,
            cardId: expedition.cardId,
            cardNumber: expedition.cardNumber,
            cardDate: expedition.cardDate?.toDate() ?? null,
            invoiceClientName: expedition.invoiceClientName,
            expeditionDate: expedition.expeditionDate?.toDate() ?? null,
            inquiry: expedition.inquiry ? {
                id: expedition.inquiry.id,
                inquiryDate: expedition.inquiry.inquiryDate?.toDate() ?? null,
                inquiryNumber: expedition.inquiry.inquiryNumber,
            } : null,
            client: expedition.client ? {
                id: expedition.client.id,
                name: expedition.client.name,
                region: expedition.client.region,
                address: expedition.client.address,
                contactPerson: expedition.client.contactPerson,
                phone: expedition.client.phone,
            } : null,
            notes: expedition.notes,
            productId: expedition.productId,
            productName: expedition.productName,
            productQty: expedition.productQty,
            productSku: expedition.productSku,
            weights: expedition.weights ? {
                leaving: expedition.weights.leaving ? {
                    parcels: expedition.weights.leaving.parcels,
                    pallets: expedition.weights.leaving.pallets,
                } : null,
                arriving: expedition.weights.arriving ? {
                    scrap: expedition.weights.arriving.scrap,
                    delivery: expedition.weights.arriving.delivery,
                } : null
            } : null,
            palets: expedition.palets ? {
                leaving: expedition.palets.leaving,
                arriving: expedition.palets.arriving,
            } : null,
            driver: expedition.driver ? {
                id: expedition.driver.id,
                name: expedition.driver.name,
            } : null,
            car: expedition.car ? {
                id: expedition.car.id,
                name: expedition.car.name,
                regPlate: expedition.car.regPlate,
                maxWeight: expedition.car.maxWeight,
            } : null,
            status: expedition.status,
            courseNumber: expedition.courseNumber ?? 'Първи',
        } as ExpeditionType;
    }
};
