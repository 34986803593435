import {useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";
import {WarehouseObjectType} from "./useWarehouseObjects";

export default function useWarehouseObjectsRepo(ownCompanyId? : string) {
    const db = useHookstate(appDb);
    const allWarehouseObjects = db.warehouses.value.map(
        w => {
            return {...w} as WarehouseObjectType;
        }
    );
    const ownCompanyWarehouseObjects =
        allWarehouseObjects.filter(w => !ownCompanyId ||  w.ownCompanyId === ownCompanyId);


    const getWarehouseObjectName =
        (warehouseObjectId: string | null) => {
            const warehouseObject = allWarehouseObjects.find(w => w.id === warehouseObjectId);
            return warehouseObject ? warehouseObject.name : "";
        }

    const getWarehouseObjectShortName =
        (warehouseObjectId: string | null) => {
            const warehouseObject = allWarehouseObjects.find(w => w.id === warehouseObjectId);
            return warehouseObject ? warehouseObject.shortName : "";
        }

    return {
        allWarehouseObjects,
        ownCompanyWarehouseObjects,
        getWarehouseObjectName,
        getWarehouseObjectShortName
    }
}
