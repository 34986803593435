import {Button, Col, Form, ModalBody, ModalFooter, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCancel,
    faCircleArrowDown,
    faEdit, faFileImport,
    faPlus,
    faSearch,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {
    orderBy,
} from "firebase/firestore";
import {useState} from "react";
import LoadDataFromServerError from "../../../common/LoadDataFromServerError";
import useDebounce from "../../../hooks/UseDebounce";
import {VirtualProductType} from "./hooks/useVirtualProductsOnce";
import useVirtualProducts from "./hooks/useVirtualProducts";
import useUserRights from "../../../hooks/useUserRights";


export interface VirtualProductsListProps {
    isVisible: boolean;
    onAdd: () => any;
    onEdit: (item: VirtualProductType) => any;
    onDelete: (item: VirtualProductType) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
}

export function VirtualProductsList({isVisible, onAdd, onEdit, onDelete, onClose, onSelect}: VirtualProductsListProps) {
    const {canUserEdit} = useUserRights();
    const [filterName, setFilterName] = useState('')
    const [filterSku, setFilterSku] = useState('');

    const [products, loading, error] = useVirtualProducts([orderBy("name", "asc")]);
    const [filtered, setFilteredUsers] = useState<any[] | null>(null)
    const [importing, setImporting] = useState(false);

    useDebounce(() => {
        if (products) {
            setFilteredUsers(products.filter(
                (r: VirtualProductType) =>
                    (!filterName || filterName.length === 0 || r.name.toLowerCase().indexOf(filterName.toLowerCase()) > -1) &&
                    (!filterSku || filterSku.length === 0 || (r.sku || "").toLowerCase().indexOf(filterSku.toLowerCase()) > -1))
            );
        } else setFilteredUsers(null);
    }, [products, filterName, filterSku], 500);

    function isLoading() {
        return loading;
    }

    if (!isLoading() && error) return <LoadDataFromServerError/>;

    async function importFromProducts() {
        setImporting(true);

        // const prodList = await getDocs(collection(fbDb, "products"));
        // const vProdList = await getDocs(collection(fbDb, "virtualProducts"));
        //
        // prodList.docs.forEach(
        //     p => {
        //         const doc = vProdList.docs.find(
        //             // vp => vp.sku
        //         )
        //     }
        // )

        setImporting(false);
    }

    return (
        <>
            {
                !loading && !error && isVisible &&
                <>
                    <ModalBody>
                        <div className={"max-h-60vh scrollable"}>
                            <Table size={"sm"} borderless hover>
                                <thead style={{backgroundColor: "white"}}>
                                <tr>
                                    <th style={{width: onSelect ? 120 : 90}}></th>
                                    <th className={"w-120px"}>SKU</th>
                                    <th>Име</th>
                                    <th className={"w-120px"}>Мер.ед.</th>
                                </tr>
                                <tr>
                                    <td className={"text-start"}>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterSku}
                                                      onChange={(e) => setFilterSku(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterName}
                                                      onChange={(e) => setFilterName(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} disabled={true}/>
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (loading || !filtered) && !error &&
                                    <tr>
                                        <td colSpan={4} className={"text-center fw-bold"}>
                                            <Spinner animation={"border"}/>
                                        </td>
                                    </tr>
                                }
                                {
                                    filtered && filtered.length === 0 && !loading && !error &&
                                    <tr>
                                        <td colSpan={4} className={"text-center fw-bold"}>Няма регистрирани продукти
                                        </td>
                                    </tr>
                                }
                                {
                                    filtered && filtered.length > 0 && filtered.map(
                                        r =>
                                            <tr key={r.id} className={"tr-bordered"}>
                                                <td>
                                                    <Button size={"sm"} variant={"outline-secondary"}
                                                            onClick={() => onEdit(r)}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit}/>
                                                    </Button>&nbsp;
                                                    { canUserEdit('Склад-Производствена номенклатура') &&
                                                    <Button size={"sm"} variant={"outline-danger"}
                                                            onClick={() => {
                                                                onDelete(r)
                                                            }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash}/>
                                                    </Button>}
                                                    &nbsp;
                                                    {
                                                        onSelect &&
                                                        <Button size={"sm"} variant={"outline-success"}
                                                                onClick={() => {
                                                                    onSelect({...r, id: r.id, ref: r.ref})
                                                                }}
                                                        >
                                                            <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                        </Button>
                                                    }
                                                </td>
                                                <td>
                                                    {r.sku}
                                                </td>
                                                <td>
                                                    {r.name}
                                                </td>
                                                <td>
                                                    {r.dimension}
                                                </td>
                                            </tr>
                                    )
                                }
                                </tbody>

                            </Table>
                        </div>
                    </ModalBody>

                    {
                        canUserEdit('Склад-Производствена номенклатура') &&
                        <ModalFooter>
                            <Col>
                                {
                                    importing ? <Spinner animation={"border"}/> :
                                        <Button variant={"outline-secondary"}
                                                onClick={() => importFromProducts()}>
                                            <FontAwesomeIcon icon={faFileImport}/> Актуализирай от продуктовата
                                            номенклатура
                                        </Button>
                                }
                            </Col>
                            <Col className={"text-end"}>
                                <Button onClick={() => onAdd()}>
                                    <FontAwesomeIcon icon={faPlus}/> Добави нов
                                </Button>&nbsp;
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onClose(null)
                                }}>
                                    <FontAwesomeIcon icon={faCancel}/> Затвори
                                </Button>
                            </Col>
                        </ModalFooter>
                    }
                </>
            }
        </>
    )
}
