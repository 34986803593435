import {FC, useEffect, useMemo, useState} from "react";
import {Button, Col, Form, FormControl, FormGroup, Modal, Row, Spinner, Table,} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import SelectValueFromDialog from "../../../common/SelectValueFromDialog";
import {ClientsDialog} from "../../nomenclatures/clients/ClientsDialog";
import {OwnCompanyType} from "../../../fb-converters/own-company-converter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import CancelIconButton from "../../../common/icon-buttons/CancelIconButton";
import DialogCloseButton from "../../../common/DialogCloseButton";
import {
    dateTimeToString,
    dateToString,
    removeEmptyFields,
    showErrorsListInToast,
    textToInt,
    zeroToEmptyStringAsNumber
} from "../../../common";
import {
    addDoc,
    collection,
    deleteDoc,
    setDoc,
    updateDoc,
} from "firebase/firestore";
import {fbDb} from "../../../App";
import {useHookstate} from "@hookstate/core";
import appState from "../../../global-state/global-state";
import ModalBackground from "../../../common/ModalBackground";
import {
    customerInvoiceConverter, CustomerInvoiceItemsType,
    CustomerInvoicePartyType,
    CustomerInvoiceType,
} from "../../../fb-converters/cutomerInvoice-converter";
import PlusIconButton from "../../../common/icon-buttons/PlusIconButton";
import MinusIconButton from "../../../common/icon-buttons/MinusIconButton";
import CustomerInvoiceItemsTable from "./CustomerInvoiceItemsTable";
import {CustomerOfferType} from "../../../fb-converters/cutomerOffer-converter";
import DeleteConfirmIconButton from "../../../common/icon-buttons/DeleteConfirmIconButton";
import {
    ProductionType
} from "../../nomenclatures/production/hooks/useProductionOnce";
import UseRecalcWarehouseItemStatus from "../../warehouse/hooks/useRecalcWarehouseItemQty";
import {BankAccountsDialog} from "../../nomenclatures/bank-accounts/BankAccountsDialog";
import {CustomerInquiryType} from "../../../fb-converters/cutomerInquiry-converter";
import I18Label from "../../../i18/i18label";
import {
    BGN_CURRENCY_NAME,
    EUR_CURRENCY_NAME,
    LEU_CURRENCY_NAME,
    useCurrency
} from "../../../fb-converters/currency-converter";
import useWarehouseObjects from "../../warehouse/hooks/useWarehouseObjects";
import HisotryIconButton from "../../../common/icon-buttons/HisotryIconButton";
import CustomerInvoiceHistory from "./CustomerInvoiceHistory";
import useUserRights from "../../../hooks/useUserRights";

interface Props {
    zIndex?: number;
    inquiry: CustomerInquiryType;
    offer: CustomerOfferType;
    offerInvoices: CustomerInvoiceType[];
    ownCompanies: OwnCompanyType[];
    availableItems: CustomerInvoiceItemsType;
    invoice: CustomerInvoiceType;
    onClose: VoidFunction;
}


const CustomerInvoiceDetails: FC<Props> = (props) => {
    const globalState = useHookstate(appState).get();
    const [working, setWorking] = useState(false);
    const [invoice, setInvoice] = useState<CustomerInvoiceType>(props.invoice);
    const clientsDialog = <ClientsDialog show={true} zIndex={(props.zIndex || 1200) + 200} onClose={() => {
    }}/>
    const bankAccsDialog = <BankAccountsDialog show={true} zIndex={(props.zIndex || 1200) + 200} onClose={() => {
    }}/>
    const [showDetails, setShowDetails] = useState(false);
    UseRecalcWarehouseItemStatus();
    const [warehouseObjects] = useWarehouseObjects();
    const [showHistory, setShowHistory] = useState(false);

    useEffect(() => {
        if (invoice && !invoice.currency) {
            const newInvoice = {...props.invoice};
            newInvoice.currency = "BGN";
            setInvoice(newInvoice);
        }
    }, [props.invoice, invoice]);

    const currencyDate = useMemo(() => {
        if (invoice.documentType === "фактура") {
            const proforma = props.offerInvoices.find(i => i.documentType === "проформа фактура");
            if (proforma) {
                return proforma.invoiceDate;
            }
        }
        return invoice.invoiceDate;
    }, [props.offerInvoices, invoice.documentType, invoice.invoiceDate]);

    const {getCurrency, currencyRates, loadingRates} = useCurrency(currencyDate);
    const LEU_RATE = useMemo(() => {
        return getCurrency(LEU_CURRENCY_NAME);
    }, [currencyDate, currencyRates]);

    const currencyRate = useMemo(() => {
        switch (invoice.currency) {
            case EUR_CURRENCY_NAME:
                return 1;
            case BGN_CURRENCY_NAME:
                return 1.95583;
            case LEU_CURRENCY_NAME:
                return LEU_RATE.rate;
        }
        return 1;
    }, [invoice.currency, loadingRates]);

    function getNextInvNumber() {
        let invoiceNumber = 1;
        switch (invoice.documentType) {
            case "потвърждение на поръчка" :
                invoiceNumber = globalState.customerOrderConfirmationNumber;
                break;
            case "проформа фактура" :
                invoiceNumber = globalState.customerProformaInvoiceNumber;
                break;
            case "фактура" :
                invoiceNumber = 0;
                switch (invoice.sender.companyId) {
                    case '0ib_bg':
                        invoiceNumber = globalState.customerInvoiceNumber;
                        break;
                    case '1ib_ro':
                        invoiceNumber = globalState.customerInvoiceNumberIb;
                        break;
                    case '2energia':
                        invoiceNumber = globalState.customerInvoiceNumberEnergia;
                        break;
                }
                break;
        }

        return invoiceNumber + 1;
    }

    async function setNextInvNumber(invoice: CustomerInvoiceType) {
        let _data = {};
        switch (invoice.documentType) {
            case "потвърждение на поръчка" :
                _data = {
                    customerOrderConfirmationNumber:
                        invoice.invoiceNumber > globalState.customerOrderConfirmationNumber ?
                            invoice.invoiceNumber : globalState.customerOrderConfirmationNumber
                };
                break;
            case "проформа фактура" :
                _data = {
                    customerProformaInvoiceNumber:
                        invoice.invoiceNumber > globalState.customerProformaInvoiceNumber ?
                            invoice.invoiceNumber : globalState.customerProformaInvoiceNumber
                };
                break;
            case "фактура" :
                switch (invoice.sender.companyId) {
                    case '0ib_bg':
                        _data = {
                            customerInvoiceNumber:
                                invoice.invoiceNumber > globalState.customerInvoiceNumber ?
                                    invoice.invoiceNumber : globalState.customerInvoiceNumber
                        };
                        break;
                    case '1ib_ro':
                        _data = {
                            customerInvoiceNumberIb:
                                invoice.invoiceNumber > globalState.customerInvoiceNumberIb ?
                                    invoice.invoiceNumber : globalState.customerInvoiceNumberIb
                        };
                        break;
                    case '2energia':
                        _data = {
                            customerInvoiceNumberEnergia:
                                invoice.invoiceNumber > globalState.customerInvoiceNumberEnergia ?
                                    invoice.invoiceNumber : globalState.customerInvoiceNumberEnergia
                        };
                        break;
                }

                break;
        }
        await updateDoc(globalState.ref!, _data);
    }


    function recalcProductionPrice(prod: ProductionType) {
        return {
            ...prod,
            price: prod.items.reduce((acc, item) => acc + (item.price || 0), 0)
        }
    }

    async function saveInvoice() {
        const senderWarehouse = warehouseObjects.find(w => w.ownCompanyId === invoice.sender.companyId);
        if (!senderWarehouse) {
            showErrorsListInToast("Грешка", ["Не е намерен склад за изпращача!"]);
            return;
        }

        const data = removeEmptyFields<CustomerInvoiceType>(invoice)!;

        data.inquiryId = props.inquiry.id ?? "";
        data.inquiryNumber = props.inquiry.inquiryNumber;
        data.inquiryDate = props.inquiry.inquiryDate;
        data.currencyRate = getCurrency(invoice.currency).rate;

        data.items.forEach(
            (p, idx) => {
                const _clearItem = removeEmptyFields(p);
                if (_clearItem) {
                    data.items[idx] = _clearItem;
                }
            }
        );

        try {
            setWorking(true)

            if (data && invoice.ref) {
                await setDoc(invoice.ref!, data);
            } else {
                data.invoiceNumber = textToInt(getGlobalStateNumber()) || 1;
                data!.invoiceNumber = getNextInvNumber();

                const collRef = collection(fbDb, "customerInvoices")
                    .withConverter(customerInvoiceConverter);
                const _data = await addDoc(collRef, data);
                data.id = _data.id;

                await setNextInvNumber(data!);
            }

            // if(invoice.documentType === "потвърждение на поръчка") {
            // if (invoice.documentType === "фактура") {
            //     // const idsToRecalculate = new Set<string>([]);
            //     // const ownProd: ProductionsType = [];
            //     // const outItems: WarehouseOutItemsType = [];
            //     //
            //     // ///==========================================
            //     //
            //     //
            //     // const currentOutData = await getDocs(
            //     //     query(collection(fbDb, "warehouseOut"), where("invoiceId", "==", data.id)));
            //     // const currentOut = convertWarehouseOutData(currentOutData.docs);
            //     // const productIds = data.items.filter(i => i.productId).map(i => i.productId!);
            //     // const newOutItem: WarehouseOutType = {
            //     //     invoiceId: data.id!,
            //     //     client: {
            //     //         id: data.receiver.clientId,
            //     //         ref: null,
            //     //         name: data.receiver.name,
            //     //         address: data.receiver.address,
            //     //         region: data.receiver.city,
            //     //         email: data.receiver.email,
            //     //         phone: data.receiver.phone,
            //     //         bulstat: data.receiver.bulstat,
            //     //         ddsNumber: data.receiver.ddsNumber,
            //     //         mol: data.receiver.mol,
            //     //         discount_charge: 0,
            //     //         discount_percent: 0,
            //     //         discount_ecopack: 0,
            //     //         discount_other: 0,
            //     //         discount_eco: 0,
            //     //         discount_transport: 0,
            //     //         discount_price: 0,
            //     //     } as ClientType,
            //     //     clientId: data.receiver.clientId,
            //     //     warehouseId: senderWarehouse.id,
            //     //     transactionNumber: data.invoiceNumber,
            //     //     transactionDate: data.invoiceDate || new Date(),
            //     //     price: outItems.reduce((acc, item) => acc + (item.price || 0), 0),
            //     //     productIds: productIds,
            //     // }
            //     //
            //     // let newDocRef = doc(collection(fbDb, "warehouseOut"));
            //     // if (currentOut.length > 0) {
            //     //     newDocRef = currentOut[0].ref!;
            //     // }
            //
            //
            //     ///==========================================
            //
            //     for (const i of data.items) {
            //         if (i.productId) {
            //             const newOutItem: WarehouseOutItemType = {
            //                 transactionId: newDocRef.id,
            //                 transactionDate: data.invoiceDate || new Date(),
            //                 ownCompanyId: invoice.sender.companyId,
            //                 warehouseId: senderWarehouse.id,
            //                 productId: i.productId,
            //                 isOwnProduction: i.ownProduction,
            //                 sku: i.code || "",
            //                 name: i.name,
            //                 dimens: i.dimension,
            //                 qty: i.qty,
            //                 singlePrice: i.price,
            //                 price: i.price * i.qty,
            //             };
            //             idsToRecalculate.add(i.productId || "");
            //             const existingItem = outItems.find(_item => {
            //                 return newOutItem.productId === _item.productId && newOutItem.isOwnProduction === _item.isOwnProduction
            //             });
            //
            //             if (existingItem) {
            //                 existingItem.qty += existingItem.qty;
            //             } else {
            //                 outItems.push(newOutItem);
            //             }
            //
            //
            //             if (i.ownProduction) {
            //                 const item: ProductionType = {...EMPTY_PRODUCTION};
            //                 item.sku = 'P' + i.code;
            //                 item.name = i.name;
            //                 item.productionDate = data.invoiceDate;
            //                 item.productId = i.productId;
            //                 item.dimension = i.dimension;
            //                 item.qty = i.qty;
            //                 item.price = i.price;
            //                 item.invoiceId = data.id;
            //                 item.invoiceNumber = data.invoiceNumber;
            //                 item.invoiceDate = data.invoiceDate;
            //                 item.clientId = props.inquiry.client?.id;
            //                 item.clientLocationId = props.inquiry.location?.id;
            //                 item.clientName = props.inquiry.client?.name ?? props.inquiry.location?.name;
            //                 item.inquiryId = props.inquiry.id;
            //                 item.inquiryNumber = props.inquiry.inquiryNumber;
            //                 item.inquiryDate = props.inquiry.inquiryDate;
            //                 item.items = [];
            //
            //                 const ownProdCards = await getDocs(
            //                     query(collection(fbDb, "productionCards"),
            //                         where("productId", "==", i.productId),
            //                         where("offerId", "==", data.offerId)
            //                     ).withConverter(productionCardConverter));
            //
            //                 if (!ownProdCards.empty) {
            //                     const materials = ownProdCards.docs[0].data().materials;
            //                     const materialItems = materials.map(async m => {
            //                         const product = productsData.find(p => p.id === m.productId);
            //                         idsToRecalculate.add(m.productId || "");
            //                         const productPrice = (product?.deliveryPrice || 0) * (props.offer.eurExchangeRate || 1);
            //
            //                         const newOutItem = {
            //                             transactionId: newDocRef.id,
            //                             transactionDate: data.invoiceDate || new Date(),
            //                             ownCompanyId: invoice.sender.companyId,
            //                             warehouseId: senderWarehouse.id,
            //                             productId: m.productId,
            //                             isOwnProduction: false,
            //                             sku: product?.sku || "",
            //                             name: m.productName,
            //                             dimens: product?.dimens || "",
            //                             qty: m.productQty,
            //                             singlePrice: productPrice,
            //                             price: productPrice * m.productQty,
            //                         }
            //
            //                         const existingItem = outItems.find(_item => {
            //                             return newOutItem.productId === _item.productId && newOutItem.isOwnProduction === _item.isOwnProduction
            //                         });
            //
            //                         if (existingItem) {
            //                             existingItem.qty += existingItem.qty;
            //                         } else {
            //                             outItems.push(newOutItem);
            //                         }
            //
            //                         const warehouseInData = await getDocs(
            //                             query(
            //                                 collection(fbDb, "warehouseInItems"),
            //                                 where("productId", "==", m.productId),
            //                                 where("availableQty", ">", 0)
            //                             )
            //                         );
            //
            //                         let price = 0;
            //                         const warehouseInItems = convertWarehouseInItemData(warehouseInData.docs);
            //                         if (warehouseInItems && warehouseInItems.length > 0) {
            //                             warehouseInItems.sort((a, b) => {
            //                                 const dateA = a.transactionDate || new Date();
            //                                 const dateB = b.transactionDate || new Date();
            //                                 return dateA.getTime() - dateB.getTime();
            //                             });
            //
            //                             let qty = m.productQty;
            //                             for (const inItem of warehouseInItems) {
            //                                 const availableQty = inItem.availableQty || 0;
            //                                 if (qty > 0) {
            //                                     if (availableQty > qty) {
            //                                         price += (inItem.singlePrice || 0) * qty;
            //                                         qty = 0;
            //                                     } else {
            //                                         price += (inItem.singlePrice || 0) * availableQty;
            //                                         qty -= availableQty;
            //                                     }
            //                                 }
            //                             }
            //                         }
            //                         const singlePrice = price / m.productQty;
            //                         return {
            //                             warehouseId: m.warehouseId,
            //                             productId: m.productId,
            //                             qty: m.productQty,
            //                             size: m.productSize,
            //                             name: m.productName,
            //                             sku: product?.sku || "",
            //                             dimension: product?.dimens || "",
            //                             singlePrice: productPrice,
            //                             price: m.productQty * productPrice,
            //                         }
            //                     });
            //                     item.items = await Promise.all(materialItems);
            //                 }
            //                 ownProd.push(item);
            //             }
            //         }
            //     }
            //
            //
            //     // try {
            //     //     await runTransaction(fbDb, async (transaction) => {
            //     //         // debugger;
            //     //         // newOutItem.price = outItems.reduce((acc, item) => acc + (item.price || 0), 0);
            //     //         // transaction.set(newDocRef, newOutItem);
            //     //         //
            //     //         // const currentOutListData = await getDocs(
            //     //         //     query(collection(fbDb, "warehouseOutItems"), where("transactionId", "==", newDocRef.id)));
            //     //         // const currentOutList = convertWarehouseOutItemData(currentOutListData.docs);
            //     //         //
            //     //         // currentOutList.forEach(
            //     //         //     outItem => {
            //     //         //         const _outItem = outItems.find(item =>
            //     //         //             item.productId === outItem.productId && item.isOwnProduction === outItem.isOwnProduction);
            //     //         //         if (!_outItem) {
            //     //         //             transaction.delete(outItem.ref!);
            //     //         //             idsToRecalculate.add(outItem.productId || "");
            //     //         //         }
            //     //         //     }
            //     //         // );
            //     //         //
            //     //         // outItems.forEach(
            //     //         //     item => {
            //     //         //         const _current = currentOutList.find(currItem =>
            //     //         //             currItem.productId === item.productId && currItem.isOwnProduction === item.isOwnProduction);
            //     //         //         if (!_current) {
            //     //         //             const newDocRef = doc(collection(fbDb, "warehouseOutItems"));
            //     //         //             transaction.set(newDocRef, item);
            //     //         //         } else {
            //     //         //             transaction.set(_current.ref!, item);
            //     //         //         }
            //     //         //         idsToRecalculate.add(item.productId || "");
            //     //         //     }
            //     //         // )
            //     //
            //     //         ///==========================================
            //     //
            //     //         // const currentListData = await getDocs(
            //     //         //     query(collection(fbDb, "production"), where("invoiceId", "==", data.id)));
            //     //         // const currentList = convertVirtualProductData(currentListData.docs);
            //     //         //
            //     //         // currentList.forEach(
            //     //         //     item => {
            //     //         //         const _ownItem = ownProd.find(ownItem => ownItem.productId === item.productId);
            //     //         //         if (!_ownItem) {
            //     //         //             transaction.delete(item.ref!);
            //     //         //             idsToRecalculate.add(item.productId || "");
            //     //         //         }
            //     //         //     }
            //     //         // );
            //     //
            //     //         // ownProd.forEach(
            //     //         //     item => {
            //     //         //         // debugger;
            //     //         //         const _current = currentList.find(currItem => currItem.productId === item.productId);
            //     //         //         if (!_current) {
            //     //         //             if (item.items.length === 0 && item.productId) {
            //     //         //                 const product = productsData.find(p => p.id === item.productId);
            //     //         //                 if (product) {
            //     //         //                     const deliveryPrice = (product.deliveryPrice || 0) * props.offer.eurExchangeRate;
            //     //         //                     item.items.push(
            //     //         //                         {
            //     //         //                             warehouseId: senderWarehouse.id,
            //     //         //                             productId: product.id,
            //     //         //                             dimension: product.dimens,
            //     //         //                             price: deliveryPrice * item.qty,
            //     //         //                             qty: item.qty,
            //     //         //                             singlePrice: deliveryPrice,
            //     //         //                             size: {
            //     //         //                                 l: product._length || 0,
            //     //         //                                 b: product.width || 0,
            //     //         //                                 h1: product.height1 || 0,
            //     //         //                                 h2: product.height2 || 0,
            //     //         //                             },
            //     //         //                             name: product.name,
            //     //         //                             sku: product.sku,
            //     //         //                         }
            //     //         //                     );
            //     //         //                 }
            //     //         //             }
            //     //         //             const newDocRef = doc(collection(fbDb, "production"));
            //     //         //             transaction.set(newDocRef, recalcProductionPrice(removeEmptyFields(item)!));
            //     //         //         } else {
            //     //         //             transaction.set(_current.ref!, recalcProductionPrice(removeEmptyFields(_current)!));
            //     //         //         }
            //     //         //         idsToRecalculate.add(item.productId || "");
            //     //         //     }
            //     //         // )
            //     //     });
            //
            //         // for (const id of Array.from(idsToRecalculate)) {
            //         //     if (id) {
            //         //         const isOwnProduction = ownProd.find(p => p.productId === id) ? true : false;
            //         //         await warehouseItemUpdater("", senderWarehouse.id, id, isOwnProduction, undefined);
            //         //         if (isOwnProduction) {
            //         //             const ownProdItem = ownProd.find(p => p.productId === id);
            //         //             if (ownProdItem) {
            //         //                 if (ownProdItem.items.find(
            //         //                     p => p.productId === id
            //         //                 )) {
            //         //                     await warehouseItemUpdater("", senderWarehouse.id, id, false, undefined);
            //         //                 }
            //         //             }
            //         //         }
            //         //     }
            //         // }
            //     // } catch (e: any) {
            //     //     console.error(e);
            //     //     showErrorsListInToast("Проблем", ["Възникна следната грешка при опит да се актуализира списъка за собственото производство:", e.message]);
            //     // }
            // }

            props.onClose();
        } catch (e: any) {
            console.error(e.message);
            showErrorsListInToast("Грешка", [`Възникна проблем:\n${e.message}`]);
        } finally {
            setWorking(false)
        }
    }

    const zIndex = (props.zIndex || 1200) + 200;

    function getGlobalStateNumber() {
        let _number = getNextInvNumber();
        return _number.toString();
    }

    function calculateInvoiceWeight(items: CustomerInvoiceItemsType) {
        if (!items) {
            return "";
        }
        let invW = 0;
        items.forEach(
            i => invW += (i.weight || 0) * (i.qty || 0)
        )
        return zeroToEmptyStringAsNumber(invW);
    }

    const calculatedInvoiceWeight = useMemo(() => {
        if (invoice?.items) {
            return calculateInvoiceWeight(invoice.items);
        }
        return 0;
    }, [invoice, invoice.items]);

    async function deleteInvoice() {
        if (invoice?.ref) {
            setWorking(true);
            await deleteDoc(invoice.ref);
            props.onClose();
            setWorking(false);
        }
    }

    const {canUserEdit} = useUserRights();

    return <ModalBackground zIndex={zIndex}>
        <Modal show={!showHistory} size={"xl"} style={{zIndex: zIndex + 1}}>
            <Modal.Header>
                <Modal.Title>
                    <span className={"text-uppercase"}> <I18Label label={invoice.documentType}/> </span>
                    <span
                        className={"ms-3 text-primary"}><I18Label
                        label={"към оферта"}/>: {invoice.offerNumber} / {dateToString(invoice.offerDate)}</span>
                    {
                        invoice.authName && invoice.createdAt &&
                        <Row className={"small fst-italic"}>
                            <Col>
                                <span className={"small text-muted"}><I18Label
                                    label={"Автор: "}/> {invoice.authName} / {dateTimeToString(invoice.createdAt)}</span>
                            </Col>
                        </Row>
                    }
                </Modal.Title>
                <DialogCloseButton onClose={props.onClose}/>
            </Modal.Header>

            <Modal.Body>
                {/*Inv number & date*/}
                <Row>
                    <Col xs={"auto"}>
                        <Form.Group className={"w-50px"}>
                            <Form.Label>
                                No:
                            </Form.Label>
                            <Form.Control disabled
                                          value={invoice.ref ? invoice.invoiceNumber : getGlobalStateNumber()}
                                          className={"fw-bold text-end"}/>
                        </Form.Group>
                    </Col>
                    <Col xs={"auto"}>
                        <Form.Group className={"w-120px"}>
                            <Form.Label className={"small"}><I18Label label={"Дата"}/>:</Form.Label>
                            <ReactDatePicker className={"form-control"}
                                             dateFormat="dd.MM.yyyy"
                                             onChange={(d) => {
                                                 setInvoice(prevState => {
                                                     return {...prevState, invoiceDate: d || new Date()}
                                                 })
                                             }}
                                             selected={invoice.invoiceDate}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={"auto"}>
                        <Form.Group>
                            <Form.Label className={"small"}><I18Label label={"Валута"}/>:</Form.Label>
                            <Form.Select value={invoice.currency}
                                         onChange={event => setInvoice({
                                             ...invoice,
                                             currency: event.target.value as "BGN" | "LEU" | "EUR",
                                         })}>
                                <option value={"BGN"}>BGN (1.95583)</option>
                                <option value={"EUR"}>EUR (1.00000)</option>
                                <option value={"LEU"}>LEU ({LEU_RATE.rate.toFixed(5)})</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    {/*<Col xs={"auto"}>*/}
                    {/*    <Form.Group className={"w-75px"}>*/}
                    {/*        <Form.Label className={"small"}><I18Label label={"Курс"}/>:</Form.Label>*/}
                    {/*        <Form.Control*/}
                    {/*            value={""}*/}
                    {/*        />*/}
                    {/*    </Form.Group>*/}
                    {/*</Col>*/}
                    <Col xs={"auto"}>
                        <Form.Group className={"w-120px"}>
                            <Form.Label className={"small"}><I18Label label={"Към запитване:"}/>:</Form.Label>
                            <Form.Control
                                value={`${props.inquiry.inquiryNumber} / ${dateToString(props.inquiry.inquiryDate)}`}
                                disabled={true}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        {/*Sender & receiver*/}
                        <Row>
                            <Col>
                                <Table size={"sm"} borderless>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <I18Label label={"Изберете доставчик"}/>:<br/>
                                            <select className={"form-select"} value={invoice.sender.companyId}
                                                    onChange={event => setInvoice(prevState => {
                                                        const ownCompany = props.ownCompanies.find(c => c.id === event.target.value) || null;
                                                        if (!ownCompany) return prevState;
                                                        const _sender: CustomerInvoicePartyType = {
                                                            name: ownCompany.name,
                                                            address: ownCompany.address,
                                                            city: ownCompany.city,
                                                            mol: ownCompany.mol,
                                                            ddsNumber: ownCompany.ddsNumber,
                                                            bulstat: ownCompany.bulstat,
                                                            phone: ownCompany.phone,
                                                            companyId: ownCompany.id || "",
                                                            clientId: "",
                                                            locationId: "",
                                                            email: ownCompany.email,
                                                        }
                                                        return {
                                                            ...prevState,
                                                            sender: _sender
                                                        }
                                                    })}
                                            >
                                                <option></option>
                                                {
                                                    props.ownCompanies.map(
                                                        c => <option key={c.id} value={c.id}> {c.name} </option>
                                                    )
                                                }
                                            </select>
                                        </td>
                                    </tr>

                                    {
                                        showDetails &&
                                        <>
                                            <tr>
                                                <td><I18Label label={"Доставчик"}/>:</td>
                                                <td>
                                                    <FormControl value={invoice.sender?.name || ""}
                                                                 onChange={event => setInvoice({
                                                                     ...invoice,
                                                                     sender: {
                                                                         ...invoice.sender,
                                                                         name: event.target.value || ""
                                                                     }
                                                                 })}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><I18Label label={"ДДС No:"}/></td>
                                                <td>
                                                    <FormControl value={invoice.sender?.ddsNumber || ""}
                                                                 onChange={event => setInvoice({
                                                                     ...invoice,
                                                                     sender: {
                                                                         ...invoice.sender,
                                                                         ddsNumber: event.target.value || ""
                                                                     }
                                                                 })}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><I18Label label={"Идентификационен No:"}/></td>
                                                <td>
                                                    <FormControl value={invoice.sender?.bulstat || ""}
                                                                 onChange={event => setInvoice({
                                                                     ...invoice,
                                                                     sender: {
                                                                         ...invoice.sender,
                                                                         bulstat: event.target.value || ""
                                                                     }
                                                                 })}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><I18Label label={"Град"}/>:</td>
                                                <td>
                                                    <FormControl value={invoice.sender?.city || ""} size={"sm"}
                                                                 onChange={event => setInvoice({
                                                                     ...invoice,
                                                                     sender: {
                                                                         ...invoice.sender,
                                                                         city: event.target.value || ""
                                                                     }
                                                                 })}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><I18Label label={"Адрес"}/>:</td>
                                                <td>
                                                    <FormControl value={invoice.sender?.address || ""} size={"sm"}
                                                                 onChange={event => setInvoice({
                                                                     ...invoice,
                                                                     sender: {
                                                                         ...invoice.sender,
                                                                         address: event.target.value || ""
                                                                     }
                                                                 })}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><I18Label label={"МОЛ"}/>:</td>
                                                <td>
                                                    <FormControl value={invoice.sender?.mol || ""} size={"sm"}
                                                                 onChange={event => setInvoice({
                                                                     ...invoice,
                                                                     sender: {
                                                                         ...invoice.sender,
                                                                         mol: event.target.value || ""
                                                                     }
                                                                 })}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><I18Label label={"Телефон"}/>:</td>
                                                <td>
                                                    <FormControl value={invoice.sender?.phone || ""} size={"sm"}
                                                                 onChange={event => setInvoice({
                                                                     ...invoice,
                                                                     sender: {
                                                                         ...invoice.sender,
                                                                         phone: event.target.value || ""
                                                                     }
                                                                 })}/>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    </tbody>
                                </Table>
                            </Col>
                            <Col xs={"auto"} className={"pt-4"}>
                                {
                                    showDetails ?
                                        <MinusIconButton onClick={() => setShowDetails(false)}/>
                                        : <PlusIconButton onClick={() => setShowDetails(true)}/>
                                }
                            </Col>

                            <Col>
                                <Table size={"sm"} borderless>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <I18Label label={"Изберете получател"}/>:<br/>
                                            <SelectValueFromDialog value={invoice.receiver?.name || ''}
                                                                   onChange={selected => {
                                                                       if (selected) {
                                                                           setInvoice(prevState => {
                                                                               return {
                                                                                   ...prevState,
                                                                                   receiver: {
                                                                                       companyId: "",
                                                                                       clientId: selected?.clientId || "",
                                                                                       locationId: selected?.locationId || "",
                                                                                       city: selected.region || "",
                                                                                       mol: selected?.mol || "",
                                                                                       address: selected?.address || "",
                                                                                       phone: selected?.phone || "",
                                                                                       name: selected?.name || "",
                                                                                       bulstat: selected?.bulstat || "",
                                                                                       ddsNumber: selected?.bulstat || "",
                                                                                       email: selected?.email || "",
                                                                                   }
                                                                               }
                                                                           })
                                                                       }
                                                                   }}
                                                // onLocationChange={(selected) => {
                                                //     setInvoice(prevState => {
                                                //         return {
                                                //             ...prevState,
                                                //             receiver: {
                                                //                 companyId: "",
                                                //                 clientId: selected.client.id,
                                                //                 locationId: selected.location.id,
                                                //                 city: selected.location.region,
                                                //                 mol: selected.location.mol,
                                                //                 address: selected.location.address,
                                                //                 phone: selected.location.phone,
                                                //                 name: selected.location.name,
                                                //                 bulstat: selected.client.bulstat || "",
                                                //                 ddsNumber: selected.client.bulstat || "",
                                                //             }
                                                //         }
                                                //     })
                                                // }}
                                                                   dialog={clientsDialog}/>
                                        </td>
                                    </tr>
                                    {
                                        showDetails &&
                                        <>
                                            <tr>
                                                <td><I18Label label={"Получател"}/>:</td>
                                                <td>
                                                    <FormControl value={invoice.receiver?.name || ""}
                                                                 onChange={event => setInvoice({
                                                                     ...invoice,
                                                                     receiver: {
                                                                         ...invoice.receiver,
                                                                         name: event.target.value || ""
                                                                     }
                                                                 })}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><I18Label label={"ДДС No:"}/></td>
                                                <td>
                                                    <FormControl value={invoice.receiver?.ddsNumber || ""}
                                                                 onChange={event => setInvoice({
                                                                     ...invoice,
                                                                     receiver: {
                                                                         ...invoice.receiver,
                                                                         ddsNumber: event.target.value || ""
                                                                     }
                                                                 })}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><I18Label label={"Идентификационен No:"}/></td>
                                                <td>
                                                    <FormControl value={invoice.receiver?.bulstat || ""}
                                                                 onChange={event => setInvoice({
                                                                     ...invoice,
                                                                     receiver: {
                                                                         ...invoice.receiver,
                                                                         bulstat: event.target.value || ""
                                                                     }
                                                                 })}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><I18Label label={"Град"}/>:</td>
                                                <td>
                                                    <FormControl value={invoice.receiver?.city || ""} size={"sm"}
                                                                 onChange={event => setInvoice({
                                                                     ...invoice,
                                                                     receiver: {
                                                                         ...invoice.receiver,
                                                                         city: event.target.value || ""
                                                                     }
                                                                 })}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><I18Label label={"Адрес"}/>:</td>
                                                <td>
                                                    <FormControl value={invoice.receiver?.address || ""} size={"sm"}
                                                                 onChange={event => setInvoice({
                                                                     ...invoice,
                                                                     receiver: {
                                                                         ...invoice.receiver,
                                                                         address: event.target.value || ""
                                                                     }
                                                                 })}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><I18Label label={"МОЛ"}/>:</td>
                                                <td>
                                                    <FormControl value={invoice.receiver?.mol || ""} size={"sm"}
                                                                 onChange={event => setInvoice({
                                                                     ...invoice,
                                                                     receiver: {
                                                                         ...invoice.receiver,
                                                                         mol: event.target.value || ""
                                                                     }
                                                                 })}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><I18Label label={"Телефон"}/>:</td>
                                                <td>
                                                    <FormControl value={invoice.receiver?.phone || ""} size={"sm"}
                                                                 onChange={event => setInvoice({
                                                                     ...invoice,
                                                                     receiver: {
                                                                         ...invoice.receiver,
                                                                         phone: event.target.value || ""
                                                                     }
                                                                 })}/>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row>


                {/*Inv items*/}
                {
                    invoice.currency && currencyRates && !loadingRates &&
                    <Row>
                        <Col>
                            <CustomerInvoiceItemsTable documentType={invoice.documentType}
                                                       currencyIndex={currencyRate}
                                                       items={[...invoice.items]}
                                                       availableItems={props.availableItems}
                                                       onChange={newItems => {
                                                           setInvoice({...invoice, items: newItems});
                                                       }}
                            />
                        </Col>
                    </Row>
                }


                {/*Inv details*/}

                {
                    invoice.documentType !== "потвърждение на поръчка" &&
                    <Row>
                        <Col>
                            <Table size={"sm"} borderless>
                                <tbody>
                                <tr>
                                    <td>
                                        <I18Label label={"Тегло"}/>:
                                    </td>
                                    <td>
                                        <FormGroup>
                                            <FormControl value={calculatedInvoiceWeight} disabled/>
                                        </FormGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <td><I18Label label={"Срок на доставка"}/>:</td>
                                    <td>
                                        <FormGroup>
                                            <ReactDatePicker className={"form-control"}
                                                             dateFormat="dd.MM.yyyy"
                                                             onChange={(d) => {
                                                                 setInvoice(prevState => {
                                                                     return {
                                                                         ...prevState,
                                                                         deliveryDate: d || new Date()
                                                                     }
                                                                 })
                                                             }}
                                                             selected={invoice.deliveryDate}
                                            />
                                        </FormGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <td><I18Label label={"Основание на сделката"}/>:</td>
                                    <td>
                                        <FormControl value={invoice.dialReason || ""}
                                                     onChange={event => setInvoice({
                                                         ...invoice,
                                                         dialReason: event.target.value || ""
                                                     })}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><I18Label label={"Описание на сделката"}/>:</td>
                                    <td>
                                        <FormControl value={invoice.dialDescription || ""}
                                                     onChange={event => setInvoice({
                                                         ...invoice,
                                                         dialDescription: event.target.value || ""
                                                     })}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><I18Label label={"Място на сделката"}/>:</td>
                                    <td>
                                        <FormControl value={invoice.dialPlace || ""}
                                                     onChange={event => setInvoice({
                                                         ...invoice,
                                                         dialPlace: event.target.value || ""
                                                     })}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col>
                            <Table size={"sm"} borderless>
                                <tbody>
                                <tr>
                                    <td><I18Label label={"Плащане"}/>:</td>
                                    <td>
                                        <FormControl value={invoice.payType || ""}
                                                     onChange={event => setInvoice({
                                                         ...invoice,
                                                         payType: event.target.value || ""
                                                     })}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><I18Label label={"IBAN"}/>:</td>
                                    <td>
                                        <SelectValueFromDialog value={invoice.iban || ''}
                                                               className={" "}
                                                               onChange={selected =>
                                                                   setInvoice({
                                                                       ...invoice,
                                                                       bank: selected?.bank || "",
                                                                       bankCode: selected?.bic || "",
                                                                       iban: selected?.iban || ""
                                                                   })}
                                                               dialog={bankAccsDialog}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><I18Label label={"Банка"}/>:</td>
                                    <td>
                                        <FormControl value={invoice.bank || ""}
                                                     readOnly={true}
                                                     onChange={event => setInvoice({
                                                         ...invoice,
                                                         bank: event.target.value || ""
                                                     })}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><I18Label label={"Банков код"}/>:</td>
                                    <td>
                                        <FormControl value={invoice.bankCode || ""}
                                                     readOnly={true}
                                                     onChange={event => setInvoice({
                                                         ...invoice,
                                                         bankCode: event.target.value || ""
                                                     })}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                }
            </Modal.Body>

            {
                canUserEdit('CRM-Запитвания и оферти') &&
                <Modal.Footer className={"text-end"}>
                    {
                        working ? <Col className={"text-center"}><Spinner animation={"border"}/></Col> :
                            <>
                                {
                                    invoice.ref &&
                                    <Col className={"text-start"}>
                                        <DeleteConfirmIconButton onClick={deleteInvoice}/>&nbsp;
                                        <HisotryIconButton onClick={() => setShowHistory(true)}/>
                                    </Col>
                                }
                                <Col className={"text-end"}>
                                    <Button onClick={saveInvoice}>
                                        <FontAwesomeIcon icon={faCheck}/>&nbsp;&nbsp;<I18Label label={"Запази"}/>
                                    </Button>
                                    <CancelIconButton onClick={props.onClose}/>
                                </Col>
                            </>
                    }
                </Modal.Footer>
            }
        </Modal>

        {
            showHistory && <CustomerInvoiceHistory customerInvoice={invoice}
                                                   zIndex={zIndex + 1}
                                                   onClose={() => setShowHistory(false)}/>
        }
    </ModalBackground>
}

export default CustomerInvoiceDetails;
