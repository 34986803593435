import {Text, View} from "@react-pdf/renderer";
import React from "react";
import {TableRow} from "../product-table/OfferProductPdfTableHeader";
import {StyleSheet} from "@react-pdf/renderer";
import {CurrencyNameType} from "../../../../../../../fb-converters/currency-converter";

const styles = StyleSheet.create({
    col1H: {width: 20, backgroundColor: "silver", textAlign: "center", padding: 2},
    col2H: {width: 300, backgroundColor: "silver", textAlign: "left", padding: 2},
    col3H: {width: 300, backgroundColor: "silver", textAlign: "center", padding: 2},
    col4H: {width: 300, backgroundColor: "silver", textAlign: "center", padding: 2},
    col5H: {width: 80, backgroundColor: "silver", textAlign: "center", padding: 2},
});

const TableSubHeader = ({currency}: { currency: string }) => (
    <View style={{fontWeight: "bold"}}>
        <TableRow cols={
            [
                <Text style={styles.col5H}>
                    Гаранция - стандартна
                    (месеци)
                </Text>,
                <Text style={styles.col5H}>
                    Стойност без допълнителни услуги
                    ({ currency })
                </Text>,
                <Text style={styles.col5H}>
                    Гаранция - удължена (месеци)
                </Text>,
                <Text style={styles.col5H}>
                    Стойност с удължена гаранция
                    ({ currency })
                </Text>
            ]
        }/>
    </View>
);

interface OfferInvestmentPdfTableHeaderProps {
    currency: CurrencyNameType;
}

const OfferInvestmentPdfTableHeader = ({currency}: OfferInvestmentPdfTableHeaderProps) => {
    return (
        <View>
            <TableRow cols={[
                <View style={styles.col1H}>
                    <Text>No:</Text>
                </View>,
                <View style={styles.col2H}>
                    <Text>Продукт</Text>
                </View>,
                <View style={styles.col3H}>
                    <Text style={{paddingBottom: 3}}>Общ размер на инвестицията</Text>
                    <TableSubHeader currency={currency}/>
                </View>,
                <View style={styles.col4H}>
                    <Text style={{paddingBottom: 3}}>Размер на инвестицията за месец</Text>
                    <TableSubHeader currency={currency}/>
                </View>,
            ]}/>
        </View>
    );
}

export default OfferInvestmentPdfTableHeader;
