import {useEffect, useMemo, useState} from "react";
import {Button, Col, Row, Tab, Table, Tabs} from "react-bootstrap";
import LoadingErrorContent from "../../../../common/LoadingErrorContent";
import {ProvidersType} from "../../../nomenclatures/providers/hooks/useProviders";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {ConfirmDeleteModal} from "../../../../common/ConfirmDeleteModal";
import {WarehouseObjectType} from "../../hooks/useWarehouseObjects";
import UseRecalcWarehouseItemStatus from "../../hooks/useRecalcWarehouseItemQty";
import WarehouseInOutFilter, {WarehouseInOutFilterType} from "../WarehpuseInOutFilter";
import {EMPTY_WAREHOUSE_REQUEST, WarehouseRequestType} from "./WarehouseRequestType";
import {useWarehouseRequestsRepo} from "./useWarehouseRequestsRepo";
import {deleteDoc, doc, QueryFieldFilterConstraint, where} from "firebase/firestore";
import {WarehouseRequestDetails} from "./WarehouseRequestDetails";
import WarehouseRequestTableRow from "./WarehouseRequestTableRow";
import {fbDb} from "../../../../App";
import WarehouseRequestPrintPreview from "../../print/warehouse-request/WarehouseRequestPrintPreview";
import OwnCompanyWarehouseSelector from "../../warehouse/OwnCompanyWarehouseSelector";
import {dateWithEndDayTime, dateWithZeroTime} from "../../../../common";
import useUserRights from "../../../../hooks/useUserRights";

export interface WarehouseObjectInComponentProps {
    whObject: WarehouseObjectType;
    allProviders: ProvidersType;
}

const toDay = new Date();

export default function WarehouseObjectRequestComponent(props: WarehouseObjectInComponentProps) {
    const {canUserEdit} = useUserRights();
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>();
    const [selectedWarehouseId, setSelectedWarehouseId] = useState<string | undefined>();

    const [isActive, setIsActive] = useState<"active" | "inactive">("active");
    const [filter, setFilter] = useState<WarehouseInOutFilterType>({
        fromDate: dateWithZeroTime(new Date(toDay.getFullYear(), toDay.getMonth(), 1, 0, 0, 0, 0)),
        toDate: dateWithEndDayTime(toDay),
    });

    const {items, loading, error} = useWarehouseRequestsRepo({
        warehouseId: selectedWarehouseId,
        createdAtFrom: filter.fromDate ?? new Date(),
        createdAtTo: filter.toDate ?? new Date(),
        isActive: isActive === "active",
    });
    const [editRequest, setEditRequest] = useState<WarehouseRequestType | null>(null);
    const [deleteTransaction, setDeleteTransaction] = useState<WarehouseRequestType | null>(null);
    const [printRequest, setPrintRequest] = useState<WarehouseRequestType | null>(null);

    function setEditTransaction(p: WarehouseRequestType) {
        setEditRequest(p);
    }

    let tableRows = useMemo(() => {
        return items.map(
            p => <WarehouseRequestTableRow data={p} key={p.id}
                                           onPrint={() => setPrintRequest(p)}
                                           onEdit={() => setEditTransaction(p)}
                                           onDelete={
                                               canUserEdit('Склад-Склад - заявки') ? () => setDeleteTransaction(p) : undefined
                                           }/>
        )
    }, [items]);

    function addNew() {
        setEditRequest({
            ...EMPTY_WAREHOUSE_REQUEST,
            ownCompanyId: selectedCompanyId!,
            warehouseId: selectedWarehouseId!,
        })
    }

    async function removeRequest() {
        if (deleteTransaction) {
            deleteDoc(doc(fbDb, `warehouseRequests/${deleteTransaction.id}`));
            setDeleteTransaction(null);
        }
    }

    return <div className={"mt-1 p-3 border shadow rounded bg-light"}>
        <Row>
            <Col>
                <h3>Заявки</h3>
            </Col>
            <Col xs={"auto"} className={"text-end"}>
                {
                    canUserEdit('Склад-Склад - заявки') &&
                    <Button onClick={addNew}>
                        <FontAwesomeIcon icon={faPlus}/> Добави
                    </Button>
                }
            </Col>
        </Row>

        <OwnCompanyWarehouseSelector
            selectedCompanyId={selectedCompanyId}
            selectedWarehouseId={selectedWarehouseId}
            onChange={(companyId, warehouseId) => {
                setSelectedCompanyId(companyId);
                setSelectedWarehouseId(warehouseId);
            }}
        />

        <div className={"max-h-65vh scrollable bg-white"}>
            <Row style={{width: "75%"}}>
                <Col>
                    <WarehouseInOutFilter
                        viewMode={"in"}
                        filter={filter}
                        onChange={(newFilter) => setFilter({...newFilter})}/>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Tabs activeKey={isActive}
                          onSelect={(k) => setIsActive(k as "active" | "inactive")}>
                        <Tab eventKey="active" title="Активни"/>
                        <Tab eventKey="inactive" title="Завършени"/>
                    </Tabs>
                </Col>
            </Row>

            <LoadingErrorContent loading={loading} error={error?.message}>
                <Row>
                    <Col>
                        <Table size={"sm"} bordered hover striped>
                            <thead>
                            <tr>
                                <th rowSpan={2}>Номер</th>
                                <th rowSpan={2}>Дата</th>
                                <th rowSpan={2}>Булстат</th>
                                <th rowSpan={2}>Доставчик</th>
                                <th colSpan={10} className={"text-center"}>Включени към заявката стоки</th>
                                <th rowSpan={2}></th>
                            </tr>
                            <tr>
                                <th>SKU</th>
                                <th>Име</th>
                                <th>Количество</th>
                                <th>Очаквана дата на доставка</th>
                                <th>Дата на доставка</th>
                                <th>Количество</th>
                                <th>Цена</th>
                                <th>Дата на фактура</th>
                                <th>Фактура №</th>
                                <th>Към поръчка</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                tableRows.length === 0 ?
                                    <tr>
                                        <td colSpan={14} className={"text-center"}>Няма данни</td>
                                    </tr> :
                                    tableRows
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </LoadingErrorContent>
        </div>

        {
            editRequest &&
            <WarehouseRequestDetails warehouseRequest={{...editRequest}}
                                     onDone={() => setEditRequest(null)}/>
        }
        {
            deleteTransaction &&
            <ConfirmDeleteModal working={false}
                                onConfirm={() => removeRequest()}
                                onReject={() => setDeleteTransaction(null)}/>
        }
        {
            printRequest &&
            <WarehouseRequestPrintPreview
                zIndex={1050}
                warehouseRequest={printRequest}
                onDismiss={
                    () => setPrintRequest(null)
                }
            />
        }
    </div>;

}
