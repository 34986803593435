import {Button, Col, Form, ModalBody, ModalFooter, Pagination, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCircleArrowDown, faEdit, faPlus, faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useMemo, useState} from "react";
import LoadDataFromServerError from "../../../common/LoadDataFromServerError";
import useDebounce from "../../../hooks/UseDebounce";
import useCountriesOnce from "./hooks/useCountriesOnce";
import {ClientsType, ClientType} from "./hooks/useClients";
import LoadingErrorContent from "../../../common/LoadingErrorContent";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";
import I18Label from "../../../i18/i18label";
import CancelIconButton from "../../../common/icon-buttons/CancelIconButton";
import useUserRights from "../../../hooks/useUserRights";


export interface ClientsListProps {
    isVisible: boolean;
    onAdd: (country: string) => any;
    onEdit: (item: ClientType) => any;
    onDelete: (item: ClientType) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
}

export function ClientsList({isVisible, onAdd, onEdit, onDelete, onClose, onSelect}: ClientsListProps) {
    const {canUserEdit} = useUserRights();
    const PAGE_SIZE = 100;

    const [countries, loadingCountries, errorCountries] = useCountriesOnce();
    const [selectedCountry, setSelectedCountry] = useState('');

    const [globalFilter, setGlobalFilter] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterSalesRepresentative, setFilterSalesRepresentative] = useState('');
    const [filterRegion, setFilterRegion] = useState('');
    const [filterSize, setFilterSize] = useState('');

    const _clients = useHookstate(appDb.clients);
    const clients = useMemo(() => {
        return _clients.value.filter(
            c => c.country === selectedCountry
        );
    }, [selectedCountry, _clients]);
    const _locations = useHookstate(appDb.clientsLocations).value;

    const [filteredClients, setFilteredClients] = useState<ClientsType>([]);
    const [pages, setPages] = useState<any[]>([]);
    const [activePage, setActivePage] = useState(0);
    const [filtering, setFiltering] = useState(false);

    useEffect(
        () => {
            if (countries && countries.length > 0 && selectedCountry === '') setSelectedCountry(countries[0].name);
        }, [countries]
    )

    // const updateLocationName = async () => {
    //     const locations = await getDocs(collection(fbDb, "clientLocations"));
    //     locations.forEach(
    //         async d => {
    //             if (!d.get("name") && d.get("clientName")) {
    //                 await updateDoc(d.ref, {name: d.get("clientName")});
    //             }
    //         }
    //     )
    // }

    // useEffect(() => {
    //     updateLocationName().then(
    //         () => {
    //             debugger
    //             alert("Done");
    //         }
    //     )
    // }, [])

    useDebounce(() => {
        setFiltering(true);

        if (clients) {
            const filter = globalFilter.toLowerCase();

            const filtered = clients.filter(
                r => {
                    if (filter.length === 0) return true;

                    const clientLocations = _locations.filter(
                        l =>
                            l.clientId === r.id &&
                            (
                                (l.name && l.name?.toLowerCase().indexOf(filter) > -1) ||
                                (l.address && l.address?.toLowerCase().indexOf(filter) > -1) ||
                                (l.mol && l.mol?.toLowerCase().indexOf(filter) > -1) ||
                                (l.phone && l.phone?.toLowerCase().indexOf(filter) > -1) ||
                                (l.email && l.email?.toLowerCase().indexOf(filter) > -1) ||
                                (l.machines && l.machines?.toLowerCase().indexOf(filter) > -1) ||
                                (l.clientName && l.clientName?.toLowerCase().indexOf(filter) > -1) ||
                                (l.position && l.position?.toLowerCase().indexOf(filter) > -1) ||
                                (l.trader && l.trader?.toLowerCase().indexOf(filter) > -1)
                            )
                    );
                    const isValid = (r.name.toLowerCase().indexOf(filter) > -1) ||
                        (r.address && r.address?.toLowerCase().indexOf(filter) > -1) ||
                        (r.bulstat && r.bulstat?.toLowerCase().indexOf(filter) > -1) ||
                        (r.email && r.email?.toLowerCase().indexOf(filter) > -1) ||
                        (r.mol && r.mol?.toLowerCase().indexOf(filter) > -1) ||
                        (r.phone && r.phone?.toLowerCase().indexOf(filter) > -1) ||
                        (r.regNom && r.regNom?.toLowerCase().indexOf(filter) > -1) ||
                        (r.sector && r.sector?.toLowerCase().indexOf(filter) > -1) ||
                        clientLocations.length > 0;

                    return isValid;
                });

            setFilteredClients(filtered.filter(
                r => (!filterName || filterName.length === 0 || r.name?.toLowerCase().indexOf(filterName.toLowerCase()) > -1) &&
                    (!filterSalesRepresentative || filterSalesRepresentative.length === 0 || !r.bulstat || r.bulstat.toLowerCase().indexOf(filterSalesRepresentative.toLowerCase()) > -1) &&
                    (!filterSize || filterSize.length === 0 || !r.sizeGroup || r.sizeGroup.name.toLowerCase().indexOf(filterSize.toLowerCase()) > -1) &&
                    (!filterRegion || filterRegion.length === 0 || !r.region || r.region?.toLowerCase().indexOf(filterRegion.toLowerCase()) > -1)
            ));
        } else setFilteredClients([]);
        setFiltering(false);
    }, [clients, filterName, filterSalesRepresentative, filterSize, filterRegion, globalFilter], 50);

    useEffect(() => setActivePage(0)
        , [selectedCountry, filterName, filterSalesRepresentative, filterSize, filterRegion]);

    useEffect(
        () => {
            const _pages: React.ReactElement[] = [];
            const total = filteredClients.length;
            const pageCnt = Math.floor(total / PAGE_SIZE);
            for (let i = 0; i < pageCnt; i++) {
                _pages.push(
                    <Pagination.Item key={i} active={i === activePage} onClick={() => setActivePage(i)}>
                        {(i + 1)}
                    </Pagination.Item>
                );
            }

            setPages(_pages);
        }, [filteredClients, activePage]
    )

    function isLoading() {
        return loadingCountries;
    }

    // async function fixClients() {
    //     const clients = await getDocs(collection(fbDb, "clients"));
    //     const locationsRef = collection(fbDb, "clientLocations");
    //     const locations = await getDocs(locationsRef);
    //     clients.forEach(
    //         async d => {
    //             // if (!d.data().country) {
    //             //     await updateDoc(d.ref, {country: "България"});
    //             // }
    //
    //
    //             // await new Promise(resolve => setTimeout(resolve, 750));
    //
    //             const docId = d.id;
    //             const data = d.data();
    //
    //             if (locations.docs.findIndex(value => value.data().clientId === docId) === -1) {
    //                 await addDoc(locationsRef, {
    //                     clientId: docId,
    //                     name: data.name,
    //                     sizeGroup: data.sizeGroup || null,
    //                     address: data.address || null,
    //                     email: data.email || null,
    //                     mol: data.mol || null,
    //                     phone: data.phone || null,
    //                     religion: "",
    //                     machinesCnt: 0,
    //                     machines: "",
    //                     country: data.country,
    //                     region: data.region || null,
    //                     trader: "",
    //                 })
    //
    //                 console.log("Add ", data);
    //
    //             } else {
    //                 // locations.forEach(
    //                 //     async (l: any) => {
    //                 //         const _data: any = {};
    //                 //         if(l.data().sizeGroup?.name) {
    //                 //             _data.sizeGroup = l.data().sizeGroup.name;
    //                 //         }
    //                 //         if(!l.data().sizeGroup) {
    //                 //             _data.sizeGroup = data.sizeGroup || "Малък клиент";
    //                 //         }
    //                 //         if(!l.data().country) {
    //                 //             _data.country = data.country;
    //                 //         }
    //                 //         if(!l.data().region) {
    //                 //             _data.region = data.region || null;
    //                 //         }
    //                 //
    //                 //         if(Object.keys(_data).length > 0 ) {
    //                 //             console.log(_data);
    //                 //             await updateDoc(l.ref, _data);
    //                 //         }
    //                 //     }
    //                 // )
    //             }
    //         }
    //     )
    // }

    // async function fixDblLoc() {
    //     const removeIds: any[] = [];
    //     const allLoc = await getDocs(query(collection(fbDb, "clientLocations"), orderBy("clientId")));
    //     const size = allLoc.size;
    //
    //     for (let i1 = 0; i1 < size; i1++) {
    //         if (removeIds.indexOf(allLoc.docs[i1].id) === -1) {
    //             const doc1 = allLoc.docs[i1].data();
    //
    //             for (let i2 = i1 + 1; i2 < size; i2++) {
    //                 const doc2 = allLoc.docs[i2].data();
    //
    //                 if (
    //                     doc1.clientId === doc2.clientId &&
    //                     doc1.sizeGroup === doc2.sizeGroup &&
    //                     doc1.address === doc2.address &&
    //                     doc1.region === doc2.region &&
    //                     doc1.trader === doc2.trader
    //                 ) {
    //                     removeIds.push(allLoc.docs[i2].ref);
    //                 } else {
    //                     break;
    //                 }
    //             }
    //         }
    //     }
    //
    //
    //     let start = 0;
    //     while (start < removeIds.length) {
    //         const batch = writeBatch(fbDb);
    //         for (let idx = 0; idx < 500; idx++) {
    //             if (start + idx < removeIds.length) {
    //                 batch.delete(removeIds[start + idx]);
    //             }
    //             start++;
    //         }
    //         await batch.commit();
    //     }
    // }

    return (
        <>
            {isLoading() && <Spinner animation={"border"}/>}

            {
                !isLoading() && " " && errorCountries && <LoadDataFromServerError/>
            }

            {
                clients && isVisible &&
                <>
                    <ModalBody className={"mt-0 mb-0 pt-0 pb-0"}>
                        <Row>
                            <Col>
                                <Tabs activeKey={selectedCountry} className={"mt-1"}
                                      onSelect={(key) => setSelectedCountry(key || '')}
                                >
                                    {
                                        countries?.map(
                                            c => <Tab key={c.name} title={<I18Label label={c.name}/>}
                                                      eventKey={c.name}/>
                                        )
                                    }
                                </Tabs>
                            </Col>
                            <Col className={"pt-2"}>
                                <Row>
                                    <Col>
                                        <Form.Control value={globalFilter} size={"sm"}
                                                      onChange={(e) => setGlobalFilter(e.target.value)}
                                                      placeholder={"Търсене..."}/>
                                    </Col>
                                    <Col xs={"auto"}>
                                        <CancelIconButton onClick={() => {
                                            setGlobalFilter('');
                                        }}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className={"max-h-60vh scrollable"}>
                            <Table size={"sm"} borderless hover>
                                <thead>
                                <tr>
                                    <th style={{width: onSelect ? 120 : 90}}></th>
                                    <th><I18Label label={"Име"}/></th>
                                    <th><I18Label label={"Търговски представител"}/></th>
                                    <th><I18Label label={"Регион"}/></th>
                                    <th><I18Label label={"Тип"}/></th>
                                    {/*<th>Търговски преставител</th>*/}
                                </tr>
                                <tr>
                                    <td className={"text-start"}>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterName} className={"w-100 overflow-hidden"}
                                                      onChange={(e) => setFilterName(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterSalesRepresentative}
                                                      className={"w-100 overflow-hidden"}
                                                      onChange={(e) => setFilterSalesRepresentative(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterRegion}
                                                      className={"w-100 overflow-hidden"}
                                                      onChange={(e) => setFilterRegion(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterSize} className={"w-100 overflow-hidden"}
                                                      onChange={(e) => setFilterSize(e.target.value)}/>
                                    </td>
                                    {/*<td>*/}
                                    {/*    <Form.Control size={"sm"} value={filterRepresentative}*/}
                                    {/*                  onChange={(e) => setFilterRepresentative(e.target.value)}/>*/}
                                    {/*</td>*/}
                                </tr>
                                </thead>


                                <LoadingErrorContent loading={loadingCountries || filtering}
                                                     error={errorCountries?.message}>
                                    <tbody>

                                    {
                                        filteredClients && filteredClients.length === 0 &&
                                        <tr>
                                            <td colSpan={6} className={"text-center fw-bold"}><I18Label
                                                label={"Няма клиенти"}/></td>
                                        </tr>
                                    }
                                    {
                                        filteredClients && filteredClients.length > 0 && !filtering &&
                                        filteredClients.slice(activePage * PAGE_SIZE, activePage * PAGE_SIZE + PAGE_SIZE).map(
                                            r =>
                                                <tr key={r.id} className={"tr-bordered"}>
                                                    <td>
                                                        <Button size={"sm"} variant={"outline-secondary"}
                                                                onClick={() => onEdit(r)}
                                                        >
                                                            <FontAwesomeIcon icon={faEdit}/>
                                                        </Button>&nbsp;
                                                        {
                                                            canUserEdit('Номенклатури-Клиенти') &&
                                                            <Button size={"sm"} variant={"outline-danger"}
                                                                    onClick={() => {
                                                                        onDelete(r)
                                                                    }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash}/>
                                                            </Button>}&nbsp;
                                                        {
                                                            onSelect &&
                                                            <Button size={"sm"} variant={"outline-success"}
                                                                    onClick={() => {
                                                                        onSelect({...r, id: r.id})
                                                                    }}
                                                            >
                                                                <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                            </Button>
                                                        }
                                                    </td>
                                                    <td>
                                                        {r.name}
                                                    </td>
                                                    <td>
                                                        {r.salesRepresentative?.toString()}
                                                    </td>
                                                    <td>
                                                        <I18Label label={r.region}/>
                                                    </td>
                                                    <td className={"text-nowrap"}>
                                                        <I18Label label={r.sizeGroup?.name}/>
                                                    </td>
                                                    {/*<td>*/}
                                                    {/*    {r.data().salesRepresentative}*/}
                                                    {/*</td>*/}
                                                </tr>
                                        )
                                    }
                                    </tbody>
                                </LoadingErrorContent>
                            </Table>

                        </div>
                        <div className={"m-0 mt-2"}>
                            <Pagination className="justify-content-center">
                                {pages}
                            </Pagination>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        {
                            canUserEdit('Номенклатури-Клиенти') &&
                            <Button onClick={() => onAdd(selectedCountry)}>
                                <FontAwesomeIcon icon={faPlus}/> <I18Label label={"Добави нов"}/>
                            </Button>
                        }
                        <Button variant={"outline-secondary"} onClick={() => {
                            onClose(null)
                        }}>
                            <FontAwesomeIcon icon={faCancel}/> <I18Label label={"Затвори"}/>
                        </Button>
                        {/*<Button onClick={() => fixClients()}>Fix clients</Button>*/}
                        {/*<Button onClick={() => fixDblLoc()}>Remove Dbl Loc</Button>*/}
                    </ModalFooter>
                </>
            }
        </>
    )
}
