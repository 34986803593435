import {
    CustomerOfferProductionAttributesType
} from "../../../../../../../fb-converters/cutomerOffer-converter";
import {TableRow} from "../product-table/OfferProductPdfTableHeader";
import React from "react";
import {View, Text, StyleSheet} from "@react-pdf/renderer";
import {getI18Text} from "../../../../../../../i18/i18label";
import {numberWithCommas, numberWithCommasToCurrency} from "../../../../../../../common";


const styles = StyleSheet.create({
    col1: {width: 580, textAlign: "left", padding: 2, paddingLeft: 22},
    col2: {width: 300, textAlign: "center", padding: 2, },
    col3: {width: 80, textAlign: "center", padding: 2, },
    col4: {width: 80, textAlign: "center", padding: 2, },
    col5: {width: 100, textAlign: "center", padding: 2, },
    col6: {width: 80, textAlign: "center", padding: 2, },
    col7: {width: 80, textAlign: "center", padding: 2, },
});

const OfferProductPdfTableRow = ({attributes, productQty, euroExchangeRate}:
                                 {
                                     attributes: CustomerOfferProductionAttributesType,
                                     productQty: number,
                                     euroExchangeRate: number
                                 }) => {
    return (
        <View>
            {
                attributes.map((attribute, index) => (
                    <TableRow cols={[
                        <View style={styles.col1}>
                            <Text>
                                { getI18Text(attribute.name) }
                            </Text>
                        </View>,
                        <View style={styles.col3}>
                            <Text>
                                {numberWithCommasToCurrency(attribute.singlePrice * euroExchangeRate)}
                            </Text>
                        </View>,
                        <View style={styles.col4}>
                            <Text>
                                {numberWithCommasToCurrency(attribute.discount)}
                            </Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>
                                {numberWithCommasToCurrency(attribute.finalPrice * euroExchangeRate)}
                            </Text>
                        </View>,
                        <View style={styles.col6}>
                            <Text>
                                {numberWithCommasToCurrency(productQty)}
                            </Text>
                        </View>,
                        <View style={styles.col7}>
                            <Text>
                                {numberWithCommasToCurrency(attribute.finalPrice * productQty * euroExchangeRate)}
                            </Text>
                        </View>
                    ]}/>
                ))
            }
        </View>
    );
};

export default OfferProductPdfTableRow;
