import {Button, Col, ModalBody, ModalFooter, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LoadDataFromServerError from "../../../common/LoadDataFromServerError";
import {OwnCompanyType} from "../../../fb-converters/own-company-converter";
import {faCancel} from "@fortawesome/free-solid-svg-icons";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";
import useOwnCompaniesRepo from "../../warehouse/hooks/useOwnCompaniesRepo";

export interface OwnCompaniesListProps {
    isVisible: boolean;
    onAdd: () => any;
    onEdit: (item: OwnCompanyType) => any;
    onDelete: (item: OwnCompanyType) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
}

export function OwnCompaniesList({isVisible, onAdd, onEdit, onDelete, onClose, onSelect}: OwnCompaniesListProps) {
    const {ownCompanies} = useOwnCompaniesRepo();

    return (
        <>
            {
                isVisible && ownCompanies && ownCompanies.length === 0 &&
                <Row className={"m-5"}>
                    <Col className={"text-center fw-bold"}>Няма дефинирани собствени фирми</Col>
                </Row>
            }


            {
                ownCompanies && isVisible && ownCompanies.length > 0 &&
                <>
                    <ModalBody>
                        <div className={"max-h-60vh scrollable"}>
                            <Table size={"sm"} borderless hover>
                                <thead style={{backgroundColor: "white"}}>
                                <tr>
                                    {/*<th style={{width: onSelect ? 120 : 90}}></th>*/}
                                    <th>Фирма</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    ownCompanies.length > 0 && ownCompanies.map(
                                        d =>
                                            <tr key={d.id} className={"tr-bordered"}>
                                                <td>
                                                    {d.name}
                                                </td>
                                            </tr>
                                    )
                                }
                                </tbody>

                            </Table>
                        </div>
                    </ModalBody>
                </>
            }

            {
                isVisible &&
                <ModalFooter>
                    <Button variant={"outline-secondary"} onClick={() => {
                        onClose(null)
                    }}>
                        <FontAwesomeIcon icon={faCancel}/> Затвори
                    </Button>
                </ModalFooter>
            }
        </>
    )
}
