import {CustomerOfferProductionType} from "../../../../../../../fb-converters/cutomerOffer-converter";
import {TableRow} from "./OfferProductPdfTableHeader";
import React from "react";
import {View, Text} from "@react-pdf/renderer";
import {styles} from "../OfferPdfStyles";
import {ProductDimens} from "../../../../CustomerOfferDetailsProducts";
import {ProductType} from "../../../../../../nomenclatures/products/hooks/useProductsOnce";
import {
    numberWithCommas,
    numberWithCommasToCurrency,
    zeroToEmptyStringAsCurrencyWithCommas
} from "../../../../../../../common";
import {getI18Price, getProductI18Name} from "../../../../../../../i18/i18label";
import appDb from "../../../../../../../global-state/global-db";

interface OfferPdfTableRowProps {
    eurExchangeRate: number;
    productIndex: number;
    product: CustomerOfferProductionType;
    finalPrice: number;
}

const OfferProductPdfTableRow = ({
                                     eurExchangeRate, productIndex, product, finalPrice
                                 }: OfferPdfTableRowProps) => {
    const lng = appDb.language.value;
    const tableRows = [
        <View style={styles.col1}>
            <Text>
                {productIndex + 1}
            </Text>
        </View>,
        <View style={styles.col2}>
            <Text>
                {getProductI18Name(product.product.name)}
            </Text>
        </View>,
        <View style={styles.col3}>
            <Text>
                <ProductDimens product={{...product.product} as ProductType}/>
            </Text>
        </View>,
        <View style={styles.col4}>
            <Text>
                {product.deliveryDays}
            </Text>
        </View>,
        <View style={styles.col5}>
            <Text>
                {product.product.warranty}
            </Text>
        </View>,
        <View style={styles.col6}>
            <Text>
                {product.product.liveCycles}
            </Text>
        </View>,
        <View style={styles.col7}>
            <Text>
                {numberWithCommasToCurrency(finalPrice * eurExchangeRate)}
            </Text>
        </View>,
        <View style={styles.col8}>
            <Text>
                {numberWithCommasToCurrency(product.skrubPrice * eurExchangeRate)}
            </Text>
        </View>,
        <View style={styles.col9}>
            <Text>
                {numberWithCommasToCurrency(product.finalSinglePrice * eurExchangeRate)}
            </Text>
        </View>,
        <View style={styles.col10}>
            <Text>
                {numberWithCommasToCurrency(product.qty)}
            </Text>
        </View>,
        <View style={styles.col11}>
            <Text>
                {numberWithCommasToCurrency(product.finalSinglePrice * product.qty * eurExchangeRate)}
            </Text>
        </View>
    ];

    if (lng !== "bg") {
        tableRows.splice(7, 1);
    }

    return (<View>
        <TableRow cols={tableRows}/>
    </View>);
};

export default OfferProductPdfTableRow;
