import React, {FC, useState} from "react";
import {Button, Col, Dropdown, DropdownButton, DropdownItem, Form, Row, Spinner} from "react-bootstrap";
import {API} from "../../api";
import {addDoc, collection} from "firebase/firestore";
import {fbDb} from "../../App";
import {showErrorsListInToast, showMsgInToast} from "../../common";
import I18Label, {getI18Text} from "../../i18/i18label";
import appDb from "../../global-state/global-db";
import HisotryIconButton from "../icon-buttons/HisotryIconButton";
import CustomPrintHistory from "./CustomPrintHistory";

export type FileAttachmentType = {
    filename: string;
    blob: string;
}

interface CustomPrintSendEmailProps {
    initialSendToEmail: string,
    email: string,
    attachments?: FileAttachmentType,
    templateName?: string,
    subject?: string,
    documentId: string,
    documentType: string,
    inquiryId?: string,
}

const CustomPrintSendEmail: FC<CustomPrintSendEmailProps> = ({
                                                                 initialSendToEmail,
                                                                 email,
                                                                 subject,
                                                                 templateName,
                                                                 attachments,
                                                                 documentId,
                                                                 documentType,
                                                                 inquiryId
                                                             }) => {
    const [working, setWorking] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [sendToEmail, setSendToEmail] = useState(initialSendToEmail);
    const lng = appDb.language.value;
    const mailCollectionName = lng === "ro" ? "mail_ro" : "mail";

    const handleSendEMail = async () => {
        const sendAddr = lng === "ro" ? "romania@industrialbatteries.bg" : "office@industrialbatteries.bg";
        const sendName = lng === "ro" ? "Industrial Batteries Romania" : "Industrial Batteries Bulgaria";

        try {
            setWorking(true);
            const data = {
                from: API.user?.name.trim() + `<${sendAddr}>`,
                to: sendToEmail,
                replyTo: `${API.user?.name}<${API.user?.email}>`,
                message: {
                    html: email,
                    subject: subject ? subject : "",
                    attachments: attachments ? [{
                        filename: attachments.filename,
                        content: attachments.blob,
                        encoding: "base64",
                        contentType: 'application/pdf'
                    },] : null,
                },
                template: templateName ? {
                    name: templateName,
                    data: attachments ? {
                        subject: subject ? subject : "",
                        filename: attachments.filename,
                        file_path: `data:application/pdf;base64,${attachments.blob}`,
                    } : null,
                } : null,
            }

            await addDoc(collection(fbDb, mailCollectionName),
                {
                    ...data,
                    documentId,
                    documentType,
                    inquiryId: inquiryId ?? null,
                });
            showMsgInToast(getI18Text("Имейлът е изпратен успешно"));
        } catch (e: any) {
            console.error(e);
            showErrorsListInToast(getI18Text("Грешка при изпращане на имейл"), [e.message]);
        } finally {
            setWorking(false);
        }
    }

    if (working) {
        return <Spinner animation={"border"} variant={"primary"}/>
    }

    return (
        <Row className={"w-480px"}>
            <Col className={"pe-0"}>
                <Form.Control
                    value={sendToEmail}
                    onChange={event => setSendToEmail(event.target.value)}
                    title={getI18Text("Имейл адрес на получателя")}
                    placeholder={getI18Text("Имейл адрес на получателя") ?? ""}
                />
            </Col>
            <Col xs={"auto"} className={"ps-0"}>
                <Button
                    onClick={handleSendEMail}
                    disabled={sendToEmail?.length === 0}
                > <I18Label label={"Изпрати на имейл"}/> </Button>&nbsp;
                <HisotryIconButton onClick={() => setShowHistory(true)}/>
            </Col>
            {
                showHistory && <CustomPrintHistory
                    collectionName={mailCollectionName}
                    documentId={documentId}
                    onClose={
                        () => setShowHistory(false)
                    }
                />
            }
        </Row>
    )
}

export default CustomPrintSendEmail
