import {DocumentData, DocumentReference, QueryDocumentSnapshot, SnapshotOptions,} from "firebase/firestore";
import {firebaseDateToDate, removeEmptyFields} from "../common";
import {CurrencyNameType} from "./currency-converter";

export type CustomerInvoiceItemType = {
    listIdx: number;
    productId: string | null,
    code: string | null;
    name: string;
    dimension: string;
    _length: number;
    width: number;
    height1: number;
    height2: number;
    qty: number;
    weight: number;
    discount: number;
    price: number;
    total: number;
    ownProduction: boolean;
}
export type CustomerInvoiceItemsType = CustomerInvoiceItemType[];
export const EMPTY_CUSTOMER_INVOICE_ITEM: CustomerInvoiceItemType = {
    listIdx: 0,
    productId: null,
    code: "",
    name: "",
    dimension: "",
    price: 0,
    qty: 0,
    weight: 0,
    discount: 0,
    total: 0,
    ownProduction: false,
    _length: 0,
    width: 0,
    height1: 0,
    height2: 0,
}

export type CustomerInvoicePartyType = {
    companyId: string;
    clientId: string;
    locationId: string;
    name: string;
    ddsNumber: string;
    bulstat: string;
    city: string;
    address: string;
    mol: string;
    phone: string;
    email: string;
}

export const EMPTY_CUSTOMER_INVOICE_PARTY: CustomerInvoicePartyType = {
    name: "",
    phone: "",
    mol: "",
    city: "",
    bulstat: "",
    companyId: "",
    clientId: "",
    locationId: "",
    ddsNumber: "",
    address: "",
    email: "",
}

export type CustomerInvoiceType = {
    id?: string;
    ref?: DocumentReference;
    invoiceNumber: number;
    invoiceDate: Date;
    documentType: string;

    authId?: string;
    authEmail?: string;
    authName?: string;
    changerName?: string;
    createdAt?: Date;

    offerId: string;
    offerNumber: number;
    offerDate: Date | null;

    inquiryId: string;
    inquiryNumber: number;
    inquiryDate: Date | null;

    receiver: CustomerInvoicePartyType;
    sender: CustomerInvoicePartyType;
    items: CustomerInvoiceItemsType;

    taxEventDate: Date | null;
    deliveryDate: Date | null;
    dialReason: string;
    dialDescription: string;
    dialPlace: string;
    payType: string;
    iban: string;
    bank: string;
    bankCode: string;
    currency: CurrencyNameType;
    currencyRate: number;
    dds: number;
}
export type CustomerInvoicesType = CustomerInvoiceType[];

export const customerInvoiceConverter = {
    toFirestore(invoice: CustomerInvoiceType): DocumentData {
        return {
            invoiceNumber: invoice.invoiceNumber,
            invoiceDate: invoice.invoiceDate,
            documentType: invoice.documentType,

            offerId: invoice.offerId,
            offerNumber: invoice.offerNumber,
            offerDate: invoice.offerDate,

            inquiryId: invoice.inquiryId,
            inquiryNumber: invoice.inquiryNumber,
            inquiryDate: invoice.inquiryDate,

            receiver: invoice.receiver,
            sender: removeEmptyFields(invoice.sender),
            items: invoice.items.map(i => removeEmptyFields(i)),
            taxEventDate: invoice.taxEventDate,
            deliveryDate: invoice.deliveryDate,
            dialReason: invoice.dialReason,
            dialDescription: invoice.dialDescription,
            dialPlace: invoice.dialPlace,
            payType: invoice.payType,
            iban: invoice.iban,
            bank: invoice.bank,
            bankCode: invoice.bankCode,

            currency: invoice.currency,
            currencyRate: invoice.currencyRate,
            dds: invoice.dds ?? 20,
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): CustomerInvoiceType {
        const invoice = snapshot.data(options)!;
        return {
            id: snapshot.id,
            ref: snapshot.ref,
            invoiceNumber: invoice.invoiceNumber,
            invoiceDate: invoice.invoiceDate.toDate(),
            documentType: invoice.documentType,

            authId: invoice.authId ?? "",
            authEmail: invoice.authEmail ?? "",
            authName: invoice.authName ?? "",
            changerName: invoice.changerName ?? "",
            createdAt: invoice.createdAt?.toDate() ?? new Date(),

            offerId: invoice.offerId,
            offerNumber: invoice.offerNumber,
            offerDate: invoice.offerDate.toDate(),

            inquiryId: invoice.inquiryId,
            inquiryNumber: invoice.inquiryNumber,
            inquiryDate: invoice.inquiryDate?.toDate(),

            receiver: invoice.receiver,
            sender: invoice.sender,
            items: invoice.items,

            taxEventDate: invoice.taxEventDate?.toDate(),
            deliveryDate: invoice.deliveryDate?.toDate(),
            dialReason: invoice.dialReason,
            dialDescription: invoice.dialDescription,
            dialPlace: invoice.dialPlace,
            payType: invoice.payType,
            iban: invoice.iban,
            bank: invoice.bank,
            bankCode: invoice.bankCode,

            currency: invoice.currency,
            currencyRate: invoice.currencyRate,

            dds: invoice.dds ?? 20,
        } as CustomerInvoiceType;
    }
};
