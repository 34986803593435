import {
    EMPTY_WAREHOUSE_REVISION_ITEM,
    WarehouseRevisionItemType
} from "../../../fb-converters/warehouse-revision-converter";
import React, {FC, useState} from "react";
import {WarehouseOutSelectItemDialog} from "../warehouse-transactions/warehouse-out/WarehouseOutSelectItemDialog";
import SelectValueFromDialog from "../../../common/SelectValueFromDialog";
import EditableTableCell from "../../../common/EditableTableCell";
import {textToInt, zeroToEmptyString, zeroToEmptyStringAsCurrency} from "../../../common";
import PlusIconButton from "../../../common/icon-buttons/PlusIconButton";
import {WarehouseOutItemType} from "../hooks/useWarehouseOutItems";
import {DEFAULT_WAREHOUSE} from "../consts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

type WarehouseRevisionNewItemProps = {
    zIndex: number;
    onAdd: (newItem: WarehouseRevisionItemType) => void;
}

const WarehouseRevisionNewItem: FC<WarehouseRevisionNewItemProps> = ({zIndex, onAdd}) => {
    const [item, setItem] = useState<WarehouseRevisionItemType>({...EMPTY_WAREHOUSE_REVISION_ITEM});
    const productsDialog = <WarehouseOutSelectItemDialog show={true} zIndex={(zIndex || 1060) + 1}
                                                         warehouseId={DEFAULT_WAREHOUSE}
                                                         onSelect={(item) => {
                                                         }}/>;

    return (
        <tr>
            <td className={"w-100px bg-secondary-light"}>{item.productCode}</td>
            <td className={" bg-secondary-light"}>
                <SelectValueFromDialog
                    size={"sm"}
                    value={
                        item.productName ?
                            item.productName :
                            "Избери продукт"
                    }
                    dialog={productsDialog}
                    onChange={
                        (value: WarehouseOutItemType | null) => {
                            console.log("value", value);
                            value &&
                            setItem({
                                ...item,
                                productName: value.name,
                                productCode: value.sku ?? null,
                                productId: value.productId,
                                productIsOwnProduction: value.isOwnProduction ?? false,
                                productDimension: value.dimens ?? null,
                                productSinglePrice: value.singlePrice,
                                expectedQty: value.availableQty ?? 0,
                            });
                        }
                    }
                />
            </td>
            <td className={"w-60px bg-secondary-light"}>{item.productDimension}</td>
            <td className={"text-center bg-secondary-light"}>{
                item.productIsOwnProduction ?
                    <FontAwesomeIcon icon={faCheck}/> : ""
            }</td>
            <td className={"text-end w-60px bg-secondary-light"}>{zeroToEmptyStringAsCurrency(item.productSinglePrice)}</td>
            <EditableTableCell
                className={"text-end w-75px bg-secondary-light"}
                inputClassName={"text-end"}
                inEditMode={true}
                isWorking={false}
                value={
                    item.expectedQty ?
                        item.expectedQty.toString() :
                        ""
                }
                onEdit={() => {
                }}
                onBlur={
                    (value) => setItem({...item, expectedQty: textToInt(value, 0) ?? 0})
                }
                onDone={() => {
                }}
            />
            <EditableTableCell
                className={"text-end w-75px bg-secondary-light"}
                inputClassName={"text-end"}
                inEditMode={true}
                isWorking={false}
                value={
                    item.actualQty ?
                        item.actualQty.toString() :
                        ""
                }
                onEdit={() => {
                }}
                onBlur={
                    (value) => setItem({...item, actualQty: textToInt(value, 0) ?? 0})
                }
                onDone={() => {
                }}
            />
            <td className={"text-end w-75px bg-secondary-light"}>{zeroToEmptyString(item.actualQty - item.expectedQty)}</td>
            <td className={"text-end w-75px bg-secondary-light"}>{zeroToEmptyStringAsCurrency((item.actualQty - item.expectedQty) * item.productSinglePrice)}</td>
            <td className={"text-center w-60px bg-secondary-light"}>
                <PlusIconButton
                    size={"sm"}
                    disabled={item.productName === null || item.productName === ""}
                    onClick={() => {
                        onAdd(item);
                        setItem({...EMPTY_WAREHOUSE_REVISION_ITEM});
                    }}/>
            </td>
        </tr>
    )
}

export default WarehouseRevisionNewItem;
