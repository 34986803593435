import {styles} from "../OfferPdfStyles";
import {Text, View} from "@react-pdf/renderer";
import React from "react";
import {
    numberWithCommasToCurrency
} from "../../../../../../../common";
import {getI18Price, getI18Text} from "../../../../../../../i18/i18label";
import appDb from "../../../../../../../global-state/global-db";
import {CurrencyNameType} from "../../../../../../../fb-converters/currency-converter";


interface TableRowProps {
    cols?: [React.JSX.Element, React.JSX.Element, React.JSX.Element, React.JSX.Element]
}

export const TableRow = (props: any) => (<View style={styles.tableRowWrapper}>
    {
        props.cols.map((item: any) => {
            return item
        })
    }
</View>);

const OfferProductPdfTableHeader = ({savedPrice, isStarred, euroExchangeRate, currency}:
                                    {
                                        savedPrice: number,
                                        isStarred: boolean,
                                        euroExchangeRate: number,
                                        currency?: CurrencyNameType
                                    }) => {
    const lng = appDb.language.value;

    const tableCols = [
        <View style={styles.col1H}>
            <Text>№</Text>
        </View>,
        <View style={styles.col2H}>
            <Text>{getI18Text("Тип на стоката (вкл. опции)")}</Text>
        </View>,
        <View style={styles.col3H}>
            <Text>{getI18Text("Размери")}</Text>
            <Text>L/B/H1/H2</Text>
        </View>,
        <View style={styles.col4H}>
            <Text>{getI18Text("Срок на доставка")}</Text>
            <Text>({getI18Text("раб.дни")})</Text>
        </View>,
        <View style={styles.col5H}>
            <Text>{getI18Text("Гаранция")}</Text>
            <Text>({getI18Text("месеци")})</Text>
        </View>,
        <View style={styles.col6H}>
            <Text>{getI18Text("Живот")}</Text>
            <Text>({getI18Text("цикли")})</Text>
        </View>,
        <View style={styles.col7H}>
            <Text>{getI18Text("Ед.цена")}</Text>
            <Text>({currency})</Text>
        </View>,
        <View style={styles.col8H}>
            <Text>{getI18Text("Цена скрап")}</Text>
            <Text>({currency})</Text>
        </View>,
        <View style={styles.col9H}>
            <Text>{getI18Text("Крайна цена")}</Text>
            <Text>({currency})</Text>
        </View>,
        <View style={styles.col10H}>
            <Text>{getI18Text("Кол.")}</Text>
            <Text></Text>
        </View>,
        <View style={styles.col11H}>
            <Text>{getI18Text("Цена")}</Text>
            <Text>({currency})</Text>
        </View>
    ];

    if (lng !== "bg") {
        tableCols.splice(7, 1);
    }

    return (
        <View>
            {
                isStarred &&
                <TableRow cols={[
                    <View style={styles.col1}></View>,
                    <View style={styles.col2}></View>,
                    <View style={styles.col3}></View>,
                    <View style={styles.col4}></View>,
                    <View style={styles.col5}></View>,
                    <View style={styles.col6}></View>,
                    <View style={styles.col7}></View>,
                    <View style={styles.col8}></View>,
                    <View style={[styles.col9_10_11, styles.col1Yellow]}>
                        <Text>{getI18Text("Най-добра цена")}</Text>
                    </View>,
                ]}/>
            }
            {
                savedPrice > 0 && lng !== "ro" &&
                <TableRow cols={[
                    <View style={styles.col1}></View>,
                    <View style={styles.col2}></View>,
                    <View style={styles.col3}></View>,
                    <View style={styles.col4}></View>,
                    <View style={styles.col5}></View>,
                    <View style={styles.col6}></View>,
                    <View style={styles.col7}></View>,
                    <View style={styles.col8}></View>,
                    <View style={[styles.col9_10, styles.col11Green]}>
                        <Text>{getI18Text("Общо спестени")}:</Text></View>,
                    <View style={[styles.col11Green, {fontWeight: "bold"}]}>
                        <Text>
                            {numberWithCommasToCurrency(getI18Price(savedPrice, euroExchangeRate), lng !== "bg" ? " €" : " лв.")}
                        </Text>
                    </View>
                ]}/>
            }
            <TableRow cols={tableCols}/>
        </View>
    );
}

export default OfferProductPdfTableHeader;
