import {Button, Card, Col, Modal, Row} from "react-bootstrap";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faPlus} from "@fortawesome/free-solid-svg-icons";
import CustomerInquiryDetails from "./CustomerInquiryDetails";
import {CustomerInquiryType} from "../../../fb-converters/cutomerInquiry-converter";
import CustomerInquiryList from "./CustomerInquiryList";
import CustomerInquiryFiles from "./CustomerInquiryFiles";
import {ClientType} from "../../nomenclatures/clients/hooks/useClients";
import MonthYearTab from "../../../common/MonthYearTab";
import I18Label from "../../../i18/i18label";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";
import useOwnCompaniesRepo from "../../warehouse/hooks/useOwnCompaniesRepo";
import useUserRights from "../../../hooks/useUserRights";

export default function CustomersInquiriesPage(props: {
    client?: ClientType | null,
    onDone?: VoidFunction,
}) {
    const {canUserEdit} = useUserRights();
    const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    const [inquiry, setInquiry] = useState<CustomerInquiryType | undefined>();
    const [inquiryFiles, setInquiryFiles] = useState<CustomerInquiryType | undefined>();

    const {ownCompanies} = useOwnCompaniesRepo();
    const lng = appDb.language.value;
    const defaultOwnCompanyIndex = lng === "ro" ? 1 : 0;

    function changeMonthYear(month: number, year: number) {
        setSelectedMonth(month);
        setSelectedYear(year);
    }

    function addNewCustomerInquiry() {
        setInquiry({
            inquiryContent: "",
            inquiryNumber: 1,
            inquiryProducts: [],
            ownCompany: (ownCompanies && ownCompanies[defaultOwnCompanyIndex]) || null,
            services: [],
            fileUrls: [],
            additionalInfo: "",
            client: props.client || null,
            location: null,
            inquiryDate: new Date(),
            bestPriceProductIndex: null,
            offers: [],
            comments: null,
        });
    }

    return <>
        {
            props.client ?
                <>
                    <Modal.Body>
                        {
                            selectedMonth >= 0 && selectedYear &&
                            <CustomerInquiryList
                                forMonth={selectedMonth}
                                forYear={selectedYear}
                                client={props.client || undefined}
                                onEdit={inquiry => {
                                    setInquiry(inquiry)
                                }}
                                onEditFiles={inquiry => {
                                    setInquiryFiles(inquiry)
                                }}
                            />
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Row className={"w-100"}>
                            <Col xs={6}>
                                {
                                    props.onDone &&
                                    <Button variant={"outline-secondary"} onClick={() => {
                                        props.onDone && props.onDone()
                                    }}>
                                        <FontAwesomeIcon icon={faArrowLeft}/> <I18Label label={"Към списъка"}/>
                                    </Button>
                                }
                            </Col>
                            {
                                canUserEdit('CRM-Запитвания и оферти') &&
                                <Col className={"text-end"}>
                                    <Button onClick={addNewCustomerInquiry}>
                                        <FontAwesomeIcon icon={faPlus}/>&nbsp;&nbsp;<I18Label label={"Добави ново"}/>
                                    </Button>
                                </Col>
                            }
                        </Row>
                    </Modal.Footer>
                </>
                :
                <Row>
                    <Col/>
                    <Col xs={8}>
                        <Card>
                            <Card.Header>
                                <Card.Title><I18Label label={"CRM - Запитвания от клиенти"}/></Card.Title>
                            </Card.Header>


                            <Card.Body className={"bg-secondary-light max-h-75vh d-block overflow-auto"}>
                                <MonthYearTab month={selectedMonth} year={selectedYear} onChange={changeMonthYear}/>
                                {
                                    selectedMonth >= 0 && selectedYear &&
                                    <CustomerInquiryList
                                        forMonth={selectedMonth}
                                        forYear={selectedYear}
                                        onEdit={inquiry => {
                                            setInquiry(inquiry)
                                        }}
                                        onEditFiles={inquiry => {
                                            setInquiryFiles(inquiry)
                                        }}
                                    />
                                }
                            </Card.Body>

                            <Card.Footer className={"text-end"}>
                                {
                                    canUserEdit('CRM-Запитвания и оферти') &&
                                    <Button onClick={addNewCustomerInquiry}>
                                        <FontAwesomeIcon icon={faPlus}/>&nbsp;&nbsp;<I18Label label={"Добави ново"}/>
                                    </Button>
                                }
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col/>
                </Row>

        }

        {
            inquiry &&
            <CustomerInquiryDetails inquiry={inquiry} ownCompanies={ownCompanies ?? []}
                                    onClose={() => setInquiry(undefined)}
            />
        }

        {
            inquiryFiles &&
            <CustomerInquiryFiles inquiry={inquiryFiles} onClose={() => setInquiryFiles(undefined)}
            />
        }
    </>
}
