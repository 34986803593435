import {
    FirestoreError,
    QuerySnapshot, where
} from "firebase/firestore";
import {useEffect, useState} from "react";
import useFirebaseCollection from "../../../hooks/useFirebaseCollection";
import {WarehouseItemsType, WarehouseItemType} from "./useWarehouseInItems";


export function convertWarehouseItemData(items: QuerySnapshot): WarehouseItemsType {
    if (items) {
        let _newObjectsCollection: WarehouseItemsType = [];
        items.forEach(
            item => {
                _newObjectsCollection.push({
                    id: item.id,
                    ref: item.ref,
                    warehouseId: item.get('warehouseId'),
                    productId: item.get('productId'),
                    isOwnProduction: item.get('isOwnProduction'),
                    sku: item.get('sku'),
                    name: item.get('name'),
                    dimens: item.get('dimens'),
                    qty: item.get('qty'),
                    singlePrice: item.get('singlePrice'),
                    deliveryPrice: item.get('deliveryPrice'),
                    minQty: item.get('minQty'),
                    usedQty: item.get('usedQty'),
                    availableQty: item.get('availableQty'),
                } as WarehouseItemType);
            }
        );
        return _newObjectsCollection;
    }
    return [];
}

export default function useWarehouseItemsForObject(wareHouseId: String):
    [WarehouseItemsType, boolean, FirestoreError | undefined] {
    const [documents, loading, error] = useFirebaseCollection("warehouseItems", [where("warehouseId", "==", wareHouseId)]);
    const [warehouseItems, setWarehouseItems] = useState<WarehouseItemsType>([]);

    useEffect(() => {
        if (documents) {
            setWarehouseItems(convertWarehouseItemData(documents));
        }
    }, [documents]);

    return [warehouseItems, loading, error];
}
