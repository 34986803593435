import {Button, Form, Modal, ModalBody, ModalFooter, ModalTitle,} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEuro,} from "@fortawesome/free-solid-svg-icons";
import DialogCloseButton from "../../../common/DialogCloseButton";
import React, {useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import ModalBackground from "../../../common/ModalBackground";
import {textToFloat} from "../../../common";
import {StringInput} from "../../../common/StringInput";
import {doc, updateDoc,} from "firebase/firestore";
import {fbDb, } from "../../../App";
import {useHookstate} from "@hookstate/core";
import appState from "../../../global-state/global-state";

export default function CurrencyIndexUpdateModal({zIndex, show, currency, onClose}: DIALOG_OPTIONS & { currency: string }) {
    const currencyIndex = useHookstate(appState.currencyIndex);
    const currentPrice = currency === "LEU" ? currencyIndex.value.LEU : currencyIndex.value.EUR;

    const [price, setPrice] = useState(currentPrice);
    if (!show) return null;

    async function updatePrice() {
        await updateDoc(doc(fbDb, "settings/globalSettings"), {currencyIndex: { currency: price}});
        onClose(false)
    }

    return <>
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} size={"sm"} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faEuro}/> Промяна на валутен курс
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(false)}/>
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Form.Group>
                            <Form.Label>Нов курс за {currency}</Form.Label>
                            <StringInput value={price.toString()}
                                         onChange={newValue => setPrice(textToFloat(newValue, 0) || 0)}/>
                        </Form.Group>
                    </Form>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={() => updatePrice()}>
                        Приложи
                    </Button>&nbsp;
                    <Button onClick={() => onClose(false)} variant={"secondary"}>
                        Откажи
                    </Button>
                </ModalFooter>
            </Modal>
        </ModalBackground>
    </>

}
