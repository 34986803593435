import React, {FC, useEffect, useMemo, useState} from "react";
import {Col, Form, FormControl, FormGroup, Modal, Row, Table,} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import DialogCloseButton from "../../../common/DialogCloseButton";
import {
    dateTimeToString,
    dateToString, zeroToEmptyStringAsCurrency,
} from "../../../common";
import {useHookstate} from "@hookstate/core";
import appState from "../../../global-state/global-state";
import ModalBackground from "../../../common/ModalBackground";
import {
    CustomerInvoiceType,
} from "../../../fb-converters/cutomerInvoice-converter";
import I18Label, {getI18Text} from "../../../i18/i18label";
import CenterAlignedCell from "../../../common/CenterAlignedCell";
import RightAlignedCell from "../../../common/RightAlignedCell";

interface Props {
    zIndex?: number;
    invoice: CustomerInvoiceType;
    onClose: VoidFunction;
}


const CustomerInvoiceViewDetails: FC<Props> = ({invoice, onClose, zIndex}) => {
    const globalState = useHookstate(appState).get();
    const _zIndex = (zIndex || 1200) + 200;

    return <ModalBackground zIndex={zIndex}>
        <Modal show={true} size={"xl"} style={{zIndex: _zIndex + 1}}>
            <Modal.Header>
                <Modal.Title>
                    <span className={"text-uppercase"}> <I18Label label={"ПРЕГЛЕД на "} /> <I18Label label={invoice.documentType}/> #{invoice.invoiceNumber} </span>
                    <span
                        className={"ms-3 text-primary"}><I18Label
                        label={"към оферта"}/>: {invoice.offerNumber} / {dateToString(invoice.offerDate)}</span>
                    {
                        invoice.authName && invoice.createdAt &&
                        <Row className={"small fst-italic"}>
                            <Col>
                                <span className={"small text-muted"}><I18Label
                                    label={"Автор: "}/> {invoice.authName} / {dateTimeToString(invoice.createdAt)}</span>
                            </Col>
                        </Row>
                    }
                </Modal.Title>
                <DialogCloseButton onClose={onClose}/>
            </Modal.Header>

            <Modal.Body>
                {/*Inv number & date*/}
                <Row>
                    <Col xs={"auto"}>
                        <Form.Group className={"w-50px"}>
                            <Form.Label>
                                No:
                            </Form.Label>
                            <Form.Control disabled
                                          value={invoice.invoiceNumber}
                                          className={"fw-bold text-end"}/>
                        </Form.Group>
                    </Col>
                    <Col xs={"auto"}>
                        <Form.Group className={"w-120px"}>
                            <Form.Label className={"small"}><I18Label label={"Дата"}/>:</Form.Label>
                            <ReactDatePicker className={"form-control"}
                                             dateFormat="dd.MM.yyyy"
                                             selected={invoice.invoiceDate}
                                             onChange={() => {
                                             }}
                                             disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={"auto"}>
                        <Form.Group>
                            <Form.Label className={"small"}><I18Label label={"Валута"}/>:</Form.Label>
                            <FormControl
                                value={`${invoice.currency} (${invoice.currencyRate})`}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={"auto"}>
                        <Form.Group className={"w-120px"}>
                            <Form.Label className={"small"}><I18Label label={"Към запитване:"}/>:</Form.Label>
                            <Form.Control
                                value={`${invoice.invoiceNumber} / ${dateToString(invoice.inquiryDate)}`}
                                disabled={true}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label className={"small"}><I18Label label={"Доставчик"}/>:</Form.Label>
                            <FormControl
                                value={invoice.sender?.name || ""}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label className={"small"}><I18Label label={"Клиент"}/>:</Form.Label>
                            <FormControl
                                value={invoice.receiver?.name || ""}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                </Row>


                {/*Inv items*/}
                <Table className={"mt-3"} size={"sm"} striped bordered hover responsive>
                    <thead>
                    <tr>
                        <th rowSpan={2}>No:<br/>&nbsp;</th>
                        <th rowSpan={2}><I18Label label={"Код"} /><br/>&nbsp;</th>
                        <th rowSpan={2}><I18Label label={"Наименование на стоката / услугата"} /><br/>&nbsp;</th>
                        <CenterAlignedCell colSpan={4} header><I18Label label={"Габаритни размери"} /></CenterAlignedCell>
                        <th rowSpan={2}><I18Label label={"Мярка"} /><br/>&nbsp;</th>
                        <RightAlignedCell header rowSpan={2}><I18Label label={"Тегло"} /><br/>&nbsp;</RightAlignedCell>
                        <RightAlignedCell header rowSpan={2}><I18Label label={"Кол."} /><br/>&nbsp;</RightAlignedCell>
                        <RightAlignedCell header rowSpan={2}><I18Label label={"Цена"} /><br/><span
                            className={"small fst-italic"}><I18Label label={"без ДДС"} /></span></RightAlignedCell>
                        <RightAlignedCell header rowSpan={2}><I18Label label={"Цена"} /><br/><span
                            className={"small fst-italic"}><I18Label label={"със ДДС"} /></span></RightAlignedCell>
                        <RightAlignedCell header rowSpan={2}><I18Label label={"Сума"} /><br/>&nbsp;</RightAlignedCell>
                        <CenterAlignedCell header className={"text-nowrap"} rowSpan={2}>
                            {
                                invoice.documentType === "потвърждение на поръчка" && <>
                                    <I18Label label={"СП"} /><br/><FontAwesomeIcon icon={faCircleInfo} size={"sm"} title={getI18Text("Собствено производство")}
                                                                                   color={"white"}/>
                                </>
                            }
                        </CenterAlignedCell>
                    </tr>
                    <tr>
                        <CenterAlignedCell header>L</CenterAlignedCell>
                        <CenterAlignedCell header>B</CenterAlignedCell>
                        <CenterAlignedCell header>H1</CenterAlignedCell>
                        <CenterAlignedCell header>H2</CenterAlignedCell>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        invoice.items.map(
                            (i, idx) => (
                                <tr>
                                    <td>{idx + 1}</td>
                                    <td>{i.code}</td>
                                    <td>{i.name}</td>
                                    <td>{i._length}</td>
                                    <td>{i.width}</td>
                                    <td>{i.height1}</td>
                                    <td>{i.height2}</td>
                                    <td>{i.dimension}</td>
                                    <RightAlignedCell>{i.weight}</RightAlignedCell>
                                    <RightAlignedCell>{i.qty}</RightAlignedCell>
                                    <RightAlignedCell>{zeroToEmptyStringAsCurrency(i.price)}</RightAlignedCell>
                                    <RightAlignedCell>{zeroToEmptyStringAsCurrency(i.price * 1.2)}</RightAlignedCell>
                                    <RightAlignedCell>{zeroToEmptyStringAsCurrency(i.total * 1.2)}</RightAlignedCell>
                                    {
                                        invoice.documentType === "потвърждение на поръчка" &&
                                        <CenterAlignedCell>
                                            {
                                                i.ownProduction &&
                                                <FontAwesomeIcon icon={faCheck} color={"green"} size={"lg"}/>
                                            }
                                        </CenterAlignedCell>
                                    }
                                </tr>
                            )

                        )
                    }
                    </tbody>
                </Table>



                <Row>
                    <Col>
                    </Col>

                    {/*Inv details*/}

                    {
                        invoice.documentType !== "потвърждение на поръчка" &&
                        <Row>
                            <Col>
                                <Table size={"sm"} borderless>
                                    <tbody>
                                    <tr>
                                        <td><I18Label label={"Срок на доставка"}/>:</td>
                                        <td>
                                            <FormGroup>
                                                <ReactDatePicker className={"form-control"}
                                                                 dateFormat="dd.MM.yyyy"
                                                                 onChange={(d) => {
                                                                 }}
                                                                 selected={invoice.deliveryDate}
                                                                 disabled
                                                />
                                            </FormGroup>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><I18Label label={"Основание на сделката"}/>:</td>
                                        <td>
                                            <FormControl
                                                value={invoice.dialReason || ""}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><I18Label label={"Описание на сделката"}/>:</td>
                                        <td>
                                            <FormControl value={invoice.dialDescription || ""} disabled/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><I18Label label={"Място на сделката"}/>:</td>
                                        <td>
                                            <FormControl value={invoice.dialPlace || ""}
                                                         disabled
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col>
                                <Table size={"sm"} borderless>
                                    <tbody>
                                    <tr>
                                        <td><I18Label label={"Плащане"}/>:</td>
                                        <td>
                                            <FormControl value={invoice.payType || ""} disabled/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><I18Label label={"IBAN"}/>:</td>
                                        <td>{invoice.iban}</td>
                                    </tr>
                                    <tr>
                                        <td><I18Label label={"Банка"}/>:</td>
                                        <td>
                                            <FormControl value={invoice.bank || ""}
                                                         readOnly={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><I18Label label={"Банков код"}/>:</td>
                                        <td>
                                            <FormControl value={invoice.bankCode || ""}
                                                         readOnly={true}
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    }
                </Row>
            </Modal.Body>

            <Modal.Footer className={"text-end"}>
            </Modal.Footer>
        </Modal>
    </ModalBackground>
}

export default CustomerInvoiceViewDetails;
