import {
    Col, Form,
    Modal,
    ModalTitle, Pagination, Row, Tab, Table, Tabs,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import React, {useEffect, useMemo, useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserTie} from "@fortawesome/free-solid-svg-icons";
import ModalBackground from "../../../common/ModalBackground";
import DialogCloseButton from "../../../common/DialogCloseButton";
import appDb from "../../../global-state/global-db";
import {I18ItemsType, I18ItemType} from "../../../fb-converters/i18-converter";
import DeleteConfirmIconButton from "../../../common/icon-buttons/DeleteConfirmIconButton";
import {addDoc, collection, deleteDoc, doc} from "firebase/firestore";
import {fbDb} from "../../../App";
import PlusIconButton from "../../../common/icon-buttons/PlusIconButton";
import {showErrorsListInToast, showMsgInToast} from "../../../common";
import I18Label from "../../../i18/i18label";
import useUserRights from "../../../hooks/useUserRights";

export function DictionaryDialog({zIndex, show, onClose, onSelect}: DIALOG_OPTIONS) {
    const {canUserEdit} = useUserRights();
    const i18Data = appDb.i18;
    const i18 = i18Data.value;
    const [markI18Labels, setMarkI18Labels] = useState(appDb.markI18Labels.value);
    const [cyrillicLetters, setCyrillicLetters] = React.useState<string[]>([]);
    const [latinLetters, setLatinLetters] = React.useState<string[]>([]);
    const [components, setComponents] = React.useState<string[]>([]);

    const [newItemBG, setNewItemBG] = React.useState("");
    const [newItemRO, setNewItemRO] = React.useState("");
    const [newItemComponent, setNewItemComponent] = React.useState("");

    const [cyrilicIndex, setCyrillicIndex] = React.useState("А");
    const [latinIndex, setLatinIndex] = React.useState("*");
    const [componentIndex, setComponentIndex] = React.useState("");

    const [filteredI18, setFilteredI18] = React.useState<I18ItemsType>([]);
    const [currentPage, setCurrentPage] = React.useState(0);
    const pageSize = 20;

    useEffect(
        () => {
            const cyrillic: string[] = [];
            const latin: string[] = ["*"];
            const components: string[] = ["Всички"];
            i18.forEach(item => {
                if (item.bg) {
                    const firstLetter = item.bg[0].toUpperCase();
                    if (!cyrillic.includes(firstLetter)) {
                        cyrillic.push(firstLetter);
                    }
                }
                if (item.ro) {
                    const firstLetter = item.ro[0].toUpperCase();
                    if (!latin.includes(firstLetter)) {
                        latin.push(firstLetter);
                    }
                }
                if (item.control) {
                    if (!components.includes(item.control)) {
                        components.push(item.control);
                    }
                }
            });
            setCyrillicLetters(cyrillic.sort());
            setLatinLetters(latin.sort());
            setComponents(components.sort());
        }, [i18]
    );

    useEffect(
        () => {
            let filtered = i18.filter(item => {
                if (cyrilicIndex !== "*" && item.bg[0].toUpperCase() !== cyrilicIndex) {
                    return false;
                }
                if (latinIndex !== "*" && item.ro[0].toUpperCase() !== latinIndex) {
                    return false;
                }
                if (componentIndex && item.control !== componentIndex) {
                    return false;
                }
                return true;
            }).sort(
                (a, b) => {
                    if (a.bg < b.bg) {
                        return -1;
                    }
                    if (a.bg > b.bg) {
                        return 1;
                    }
                    return 0;
                }
            );
            setFilteredI18(filtered);
            setCurrentPage(0);
        }, [i18, cyrilicIndex, latinIndex, componentIndex]
    );

    const paginationItems = useMemo(() => {
        const items = [];
        const pageCount = Math.ceil(filteredI18.length / pageSize);
        for (let number = 0; number < pageCount; number++) {
            items.push(
                <Pagination.Item key={number} active={number === currentPage}
                                 onClick={() => setCurrentPage(number)}>
                    {(number + 1)}
                </Pagination.Item>,
            );
        }
        return items;
    }, [filteredI18, currentPage]);

    async function deleteItem(item: I18ItemType) {
        if (item.ref) {
            await deleteDoc(item.ref);
            showMsgInToast("Изтрит е запис от речника");
            const index = filteredI18.findIndex(i => i.id === item.id);
            if (index >= 0) {
                filteredI18.slice(index, 1);
                setFilteredI18([...filteredI18]);
            }
        }
    }

    async function addNewItem() {
        if (newItemBG && newItemRO) {
            const newItem: I18ItemType = {
                bg: newItemBG,
                ro: newItemRO,
                control: newItemComponent
            }

            await addDoc(collection(fbDb, 'i18'), newItem);

            setNewItemBG("");
            setNewItemRO("");
            setNewItemComponent("");

            showMsgInToast("Добавен е нов запис в речника");
        }
    }

    return show ?
        <ModalBackground zIndex={zIndex}>
            <Modal show={true} size={"lg"} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faUserTie}/> Езиков речник
                    </ModalTitle>
                    <Row>
                        {
                            canUserEdit('Номенклатури-Речник') &&
                            <Col>
                                <Form.Group>
                                    <Form.Check type={"switch"} id={"showI18Labels"} checked={markI18Labels}
                                                onChange={(e) => {
                                                    console.log(e.target.checked);
                                                    appDb.markI18Labels.set(e.target.checked);
                                                    setMarkI18Labels(e.target.checked);
                                                }}
                                                label={"Маркирай думите"}
                                    />
                                </Form.Group>
                            </Col>
                        }
                        <Col xs={"auto"}>
                            <DialogCloseButton onClose={() => onClose(null)}/>
                        </Col>
                    </Row>
                </ModalHeader>
                <Modal.Body>
                    <Row>
                        <Col className={"border shadow rounded p-2 m-2 me-0"}>
                            BG:
                            <Tabs variant={"pills"}
                                  onSelect={(k) => setCyrillicIndex(k ?? "А")}
                                  activeKey={cyrilicIndex}
                            >
                                {
                                    cyrillicLetters.map((letter, index) => {
                                        return <Tab key={index} eventKey={letter} title={letter}></Tab>
                                    })
                                }
                            </Tabs>
                        </Col>
                        <Col className={"border shadow rounded p-2 m-2 me-0"}>
                            RO:
                            <Tabs variant={"pills"}
                                  onSelect={(k) => setLatinIndex(k ?? "*")}
                                  activeKey={latinIndex}
                            >
                                {
                                    latinLetters.map((letter, index) => {
                                        return <Tab key={index} eventKey={letter} title={letter}></Tab>
                                    })
                                }
                            </Tabs>
                        </Col>
                        <Col className={"border shadow rounded p-2 m-2"}>
                            Компонент:
                            <Form.Select
                                value={componentIndex}
                                onChange={(e) => setComponentIndex(e.target.value)}
                            >
                                <option value={""}>Всички</option>
                                {
                                    components.map((component, index) => {
                                        return <option key={index} value={component}>{component}</option>
                                    })
                                }
                            </Form.Select>
                        </Col>
                    </Row>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Български</th>
                            <th>Румънски</th>
                            <th>Компонент</th>
                            <th>Действия</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <Form.Group>
                                    <Form.Control type={"text"} value={newItemBG}
                                                  onChange={(e) => setNewItemBG(e.target.value)}/>
                                </Form.Group>
                            </td>
                            <td>
                                <Form.Group>
                                    <Form.Control type={"text"} value={newItemRO}
                                                  onChange={(e) => setNewItemRO(e.target.value)}/>
                                </Form.Group>
                            </td>
                            <td>
                                <Form.Group>
                                    <Form.Control type={"text"} value={newItemComponent}
                                                  onChange={(e) => setNewItemComponent(e.target.value)}/>
                                </Form.Group>
                            </td>
                            <td>
                                {
                                    canUserEdit('Номенклатури-Речник') &&
                                    <PlusIconButton onClick={addNewItem}/>
                                }
                            </td>
                        </tr>
                        {filteredI18
                            .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                            .map((item, index) => {
                                return <tr key={index}>
                                    <td>{item.bg}</td>
                                    <td><I18Label label={item.ro}/></td>
                                    <td>{item.control}</td>
                                    <td>
                                        {
                                            canUserEdit('Номенклатури-Речник') &&
                                            <DeleteConfirmIconButton onClick={() => deleteItem(item)}/>
                                        }
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                    <Row>
                        <Col size={"auto"}>
                            {
                                filteredI18.length > pageSize &&
                                <Pagination className={"justify-content-center"}>
                                    {paginationItems}
                                </Pagination>
                            }
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </ModalBackground>
        : null
}
