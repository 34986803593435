import {dateToString, dateWithEndDayTime, dateWithZeroTime} from "../common";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, doc, FirestoreError, getDocs, limit, orderBy, query, setDoc, where} from "firebase/firestore";
import {fbDb} from "../App";
import {useState} from "react";

export type CurrencyNameType = "EUR" | "BGN" | "LEU";

export type CurrencyType = {
    id?: string;
    name: CurrencyNameType;
    date: Date;
    dateAsStr: string;
    rate: number;
}

export type CurrenciesType = CurrencyType[];

export const EMPTY_CURRENCY: CurrencyType = {
    name: "LEU",
    date: dateWithZeroTime(new Date())!,
    dateAsStr: dateToString(dateWithZeroTime(new Date())),
    rate: 0
}

export const EUR_CURRENCY: CurrencyType = {
    ...EMPTY_CURRENCY,
    name: "EUR",
    rate: 1
}

export const BGN_CURRENCY: CurrencyType = {
    ...EMPTY_CURRENCY,
    name: "BGN",
    rate: 1.95583
}

export const EUR_CURRENCY_NAME: CurrencyNameType = "EUR";
export const BGN_CURRENCY_NAME: CurrencyNameType = "BGN";
export const LEU_CURRENCY_NAME: CurrencyNameType = "LEU";

export const CURRENCY_NAMES = [EUR_CURRENCY_NAME, BGN_CURRENCY_NAME, LEU_CURRENCY_NAME];

export const currencyConverter = {
    toFirestore: (data: CurrencyType): CurrencyType => {
        const date = dateWithZeroTime(data.date)!;
        return {
            name: data.name,
            date: date,
            dateAsStr: dateToString(date),
            rate: data.rate
        }
    },
    fromFirestore: (snapshot: any, options: any): CurrencyType => {
        const data = snapshot.data(options);
        return {
            id: snapshot.id,
            name: data.name,
            date: data.date.toDate(),
            dateAsStr: data.dateAsStr,
            rate: data.rate
        }
    }
}

export const useCurrency = (date: Date | null) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const forDate = dateWithZeroTime(date ?? new Date())!;
    const dateEndDay = dateWithEndDayTime(forDate ?? new Date());
    const dateAsStr = dateToString(dateEndDay);
    const [rates, loadingRates, errorRates] = useCollection<CurrencyType>(
        date ?
            query(collection(fbDb, "currencyHistory"),
                where("date", "<=", dateEndDay),
                orderBy("date", "desc"),
                limit(1)
            ).withConverter(currencyConverter)
            : null
    );

    const getCurrency = (currency: CurrencyNameType): CurrencyType => {

        if(currency === "EUR") {
            return EUR_CURRENCY;
        }
        if(currency === "BGN") {
            return BGN_CURRENCY;
        }

        if (rates && rates.docs.length > 0) {
            const rate = rates.docs[0].data().rate ?? 0;
            return {...EMPTY_CURRENCY,
                name: currency,
                date: forDate,
                dateAsStr,
                rate
            };
        } else {
            return {...EMPTY_CURRENCY, name: currency, date: forDate ?? new Date(), dateAsStr};
        }
    }

    const setCurrency = async (currency: CurrencyNameType, rate: number) => {
        const docRef = doc(collection(fbDb, "currencyHistory"), currency);
        const data = {
            name: currency,
            date: forDate,
            dateAsStr: dateAsStr,
            rate: rate
        };
        await setDoc(docRef, data);
    }

    const getCurrencyForDate = async (currency: CurrencyNameType, forDate: Date): Promise<CurrencyType> => {
        if(currency === "EUR") {
            return EUR_CURRENCY;
        }
        if(currency === "BGN") {
            return BGN_CURRENCY;
        }
        const date = dateWithZeroTime(forDate)!;
        const dateAsStr = dateToString(forDate);
        const docQuery = query(collection(fbDb, "currencyHistory"),
            where("name", "==", currency),
            where("dateAsStr", "==", dateAsStr),
            limit(1)
        ).withConverter(currencyConverter);
        const docSnap = await getDocs(docQuery);
        if (!docSnap.empty) {
            return docSnap.docs[0].data() as CurrencyType;
        } else {
            return {...EMPTY_CURRENCY, name: currency, date: date, dateAsStr: dateAsStr};
        }
    }

    const setCurrencyForDate = async (currency: CurrencyNameType, rate: number, forDate: Date) => {
        const date = dateWithZeroTime(forDate)!;
        const dateAsStr = dateToString(forDate);

        const current = await getCurrencyForDate(currency, forDate);
        console.log("current", current);

        debugger

        if (current.id) {
            const docRef = doc(collection(fbDb, "currencyHistory"), current.id);
            const data = {
                name: currency,
                date: date,
                dateAsStr: dateAsStr,
                rate: rate
            };
            await setDoc(docRef, data);
        } else {
            const docRef = doc(collection(fbDb, "currencyHistory"));
            const data = {
                name: currency,
                date: date,
                dateAsStr: dateAsStr,
                rate: rate
            };
            await setDoc(docRef, data);
        }
    }

    const getCurrencyHistory = async (currency: CurrencyNameType, fromDate: Date, toDate: Date) => {
        try {
            setLoading(true);
            const from = dateWithZeroTime(fromDate)!;
            const to = dateWithEndDayTime(toDate)!;
            const docQuery = query(collection(fbDb, "currencyHistory"),
                where("name", "==", currency),
                where("date", ">=", from),
                where("date", "<=", to)
            ).withConverter(currencyConverter);
            const docSnap = await getDocs(docQuery);
            const history = docSnap.docs.map(doc => doc.data() as CurrencyType);
            return history;
        } catch (e) {
            console.error(e);
            setError((e as FirestoreError).message);
            return [];
        } finally {
            setLoading(false);
        }
    }

    return {
        getCurrency,
        setCurrency,
        setCurrencyForDate,
        getCurrencyForDate,
        getCurrencyHistory,
        loading,
        error,
        currencyRates: rates,
        loadingRates,
        errorRates,
    };
}
