import {CustomerOfferServicesType} from "../../../../../../../fb-converters/cutomerOffer-converter";
import React from "react";
import OfferServicesPdfTableHeader from "./OfferServicesPdfTableHeader";
import OfferServicesPdfTableRow from "./OfferServicesPdfTableRow";
import {View} from "@react-pdf/renderer";
import {CurrencyNameType} from "../../../../../../../fb-converters/currency-converter";
import OfferAttrPdfTableTotal from "../attr-table/OfferAttrPdfTableTotal";

interface OfferPdfTableProps {
    services: CustomerOfferServicesType,
    productQty: number,
    euroExchangeRate: number,
    currency?: CurrencyNameType,
    total: number
}

const OfferProductPdfTable = ({services, productQty, euroExchangeRate, currency, total}: OfferPdfTableProps) => {
    const servicesTotal = services.reduce((acc, service) => acc + service.finalPrice * service.qty, 0);
    return services.length > 0 ? (<View wrap={false}>
        <OfferServicesPdfTableHeader currency={currency}/>
        <OfferServicesPdfTableRow services={services} productQty={productQty} euroExchangeRate={euroExchangeRate}/>
        <OfferAttrPdfTableTotal
            isExtraServices={true}
            total={total + servicesTotal}
            euroExchangeRate={euroExchangeRate}/>
    </View>) : null;
};

export default OfferProductPdfTable;
