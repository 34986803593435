import {useEffect, useMemo, useState} from "react";

import {
    DocumentData,
    updateDoc,
    addDoc,
    collection,
    getFirestore,
    getDoc,
    getDocs,
    query,
    where, orderBy, limit
} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave, faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {fbDb, firebaseApp} from "../../../App";
import {removeEmptyFields, showErrorsListInToast, textToFloat, zeroToEmptyStringAsCurrency} from "../../../common";
import {ProducersDialog} from "../producers/ProducersDialog";
import {ProvidersDialog} from "../providers/ProvidersDialog";
import SelectValueFromDialog from "../../../common/SelectValueFromDialog";
import {ProductGroupsDialog} from "../product-groups/ProductGroupsDialog";
import {ProductGroup} from "../product-groups/ProductGroupsList";
import {ProductDimensesDialog} from "../product-dimens/ProductDimensesDialog";
import {ProductType} from "./hooks/useProductsOnce";
import {useHookstate} from "@hookstate/core";
import appState from "../../../global-state/global-state";
import I18Label, {getI18Text} from "../../../i18/i18label";
import useUserRights from "../../../hooks/useUserRights";
import appDb from "../../../global-state/global-db";


export interface ProviderDetailsProps {
    zIndex?: number;
    product: ProductType | null;
    connectors: Array<DocumentData> | null | undefined;
    outputs: Array<DocumentData> | null | undefined;
    onDone: () => any;
}

export function ProductDetails({zIndex, connectors, outputs, product, onDone}: ProviderDetailsProps) {
    const {canUserEdit} = useUserRights();
    const ecoTaxPerKgPrice = useHookstate(appState.ecoTaxPerKgPrice).value;
    const [weightWithElectrolit, setWeightWithElectrolit] = useState(product?.weightWithElectrolit || 0);
    const [saving, setSaving] = useState(false);
    const [producer, setProducer] = useState<string | null | undefined>(product?.producer);
    const [supplier, setSupplier] = useState<string | null | undefined>(product?.supplier);
    const [deliveryPrice, setDeliveryPrice] = useState<string>(
        zeroToEmptyStringAsCurrency((product?.deliveryPrice ?? "").toString()));
    const [sellPrice, setSellPrice] = useState<string>(
        zeroToEmptyStringAsCurrency((product?.price ?? "").toString()));
    const globalState = useHookstate(appState).get();
    const [sku, setSku] = useState<string | undefined>(product?.sku);
    const allProducts = useHookstate(appDb.products).get();

    const [productGroup, setProductGroup] = useState<ProductGroup>(
        {
            id: product?.productGroup?.id,
            name: product?.productGroup?.name,
            variant: product?.productGroup?.variant ?? 2,
            percent: product?.productGroup?.percent,
        } as ProductGroup
    );
    const [productDimens, setProductDimens] = useState<string>(product?.dimens ?? "");

    const [attributes, setAttributes] = useState<any>(Object.values(product?.attributes || []));

    useEffect(() => {
        if (productGroup && productGroup.variant !== null && productGroup.variant !== undefined) {
            let _dPrice = textToFloat(deliveryPrice.toString(), 0) || 0;
            let _sPrice = textToFloat(sellPrice.toString(), 0) || 0;

            switch (productGroup.variant) {
                case 0:
                    setSellPrice(
                        (_dPrice + _dPrice * productGroup!.percent / 100).toString()
                    );
                    break;
                case 1:
                    setDeliveryPrice(
                        (_sPrice - _sPrice * productGroup!.percent / 100).toString()
                    );
                    break;
            }
        }
    }, [productGroup, sellPrice, deliveryPrice, product?.deliveryPrice, product?.price]);

    const save = async (data: any) => {
        setSaving(true);
        let _data = removeEmptyFields(data);
        _data.isOwnProduct = false;

        if (!_data.sku) {
            let lastSKU = 0;
            allProducts.forEach(
                (p: any) => {
                    const sku = parseInt(p.sku);
                    if (!isNaN(sku) && sku > lastSKU) {
                        lastSKU = sku;
                    }
                }
            );
            _data.sku = (lastSKU + 1).toString();
        }

        const existingProduct = await getDocs(query(
            collection(fbDb, "products"),
            where("sku", "==", _data.sku),
            where("isOwnProduct", "==", false)
        ));

        if (!existingProduct.empty && existingProduct.docs.length > 0 &&
            existingProduct.docs[0].id !== product?.id) {

            showErrorsListInToast("Грешка", ["Продукт с този SKU вече съществува!"]);
            setSaving(false);
            return;
        }

        let ref = null;
        if (product && product.ref) {
            ref = product.ref;
            await updateDoc(product.ref, _data);
        } else {
            ref = await addDoc(collection(fbDb, "products"), _data);
        }

        const productId = ref.id;
        const warehouseItems = await getDocs(query(
            collection(fbDb, "warehouseItems"),
            where("productId", "==", productId)
        ));
        for (const d of warehouseItems.docs) {
            await updateDoc(d.ref, {"minQty": _data.minQty || 0});
        }

        setSaving(false);
        onDone();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.currentTarget.id !== "ProductDetailsForm") {
            return;
        }

        const data = removeEmptyFields<ProductType>({
            connectorType: event.target.elements.connectorType.value || '',
            description: event.target.elements.description.value || '',
            descriptionForClient: event.target.elements.descriptionForClient.value || '',
            height1: textToFloat(event.target.elements.height1.value, 0),
            height2: textToFloat(event.target.elements.height2.value, 0),
            _length: textToFloat(event.target.elements["_length"].value, 0),
            name: event.target.elements.name.value || '',
            outputType: event.target.elements.outputType.value || '',
            price: textToFloat(event.target.elements.price.value, 0),
            producer: producer,
            supplier: supplier,
            productGroup: removeEmptyFields(productGroup),
            dimens: productDimens || "",
            deliveryPrice: textToFloat(event.target.elements.deliveryPrice.value, 0),
            sku: sku || "",
            weightWithElectrolit: textToFloat(event.target.elements.weightWithElectrolit.value, 0),
            width: textToFloat(event.target.elements.width.value, 0),
            weightWithoutElectrolit: textToFloat(event.target.elements.weightWithoutElectrolit.value, 0),
            warranty: textToFloat(event.target.elements.warranty.value, 0),
            liveCycles: textToFloat(event.target.elements.liveCycles.value, 0),
            chargePrice: textToFloat(event.target.elements.chargePrice.value, 0),
            minQty: textToFloat(event.target.elements.minQty.value, 0),
            attributes: attributes,
        } as unknown as ProductType);

        if (data &&
            data.name &&
            data.dimens &&
            data.productGroup) {
            save(data);
        } else {
            showErrorsListInToast(
                "Грешка",
                ["Моля попълнете задължителните полета!"]
            )
        }
    }

    const attributesTable = [];
    let name = '';
    let price = '0.00';

    for (let i = 0; i < 7; i++) {
        name = attributes?.[i]?.name || '';
        price = attributes?.[i]?.price?.toString() || '';
        attributes[i] = {name: name, price: price};

        attributesTable.push(
            <tr key={`attr-${i}`}>
                <td>
                    <Form.Control type="text" value={name} onChange={(e) => {
                        attributes[i].name = e.target.value;
                        setAttributes([...attributes]);
                    }}/>
                </td>
                <td>
                    <Form.Control type="text" value={price} onChange={(e) => {
                        attributes[i].price = e.target.value;
                        setAttributes([...attributes]);
                    }}
                                  onBlur={() => {
                                      attributes[i].price = zeroToEmptyStringAsCurrency(attributes[i].price);
                                      setAttributes([...attributes]);
                                  }}
                    />
                </td>
                <td>
                    <Button variant={"outline-secondary"} onClick={() => {
                        attributes[i].name = '';
                        attributes[i].price = '';
                        setAttributes([...attributes]);
                    }}>
                        <FontAwesomeIcon icon={faCircleXmark}/>
                    </Button>
                </td>
            </tr>
        )
    }

    const producerDialog = <ProducersDialog show={true} zIndex={zIndex || 1020} onClose={() => {
    }}/>;

    const supplierDialog = <ProvidersDialog show={true} zIndex={zIndex || 1020} onClose={() => {
    }}/>;

    const productTypeDialog = <ProductGroupsDialog show={true} zIndex={zIndex || 1020} onClose={() => {
    }}/>

    const productDimensDialog = <ProductDimensesDialog show={true} zIndex={zIndex || 1020} onClose={() => {
    }}/>

    function recalcEcoTax(e: any) {
        setWeightWithElectrolit(textToFloat(e.target.value) || 0);
    }

    const ecoTax = zeroToEmptyStringAsCurrency((ecoTaxPerKgPrice * weightWithElectrolit).toString());

    // useEffect(() => {
    //     const sku = product?.sku ? product.sku : globalState.productsNumber + 1;
    //     setSku(sku.toString());
    // }, [globalState.productsNumber, product?.sku]);

    return (
        <>
            <Form className={"p-2"} onSubmit={handleSubmit} id={"ProductDetailsForm"}>
                <Modal.Body>
                    <h5 className={"mb-3"}>
                        <I18Label label={product ? "Корекция на продукт" : "Добавяне на продукт"}/>
                    </h5>


                    <Row>
                        <Col>
                            <Row>
                                <Col xs={5}>
                                    <Form.Group>
                                        <Form.Label><I18Label label={"Име"}/> *</Form.Label>
                                        <Form.Control type="text" name="name" required defaultValue={product?.name}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col xs={2}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>SKU</Form.Label>
                                                <Form.Control value={sku}
                                                              placeholder={'Автоматично'}
                                                              onChange={(e) => setSku(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6}>
                                            <SelectValueFromDialog label={<><I18Label label={"Продуктова група"}/> *</>}
                                                                   required
                                                                   value={productGroup?.name}
                                                                   onChange={(productGroup: any) => setProductGroup(productGroup || undefined)}
                                                                   dialog={productTypeDialog}
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <SelectValueFromDialog label={<><I18Label label={"Мерна единица"}/> *</>}
                                                                   required
                                                                   value={getI18Text(productDimens)}
                                                                   onChange={(productDimens: any) => setProductDimens(productDimens?.name || "")}
                                                                   dialog={productDimensDialog}
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <Form.Group className="mb-3">
                                                <Form.Label><I18Label label={"Мин. к-во"}/></Form.Label>
                                                <Form.Control type="text" name="minQty" defaultValue={product?.minQty}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><I18Label label={"Описание на продукт"}/></Form.Label>
                                        <Form.Control as="textarea" rows={3} name="description"
                                                      defaultValue={product?.description}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><I18Label label={"Име (English)"}/></Form.Label>
                                        <Form.Control as="textarea" rows={3} name="descriptionForClient"
                                                      defaultValue={product?.descriptionForClient}/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><I18Label label={"Дължина"}/>, <I18Label label={"мм"}/></Form.Label>
                                        <Form.Control type="text" name="_length" defaultValue={product?._length}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><I18Label label={"Широчина"}/>, <I18Label
                                            label={"мм"}/></Form.Label>
                                        <Form.Control type="text" name="width" defaultValue={product?.width}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><I18Label label={"Височина"}/> (h1), <I18Label
                                            label={"мм"}/></Form.Label>
                                        <Form.Control type="text" name="height1" defaultValue={product?.height1}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><I18Label label={"Височина"}/> (h2), <I18Label
                                            label={"мм"}/></Form.Label>
                                        <Form.Control type="text" name="height2" defaultValue={product?.height2}/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><I18Label label={"Тип на конектор"}/></Form.Label>
                                        <select className={"form-select"} name="connectorType"
                                                defaultValue={product?.connectorType}>
                                            <option></option>
                                            {
                                                connectors?.map(
                                                    c => <option key={c.name}>{c.name}</option>
                                                )
                                            }
                                        </select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><I18Label label={"Тип на изводите"}/></Form.Label>
                                        <select className={"form-select"} name="outputType"
                                                defaultValue={product?.outputType}>
                                            <option></option>
                                            {
                                                outputs?.map(
                                                    c => <option key={c.name}>{c.name}</option>
                                                )
                                            }
                                        </select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><I18Label label={"Тегло с електролит"}/>, kg</Form.Label>
                                        <Form.Control type="text" name="weightWithElectrolit"
                                                      onChange={recalcEcoTax}
                                                      defaultValue={product?.weightWithElectrolit}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><I18Label label={"Тегло без електролит"}/>, kg</Form.Label>
                                        <Form.Control type="text" name="weightWithoutElectrolit"
                                                      defaultValue={product?.weightWithoutElectrolit}/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><I18Label label={"Гаранция"}/>, <I18Label
                                            label={"месец"}/></Form.Label>
                                        <Form.Control type="text" name="warranty"
                                                      defaultValue={product?.warranty}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><I18Label label={"Брой цикли"}/></Form.Label>
                                        <Form.Control type="text" name="liveCycles"
                                                      defaultValue={product?.liveCycles}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><I18Label label={"Цена заряд"}/>, EUR</Form.Label>
                                        <Form.Control type="text" name="chargePrice"
                                                      defaultValue={product?.chargePrice}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><I18Label label={"Еко такса"}/>, EUR</Form.Label>
                                        <Form.Control type="text"
                                                      value={ecoTax}
                                                      readOnly/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <SelectValueFromDialog label={<I18Label label={"Производител"}/>}
                                                               value={producer}
                                                               onChange={(producer: any) => setProducer(producer?.name || undefined)}
                                                               dialog={producerDialog}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <SelectValueFromDialog label={<I18Label label={"Доставчик"}/>}
                                                           value={supplier}
                                                           onChange={(supplier: any) => setSupplier(supplier?.name || undefined)}
                                                           dialog={supplierDialog}
                                    />
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><I18Label label={"Доставна цена"}/>, EUR</Form.Label>
                                        <Form.Control type="text" name="deliveryPrice"
                                                      value={deliveryPrice}
                                                      onChange={e => setDeliveryPrice(e.target.value)}
                                                      disabled={productGroup?.variant === 1}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><I18Label label={"Продажна цена"}/>, EUR</Form.Label>
                                        <Form.Control type="text" name="price" value={sellPrice}
                                                      onChange={e => setSellPrice(e.target.value)}
                                                      disabled={productGroup?.variant === 0}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={"3"}>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label><I18Label label={"Допълнителни атрибути"}/></Form.Label>

                                        <div className={"p-1 rounded-1"} style={{border: "solid 1px silver"}}>
                                            <Table size={"small"} borderless>
                                                <thead>
                                                <tr>
                                                    <td><I18Label label={"Име"}/></td>
                                                    <td className={"w-100px"}><I18Label label={"Цена"}/> (EUR)</td>
                                                    <td></td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {attributesTable}
                                                </tbody>
                                            </Table>
                                        </div>

                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Row className={"w-100"}>
                        {
                            saving && <Col xs={12} className={"text-center"}>
                                <Spinner animation={"border"}/>
                            </Col>
                        }

                        {
                            !saving &&
                            <>
                                <Col xs={6}>
                                    <Button variant={"outline-secondary"} onClick={() => {
                                        onDone()
                                    }}>
                                        <FontAwesomeIcon icon={faArrowLeft}/> <I18Label label={"Към списъка"}/>
                                    </Button>
                                </Col>
                                {
                                    canUserEdit('Номенклатури-Продукти') &&
                                    <Col xs={6} className={"text-end"}>
                                        <Button type={"submit"}>
                                            <FontAwesomeIcon icon={faSave}/> <I18Label label={"Запиши"}/>
                                        </Button>
                                    </Col>
                                }
                            </>
                        }
                    </Row>
                </Modal.Footer>
            </Form>
        </>
    )
}
