import {ExpeditionType} from "../../../fb-converters/expedition-converter";
import {addDoc, collection, doc, FirestoreError, getDoc, updateDoc} from "firebase/firestore";
import {fbDb} from "../../../App";
import {productionCardConverter, ProductionCardExpeditionType} from "../types/ProductionCardType";
import {showErrorsListInToast} from "../../../common";
import {useState} from "react";

const useExpedition = () => {
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    const updateProductionCardExpeditionData = async (expedition: ExpeditionType) => {
        debugger;
        if (expedition.cardId) {
            const productionCardRef =
                doc(fbDb, "productionCards", expedition.cardId).withConverter(productionCardConverter);
            if (expedition.status === "ПЛАНИРАНА") {
                await updateDoc(productionCardRef, {
                    planExpeditionDate: expedition.expeditionDate
                });
            }
            const productionCardSnap = await getDoc(productionCardRef);
            if (productionCardSnap.exists()) {
                const productionCard = productionCardSnap.data()!;
                const productionCardExpedition: ProductionCardExpeditionType = {
                    expeditionId: expedition.id!,
                    expeditionDate: expedition.expeditionDate,
                    driverName: expedition.driver?.name ?? null,
                    carName: expedition.car?.name ?? null,
                    qty: expedition.productQty ?? 0,
                    expeditionStatus: expedition.status
                };

                const expeditions = productionCard.expeditions ?? [];
                const existingExpeditionIndex = expeditions.findIndex(e => e.expeditionId === expedition.id);
                if (existingExpeditionIndex >= 0) {
                    expeditions[existingExpeditionIndex] = productionCardExpedition;
                } else {
                    expeditions.push(productionCardExpedition);
                }
                productionCard.expeditions = expeditions;

                const totalExpQty = expeditions
                    .filter(e => e.expeditionStatus === "ПРИКЛЮЧЕНА")
                    .reduce((acc, e) => acc + (e.qty ?? 0), 0);
                const totalCardQty = productionCard.specification[0]?.productQty ?? 0;
                if (totalExpQty > totalCardQty) {
                    showErrorsListInToast("Грешка при запазване на експедиция", ["Общото количество на експедициите надвишава планираното количество за производство"]);
                    setSaving(false);
                    return;
                }
            }
        }
    }

    const saveExpedition = async (expedition: ExpeditionType) => {
        setSaving(true);
        try {
            if (expedition.id) {
                await updateDoc(doc(fbDb, "expedition", expedition.id), expedition);
            } else {
                const newDoc = await addDoc(collection(fbDb, "expedition"), expedition);
                expedition.id = newDoc.id;
            }
            await updateProductionCardExpeditionData(expedition);
            setSaving(false);
            setErrors([]);
        } catch (e) {
            const error = e as FirestoreError;
            showErrorsListInToast("Грешка при запазване на експедиция", [error.message]);
            setSaving(false);
            setErrors([error.message]);
        }
    }

    function setExpeditionStatus(expeditionId: string, value: string) {
        setSaving(true);
        updateDoc(doc(fbDb, "expedition", expeditionId), {
            status: value
        }).then(() => {
        }).catch((e) => {
            const error = e as FirestoreError;
            showErrorsListInToast("Грешка при запазване на експедиция", [error.message]);
        }).finally(() => {
            setSaving(false);
        });
    }

    return {saving, saveExpedition, errors, setExpeditionStatus};
}

export default useExpedition;
