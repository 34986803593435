import {Col, Row, Table} from "react-bootstrap";
import RightAlignedCell from "../../../common/RightAlignedCell";
import {textToInt, zeroToEmptyString, zeroToEmptyStringAsCurrencyWithCommas} from "../../../common";
import React, {useMemo} from "react";
import {
    CustomerOfferType,
} from "../../../fb-converters/cutomerOffer-converter";
import {__State, State, StateMethods, useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";
import CenterAlignedCell from "../../../common/CenterAlignedCell";
import {ProductType} from "../../nomenclatures/products/hooks/useProductsOnce";
import I18Label from "../../../i18/i18label";

interface Props {
    offer: CustomerOfferType
}

export type ProductSummaryType = {
    product: string;
    warranty: number;
    extendedWarranty: number;
    priceWithoutServices: number;
    priceWithExtWarranty: number;
    monthPriceWithoutServices: number;
    monthPriceWithExtWarranty: number;
}

export const calculateOfferSummary = (offer: CustomerOfferType,
                                      products: __State<ProductType[], {}> &
                                          StateMethods<ProductType[], {}> &
                                          {} &
                                          ReadonlyArray<State<ProductType, {}>>) => {
    const summary: ProductSummaryType[] = [];

    offer?.products.forEach(
        p => {
            const _productValue = products.value.find(item => item.id === p.product.id);
            if (_productValue) {
                const _product = {..._productValue} as ProductType;
                const warranty = textToInt((_product.warranty || "").toString(), 0)!;
                const price = (p.singlePrice || 0);
                const discount = p.discount || 0;
                const finalPrice = price - (price * discount / 100);

                let servicesPrice = 0;
                const service = p?.services?.find(s => s.service.isWarrantyService);

                if (service) {
                    const priceWithoutServices = finalPrice - p.skrubPrice;
                    const servicePrice = service.finalPrice || 0;
                    const priceWithServices = priceWithoutServices + servicePrice;
                    const extendedWarranty = service.service.name.indexOf(" 12 ") > -1 ? 12 : 24;

                    const item: ProductSummaryType = {
                        product: p.product.name,
                        warranty: warranty,
                        priceWithoutServices: priceWithoutServices,
                        extendedWarranty: extendedWarranty + warranty,
                        priceWithExtWarranty: priceWithServices,
                        monthPriceWithoutServices: warranty > 0 ? (priceWithoutServices) / warranty : 0,
                        monthPriceWithExtWarranty: priceWithServices / (warranty + extendedWarranty),
                    }

                    summary.push(item);
                }
            }
        }
    )

    return summary;
}

export default function CustomerOfferDetailsSummary({offer}: Props) {
    const products = useHookstate(appDb.products);

    const summary = useMemo(() => {
        return calculateOfferSummary(offer, products);
    }, [offer])

    if (summary.length === 0) {
        return null;
    }

    return (
        <>
            <Row className={"mt-3"}>
                <Col xs={"12"}><strong><I18Label
                    label={"Калкулация на месечната инвестиция за рамките на гаранционния срок"}/>:</strong></Col>
                <Col xs={"12"}>
                    <Table className={"border print-no-bg"}>
                        <thead>
                        <tr>
                            <RightAlignedCell header className={"w-75px print-no-bg"} rowSpan={2}
                                              style={{borderRightWidth: "1px"}}>No:</RightAlignedCell>
                            <th rowSpan={2} style={{borderRightWidth: "1pt"}}><I18Label label={"Продукт"}/></th>
                            <CenterAlignedCell header colSpan={4}
                                               style={{borderRightWidth: "1pt", borderBottomWidth: "1pt"}}>
                                <I18Label label={"Общ размер на инвестицията"}/></CenterAlignedCell>
                            <CenterAlignedCell header colSpan={4}><I18Label label={"Размер на инвестицията за месец"}/></CenterAlignedCell>
                        </tr>
                        <tr>
                            <RightAlignedCell header><I18Label label={"Гаранция - стандартна"}/><br/>
                                (<I18Label label={"месеци"}/>)</RightAlignedCell>
                            <RightAlignedCell header><I18Label label={"Стойност без допълнителни услуги"}/>
                                <br/>({offer.currency})</RightAlignedCell>
                            <RightAlignedCell header><I18Label label={"Гаранция - удължена"}/><br/>
                                (<I18Label label={"месеци"}/>)</RightAlignedCell>
                            <RightAlignedCell header style={{borderRightWidth: "1px"}}><I18Label
                                label={"Стойност с удължена гаранция"}/>
                                <br/>({offer.currency})</RightAlignedCell>
                            <RightAlignedCell header><I18Label label={"Гаранция - стандартна"}/><br/>
                                (<I18Label label={"месеци"}/>)</RightAlignedCell>
                            <RightAlignedCell header><I18Label label={"Стойност без допълнителни услуги"}/>
                                <br/>({offer.currency})</RightAlignedCell>
                            <RightAlignedCell header><I18Label label={"Гаранция - удължена"}/><br/>
                                (<I18Label label={"месеци"}/>)</RightAlignedCell>
                            <RightAlignedCell header style={{borderRightWidth: "1px"}}><I18Label
                                label={"Стойност с удължена гаранция"}/>
                                <br/>({offer.currency})</RightAlignedCell>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            summary.map(
                                (s, idx) => <tr key={idx}>
                                    <RightAlignedCell> {idx + 1} </RightAlignedCell>
                                    <td> {s.product} </td>
                                    <RightAlignedCell> {zeroToEmptyString(s.warranty)} </RightAlignedCell>
                                    <RightAlignedCell> {zeroToEmptyStringAsCurrencyWithCommas(s.priceWithoutServices * offer.eurExchangeRate)} </RightAlignedCell>
                                    <RightAlignedCell> {zeroToEmptyString(s.extendedWarranty)} </RightAlignedCell>
                                    <RightAlignedCell> {zeroToEmptyStringAsCurrencyWithCommas(s.priceWithExtWarranty * offer.eurExchangeRate)} </RightAlignedCell>
                                    <RightAlignedCell> {zeroToEmptyString(s.warranty)} </RightAlignedCell>
                                    <RightAlignedCell> {zeroToEmptyStringAsCurrencyWithCommas(s.monthPriceWithoutServices * offer.eurExchangeRate)} </RightAlignedCell>
                                    <RightAlignedCell> {zeroToEmptyString(s.extendedWarranty)} </RightAlignedCell>
                                    <RightAlignedCell> {zeroToEmptyStringAsCurrencyWithCommas(s.monthPriceWithExtWarranty * offer.eurExchangeRate)} </RightAlignedCell>
                                </tr>
                            )
                        }

                        </tbody>
                    </Table>
                </Col>
                <Col>&nbsp;</Col>
            </Row>
        </>
    )
}
