import {
    collection,
    DocumentData,
    FirestoreDataConverter,
    query,
} from "firebase/firestore";
import {useCollection} from "react-firebase-hooks/firestore";
import {fbDb} from "../../../App";
import {OwnCompanyType} from "../../../fb-converters/own-company-converter";

export type OwnCompaniesType = OwnCompanyType[];

export const ownCompanyConverter: FirestoreDataConverter<OwnCompanyType> = {
    toFirestore(company: OwnCompanyType): DocumentData {
        return {
            name: company.name,
            shortName: company.shortName,
            productionCardCounterName: company.productionCardCounterName,
            address: company.address,
            bulstat: company.bulstat,
            city: company.city,
            ddsNumber: company.ddsNumber,
            email: company.email,
            mol: company.mol,
            phone: company.phone,
            listIndex: company.listIndex,
        };
    },
    fromFirestore(snapshot, options): OwnCompanyType {
        const data = snapshot.data(options)!;
        return {
            id: snapshot.id,
            ref: snapshot.ref,
            name: data.name,
            shortName: data.shortName ?? data.name,
            productionCardCounterName: data.productionCardCounterName,
            address: data.address,
            bulstat: data.bulstat,
            city: data.city,
            ddsNumber: data.ddsNumber,
            email: data.email,
            mol: data.mol,
            phone: data.phone,
            listIndex: data.listIndex,
        };
    }
};


export default function useOwnCompanies() {
    const ownCompaniesQuery = query(collection(fbDb, "ownCompanies").withConverter(ownCompanyConverter));
    const [ownCompanies, loadingOwnCompanies, errorOwnCompanies] = useCollection<OwnCompanyType>(ownCompaniesQuery);

    return {
        ownCompanies: ownCompanies?.docs.map(d => d.data()),
        loadingOwnCompanies,
        errorOwnCompanies,
    };
}
