import React, {useEffect, useMemo, useState} from "react";

import {
    addDoc,
    collection, deleteDoc, doc,
    doc as getDocRef, getDocs, query, runTransaction, updateDoc, where
} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb} from "../../../App";
import {
    dateToString, removeEmptyFields, showErrorsListInToast, showMsgInToast,
    zeroToEmptyString,
} from "../../../common";
import ReactDatePicker from "react-datepicker";
import {useDocument} from "react-firebase-hooks/firestore";
import {productionCardConverter, ProductionCardType} from "../types/ProductionCardType";
import {CustomerInvoiceItemType, CustomerInvoiceType} from "../../../fb-converters/cutomerInvoice-converter";
import ProductionPlanSpecificationTable from "./ProductionPlanSpecificationTable";
import ProductionPlanLoader from "./ProductionPlanLoader";
import {ProductionCardProductsType} from "../types/ProductionCardProductType";
import ProductionPlanMaterialsTable from "./ProductionPlanMaterialsTable";
import globalState from "../../../global-state/global-state";
import {CustomerInquiryType} from "../../../fb-converters/cutomerInquiry-converter";
import DeleteConfirmIconButton from "../../../common/icon-buttons/DeleteConfirmIconButton";
import I18Label, {getI18Text} from "../../../i18/i18label";
import {GlobalStateType} from "../../../global-state/types/globalState";
import useOwnCompaniesRepo from "../../warehouse/hooks/useOwnCompaniesRepo";
import {OwnCompanyDropdown} from "../../nomenclatures/own-companies/OwnCompanySelector";
import useWarehouseObjectsRepo from "../../warehouse/hooks/useWarehouseObjectsRepo";
import {WarehouseItemType} from "../../warehouse/hooks/useWarehouseInItems";
import HisotryIconButton from "../../../common/icon-buttons/HisotryIconButton";
import useUserRights from "../../../hooks/useUserRights";
import {CustomerOfferType} from "../../../fb-converters/cutomerOffer-converter";


interface Props {
    zIndex?: number,
    inquiry: CustomerInquiryType,
    offer: CustomerOfferType,
    invoice: CustomerInvoiceType,
    product: CustomerInvoiceItemType,
    onDone: () => any,
    onChange: (card: ProductionCardType | undefined) => void,
    onShowHistoryChange?: (value: boolean) => void
}

export function ProductionPlanDetails({
                                          zIndex,
                                          onDone,
                                          invoice,
                                          inquiry,
                                          offer,
                                          product,
                                          onChange,
                                          onShowHistoryChange
                                      }: Props) {
    const {canUserEdit} = useUserRights();
    const {ownCompanies} = useOwnCompaniesRepo();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [card, setCard] = useState<ProductionCardType | undefined>(undefined);
    const [originalCard, setOriginalCard] = useState<ProductionCardType | undefined>(undefined);
    const [showHistory, setShowHistory] = useState(false);

    function getNextProductionCardNumber() {
        const ownCompany = ownCompanies.find(c => c.id === card?.ownCompanyId);
        const counterName: any = ownCompany?.productionCardCounterName || "productionCardNumber";
        const gs = {...globalState.value} as GlobalStateType;
        const keys = Object.keys(gs);
        const values = Object.values(gs);
        const index = keys.indexOf(counterName);
        const nextNumber = (index > -1 ? values[index] : 0) as number;
        return nextNumber;
    }

    useEffect(() => {
        if (invoice) {
            loadOrCreateProductionCard(invoice);
        }
    }, [invoice, invoice.id, product.productId]);

    useEffect(
        () => {
            if (onChange) {
                onChange(originalCard);
            }
        }, [originalCard]);

    useEffect(
        () => {
            if (onShowHistoryChange) {
                onShowHistoryChange(showHistory);
            }
        }, [showHistory]);

    const {ownCompanyWarehouseObjects, getWarehouseObjectName} = useWarehouseObjectsRepo(card?.ownCompanyId);

    async function loadOrCreateProductionCard(invoice: CustomerInvoiceType) {
        try {
            setLoading(true);
            const cards = await getDocs(
                query(collection(fbDb, "productionCards"),
                    where("invoiceId", "==", invoice.id),
                    where("specificationIds", "array-contains", product.productId)
                ).withConverter(productionCardConverter));

            if (cards.docs.length) {
                const cardData = {...cards.docs[0].data()};
                if (!cardData.inquiryId || !cardData.inquiryNumber || !cardData.inquiryDate) {
                    cardData.inquiryId = inquiry.id!;
                    cardData.inquiryNumber = inquiry.inquiryNumber;
                    cardData.inquiryDate = inquiry.inquiryDate;
                }
                if (!cardData.cardDate) {
                    cardData.cardDate = new Date();
                }
                if (!cardData.ownCompanyId) {
                    cardData.ownCompanyId = inquiry.ownCompany?.id || (ownCompanies[0].id ?? "");
                    if (cardData.ownCompanyId === "1ib_ro") {
                        cardData.ownCompanyId = "0ib_bg";
                    }
                }
                setCard(cardData);
                setOriginalCard({...cardData});
            } else {
                setCard(undefined);
            }
        } catch (e: any) {
            console.error(e);
            showErrorsListInToast("Грешка при зареждане на карта", [e.message]);
        } finally {
            setLoading(false);
        }
    }

    const nextNumber = useMemo(() => {
        if (originalCard && originalCard.ownCompanyId === card?.ownCompanyId) {
            return originalCard.cardNumber;
        }
        return getNextProductionCardNumber() + 1;
    }, [card?.ownCompanyId, card?.cardNumber]);

    const save = async () => {
        if (!card) {
            return;
        }

        const errors: string[] = [];
        if (!card?.specification || !card.specification.length) errors.push("Картата е празна. Трябва да има поне един продукт");
        if (errors.length > 0) {
            showErrorsListInToast(getI18Text("Намерени проблеми"), errors);
            return
        }

        if (!card.productionStatus) {
            card.productionStatus = "Чакащи";
        }

        const ownCompany = ownCompanies.find(c => c.id === card.ownCompanyId);
        const counterName = ownCompany?.productionCardCounterName || "productionCardNumber";

        setSaving(true);

        try {
            const _items: ProductionCardProductsType = [];
            const _itemIds: string[] = [];
            card.specification.forEach(
                i => {
                    _items.push(removeEmptyFields(i)!);
                    _itemIds.push(i.productId);
                }
            );

            card.materialIds = card.materials.map(m => m.productId);

            for (const m of card.materials) {
                const warehouseItem = await getDocs(
                    query(collection(fbDb, "warehouseItems"),
                        where("productId", "==", m.productId),
                        where("warehouseId", "==", m.warehouseId),
                        where("isOwnProduction", "==", false)
                    )
                );
                if (warehouseItem.empty) {
                    const newItem: WarehouseItemType = {
                        qty: 0,
                        usedQty: 0,
                        availableQty: 0,
                        sku: m.productCode || "",
                        dimens: m.productDimension || "",
                        name: m.productName || "",
                        ownCompanyId: card.ownCompanyId,
                        warehouseId: m.warehouseId || "",
                        productId: m.productId || "",
                        isOwnProduction: false,
                        singlePrice: 0,
                    };

                    await addDoc(collection(fbDb, "warehouseItems"), newItem);
                }
            }

            let _data = removeEmptyFields({
                ...card,
                specification: _items,
                specificationIds: _itemIds,
                offerId: invoice.offerId,
                productId: card.specification[0] ? card.specification[0].productId : "",
            });

            if (_data) {
                if (card.ref) {
                    if (originalCard?.ownCompanyId !== card.ownCompanyId) {
                        const nextNumber = getNextProductionCardNumber();
                        const cardNumber = nextNumber + 1;
                        _data.cardNumber = cardNumber;
                        await updateDoc(getDocRef(fbDb, "settings", "globalSettings"), {[counterName]: cardNumber})
                    }
                    await updateDoc(card.ref, _data);
                } else {
                    await runTransaction(fbDb, async (transaction) => {
                        const nextNumber = getNextProductionCardNumber();
                        const cardNumber = nextNumber + 1;
                        transaction.update(getDocRef(fbDb, "settings", "globalSettings"), {[counterName]: cardNumber});
                        _data!.cardNumber = cardNumber;
                        const newCardRef = doc(collection(fbDb, "productionCards"));
                        _data!.ref = newCardRef;
                        await transaction.set(newCardRef, _data);
                    });
                }

                showMsgInToast(getI18Text("Картата е запазена успешно"));
                onDone();
            }
        } catch (e: any) {
            showErrorsListInToast(getI18Text("Възникна грешка"), [e.message]);
        } finally {
            setSaving(false);
        }
    }

    if (loading) return (
        <Row>
            <Col className={"text-center"}>
                <Spinner animation={"border"}/>
            </Col>
        </Row>
    );

    if (!card) return (
        <Row>
            <Col className={"text-center"}>
                <ProductionPlanLoader inquiry={inquiry}
                                      offer={offer}
                                      invoice={invoice}
                                      product={product}
                                      onCardLoaded={(productionCard) => {
                                          setCard({
                                              ...productionCard,
                                              specification: [...productionCard.specification],
                                              materials: [...productionCard.materials],
                                          });
                                      }}/>
            </Col>
        </Row>
    );

    return (
        <>
            <Form className={"p-2"}>
                <Modal.Body>
                    <Row>
                        <Col>
                            <h3>{product.name}
                                <span className={"text-primary ms-2"}>
                                    {
                                        !card.ref ? <> [<I18Label label={"Нова карта"}/>]</> : ""
                                    }
                                </span>
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={"auto"}>
                            <Form.Group className="w-200px">
                                <Form.Label><I18Label label={"Фирма"}/>:</Form.Label>
                                <OwnCompanyDropdown
                                    readOnly={card.materials.length > 0}
                                    selectedOwnCompanyId={card.ownCompanyId}
                                    onChange={(selectedOwnCompanyId) =>
                                        setCard({...card, ownCompanyId: selectedOwnCompanyId})}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Group className="w-100px">
                                <Form.Label><I18Label label={"Карта номер"}/>:</Form.Label>
                                <Form.Control value={zeroToEmptyString(nextNumber)} readOnly={true} disabled={true}/>
                            </Form.Group>
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Group className="w-100px">
                                <Form.Label><I18Label label={"Дата"}/>:</Form.Label>
                                <Form.Control value={dateToString(card.cardDate)} disabled readOnly/>
                            </Form.Group>
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Group className="w-140px">
                                <Form.Label><I18Label label={"Запитване номер/дата"}/>:</Form.Label>
                                <Form.Control value={
                                    `${card.inquiryNumber ?? ""} / ${dateToString(card.inquiryDate)}`} disabled/>
                            </Form.Group>
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Group className="w-140px">
                                <Form.Label><I18Label label={"Поръчка номер/дата"}/>:</Form.Label>
                                <Form.Control value={
                                    `${card.invoiceNumber} / ${dateToString(card.invoiceDate)}`} disabled/>
                            </Form.Group>
                        </Col>
                        {/*<Col>*/}
                        {/*    <Form.Group>*/}
                        {/*        <Form.Label><I18Label label={"Относно"}/>:</Form.Label>*/}
                        {/*        <Form.Select value={card.regarding}*/}
                        {/*                     onChange={event => setCard({...card, regarding: event.target.value})}>*/}
                        {/*            <option value={""}></option>*/}
                        {/*            <option value={"Доставка"}>{getI18Text("Доставка")}</option>*/}
                        {/*            <option value={"Ремонт"}>{getI18Text("Ремонт")}</option>*/}
                        {/*            <option value={"Производство"}>{getI18Text("Производство")}</option>*/}
                        {/*            <option value={"Продажба"}>{getI18Text("Продажба")}</option>*/}
                        {/*        </Form.Select>*/}
                        {/*    </Form.Group>*/}
                        {/*</Col>*/}
                        {/*<Col>*/}
                        {/*    <Form.Group>*/}
                        {/*        <Form.Label><I18Label label={"Място на извършване"}/>:</Form.Label>*/}
                        {/*        <Form.Select value={card.productionPlace}*/}
                        {/*                     onChange={event => setCard({*/}
                        {/*                         ...card,*/}
                        {/*                         productionPlace: event.target.value*/}
                        {/*                     })}>*/}
                        {/*            <option value={""}></option>*/}
                        {/*            <option value={"В сервиза"}>{getI18Text("В сервиза")}</option>*/}
                        {/*            <option value={"При клиент"}>{getI18Text("При клиент")}</option>*/}
                        {/*            <option value={"От доставчик"}>{getI18Text("От доставчик")}</option>*/}
                        {/*        </Form.Select>*/}
                        {/*    </Form.Group>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row>
                        <h5 className={"mt-5"}>
                            <I18Label label={"Спецификация"}/>:
                        </h5>
                    </Row>
                    <Row>
                        <Col>
                            <ProductionPlanSpecificationTable items={card.specification}
                                                              onChange={items => setCard({
                                                                  ...card,
                                                                  specification: items
                                                              })}/>
                        </Col>
                    </Row>
                    <Row className={"mt-3"}>
                        <Col xs={"auto"}>
                            <h5>
                                <I18Label label={"Допълнителни изисквания"}/>:
                            </h5>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Control as={"textarea"} value={card.notes}
                                              onChange={event => setCard({...card, notes: event.target.value})}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mt-3"}>
                        <Col>
                            <h5>
                                <I18Label label={"Необходими материали"}/>:
                            </h5>
                        </Col>
                    </Row>
                    <Row className={"mb-3"}>
                        <Col>
                            <ProductionPlanMaterialsTable items={card.materials}
                                                          availableWarehouses={ownCompanyWarehouseObjects}
                                                          onChange={items => setCard({
                                                              ...card,
                                                              materials: items
                                                          })}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5>
                                <I18Label label={"Условия на доставка към клиент"}/>:</h5>
                        </Col>
                    </Row>
                    <Row className={"mb-3"}>
                        <Col>
                            <Form.Group>
                                <Form.Label><I18Label label={"Транспорт"}/>:</Form.Label>
                                <Form.Select value={card.transport || ""}
                                             onChange={event => setCard({...card, transport: event.target.value})}>
                                    <option value={""}></option>
                                    <option value={"Собствен"}>{getI18Text("Собствен")}</option>
                                    <option value={"Нает"}>{getI18Text("Нает")}</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label><I18Label label={"Адрес за доставка"}/>:</Form.Label>
                                <Form.Control value={card.address || ""}
                                              onChange={event => setCard({...card, address: event.target.value})}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-3"}>
                        <Col>
                            <Form.Group>
                                <Form.Label><I18Label label={"Лице за контакти"}/>:</Form.Label>
                                <Form.Control value={card.personForContact || ""}
                                              onChange={event => setCard({
                                                  ...card,
                                                  personForContact: event.target.value
                                              })}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label><I18Label label={"Телефон за контакти"}/>:</Form.Label>
                                <Form.Control value={card.phone || ""}
                                              onChange={event => setCard({...card, phone: event.target.value})}/>
                            </Form.Group>
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Group>
                                <Form.Label><I18Label label={"Краен срок за доставка"}/>:</Form.Label>
                                <div className={"w-100"}>
                                    <ReactDatePicker
                                        className={"form-control"} isClearable
                                        dateFormat="dd.MM.yyyy"
                                        onChange={(d) => {
                                            const newCard = {
                                                ...card,
                                                deliveryDate: d || null,
                                                deadlineDate: d || null
                                            };
                                            setCard(newCard);
                                        }}
                                        selected={card.deliveryDate}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Row className={"w-100"}>
                        {
                            saving && <Col xs={12} className={"text-center"}>
                                <Spinner animation={"border"}/>
                            </Col>
                        }

                        {
                            !saving &&
                            <>
                                <Col xs={6}>
                                    <Button variant={"outline-secondary"} onClick={() => {
                                        onDone()
                                    }}>
                                        <FontAwesomeIcon icon={faArrowLeft}/> <I18Label label={"Към списъка"}/>
                                    </Button>

                                    {
                                        card.ref &&
                                        canUserEdit('CRM-Запитвания и оферти') &&
                                        <span className={"ms-2"}>
                                        <DeleteConfirmIconButton
                                            onClick={
                                                () => {
                                                    if (card.ref) {
                                                        deleteDoc(card.ref);
                                                        onDone();
                                                        showMsgInToast(getI18Text("Картата е изтрита успешно"));
                                                    }
                                                }
                                            }/>
                                            </span>
                                    }&nbsp;
                                    <span className={"ms-2"}>
                                        <HisotryIconButton onClick={() => setShowHistory(true)}/>
                                    </span>
                                </Col>
                                {
                                    canUserEdit('CRM-Запитвания и оферти') &&
                                    <Col xs={6} className={"text-end"}>
                                        <Button onClick={save}>
                                            <FontAwesomeIcon icon={faSave}/> <I18Label label={"Запиши"}/>
                                        </Button>
                                    </Col>
                                }
                            </>
                        }
                    </Row>
                </Modal.Footer>
            </Form>
        </>
    )
}
