import {Button, Form, ModalBody, ModalFooter, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCircleArrowDown, faEdit, faPlus, faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, getFirestore, DocumentData} from "firebase/firestore";
import {fbDb, firebaseApp, functions} from "../../../App";
import {useEffect, useState} from "react";
import LoadDataFromServerError from "../../../common/LoadDataFromServerError";
import {getAuth} from "firebase/auth";
import {httpsCallable} from "firebase/functions";
import useUserRights from "../../../hooks/useUserRights";


export interface ClientsListProps {
    isVisible: boolean;
    onAdd: () => any;
    onEdit: (item: DocumentData) => any;
    onDelete: (item: DocumentData) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
}

export function UsersList({isVisible, onAdd, onEdit, onDelete, onClose, onSelect}: ClientsListProps) {
    const {canUserEdit} = useUserRights();
    const [filterName, setFilterName] = useState('')
    const [filterEmail, setFilterEmail] = useState('')
    const [filterPhone, setFilterPhone] = useState('')
    const [filterRole, setFilterRole] = useState('')
    const [filterPosition, setFilterPosition] = useState('')
    const [filterLanguage, setFilterLanguage] = useState('')

    const [users, loadingUsers, errorUsers] = useCollection(
        collection(fbDb, "users")
    );

    const [positions, loadingPositions, errorPositions] = useCollection(
        collection(fbDb, "positions")
    );

    const [filteredUsers, setFilteredUsers] = useState<any[]>([])

    useEffect(() => {
        if (users) {
            setFilteredUsers(users.docs.filter(
                r => (!filterName || filterName.length === 0 || r.data().name.toLowerCase().indexOf(filterName.toLowerCase()) > -1) &&
                    (!filterEmail || filterEmail.length === 0 || r.data().email.toLowerCase().indexOf(filterEmail.toLowerCase()) > -1) &&
                    (!filterPhone || filterPhone.length === 0 || r.data().phone.toLowerCase().indexOf(filterPhone.toLowerCase()) > -1) &&
                    (!filterRole || filterRole.length === 0 || r.data().role.toLowerCase().indexOf(filterRole.toLowerCase()) > -1) &&
                    (!filterPosition || filterPosition.length === 0 || r.data().position.toLowerCase().indexOf(filterPosition.toLowerCase()) > -1) &&
                    (!filterLanguage || filterLanguage.length === 0 || r.data().language?.toLowerCase().indexOf(filterLanguage.toLowerCase()) > -1)
            ));
        } else setFilteredUsers([]);
    }, [users, filterName, filterEmail, filterPhone, filterPosition, filterRole, filterLanguage]);

    function isLoading() {
        return loadingUsers;
    }


    function getPositionName(positionId: string) {
        const _position = positions?.docs.find(p => p.id === positionId);
        return _position ? _position.data().name : positionId;
    }

    return (
        <>
            {isLoading() && <Spinner animation={"border"}/>}

            {
                !isLoading() && errorUsers && <LoadDataFromServerError/>
            }

            {
                !loadingUsers && !errorUsers && isVisible &&
                <>
                    <ModalBody>
                        <div className={"max-h-60vh scrollable"}>
                            <Table size={"sm"} borderless hover>
                                <thead style={{backgroundColor: "white"}}>
                                <tr>
                                    <th style={{width: onSelect ? 120 : 90}}></th>
                                    <th>Име</th>
                                    <th>Имейл</th>
                                    <th>Телефон</th>
                                    <th>Позиция</th>
                                    <th>Роля</th>
                                    <th>Език</th>
                                </tr>
                                <tr>
                                    <td className={"text-start"}>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterName}
                                                      onChange={(e) => setFilterName(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterEmail}
                                                      onChange={(e) => setFilterEmail(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterPhone}
                                                      onChange={(e) => setFilterPhone(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterPosition}
                                                      onChange={(e) => setFilterPosition(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterRole}
                                                      onChange={(e) => setFilterRole(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Select onChange={(e) =>
                                            setFilterLanguage(e.target.value as "bg" | "en")} size={"sm"}>
                                            <option value={''}>Всички</option>
                                            <option value={'bg'}>BG</option>
                                            <option value={'ro'}>RO</option>
                                        </Form.Select>
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    loadingUsers &&
                                    <tr>
                                        <td colSpan={6} className={"text-center fw-bold"}>
                                            <Spinner animation={"border"}/>
                                        </td>
                                    </tr>
                                }
                                {
                                    filteredUsers && filteredUsers.length === 0 &&
                                    <tr>
                                        <td colSpan={6} className={"text-center fw-bold"}>Няма потребители</td>
                                    </tr>
                                }
                                {
                                    filteredUsers && filteredUsers.length > 0 && filteredUsers.map(
                                        r =>
                                            <tr key={r.id} className={"tr-bordered"}>
                                                <td>
                                                    {
                                                        canUserEdit('Номенклатури-Потребители') && <>
                                                            <Button size={"sm"} variant={"outline-secondary"}
                                                                    onClick={() => onEdit(r)}
                                                            >
                                                                <FontAwesomeIcon icon={faEdit}/>
                                                            </Button>&nbsp;
                                                            <Button size={"sm"} variant={"outline-danger"}
                                                                    onClick={() => {
                                                                        onDelete(r)
                                                                    }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash}/>
                                                            </Button>&nbsp;
                                                        </>
                                                    }
                                                    {
                                                        onSelect &&
                                                        <Button size={"sm"} variant={"outline-success"}
                                                                onClick={() => {
                                                                    onSelect({...r.data(), id: r.id})
                                                                }}
                                                        >
                                                            <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                        </Button>
                                                    }
                                                </td>
                                                <td>
                                                    {r.data().name}
                                                </td>
                                                <td>
                                                    {r.data().email}
                                                </td>
                                                <td>
                                                    {r.data().phone}
                                                </td>
                                                <td>
                                                    {getPositionName(r.data().position)}
                                                </td>
                                                <td>
                                                    {r.data().role}
                                                </td>
                                                <td>
                                                    {r.data().language}
                                                </td>
                                            </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        {
                            canUserEdit('Номенклатури-Потребители') &&
                            <Button onClick={() => onAdd()}>
                                <FontAwesomeIcon icon={faPlus}/> Добави нов
                            </Button>
                        }
                        <Button variant={"outline-secondary"} onClick={() => {
                            onClose(null)
                        }}>
                            <FontAwesomeIcon icon={faCancel}/> Затвори
                        </Button>
                    </ModalFooter>
                </>
            }
        </>
    )
}
