import useOwnCompaniesRepo from "../hooks/useOwnCompaniesRepo";
import {FC, useEffect, useState} from "react";
import useWarehouseObjectsRepo from "../hooks/useWarehouseObjectsRepo";
import {WarehouseObjectType} from "../hooks/useWarehouseObjects";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import {OwnCompanyType} from "../../../fb-converters/own-company-converter";

interface OwnCompanyWarehouseSelectorProps {
    selectedCompanyId: string | undefined;
    selectedWarehouseId: string | undefined;
    onChange: (companyId: string | undefined, warehouseId: string | undefined) => void;
}

const OwnCompanyWarehouseSelector: FC<OwnCompanyWarehouseSelectorProps> =
    ({selectedCompanyId, selectedWarehouseId, onChange}) => {
        const {ownCompanies} = useOwnCompaniesRepo();
        const [selectedCompany, setSelectedCompany] = useState<OwnCompanyType | undefined>();
        const {ownCompanyWarehouseObjects: warehouses} = useWarehouseObjectsRepo(selectedCompany?.id);
        const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseObjectType | undefined>();

        useEffect(() => {
            if (!selectedCompanyId && ownCompanies && ownCompanies.length > 0) {
                setSelectedCompany(ownCompanies[0]);
            } else {
                setSelectedCompany(ownCompanies.find(c => c.id === selectedCompanyId));
            }
            if (!selectedWarehouseId && warehouses && warehouses.length > 0) {
                setSelectedWarehouse(warehouses[0]);
            } else {
                setSelectedWarehouse(warehouses.find(w => w.id === selectedWarehouseId));
            }
        }, []);

        useEffect(() => {
            if (!warehouses || warehouses.length === 0) {
                setSelectedWarehouse(undefined);
            } else {
                if (selectedCompany && (!selectedWarehouse || selectedWarehouse.ownCompanyId !== selectedCompany.id)) {
                    setSelectedWarehouse(warehouses[0]);
                }
            }
        }, [warehouses, selectedCompany]);

        useEffect(() => {
            if (selectedCompany?.id !== selectedCompanyId || selectedWarehouse?.id !== selectedWarehouseId) {
                onChange(selectedCompany?.id, selectedWarehouse?.id);
            }
        }, [selectedCompany, selectedWarehouse]);


        return (<>
            <Row className={"border-black border-0 border-bottom"}>
                <Col xs={"auto"}>
                    <Tabs activeKey={selectedCompany?.id}
                          onSelect={(k) => setSelectedCompany(ownCompanies.find(c => c.id === k))}>
                        {ownCompanies.map(c => <Tab key={c.id} eventKey={c.id} title={c.name}/>)}
                    </Tabs>
                </Col>
                <Col xs={"auto"}>
                    <span className={"d-block pt-2 fw-bold"}>&nbsp;&nbsp;&nbsp;Склад:</span>
                </Col>
                <Col>
                    <Tabs activeKey={selectedWarehouse?.id} variant={"pills"}
                          onSelect={(k) => setSelectedWarehouse(warehouses.find(w => w.id === k))}>
                        {warehouses
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .filter(w => w.ownCompanyId === selectedCompany?.id)
                            .map(w => <Tab key={w.id} eventKey={w.id} title={w.name}/>)}
                    </Tabs>
                </Col>
            </Row>
        </>);

    }

export default OwnCompanyWarehouseSelector;
