import {
    EMPTY_WAREHOUSE_REVISION_ITEM,
    WarehouseRevisionItemType
} from "../../../fb-converters/warehouse-revision-converter";
import React, {FC, useEffect, useState} from "react";
import {WarehouseOutSelectItemDialog} from "../warehouse-transactions/warehouse-out/WarehouseOutSelectItemDialog";
import SelectValueFromDialog from "../../../common/SelectValueFromDialog";
import EditableTableCell from "../../../common/EditableTableCell";
import {textToInt, zeroToEmptyString, zeroToEmptyStringAsCurrency} from "../../../common";
import PlusIconButton from "../../../common/icon-buttons/PlusIconButton";
import {WarehouseOutItemType} from "../hooks/useWarehouseOutItems";
import DeleteConfirmIconButton from "../../../common/icon-buttons/DeleteConfirmIconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

type WarehouseRevisionEditableItemProps = {
    zIndex: number;
    editItem: WarehouseRevisionItemType;
    isEditMode: boolean;
    onChange: (newItem: WarehouseRevisionItemType | null) => void;
    onEdit: () => void;
    onCancel: () => void;
}

const WarehouseRevisionEditableItem: FC<WarehouseRevisionEditableItemProps> = ({zIndex, editItem, isEditMode, onEdit, onChange, onCancel}) => {
    const [item, setItem] = useState<WarehouseRevisionItemType>({...editItem});
    useEffect(() => {
        setItem({...editItem});
    }, [editItem]);

    function onDelete() {
        onChange(null);
    }

    return (
        <tr>
            <td className={"w-100px"}>{item.productCode}</td>
            <td>{item.productName}</td>
            <td className={"w-60px"}>{item.productDimension}</td>
            <td className={"text-center text-success"}>{
                item.productIsOwnProduction ?
                    <FontAwesomeIcon icon={faCheck} /> : ""
            }</td>
            <td className={"text-end w-60px"}>{zeroToEmptyStringAsCurrency(item.productSinglePrice)}</td>
            <EditableTableCell
                className={"text-end w-75px bg-secondary-light"}
                inputClassName={"text-end"}
                inEditMode={true}
                isWorking={false}
                value={
                    item.expectedQty ?
                        item.expectedQty.toString() :
                        ""
                }
                onEdit={() => {
                }}
                onBlur={
                    (value) => onChange({...item, expectedQty: textToInt(value, 0) ?? 0})
                }
                onDone={() => {
                }}
            />
            <EditableTableCell
                className={"text-end w-75px bg-secondary-light"}
                inputClassName={"text-end"}
                inEditMode={true}
                isWorking={false}
                value={
                    item.actualQty ?
                        item.actualQty.toString() :
                        ""
                }
                onEdit={() => {
                }}
                onBlur={
                    (value) => onChange({...item, actualQty: textToInt(value, 0) ?? 0})
                }
                onDone={() => {
                }}
            />
            <td className={"text-end w-75px"}>{zeroToEmptyString(item.actualQty - item.expectedQty)}</td>
            <td className={"text-end w-75px"}>{zeroToEmptyStringAsCurrency((item.actualQty - item.expectedQty) * item.productSinglePrice)}</td>
            <td className={"text-center w-75px"}>
                <DeleteConfirmIconButton onClick={onDelete} size={"sm"} />
            </td>
        </tr>
    )
}

export default WarehouseRevisionEditableItem;
