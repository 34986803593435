import {Button, Form, ModalBody, ModalFooter, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCircleArrowDown, faEdit, faPlus, faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, getFirestore, DocumentData} from "firebase/firestore";
import {fbDb, firebaseApp} from "../../../App";
import {useEffect, useState} from "react";
import LoadDataFromServerError from "../../../common/LoadDataFromServerError";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import {dateToString} from "../../../common";
import {TableFilterRow} from "../../../common/TableFilterRow";
import useUserRights from "../../../hooks/useUserRights";


export interface CampaignsListProps {
    isVisible: boolean;
    onAdd: () => any;
    onEdit: (item: DocumentData) => any;
    onDelete: (item: DocumentData) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
}

export function CampaignsList({isVisible, onAdd, onEdit, onDelete, onClose, onSelect}: CampaignsListProps) {
    const {canUserEdit} = useUserRights();
    const [filterName, setFilterName] = useState('');
    const [filterFromDate, setFilterFromDate] = useState<Date | undefined | null>(undefined);
    const [filterToDate, setFilterToDate] = useState<Date | undefined | null>(undefined);
    const [filterIsActive, setFilterIsActive] = useState<string>('Да');
    const [positions, loadingPositions, errorPositions] = useCollection(
        collection(fbDb, "campaigns")
    );

    const [filtered, setFiltered] = useState<any[]>([])

    useEffect(() => {
        if (positions) {
            setFiltered(positions.docs?.filter(
                r => {
                    const fromInSec = filterFromDate ? filterFromDate.valueOf() / 1000 : 0;
                    const toInSec = filterToDate ? filterToDate.valueOf() / 1000 : 0;

                    return (!filterName || filterName.length === 0 || r.data().name.toLowerCase().indexOf(filterName.toLowerCase()) > -1) &&
                        (!fromInSec || fromInSec <= r.data().fromDate.seconds) &&
                        (!toInSec || toInSec >= r.data().toDate.seconds) &&
                        (filterIsActive === "" || filterIsActive == r.data().isActive);
                }
            ));
        } else setFiltered([]);
    }, [positions, filterName, filterFromDate, filterToDate, filterIsActive]);

    function isLoading() {
        return loadingPositions;
    }

    return (
        <>
            {isLoading() && <Spinner animation={"border"}/>}

            {
                !isLoading() && errorPositions && <LoadDataFromServerError/>
            }

            {
                !isLoading() && !errorPositions && positions && isVisible &&
                <>
                    <ModalBody>
                        <div className={"max-h-60vh scrollable min-h-400px"}>
                            <Table size={"sm"} borderless hover>
                                <thead style={{backgroundColor: "white"}}>
                                <tr>
                                    <th style={{width: onSelect ? 120 : 90}}></th>
                                    <th>Име</th>
                                    <th>От дата</th>
                                    <th>До дата</th>
                                    <th className={"text-end"}>Брой седм.</th>
                                    <th className={"text-end"}>Брой клиенти.</th>
                                    <th className={"text-end"}>Брой активн.</th>
                                    <th className={"text-center"}>Активна</th>
                                </tr>
                                <TableFilterRow>
                                    <th className={"text-start"}>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </th>
                                    <th>
                                        <Form.Control size={"sm"} value={filterName}
                                                      onChange={(e) => setFilterName(e.target.value)}/>
                                    </th>
                                    <th>
                                        <ReactDatePicker className={"form-control form-control-sm"} isClearable
                                                         dateFormat="dd.MM.yyyy"
                                                         onChange={(d) => {
                                                             setFilterFromDate(d)
                                                         }}
                                                         selected={filterFromDate}
                                        />
                                    </th>
                                    <th>
                                        <ReactDatePicker className={"form-control form-control-sm"} isClearable
                                                         dateFormat="dd.MM.yyyy"
                                                         onChange={(d) => {
                                                             setFilterToDate(d)
                                                         }}
                                                         selected={filterToDate}
                                        />
                                    </th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>
                                        <select className={"form-select text-center"} defaultValue={filterIsActive}
                                                onChange={e => setFilterIsActive(e.target.value)}
                                        >
                                            <option value={""}></option>
                                            <option value={"Да"}>Да</option>
                                            <option value={"Не"}>Не</option>
                                        </select>
                                    </th>
                                </TableFilterRow>
                                </thead>
                                <tbody>
                                {
                                    isLoading() &&
                                    <tr>
                                        <td colSpan={6} className={"text-center fw-bold"}>
                                            <Spinner animation={"border"}/>
                                        </td>
                                    </tr>
                                }
                                {
                                    filtered && filtered.length === 0 &&
                                    <tr>
                                        <td colSpan={6} className={"text-center fw-bold"}>Няма кампании</td>
                                    </tr>
                                }
                                {
                                    filtered && filtered.length > 0 && filtered.map(
                                        r =>
                                            <tr key={r.id} className={"tr-bordered"}>
                                                <td>
                                                    {
                                                        canUserEdit('Номенклатури-Кампании') &&
                                                        <>
                                                            <Button size={"sm"} variant={"outline-secondary"}
                                                                    onClick={() => onEdit(r)}
                                                            >
                                                                <FontAwesomeIcon icon={faEdit}/>
                                                            </Button>&nbsp;
                                                            <Button size={"sm"} variant={"outline-danger"}
                                                                    onClick={() => {
                                                                        onDelete(r)
                                                                    }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash}/>
                                                            </Button>&nbsp;
                                                        </>
                                                    }
                                                    {
                                                        onSelect &&
                                                        <Button size={"sm"} variant={"outline-success"}
                                                                onClick={() => {
                                                                    onSelect(r)
                                                                }}
                                                        >
                                                            <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                        </Button>
                                                    }
                                                </td>
                                                <td> {r.data().name} </td>
                                                <td> {moment(new Date(r.data().fromDate.seconds * 1000)).format('DD.MM.YYYY')} </td>
                                                <td> {moment(new Date(r.data().toDate.seconds * 1000)).format('DD.MM.YYYY')} </td>
                                                <td className={"text-end"}> {r.data().weekTarget} </td>
                                                <td className={"text-end"}> {r.data().totalClients} </td>
                                                <td className={"text-end"}> {r.data().totalActivities} </td>
                                                <td className={"text-center"}> {r.data().isActive} </td>
                                            </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        {
                            canUserEdit('Номенклатури-Кампании') &&
                            <Button onClick={() => onAdd()}>
                                <FontAwesomeIcon icon={faPlus}/> Добави нова
                            </Button>
                        }
                        <Button variant={"outline-secondary"} onClick={() => {
                            onClose(null)
                        }}>
                            <FontAwesomeIcon icon={faCancel}/> Затвори
                        </Button>
                    </ModalFooter>
                </>
            }
        </>
    )
}
