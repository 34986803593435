import {
    Modal,
    ModalTitle,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import React, {useEffect, useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import {ClientsList} from "./ClientsList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandshake} from "@fortawesome/free-solid-svg-icons";
import {ClientDelete} from "./ClientDelete";
import {ClientDetail} from "./details/ClientDetails";
import ModalBackground from "../../../common/ModalBackground";
import DialogCloseButton from "../../../common/DialogCloseButton";
import useBusinessSizesOnce from "./hooks/useBusinessSizesOnce";
import useSalesRepresentativesOnce from "./hooks/useSalesRepresentativesOnce";
import LoadingErrorContent from "../../../common/LoadingErrorContent";
import {ClientType} from "./hooks/useClients";
import I18Label from "../../../i18/i18label";

export function ClientsDialog({zIndex, show, onClose, onSelect, onSelectLocation}: DIALOG_OPTIONS) {

    const [viewMode, setViewMode] = useState<"VIEW_LIST" | "VIEW_ADD" | "VIEW_EDIT" | "VIEW_DELETE">('VIEW_LIST');
    const [editDocument, setEditDocument] = useState<ClientType | null>(null);
    const [deleteDocument, setDeleteDocument] = useState<ClientType | null>(null);
    const [addToCountry, setAddToCountry] = useState<string | null>(null);

    const [saleRepresentatives, loadingSaleRepresentatives, errorSaleRepresentatives] = useSalesRepresentativesOnce();
    const [sizeGroups, loadingSizeGroups, errorSizeGroups] = useBusinessSizesOnce();

    useEffect(() => {
        setViewMode(editDocument ? "VIEW_EDIT" : "VIEW_LIST");
    }, [editDocument])

    useEffect(() => {
        setViewMode(deleteDocument ? "VIEW_DELETE" : "VIEW_LIST");
    }, [deleteDocument])

    useEffect(() => {
        setViewMode(addToCountry ? "VIEW_ADD" : "VIEW_LIST");
    }, [addToCountry])

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} size={"xl"} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faHandshake}/> <I18Label label={"Клиенти"} />
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(null)} />
                </ModalHeader>
                <LoadingErrorContent loading={loadingSaleRepresentatives || loadingSizeGroups}
                                     error={errorSaleRepresentatives?.message || errorSizeGroups?.message}>
                    <>
                        {
                            <ClientsList onClose={onClose} isVisible={viewMode === "VIEW_LIST"}
                                         onSelect={onSelect}
                                         onAdd={(country) => setAddToCountry(country)}
                                         onEdit={(doc: ClientType) => {
                                             setEditDocument(doc);
                                         }}
                                         onDelete={(doc: ClientType) => {
                                             setDeleteDocument(doc);
                                         }}
                            />
                        }
                        {
                            viewMode === "VIEW_EDIT" && editDocument &&
                            <ClientDetail doc={editDocument}
                                          zIndex={(zIndex || 1100) + 10}
                                          sizeGroups={sizeGroups}
                                          onDone={() => {
                                              setEditDocument(null)
                                          }}
                                          onSelect={onSelectLocation ? (location) =>
                                              onSelectLocation({client: editDocument, location: location}) : undefined}
                            />
                        }
                        {
                            viewMode === "VIEW_DELETE" && deleteDocument &&
                            <ClientDelete doc={deleteDocument} onDone={() => {
                                setDeleteDocument(null)
                            }}/>
                        }
                        {
                            viewMode === "VIEW_ADD" && addToCountry &&
                            <ClientDetail doc={null}
                                          zIndex={(zIndex || 1100) + 10}
                                          sizeGroups={sizeGroups}
                                          onDone={() => {
                                              setAddToCountry(null)
                                          }}
                                          onSelect={onSelect}
                            />
                        }
                    </>
                </LoadingErrorContent>
            </Modal>
        </ModalBackground>
    )
}
