import {toast} from "react-toastify";
import moment, {Moment} from "moment";


export const minActivityTime = new Date();
minActivityTime.setHours(7, 0);

export const maxActivityTime = new Date();
maxActivityTime.setHours(21, 0);


export function mapToObject(map: any) {
    const obj: any = [];

    map.forEach(
        (k: string, v: any) => {
            obj[v] = k;
        }
    )
    return {...obj};
}


export function showErrorsListInToast(title: string, errors: string[]) {
    const msgs: any = [];

    const toastMsg = function (title: string, errors: string[]) {
        return <>
            <div className={"font-weight-bold text-center w-100 border-bottom p-1 m-1"}>{title}</div>
            <ul className={"pt-3"}>
                {errors.map(e => e)}
            </ul>
        </>
    }

    errors.forEach(
        (m: string, mIdx: number) => msgs.push(<li key={mIdx}>{m}</li>)
    );

    toast.error(toastMsg(title, msgs), {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
    });

}

export function showMsgInToast(msg: string) {
    const toastMsg = function (msg: string) {
        return <>
            <div className={"font-weight-bold text-center w-100 p-1 m-1 text-center"}>{msg}</div>
        </>
    }

    toast.success(toastMsg(msg), {
        position: toast.POSITION.BOTTOM_CENTER
    });

}

export const ARRAY22 = Array.of(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22);
export const ARRAY_MONTHS_SHORT = Array.of('ЯНУ', 'ФЕВ', 'МАР', 'АПР', 'МАЙ', 'ЮНИ', 'ЮЛИ', 'АВГ', 'СЕП', 'ОКТ', 'НОЕ', 'ДЕК');
export const ARRAY_MONTHS_FULL = Array.of('Януари', 'Февруари', 'Март', 'Април', 'Май', 'Юни', 'Юли', 'Август', 'Септември', 'Октомври', 'Ноември', 'Декември');

export const DICT = new Map<string, string>(
    [
    ]
);

export function translateError(e: string) {
    // debugger
    let err: string = e;
    // @ts-ignore
    for (const [key, value] of DICT.entries()) {
        err = err.replace(key, value);
    }

    return err;
}

export function translate(text: string) {
    return translateError(text);
}

export function translateDay(s: string) {
    return s.replace('Mon', 'Пон')
        .replace('Tue', 'Вто')
        .replace('Wed', 'Сря')
        .replace('Thu', 'Четв')
        .replace('Fri', 'Пет')
        .replace('Sat', 'Съб')
        .replace('Sun', 'Нед');
}

export function minutesToHours(m: number, suffix: string = ""): string {
    if (m === 0) return '';

    const _h = Math.floor(m / 60);
    const _m = m % 60;

    return ("00" + _h).substr(-2) + ":" + ("00" + _m).substr(-2) + suffix;
}

export function zeroToEmptyString(text: string|number|undefined|null, suff: string = '') {
    if(!text) return "";
    return (!text || (text == "0") || (text == 0) || (text == "NaN")) ? "" : text + suff;
}

export function zeroToEmptyStringAsNumber(text: string|number|undefined|null, suff: string = '') {
    if(!text) return "";
    let newVal: string = zeroToEmptyString(text).toString();
    return (newVal && newVal !== "") ? Number.parseFloat(newVal).toFixed(2) + suff : '';
}

export function zeroToEmptyStringAsCurrency(text: string | number | undefined, suff: string = '') {
    let newVal: string = zeroToEmptyString(text).toString();
    return (newVal && newVal !== "") ? Number.parseFloat(newVal).toFixed(2) + suff : '';
}

export function zeroToEmptyStringAsCurrencyWithCommas(text: string | number | undefined | null, suff: string = '') {
    if(!text) return "";

    let newVal: string = zeroToEmptyString(text).toString();
    return (newVal && newVal !== "") ? numberWithCommas(Number.parseFloat(newVal).toFixed(2)) + suff : '';
}

export function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export function numberWithCommasToCurrency(x: any, suff: string = '') {
    if(typeof x === 'string') x = Number.parseFloat(x);
    if(isNaN(x)) return x;
    return x.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + suff;
}

export function nullsToEmptyString(obj: any) {
    Object.keys(obj).forEach(
        $key => {
            if(obj[$key] === "null" || obj[$key] === null) obj[$key] = '';
        }
    );

    return obj;
}

export function textToFloat(value: string | number, defaultValue: any = null): number | null {
    if(typeof value === "number") return value;

    const _v = value ? Number.parseFloat(value.replace(',', '.')) : defaultValue;
    return isNaN(_v) ? defaultValue : _v;
}
export function editTextToFloat(value: string): string {
    const _v = Number.parseFloat(value.replace(',', '.'));
    return isNaN(_v) ? '' : value;
}

export function textToInt(value: string, defaultValue: any = null): number | null {
    if(!value) return defaultValue;

    const _v = Number.parseInt(value.replace(',', '.'));
    return isNaN(_v) ? defaultValue : _v;
}

export function onlyPozitive(value: number|null): number | null {
    return value !== null && value >= 0 ? value : null;
}
export function onlyPozitiveText(value: string|null): string | null {
    const _v = textToFloat(value || '');
    return _v !== null && _v >= 0 ? value : null;
}

export function momentToString(date: Moment) {
    return date.format('DD.MM.YYYY')
}

export function dateToString(date: Date | null | undefined) {
    return date ? moment(date).format('DD.MM.YYYY') : ''
}
export function dateTimeToString(date: Date | null | undefined) {
    return date ? moment(date).format('DD.MM.YYYY HH:mm') : ''
}

export function dateWithZeroTime(date: Date | null | undefined): Date | undefined {
    if (!date) return undefined;
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
}

export function dateWithEndDayTime(date: Date | null | undefined): Date | undefined {
    if (!date) return undefined;
    const newDate = new Date(date);
    newDate.setHours(23, 59, 59, 999);
    return newDate;
}

export function isIterable(value: any) {
    return Symbol.iterator in Object(value);
}

export function getShiftName(id: number) {
    switch (id) {
        case 1: return "Първа смяна";
        case 2: return "Втора смяна";
        case 3: return "Редовна смяна";
    }
    return '';
}

export function removeEmptyFields<T>(obj: T | null | undefined): T | null {
    if(!obj) return null;
    return removeUndefinedFields(obj);
}

export function getRequiredActivitiesCntBySizeGroup(campaign: any, sg: string) {
    let cnt = 0;
    switch (sg) {
        case "Малък клиент" : cnt = campaign.get('smallTries') || 0;  break;
        case "Среден клиент" : cnt = campaign.get('midTries') || 0; break;
        case "Голям клиент" : cnt = campaign.get('bigTries') || 0; break;
        case "Ключов клиент/дилър" : cnt = campaign.get('keyTries') || 0; break;
        default: cnt = 0;
    }
    return cnt;
}


export function removeUndefinedFields<T>(obj: T | null): T | null {
    if(obj === null) return null;
    let newObj: T = {...obj};
    const keys = Object.keys(newObj as Object);
    keys.forEach(
        (k: string) => {
            // @ts-ignore
            if(newObj[k] === undefined) delete newObj[k];
        }
    );
    return newObj;
}

export function firebaseDateToDate(fbDate: any): Date {
    if(!fbDate) return fbDate;

    const _date = moment(fbDate);
    if (_date.isValid()) return fbDate;
    // @ts-ignore
    return new Date(fbDate.seconds * 1000);
}

export function dateDiffInDays(a: Date, b: Date) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}
