import ReactDatePicker from "react-datepicker";
import {dateToString} from "../../../common";
import React, {FC} from "react";

interface ProductionPlanDateEditCellProps {
    date?: Date,
    setDate: (date: Date | undefined) => void,
    disabled?: boolean
}

const ProductionPlanDateEditCell: FC<ProductionPlanDateEditCellProps> = ({date, setDate, disabled}) => {
    return (
        <td className={"w-75px"} style={{maxWidth: "82px"}}>
            <ReactDatePicker
                disabled={disabled}
                className={"w-75px z-0 "}
                isClearable={true}
                selected={date}
                dateFormat={"dd.MM.yyyy"}
                value={dateToString(date)}
                onChange={
                    (date) => {
                        setDate(date ?? undefined)
                    }
                }/>
        </td>
    )
}

export default ProductionPlanDateEditCell;
