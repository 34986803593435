import {useState} from "react";
import TrashIconButton from "./TrashIconButton";
import CheckIconButton from "./CheckIconButton";
import CancelIconButton from "./CancelIconButton";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHistory} from "@fortawesome/free-solid-svg-icons";

export default function HisotryIconButton(props: { size?: "sm" | "lg", onClick: VoidFunction }) {
    return (
        <Button
            variant={"outline-primary"}
            size={props.size ?? "sm"}
            onClick={props.onClick}
        >
            <FontAwesomeIcon icon={faHistory}/>
        </Button>
    )
}
