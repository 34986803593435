import {customerInvoiceConverter, CustomerInvoiceType} from "../../../fb-converters/cutomerInvoice-converter";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, orderBy, query, where} from "firebase/firestore";
import {fbDb} from "../../../App";
import {Modal, Spinner, Table} from "react-bootstrap";
import {dateTimeToString, dateToString} from "../../../common";
import I18Label from "../../../i18/i18label";
import DialogCloseButton from "../../../common/DialogCloseButton";
import ModalBackground from "../../../common/ModalBackground";
import ViewIconButton from "../../../common/icon-buttons/ViewIconButton";
import CustomerInvoiceViewDetails from "./CustomerInvoiceViewDetails";
import {useState} from "react";

interface CustomerInvoiceHistoryProps {
    zIndex?: number;
    customerInvoice: CustomerInvoiceType;
    onClose: () => void;
}

const CustomerInvoiceHistory = ({zIndex = 1200, customerInvoice, onClose}: CustomerInvoiceHistoryProps) => {
    const [documentToView, setDocumentToView] = useState<CustomerInvoiceType | null>(null);
    const [invoices, loading, error] = useCollectionData(
        query(
            collection(fbDb, "customerInvoicesHistory"),
            where("invoiceNumber", "==", customerInvoice.invoiceNumber),
            where("documentType", "==", customerInvoice.documentType),
            orderBy("createdAt", "desc")
        ).withConverter(customerInvoiceConverter)
    );

    if(error) {
        console.error(error);
    }

    return (

        <ModalBackground zIndex={zIndex}>
            <Modal show={true} size={"lg"} style={{zIndex: zIndex + 1}}>
                <Modal.Header>
                    <Modal.Title>
                        <span className={"text-uppercase"}> <I18Label label={"История за "}/> <I18Label
                            label={customerInvoice.documentType}/> #{customerInvoice.invoiceNumber} </span>
                        <span
                            className={"ms-3 text-primary"}><I18Label
                            label={"към оферта"}/>: {customerInvoice.offerNumber} / {dateToString(customerInvoice.offerDate)}</span>
                    </Modal.Title>
                    <DialogCloseButton onClose={onClose}/>
                </Modal.Header>

                <Modal.Body>

                    <div>
                        {loading && <div className={"mt-5 text-center"}><Spinner animation={"border"}/></div>}
                        {error && <div className={"mt-5 text-center text-danger fw-bold"}>
                            {error.message}
                        </div>}
                        {invoices && invoices.length === 0 && <div className={"m-5 text-center text-danger fw-bold"}>
                            <I18Label label={"Няма намерени записи"}/>
                        </div>}
                        {invoices && invoices.map((invoice: CustomerInvoiceType) => (
                            <Table size={"sm"} striped bordered hover responsive>
                                <thead>
                                <tr>
                                    <th>Дата</th>
                                    <th>Автор</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{dateTimeToString(invoice.createdAt)}</td>
                                    <td>{invoice.changerName}</td>
                                    <td className={"text-end w-50px"}>
                                        <ViewIconButton size={"sm"} onClick={() => {
                                            setDocumentToView(invoice);
                                        }} />
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer />
            </Modal>
            {
                documentToView &&
                <CustomerInvoiceViewDetails
                    zIndex={zIndex+1}
                    invoice={customerInvoice}
                    onClose={() => setDocumentToView(null)}
                />
            }
        </ModalBackground>
    )
}

export default CustomerInvoiceHistory;
