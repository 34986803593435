import {useEffect, useMemo, useState} from "react";

import {
    addDoc,
    setDoc as setFBDoc,
    collection,
    runTransaction,
    query,
    where,
    getDocs,
    doc as getDocRef, updateDoc
} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb} from "../../../App";
import {
    removeEmptyFields,
    showErrorsListInToast,
    textToFloat, textToInt,
    zeroToEmptyString,
    zeroToEmptyStringAsNumber
} from "../../../common";
import SelectValueFromDialog from "../../../common/SelectValueFromDialog";
import {ProductsDialog} from "../products/ProductsDialog";
import SmallBorderedHooveredTable from "../../../common/SmallBorderedHooveredTable";
import EditableTableCell from "../../../common/EditableTableCell";
import RightAlignedCell from "../../../common/RightAlignedCell";
import TrashIconButton from "../../../common/icon-buttons/TrashIconButton";
import PlusIconButton from "../../../common/icon-buttons/PlusIconButton";
import {ProductionItemsType, ProductionItemType, ProductionType} from "./hooks/useProductionOnce";
import {VirtualProductsDialog} from "../virtual-products/VirtualProductsDialog";
import {WarehouseItemType} from "../../warehouse/hooks/useWarehouseInItems";
import ReactDatePicker from "react-datepicker";
import UseRecalcWarehouseItemStatus from "../../warehouse/hooks/useRecalcWarehouseItemQty";
import {StringInput} from "../../../common/StringInput";
import {WarehouseOutItemType} from "../../warehouse/hooks/useWarehouseOutItems";
import {useDocument} from "react-firebase-hooks/firestore";
import {DEFAULT_WAREHOUSE} from "../../warehouse/consts";
import WarehouseObjectsDropdown from "../warehouse-objects/WarehouseObjectsDropdown";
import {OwnCompanyDropdown} from "../own-companies/OwnCompanySelector";
import useUserRights from "../../../hooks/useUserRights";


interface Props {
    zIndex?: number;
    product: ProductionType;
    onDone: () => any;
}

interface EditableVirtualProductItemRowProps {
    zIndex?: number;
    item: ProductionItemType,
    onSave: (item: ProductionItemType) => void,
    onCancel: VoidFunction
}

const EditableVirtualProductItemRow: React.FC<EditableVirtualProductItemRowProps> = (props: EditableVirtualProductItemRowProps) => {
    const [item, setItem] = useState({...props.item});
    const [editCell, setEditCell] = useState("");

    useEffect(() => {
        setItem({...props.item});
        setEditCell("");
    }, [props.item]);

    useEffect(() => {
        setItem((prevState: ProductionItemType) => {
            return {...prevState, price: prevState.qty * prevState.singlePrice} as ProductionItemType;
        });
    }, [item.qty, item.singlePrice]);

    const productDialog = <ProductsDialog show={true} zIndex={props.zIndex || 2000} onClose={() => {
    }}/>

    const removeSelection = () => {
        setEditCell("");
    }

    function saveItem() {
        props.onSave(item);
    }

    return <tr>
        <td> {item.sku} </td>
        <td>
            <SelectValueFromDialog value={item.name} className={"mb-0"} size={"sm"}
                                   onChange={(product) => setItem({
                                       ...item,
                                       productId: product.id,
                                       sku: product.sku,
                                       name: product.name,
                                       singlePrice: product.price,
                                       qty: 1,
                                       dimension: product.dimens,
                                       price: product.price,
                                       size: {
                                           l: product._length || 0,
                                           h1: product.height1 || 0,
                                           h2: product.height2 || 0,
                                           b: product.width || 0,
                                       }
                                   })}
                                   dialog={productDialog}/>
        </td>
        <EditableTableCell inEditMode={editCell === "qty"}
                           className={"text-end w-120px"}
                           value={zeroToEmptyString(item.qty)}
                           isWorking={false}
                           onEdit={() => {
                               setEditCell("qty")
                           }}
                           onBlur={newValue => {
                               setItem(item => {
                                   return {...item, qty: textToFloat(newValue) || 0};
                               })
                               setEditCell("");
                           }}
                           onDone={removeSelection}
        />
        <EditableTableCell inEditMode={editCell === "singlePrice"}
                           className={"text-end w-120px"}
                           value={zeroToEmptyStringAsNumber(item.singlePrice)}
                           isWorking={false}
                           onEdit={() => {
                               setEditCell("singlePrice")
                           }}
                           onBlur={newValue => {
                               setItem({...item, singlePrice: textToFloat(newValue) || 0});
                               setEditCell("");
                           }}
                           onDone={removeSelection}
        />
        <RightAlignedCell> {zeroToEmptyStringAsNumber(item.price)} </RightAlignedCell>
        <RightAlignedCell>
            <PlusIconButton size={"sm"} onClick={saveItem} disabled={editCell !== ""}/>
        </RightAlignedCell>
    </tr>
}


export function ProductionDetails({zIndex, product, onDone}: Props) {
    const EMPTY_VIRTUAL_PRODUCT_ITEM: ProductionItemType = {
        warehouseId: null,
        name: "",
        productId: "",
        qty: 0,
        dimension: "",
        singlePrice: 0,
        price: 0,
        sku: "",
        size: {
            l: 0, b: 0, h1: 0, h2: 0
        }
    };
    const {canUserEdit} = useUserRights();
    const [saving, setSaving] = useState(false);
    const [doc, setDoc] = useState<ProductionType>({...product});
    const [newItem, setNewItem] = useState<ProductionItemType>(EMPTY_VIRTUAL_PRODUCT_ITEM);
    const warehouseItemUpdater = UseRecalcWarehouseItemStatus();

    const [stat] = useDocument(getDocRef(fbDb, "production", "statistics"))

    useEffect(() => {
        if (!product.number) {
            if (stat) {
                const current = stat.get("protocolNumber");
                const number = textToInt(current, 0)! + 1;
                setDoc({...doc, number: number!.toString().padStart(8, "0")})
            }
        } else {
            setDoc({...doc, number: product.number});
        }
    }, [stat, product.number]);

    const save = async () => {
        const errors: string[] = [];
        if (!doc.name) errors.push("Изберете продукт");
        if (!doc.number) errors.push("Въведете номер на протокола");
        if (!doc.sku) errors.push("Въведете SKU на продукта");
        if (!doc.items || doc.items.length === 0) errors.push("Добавете поне един материал за произвоството на този продукт");
        if (errors.length > 0) {
            showErrorsListInToast("Намерени проблеми", errors);
            return
        }

        setSaving(true);

        console.log(doc);

        try {
            const _items: ProductionItemsType = [];
            const _itemIds: string[] = [];
            doc.items?.forEach(
                i => {
                    _items.push(removeEmptyFields(i)!);
                    _itemIds.push(i.productId);
                }
            );
            let _data = removeEmptyFields({
                ...doc,
                items: _items,
                itemIds: _itemIds,
            });

            if (_data) {
                const warehouseItem: WarehouseItemType = {
                    productId: doc.productId || "",
                    isOwnProduction: true,
                    name: doc.name || "",
                    sku: doc.sku || "",
                    dimens: doc.dimension || "",
                    ownCompanyId: doc.ownCompanyId ?? "",
                    warehouseId: doc.warehouseId ?? "",
                    singlePrice: doc.price || 0,
                    usedQty: 0,
                    qty: _data?.qty || 1,
                    minQty: 0,
                    availableQty: _data?.qty || 1,
                    deliveryPrice: doc.price || 0,
                    transactionDate: doc.productionDate,
                    transactionId: doc.id,
                };

                const productIds = new Set<string>();
                const whOutItemsColl = collection(fbDb, "warehouseOutItems");

                await runTransaction(fbDb, async (transaction) => {
                    if (doc.ref) {
                        await transaction.update(doc.ref, {..._data});

                        const whInItemsColl = collection(fbDb, "warehouseInItems");
                        const whInItems = await getDocs(
                            query(whInItemsColl,
                                where("warehouseId", "==", DEFAULT_WAREHOUSE),
                                where("transactionId", "==", doc.id)
                            )
                        );
                        if (whInItems.empty) {
                            await addDoc(whInItemsColl, warehouseItem);
                        } else {
                            transaction.update(whInItems.docs[0].ref, {...warehouseItem});
                        }

                        const whOutItems = await getDocs(
                            query(whOutItemsColl,
                                where("warehouseId", "==", DEFAULT_WAREHOUSE),
                                where("transactionId", "==", doc.id)
                            )
                        );
                        if (!whOutItems.empty) {
                            whOutItems.forEach(
                                item => {
                                    transaction.delete(item.ref);
                                    productIds.add(item.get("productId"));
                                }
                            )
                        }
                    } else {
                        const newDoc = await addDoc(collection(fbDb, "production"), _data);
                        warehouseItem.transactionId = newDoc.id;
                        doc.id = newDoc.id;
                        await addDoc(
                            collection(fbDb, "warehouseInItems"),
                            warehouseItem
                        );
                    }

                    if (stat) {
                        if (stat.exists()) {
                            const current = textToInt(stat.get("protocolNumber") || "0")!;
                            const docNumber = textToInt(doc.number || "0")!;
                            if (docNumber > current) {
                                transaction.update(stat.ref, {protocolNumber: doc.number});
                            }
                        } else {
                            await setFBDoc(stat.ref, {protocolNumber: doc.number});
                        }
                    }
                });


                for (const item of doc.items) {
                    const whOutItem: WarehouseOutItemType = {
                        productId: item.productId,
                        isOwnProduction: false,
                        name: item.name,
                        sku: item.sku || "",
                        dimens: "",
                        qty: (item.qty || 1) * (doc.qty || 1),
                        singlePrice: item.singlePrice || 0,
                        price: item.price || 0,
                        ownCompanyId: "",
                        warehouseId: doc.warehouseId || DEFAULT_WAREHOUSE,
                        transactionId: doc.id!,
                        transactionDate: doc.productionDate || new Date(),
                    }

                    await addDoc(whOutItemsColl, whOutItem);
                    productIds.add(item.productId);
                }

                productIds.add(warehouseItem.productId);
                productIds.forEach(productId => warehouseItemUpdater("", DEFAULT_WAREHOUSE, productId, false, undefined));
                if (doc.productId) {
                    warehouseItemUpdater("", DEFAULT_WAREHOUSE, doc.productId, true, undefined);
                }
            }

            onDone();
        } catch (e: any) {
            showErrorsListInToast("Възникна грешка", [e.message]);
        } finally {
            setSaving(false);
        }
    }


    const productsDialog = <VirtualProductsDialog show={true} zIndex={zIndex || 1020} onClose={() => {
    }}/>

    // const warehousesDialog = <WarehouseObjectsDialog show={true} zIndex={zIndex || 1020} onClose={() => {
    // }}/>

    const tableHead = <thead>
    <tr>
        <th>SKU</th>
        <th>Наименование</th>
        <RightAlignedCell header>Количество</RightAlignedCell>
        <RightAlignedCell header>Ед.цена</RightAlignedCell>
        <RightAlignedCell header>Всичко</RightAlignedCell>
        <th></th>
    </tr>
    </thead>

    function removeItem(idx: number) {
        const newDoc = {...doc};
        newDoc.items.splice(idx, 1);
        setDoc(newDoc);
    }

    const tableBody = useMemo(() => {
        doc.price = 0;
        doc.items.forEach((d: any) => doc.price! += d?.price || 0);

        return doc.items.map(
            (i: ProductionItemType, idx: number) => <tr key={idx}>
                <td> {i.sku} </td>
                <td> {i.name} </td>
                <td> {zeroToEmptyString(i.qty)} </td>
                <td> {zeroToEmptyStringAsNumber(i.singlePrice)} </td>
                <td> {zeroToEmptyStringAsNumber(i.price)} </td>
                <RightAlignedCell>
                    <TrashIconButton onClick={() => removeItem(idx)} size={"sm"}/>
                </RightAlignedCell>
            </tr>
        );
    }, [doc]);

    return (
        <>
            <Form className={"p-2"}>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Row>
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={"auto"}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Фирма:</Form.Label>
                                                <OwnCompanyDropdown
                                                    selectedOwnCompanyId={doc.ownCompanyId}
                                                    onChange={(ownCompanyId) => setDoc({
                                                        ...doc,
                                                        ownCompanyId: ownCompanyId
                                                    })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={"auto"}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Склад:</Form.Label>
                                                <WarehouseObjectsDropdown
                                                    ownCompanyId={doc.ownCompanyId}
                                                    selectedWarehouseObjectId={doc.warehouseId}
                                                    onChange={
                                                        (warehouseId) => setDoc({...doc, warehouseId: warehouseId})
                                                    }/>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={"auto"}>
                                            <Form.Group className="mb-3 w-140px">
                                                <Form.Label>Протокол номер:</Form.Label>
                                                <Form.Control value={doc.number}
                                                              onChange={e => setDoc({...doc, number: e.target.value})}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3 w-140px">
                                                <Form.Label>Дата:</Form.Label>
                                                <ReactDatePicker
                                                    className={"form-control"} isClearable
                                                    dateFormat="dd.MM.yyyy"
                                                    onChange={(d) => {
                                                        setDoc({...doc, productionDate: d || new Date()})
                                                    }}
                                                    selected={doc.productionDate}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    {/*<SelectValueFromDialog label={"Към склад:"}*/}
                                    {/*                       value={doc.warehouseName}*/}
                                    {/*                       onChange={(warehouse: WarehouseObjectType) => setDoc({*/}
                                    {/*                           ...doc,*/}
                                    {/*                           warehouseId: warehouse?.id,*/}
                                    {/*                           warehouseName: warehouse?.name || "",*/}
                                    {/*                       })}*/}
                                    {/*                       dialog={warehousesDialog}*/}
                                    {/*/>*/}
                                    <Row>
                                        <Col xs={12}>
                                            <SelectValueFromDialog label={"Продукт:"}
                                                                   value={doc.name}
                                                                   onChange={(product: ProductionType) => setDoc({
                                                                       ...doc,
                                                                       productId: product?.id,
                                                                       productRef: product?.ref,
                                                                       sku: product?.sku || "",
                                                                       name: product?.name || "",
                                                                       dimension: product?.dimension || "",
                                                                   })}
                                                                   dialog={productsDialog}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Количество:</Form.Label>
                                                <StringInput value={doc.qty?.toString() || "1"}
                                                             onChange={e =>
                                                                 setDoc({...doc, qty: textToInt(e) || 1})}/>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>SKU:</Form.Label>
                                                <Form.Control type="text" value={doc.sku} disabled
                                                              onChange={e => setDoc({...doc, sku: e.target.value})}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group className="mb-3 w-">
                                                <Form.Label>Мерна единица:</Form.Label>
                                                <Form.Control type="text" value={doc.dimension} disabled
                                                              onChange={() => {
                                                              }}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Цена:</Form.Label>
                                                <Form.Control type="text" value={zeroToEmptyStringAsNumber(doc.price)}
                                                              disabled className={"text-end"}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className={"mt-3"}>
                        <Col xs={12}>
                            Включени материали:
                        </Col>
                        <Col xs={12}>
                            <SmallBorderedHooveredTable>
                                {tableHead}
                                <tbody>
                                {tableBody}
                                <EditableVirtualProductItemRow item={newItem}
                                                               onSave={item => {
                                                                   const items = [...doc.items];
                                                                   items.push(item);
                                                                   doc.items = items;
                                                                   setDoc({...doc});
                                                                   setNewItem({...EMPTY_VIRTUAL_PRODUCT_ITEM})
                                                               }}
                                                               onCancel={() => setNewItem({...EMPTY_VIRTUAL_PRODUCT_ITEM})}/>
                                </tbody>
                            </SmallBorderedHooveredTable>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Описание:</Form.Label>
                                <Form.Control as="textarea" rows={2}
                                              onChange={e => setDoc({...doc, description: e.target.value})}
                                              value={doc.description}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Row className={"w-100"}>
                        {
                            saving && <Col xs={12} className={"text-center"}>
                                <Spinner animation={"border"}/>
                            </Col>
                        }

                        {
                            !saving &&
                            <>
                                <Col xs={6}>
                                    <Button variant={"outline-secondary"} onClick={() => {
                                        onDone()
                                    }}>
                                        <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                    </Button>
                                </Col>
                                {
                                    canUserEdit('Склад-Собствено производство') &&
                                    <Col xs={6} className={"text-end"}>
                                        <Button onClick={save}>
                                            <FontAwesomeIcon icon={faSave}/> Запиши
                                        </Button>
                                    </Col>
                                }
                            </>
                        }
                    </Row>
                </Modal.Footer>
            </Form>
        </>
    )
}
