import {Text, View} from "@react-pdf/renderer";
import React from "react";
import {TableRow} from "../product-table/OfferProductPdfTableHeader";
import {StyleSheet} from "@react-pdf/renderer";
import {numberWithCommas, numberWithCommasToCurrency} from "../../../../../../../common";
import {getI18Text} from "../../../../../../../i18/i18label";

const styles = StyleSheet.create({
    col1H: {width: 1150, backgroundColor: "white", textAlign: "right", padding: 2, paddingRight: 22},
    col7H: {width: 180, backgroundColor: "white", textAlign: "right", padding: 2, paddingRight: 18},
});

interface OfferProductPdfTableTotalProps {
    total: number,
    euroExchangeRate: number,
    isExtraServices?: boolean
}

const OfferProductPdfTableTotal = ({total, euroExchangeRate, isExtraServices}: OfferProductPdfTableTotalProps) => {

    return (
        <View style={{fontWeight: "bold"}}>
            <TableRow cols={[
                <View style={styles.col1H}>
                    <Text>{getI18Text("Общо с допълнителни разходи" + (isExtraServices ? " и услуги" : ""))}:</Text>
                </View>,
                <View style={styles.col7H}>
                    <Text>{numberWithCommasToCurrency(total * euroExchangeRate)}</Text>
                </View>
            ]}/>
        </View>
    );
}

export default OfferProductPdfTableTotal;
