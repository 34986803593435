import {useEffect, useState} from "react";

import {DocumentData, updateDoc, addDoc, collection, getFirestore, doc} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner, Tab, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb, firebaseApp, functions} from "../../../App";
import moment from "moment";
import {httpsCallable} from "firebase/functions";
import SelectValueFromDialog from "../../../common/SelectValueFromDialog";
import {PositionsDialog} from "../positions/PositionsDialog";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {UserRightConverter} from "../../../fb-converters/user-right-converter";
import {HeaderComponent} from "../../../common/HeaderComponent";


export interface ClientDetailsProps {
    zIndex?: number;
    document: DocumentData | null;
    onDone: () => any;
}

export function UserDetails({zIndex, document, onDone}: ClientDetailsProps) {
    const [saving, setSaving] = useState(false);
    const [role, setRole] = useState(document?.data().role);
    const [position, setPosition] = useState<any>(null);

    const [userRights, loadingRights, errorRights] = useCollection(
        collection(fbDb, 'appModules').withConverter(UserRightConverter)
    )

    const userRedux = async function (data: any) {
        const userReduxFn = httpsCallable(functions, 'userReduxService');
        await userReduxFn(data)
    }

    const [positionValue, loading, error] = useDocument(
        doc(fbDb, 'positions', document?.data().position || "not-set")
    );

    useEffect(() => {
        setPosition(positionValue);
    }, [positionValue]);

    const save = async (data: any) => {
        setSaving(true);

        if (document) {
            data.email = document?.data().email;
            if (!data.password) data.password = null;
            await updateDoc(document.ref, data);

            let userData: Object;
            if (data.password) {
                userData = {email: data.email, password: data.password};
            } else {
                userData = {
                    email: data.email,
                }
            }
            await userRedux({
                action: "update",
                email: document?.data().email,
                userData: userData,
            })
        } else {
            await addDoc(collection(fbDb, "users"), data);
            userRedux({
                action: 'create',
                userData: {
                    email: data.email,
                    password: data.password,
                }
            })
        }
        setSaving(false);
        onDone();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const activeTo = moment(); //moment(event.target.elements.address.value || '', 'DD.MM.YYYY');

        const formData = new FormData(event.target);

        const canView = formData.getAll('canView');
        const canEdit = formData.getAll('canEdit');

        const data: any = {
            name: event.target.elements.name.value || '',
            activeTo: activeTo.isValid() ? activeTo.toDate() : null,
            email: event.target.elements.email.value || '',
            // email_server: event.target.elements.email_server.value || '',
            // email_username: event.target.elements.email_username.value || '',
            // email_password: event.target.elements.email_password.value || '',
            password: event.target.elements.password.value || '',
            position: position?.id || null,
            phone: event.target.elements.phone.value || '',
            role: role || '',
            isAdmin: role === 'Администратор',
            isManager: role === 'Мениджър',
            isSalesRepresentative: role === 'Търговски представител',
            isServiceWorker: role === 'Сервизен работник',
            language: event.target.elements.language.value || 'bg',
            canEdit,
            canView
        };
        save(data);
    }

    const positionsDialog = <PositionsDialog show={true} zIndex={zIndex || 1010} onClose={() => {
    }}/>

    const getPositionName = () => {
        const d = position?.data();
        return d?.name || '';
    }

    return (
        <><Form className={"p-2"} onSubmit={handleSubmit}>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    {document ? "Корекция" : "Добавяне"} на потребител
                </h5>

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Име</Form.Label>
                            <Form.Control type="text" name="name" defaultValue={document?.data().name}/>
                        </Form.Group>
                    </Col>
                    <Col xs={5}>
                        <Form.Group className="mb-3">
                            <Form.Label>Роля</Form.Label>
                            <select className={"form-select"} value={role} onChange={e => setRole(e.target.value)}>
                                <option></option>
                                <option>Администратор</option>
                                <option>Мениджър</option>
                                <option>Търговски представител</option>
                                <option>Сервизен работник</option>
                            </select>
                        </Form.Group>
                    </Col>
                    <Col xs={1}>
                        <Form.Group className="mb-3">
                            <Form.Label>Език</Form.Label>
                            <select className={"form-select"} name="language" defaultValue={document?.data().language}>
                                <option value="bg">BG</option>
                                <option value="ro">RO</option>
                            </select>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Телефон</Form.Label>
                            <Form.Control type="text" name="phone" defaultValue={document?.data().phone}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Е-мейл</Form.Label>
                            <Form.Control type="text" name="email" defaultValue={document?.data().email}
                                          disabled={document?.data().email}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <SelectValueFromDialog label={"Позиция"}
                                                   value={getPositionName()}
                                                   onChange={(position) => setPosition(position)}
                                                   dialog={positionsDialog}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Парола</Form.Label>
                            <Form.Control type="password" name="password"/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <b>Права за достъп до системата</b>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Tabs>
                            {
                                userRights?.docs
                                    .filter(doc => doc.data().group === "main")
                                    .map((doc) => {
                                        const right = doc.data();
                                        return <Tab key={doc.id} eventKey={doc.id} title={right.item}>
                                            <Row>
                                                <Col className={"m-2 border rounded shadow"}>
                                                    <Row>
                                                        <Col xs={12} className={"bg-body-secondary text-center p-2"}>
                                                            <b>Възможности за преглед</b>
                                                        </Col>
                                                        <Col xs={"4"} className={"p-2"}>
                                                            <Form.Check
                                                                key={doc.id}
                                                                type={"checkbox"}
                                                                label={right.item}
                                                                defaultChecked={document?.data().canView?.includes(right.item)}
                                                                name={"canView"}
                                                                value={right.item}
                                                            /></Col>
                                                        {
                                                            userRights?.docs
                                                                .filter(
                                                                    doc => doc.data().group === right.item
                                                                ).map(
                                                                (doc) => {
                                                                    const right1 = doc.data();
                                                                    return <Col key={right1.id} xs={"4"} className={"p-2"}>
                                                                        <Form.Check
                                                                            key={doc.id}
                                                                            type={"checkbox"}
                                                                            label={right1.item}
                                                                            defaultChecked={document?.data().canView?.includes(right1.group+"-"+right1.item)}
                                                                            name={"canView"}
                                                                            value={right1.group+"-"+right1.item}
                                                                        /></Col>
                                                                }
                                                            )
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col className={"m-2 border rounded shadow"}>
                                                    <Row>
                                                        <Col xs={12} className={"bg-body-secondary text-center p-2"}>
                                                            <b>Възможности за редакция</b>
                                                        </Col>
                                                        <Col key={right.id} xs={4} className={"p-2"}>
                                                            <Form.Check
                                                                key={doc.id}
                                                                type={"checkbox"}
                                                                label={right.item}
                                                                defaultChecked={document?.data().canEdit?.includes(right.item)}
                                                                name={"canEdit"}
                                                                value={right.item}
                                                            /></Col>
                                                        {
                                                            userRights?.docs
                                                                .filter(
                                                                    doc => doc.data().group === right.item
                                                                ).map(
                                                                (doc) => {
                                                                    const right1 = doc.data();
                                                                    return <Col key={right1.id} xs={4} className={"p-2"}>
                                                                        <Form.Check
                                                                            key={doc.id}
                                                                            type={"checkbox"}
                                                                            label={right1.item}
                                                                            defaultChecked={document?.data().canEdit?.includes(right1.group+"-"+right1.item)}
                                                                            name={"canEdit"}
                                                                            value={right1.group+"-"+right1.item}
                                                                        /></Col>
                                                                }
                                                            )
                                                        }
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    })
                            }
                        </Tabs>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        saving && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !saving &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button type={"submit"}>
                                    <FontAwesomeIcon icon={faSave}/> Запиши
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </Form>
        </>
    )
}
