import {ExpeditionType} from "../../../fb-converters/expedition-converter";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import CenterAlignedCell from "../../../common/CenterAlignedCell";
import {dateToString, showErrorsListInToast, zeroToEmptyString} from "../../../common";
import RightAlignedCell from "../../../common/RightAlignedCell";
import React, {FC, useState} from "react";
import {addDoc, collection, deleteDoc, doc, FirestoreError, getDoc, updateDoc} from "firebase/firestore";
import {fbDb} from "../../../App";
import DeleteConfirmIconButton from "../../../common/icon-buttons/DeleteConfirmIconButton";
import EditIconButton from "../../../common/icon-buttons/EditIconButton";
import ExpeditionDetails from "./ExpeditionDetails";
import {productionCardConverter, ProductionCardExpeditionType, ProductionCardType} from "../types/ProductionCardType";
import useExpedition from "./useExpedition";
import CheckIconButton from "../../../common/icon-buttons/CheckIconButton";
import useUserRights from "../../../hooks/useUserRights";

type ExpeditionItemProps = {
    expedition: ExpeditionType,
    isSelected: boolean,
    onSelect: () => void
}

const ExpeditionItem: FC<ExpeditionItemProps> = ({expedition, isSelected, onSelect}) => {
    const {canUserEdit} = useUserRights();
    const [editing, setEditing] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const {saveExpedition, saving, errors, setExpeditionStatus} = useExpedition();

    function onEdit() {
        setEditing(true);
    }

    async function onDelete() {
        setDeleting(true);
        // delete

        try {
            await deleteDoc(doc(fbDb, "expedition", expedition.id!));
            if (expedition.cardId) {
                const card = await getDoc(doc(fbDb, "productionCards", expedition.cardId)).then(s => s.data() as ProductionCardType);
                const cardExpeditions = card.expeditions ?? [];
                await updateDoc(
                    doc(fbDb, "productionCards", expedition.cardId),
                    {
                        planExpeditionDate: null,
                        expeditions: cardExpeditions.filter(e => e.expeditionId !== expedition.id)
                    }
                );
            }
            setDeleting(false);
        } catch (e) {
            const error = e as FirestoreError;
            showErrorsListInToast("Грешка при изтриване на експедиция", [error.message]);
            setDeleting(false);
        }
    }

    async function doSaveExpedition(expedition: ExpeditionType) {
        saveExpedition(expedition).then(() => {
            if (errors.length === 0) {
                setEditing(false);
            }
        });
    }

    return (
        <>
            <tr key={expedition.id}>
                <td>
                    {
                        deleting ? <Spinner animation={"border"} size={"sm"}/> :
                            <>
                                {canUserEdit("Производство и експедиция-Дневен план за експедиция") &&
                                    <CheckIconButton onClick={onSelect} size={"sm"}
                                                     variant={isSelected ? "primary" : "secondary"}/>
                                }&nbsp;
                                <EditIconButton onClick={onEdit}/>&nbsp;
                                {canUserEdit("Производство и експедиция-Дневен план за експедиция") &&
                                    <DeleteConfirmIconButton onClick={onDelete} size={"sm"}/>
                                }
                            </>
                    }
                </td>
                <td colSpan={4}></td>
                <CenterAlignedCell>{expedition.inquiry?.inquiryNumber}</CenterAlignedCell>
                <td>{expedition.client?.name}</td>
                <td>{expedition.client?.region}</td>
                <td>{expedition.client?.address}</td>
                <td>{expedition.client?.contactPerson}</td>
                <td>{expedition.client?.phone}</td>
                <td>{expedition.notes}</td>
                <RightAlignedCell>{zeroToEmptyString(expedition.productQty)}</RightAlignedCell>
                <RightAlignedCell>{zeroToEmptyString(expedition.weights?.leaving?.parcels)}</RightAlignedCell>
                <RightAlignedCell>{zeroToEmptyString(expedition.weights?.leaving?.parcels)}</RightAlignedCell>
                {/*<RightAlignedCell className={"fw-bold"}>{zeroToEmptyString(*/}
                {/*    (expedition.weights?.leaving?.parcels ?? 0) +*/}
                {/*    (expedition.weights?.leaving?.pallets ?? 0)*/}
                {/*)}</RightAlignedCell>*/}

                <RightAlignedCell>{zeroToEmptyString(expedition.weights?.arriving?.scrap)}</RightAlignedCell>
                <RightAlignedCell>{zeroToEmptyString(expedition.weights?.arriving?.delivery)}</RightAlignedCell>
                <RightAlignedCell className={"fw-bold"}>{zeroToEmptyString(
                    (expedition.weights?.arriving?.scrap ?? 0) +
                    (expedition.weights?.arriving?.delivery ?? 0)
                )}</RightAlignedCell>

                <RightAlignedCell>{zeroToEmptyString(expedition.palets?.leaving)}</RightAlignedCell>
                <RightAlignedCell>{zeroToEmptyString(expedition.palets?.arriving)}</RightAlignedCell>
                <CenterAlignedCell>
                    {saving ? <Spinner animation={"border"} size={"sm"}/> :
                        <Form.Control
                            as={"select"}
                            disabled={!canUserEdit("Производство и експедиция-Дневен план за експедиция")}
                            value={expedition.status ?? undefined}
                            onChange={(e) => {
                                setExpeditionStatus(expedition.id!, e.target.value);
                            }}
                        >
                            <option value={"ПЛАНИРАНА"}>ПЛАНИРАНА</option>
                            <option value={"ПРИКЛЮЧЕНА"}>ПРИКЛЮЧЕНА</option>
                        </Form.Control>
                    }
                </CenterAlignedCell>
            </tr>
            {editing &&
                <ExpeditionDetails show={true}
                                   saving={saving}
                                   zIndex={1050}
                                   expeditionToEdit={expedition}
                                   onDone={doSaveExpedition}
                                   onCancel={() => setEditing(false)}
                />
            }
        </>
    );
}

export default ExpeditionItem;
