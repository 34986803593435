import {FC} from "react";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";
import {Form, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import useOwnCompaniesRepo from "../../warehouse/hooks/useOwnCompaniesRepo";

interface OwnCompanySelectorProps {
    idPrefix: string;
    selectedOwnCompaniesId: string[];
    onChange: (selectedOwnCompaniesId: string[]) => void;
}

const OwnCompanySelector: FC<OwnCompanySelectorProps> = ({idPrefix, selectedOwnCompaniesId, onChange}) => {
    const db = useHookstate(appDb);
    const ownCompanies = db.ownCompanies.value;

    return (
        <ToggleButtonGroup type="checkbox" value={selectedOwnCompaniesId}
                           onChange={v => {
                               if (v) {
                                   onChange(v);
                               } else {
                                   onChange([ownCompanies[0].id ?? ""]);
                               }
                           }}
        >
            {
                ownCompanies.map(company => (
                    <ToggleButton key={company.id} id={`${idPrefix}-tbg-btn-${company.id}`} value={company.id ?? ""}>
                        {company.name ?? ""}
                    </ToggleButton>
                ))
            }
        </ToggleButtonGroup>
    )
}


interface OwnCompanyDropdownProps {
    selectedOwnCompanyId: string | undefined,
    onChange: (selectedOwnCompanyId: string) => void,
    readOnly?: boolean
}

export const OwnCompanyDropdown: FC<OwnCompanyDropdownProps> = ({selectedOwnCompanyId, onChange, readOnly}) => {
    const {ownCompanies} = useOwnCompaniesRepo();

    return (
        <Form.Select value={selectedOwnCompanyId}
                        disabled={readOnly}
                     onChange={(e) => onChange(e.target.value)}>
            {
                ownCompanies.map(company => (
                    <option key={company.id} value={company.id}>
                        {company.name ?? ""}
                    </option>
                ))
            }
        </Form.Select>
    )
}

export default OwnCompanySelector;
