import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    updateDoc,
    where
} from "firebase/firestore";
import {fbDb} from "../../../App";
import {WarehouseItemType} from "./useWarehouseInItems";
import {convertProduct, ProductType} from "../../nomenclatures/products/hooks/useProductsOnce";
import {warehouseRevisionItemConverter} from "../../../fb-converters/warehouse-revision-converter";
import {dateWithZeroTime} from "../../../common";

export default function UseRecalcWarehouseItemStatus() {
    const recalcWarehouseItemQty = async (
        ownCompanyId: string = "", warehouseId: string, productId: string,
        isOwnProduction: boolean, product: ProductType | undefined) => {

        return;

        // const lastProductRevisionData = await getDocs(
        //     query(
        //         collection(fbDb, "warehouseRevisions"),
        //         where("warehouseId", "==", warehouseId),
        //         where("productIds", "array-contains", productId),
        //         orderBy("revisionDate", "desc"),
        //         limit(1)
        //     ).withConverter(warehouseRevisionItemConverter)
        // );
        // const lastProductsRevision = lastProductRevisionData.docs.map(doc => doc.data());
        //
        // let productRevisionActualQty = 0;
        // let productRevisionPrice = 0;
        // let lastRevisionDate = null;
        //
        // if (lastProductsRevision.length > 0) {
        //     productRevisionActualQty = lastProductsRevision[0].items.find(item => item.productId === productId)?.actualQty || 0;
        //     productRevisionPrice = lastProductsRevision[0].items.find(item => item.productId === productId)?.productSinglePrice || 0;
        //     lastRevisionDate = dateWithZeroTime(lastProductsRevision[0].revisionDate);
        // }
        //
        // const whereClause = [
        //     where("warehouseId", "==", warehouseId),
        //     where("productId", "==", productId),
        //     where("isOwnProduction", "==", isOwnProduction)
        // ];
        // if (lastRevisionDate) {
        //     whereClause.push(where("transactionDate", ">", lastRevisionDate));
        // }
        // const inData = await getDocs(query(
        //     collection(fbDb, "warehouseInItems"),
        //     ...whereClause
        // ));
        //
        // const outData = await getDocs(query(
        //     collection(fbDb, "warehouseOutItems"),
        //     ...whereClause
        // ));
        //
        // const itemCollection = collection(fbDb, "warehouseItems");
        // const itemDataRef = query(itemCollection,
        //     where("warehouseId", "==", warehouseId),
        //     where("productId", "==", productId),
        //     where("isOwnProduction", "==", isOwnProduction)
        // );
        // const itemData = await getDocs(itemDataRef);
        //
        // let productData = product;
        // if (!productData) {
        //     const productDataSnap = await getDoc(doc(fbDb, "products", productId));
        //     if (productDataSnap.exists()) {
        //         productData = convertProduct(productDataSnap) || undefined;
        //     }
        // }
        // if (!productData) return;
        //
        // let qty = productRevisionActualQty;
        // let price = productRevisionPrice * productRevisionActualQty;
        // let priceQty = 0;
        // let used = 0;
        // let itemCnt = 0;
        // let currentPrice = 0;
        //
        // inData.docs.forEach(
        //     value => {
        //         const itemQty = value.get("qty") || 0;
        //         const itemPrice = value.get("singlePrice") || 0;
        //
        //         if (itemQty > 0) {
        //             qty += itemQty;
        //             if (itemPrice > 0) {
        //                 price += (itemPrice * itemQty);
        //                 priceQty += itemQty;
        //             }
        //             itemCnt += 1;
        //         }
        //     }
        // );
        //
        // currentPrice = priceQty === 0 ? price : price / priceQty;
        //
        // outData.docs.forEach(
        //     value => {
        //         const itemQty = value.get("qty") || 0;
        //
        //         if (itemQty > 0) {
        //             used += itemQty;
        //         }
        //     }
        // );
        //
        // if (itemData.empty) {
        //     const newItem: WarehouseItemType = {
        //         qty: qty,
        //         usedQty: used,
        //         availableQty: qty - used,
        //         sku: productData.sku || "",
        //         dimens: productData.dimens || "",
        //         name: productData.name || "",
        //         ownCompanyId: ownCompanyId || "",
        //         warehouseId: warehouseId || "",
        //         productId: productId || "",
        //         isOwnProduction: isOwnProduction || false,
        //         singlePrice: currentPrice || 0,
        //     };
        //
        //     addDoc(itemCollection, newItem);
        // } else {
        //     const item = itemData.docs[0];
        //     updateDoc(item.ref, {
        //         qty: qty,
        //         usedQty: used,
        //         availableQty: qty - used,
        //         singlePrice: currentPrice,
        //     });
        // }
    }

    return recalcWarehouseItemQty;
}
