import {useDocument} from "react-firebase-hooks/firestore";
import {collection, doc} from "firebase/firestore";
import {fbDb} from "../App";
import {useEffect} from "react";

const AppVersionControl = () => {
    const currentVersion = "V.2.04 - 25.03.2025";

    const[version] = useDocument(
        doc(
            collection(fbDb, "app-version"),
            "version"
        )
    );

    useEffect(() => {
        if(version) {
            const dbVersion = version.data()?.currentVersion;
            const isImportant = version.data()?.isImportant;

            if(dbVersion && dbVersion !== currentVersion) {
                if(isImportant) {
                    alert("Има нова КРИТИЧНА версия на приложението. Страницата ще бъде обновена автоматично. Новата версия е: " + dbVersion);
                    window.location.reload();
                } else {
                    alert("Има нова версия на приложението. Моля обновете страницата. Новата версия е: " + dbVersion);
                }
            }
        }
    }, [version]);



    return <span>{currentVersion}</span>;
}

export default AppVersionControl;
