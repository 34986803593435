import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import {LayoutComponent} from './common/LayoutComponent';
import 'react-toastify/dist/ReactToastify.css';
import {API} from "./api";
import {Account} from "./types";

import {registerLocale, setDefaultLocale} from "react-datepicker";
import bg from 'date-fns/locale/bg';
import HomePage from "./pages/homepage/HomePage";
import {LoginPage} from "./pages/LoginPage";
import "react-datepicker/dist/react-datepicker.css";

import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {useAuthState} from "react-firebase-hooks/auth";
import {getStorage} from "firebase/storage";
import {getAuth} from 'firebase/auth';
import {getFunctions} from "firebase/functions";
import {Container} from "react-bootstrap";
import {
    useCollection, useCollectionData,
} from "react-firebase-hooks/firestore";
import {collection, doc, getDoc, getFirestore} from "firebase/firestore";
import CrmPage from "./pages/crm/CrmPage";
import ImportDataPage from "./pages/import-data/ImportDataPage";
import WarehousePage from "./pages/warehouse/warehouse/WarehousePage";
import {useHookstate} from "@hookstate/core";
import GlobalStateListener from "./global-state/firebase-listeners/GlobalStateListener";
import CrmReportActivitiesPage from "./pages/crm/reports/activities/CrmReportActivitiesPage";
import CustomersInqueriesPage from "./pages/crm/customers-inquiries/CustomersInquiriesPage";
import appDb from "./global-state/global-db";
import useVirtualProducts from "./pages/nomenclatures/virtual-products/hooks/useVirtualProducts";
import useProducts from "./pages/nomenclatures/products/hooks/useProducts";
import useClients from "./pages/nomenclatures/clients/hooks/useClients";
import useClientsLocations from "./pages/nomenclatures/clients/hooks/useClientLocations";
import CustomersInvoicesPage from "./pages/crm/customers-invoices/CustomersInvoicesPage";
import WarehouseReportsPage from "./pages/warehouse/warehouse-reports/WarehouseReportsPage";
import ProductionPlan from "./pages/production/production-plan/ProductionPlan";
import WarehouseInOutPage from "./pages/warehouse/warehouse-transactions/WarehouseInOutPage";
import ExpeditionPlan from "./pages/production/expedition-plan/ExpeditionPlan";
import WarehouseRevisionsPage from "./pages/warehouse/warehouse-revisions/WarehouseRevisionsPage";
import CRMSummaryReportPage from "./pages/crm/reports/summary/CRMSummaryReportPage";
import i18Converter from "./fb-converters/i18-converter";
import useServices from "./pages/nomenclatures/services/useServices";
import useOwnCompanies from "./pages/warehouse/hooks/useOwnCompanies";
import useWarehouseObjects from "./pages/warehouse/hooks/useWarehouseObjects";
import {UserType} from "./fb-converters/users-converter";

const firebaseConfig = {
    apiKey: "AIzaSyBugMutlURvPm4Ufh4d9ywcnHqw2nxTMYM",
    authDomain: "industrial-batteries-e516d.firebaseapp.com",
    projectId: "industrial-batteries-e516d",
    storageBucket: "industrial-batteries-e516d.appspot.com",
    messagingSenderId: "939247964755",
    appId: "1:939247964755:web:3ffe7fb2dabb49f908eca6",
    measurementId: "G-0FZ96981TL"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const auth = getAuth();
export const functions = getFunctions();
export const fbDb = getFirestore(firebaseApp);
export const fStorage = getStorage(firebaseApp);


registerLocale('bg', bg);
setDefaultLocale('bg');

const App = () => {
    const [user, loading, error] = useAuthState(auth);
    const [allUsers, loadingAll, errorAll] = useCollection(collection(fbDb, 'users'));
    const db = useHookstate(appDb);
    const [apiUser, setApiUser] = useState<Account | null>(null);

    const [virtualProducts, loadingVirtualProducts, errorVirtualProducts] = useVirtualProducts();
    const [products, loadingProducts, errorProducts] = useProducts();
    const [clients, loadingClients, errorClients] = useClients();
    const [clientsLocations, loadingLocations, errorLocations] = useClientsLocations();
    const [services, loadingServices, errorServices] = useServices();

    const [i18Data, loadingI18Data, errorI18Data] = useCollectionData(collection(fbDb, 'i18').withConverter(i18Converter));
    const {ownCompanies, loadingOwnCompanies, errorOwnCompanies} = useOwnCompanies();
    const [warehouses, loadingWarehouses, errorWarehouses] = useWarehouseObjects();

    useEffect(() => {
        db.virtualProducts.set(virtualProducts);
    }, [virtualProducts]);

    useEffect(() => {
        db.products.set(products);
    }, [products]);

    useEffect(() => {
        db.services.set(services);
    }, [services]);

    useEffect(() => {
        db.clients.set(clients);
    }, [clients]);

    useEffect(() => {
        db.clientsLocations.set(clientsLocations);
    }, [clientsLocations]);

    useEffect(() => {
        db.i18.set(i18Data ?? []);
    }, [i18Data]);

    useEffect(() => {
        db.ownCompanies.set(ownCompanies ?? []);
    }, [ownCompanies]);

    useEffect(() => {
        db.warehouses.set(warehouses ?? []);
    }, [warehouses]);

    useEffect(() => {
        if (auth && auth.currentUser && allUsers) {
            allUsers.forEach(
                async u => {
                    const data = u.data() as UserType;
                    if (data.email === auth.currentUser?.email) {
                        const positionQuery = doc(fbDb, 'positions', data.position ?? "");
                        const positionSnapshot = await getDoc(positionQuery);

                        const user = {
                            id: u.id,
                            name: data.name,
                            email: data.email,
                            position: data.position,
                            positionName: (positionSnapshot.exists() ? positionSnapshot.data().name : ""),
                            phone: data.phone ?? "",
                            isAdmin: data.isAdmin,
                            isManager: data.isManager,
                            isSalesRepresentative: data.isSalesRepresentative,
                            isServiceWorker: data.serviceWorker,
                            activeTo: data.activeTo,
                            language: data.language ?? "bg",
                            canEdit: data.canEdit ?? [],
                            canView: data.canView ?? []
                        } as Account;

                        setApiUser(user);
                    }
                }
            )
        } else {
            API.user = null;
        }
    }, [auth, auth.currentUser, allUsers]);

    API.user = apiUser;
    db.language.set(apiUser?.language ?? "bg");

    if (loading || loadingAll || loadingProducts || loadingVirtualProducts ||
        loadingClients || loadingLocations || loadingI18Data || loadingServices ||
        loadingOwnCompanies || loadingWarehouses) {
        return <>
            <Container className={"bg-dark text-light text-center p-3"}>
                Моля, изчакайте...
            </Container>
        </>
    }

    if (errorProducts || errorVirtualProducts || errorClients || errorLocations ||
        errorI18Data || errorServices || errorOwnCompanies || errorWarehouses) {
        if (auth && auth.currentUser) {
            return <>
                <Container className={"bg-danger text-dark text-center p-3"}>
                    За съжаление възникна грешка при първоначалното зареждане. Системата не може да продължи...
                </Container>
            </>
        }
    }

    return (
        <>
            <Router>
                {
                    !user && <LayoutComponent><LoginPage/></LayoutComponent>
                }

                <GlobalStateListener/>
                <LayoutComponent>
                    <Switch>
                        <Route exact path={"/"}><HomePage/></Route>
                        <Route path={"/crm/reports/activities"}><CrmReportActivitiesPage/></Route>
                        <Route path={"/crm/reports/summary"}><CRMSummaryReportPage/></Route>
                        <Route path={"/crm/customers-inquiries"}><CustomersInqueriesPage/></Route>
                        <Route path={"/crm/customers-invoices"}><CustomersInvoicesPage openMode={"list"}/></Route>
                        <Route path={"/crm"}><CrmPage/></Route>
                        <Route path={"/warehouses/requests"}><WarehouseInOutPage mode={"request"}/></Route>
                        <Route path={"/warehouses/in-transactions"}><WarehouseInOutPage mode={"in"}/></Route>
                        <Route path={"/warehouses/out-transactions"}><WarehouseInOutPage mode={"out"}/></Route>
                        <Route path={"/warehouses/reports"}><WarehouseReportsPage/></Route>
                        <Route path={"/warehouses"}><WarehousePage/></Route>
                        <Route path={"/warehouses-revisions"}><WarehouseRevisionsPage/></Route>
                        <Route path={"/import-data"}><ImportDataPage/></Route>
                        <Route path={"/production/plan"}><ProductionPlan/></Route>
                        <Route path={"/expedition/plan"}><ExpeditionPlan/></Route>
                    </Switch>
                </LayoutComponent>
            </Router>
        </>
    );
}

export default App;

