import React, {FC, useEffect, useMemo, useState} from "react";
import {Col, Container, Form, FormGroup, Row, Spinner, Tab, Tabs} from "react-bootstrap";
import {dateTimeToString, dateToString} from "../../../../common";
import CustomerOfferDetailsProducts from "../CustomerOfferDetailsProducts";
import CustomerOfferDetailsAttributes from "../CustomerOfferDetailsAttributes";
import CustomerOfferDetailsServices from "../CustomerOfferDetailsServices";
import CustomerOfferDetailsSummary from "../CustomerOfferDetailsSummary";
import {CustomerOfferType} from "../../../../fb-converters/cutomerOffer-converter";
import {CustomerInquiryType} from "../../../../fb-converters/cutomerInquiry-converter";
import I18Label, {getProductI18Name} from "../../../../i18/i18label";
import {
    BGN_CURRENCY_NAME,
    CurrencyNameType,
    EUR_CURRENCY_NAME,
    LEU_CURRENCY_NAME, useCurrency
} from "../../../../fb-converters/currency-converter";

interface Props {
    zIndex?: number;
    inquiry: CustomerInquiryType
    offer: CustomerOfferType;
    onChange?: (newOffer: CustomerOfferType) => void;
}

const CustomerOfferDetailsTabOffer: FC<Props> = (props) => {
    const [offer, setOffer] = useState<CustomerOfferType>(props.offer);
    const isPrevew = useMemo(() => !props.onChange, [props.onChange]);

    useEffect(() => props.onChange && props.onChange(offer), [offer]);

    const {getCurrency, currencyRates, loadingRates} = useCurrency(offer.offerDate);
    const LEU_RATE = useMemo(() => {
        return getCurrency(LEU_CURRENCY_NAME);
    }, [offer.offerDate, currencyRates]);
    useMemo(() => {
        switch (offer.currency) {
            case EUR_CURRENCY_NAME:
                return 1;
            case BGN_CURRENCY_NAME:
                return 1 / 1.95583;
            case LEU_CURRENCY_NAME:
                return LEU_RATE.rate;
        }
        return 1;
    }, [offer.currency]);

    useEffect(() => {
        let newEurExchangeRate = 1;
        switch (offer.currency) {
            case BGN_CURRENCY_NAME:
                newEurExchangeRate = 1.95583;
                break;
            case LEU_CURRENCY_NAME:
                newEurExchangeRate = LEU_RATE.rate;
                break;
        }
        setOffer(prevState => {
            const _offer = {...prevState};
            _offer.eurExchangeRate = newEurExchangeRate;
            return _offer;
        });
    }, [offer.currency, currencyRates]);

    return (
        <>
            <h4 className={"mb-3 fw-bold"}>
                {
                    props.onChange ? <>
                        {offer.ref ? <I18Label label={"Корекция на оферта"} />: <I18Label label={"Добавяне на оферта"} /> }
                    </> : <I18Label label={"Преглед на оферта"} />
                }

            </h4>

            <Row className={"mb-3"}>
                <Col xs={"auto"}>
                    <FormGroup>
                        <Form.Label><I18Label label={"Номер"} />:</Form.Label>
                        <Form.Control disabled
                                      defaultValue={`${props.inquiry.inquiryNumber} - ${offer.offerNumber || "x"}`}/>
                    </FormGroup>
                </Col>
                <Col xs={"auto"}>
                    <FormGroup>
                        <Form.Label><I18Label label={"Дата"} />:</Form.Label>
                        <Form.Control disabled defaultValue={dateToString(offer.offerDate)}/>
                    </FormGroup>
                </Col>
                <Col xs={"auto"}>
                    <Form.Label><I18Label label={"Валутен курс"} />:</Form.Label>
                    <Form.Select value={offer.currency}
                                 disabled={isPrevew}
                                    onChange={e => setOffer({...offer, currency: e.target.value as CurrencyNameType})}
                    >
                        <option value={BGN_CURRENCY_NAME}>{BGN_CURRENCY_NAME} (1.95583)</option>
                        <option value={EUR_CURRENCY_NAME}>{EUR_CURRENCY_NAME} (1.00000)</option>
                        <option value={LEU_CURRENCY_NAME}>{LEU_CURRENCY_NAME} ({LEU_RATE.rate.toFixed(5)})</option>
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Label><I18Label label={"Начин на плащане"} />:</Form.Label>
                    <Form.Control
                        value={offer.paymentMethod || ""}
                        disabled={isPrevew}
                        onChange={e => setOffer({...offer, paymentMethod: e.target.value})}
                    />
                </Col>
                <Col>
                    <Form.Label><I18Label label={"Срок на валидност"} />:</Form.Label>
                    <Form.Control
                        value={offer.validityPeriod || ""}
                        disabled={isPrevew}
                        onChange={e => setOffer({...offer, validityPeriod: e.target.value})}
                    />
                </Col>
                <Col>
                    <Form.Label><I18Label label={"Автор"} />:</Form.Label>
                    <Form.Control disabled value={offer.authorName || ""}/>
                </Col>
                <Col>
                    <Form.Label><I18Label label={"От дата:"} />:</Form.Label>
                    <Form.Control disabled value={dateTimeToString(offer.createdAt)}/>
                </Col>
            </Row>
            <Row className={"mb-5"}>
                <Col>
                    <Form.Label><I18Label label={"Забележки"} />:</Form.Label>
                    <Form.Control
                        as={"textarea"}
                        value={offer.comment || ""}
                        disabled={isPrevew}
                        onChange={e => setOffer({...offer, comment: e.target.value})}
                    />
                </Col>
            </Row>


            <CustomerOfferDetailsProducts
                disabled={isPrevew}
                zIndex={props.zIndex}
                offer={offer}
                setOffer={newOffer => setOffer(newOffer)}
                discount_price={props.inquiry.client?.discount_price || 0}
                bestProductPriceIdx={offer.bestProductPriceIdx}
                setBestProductPriceIdx={idx => setOffer({...offer, bestProductPriceIdx: idx})}
            />


            <Tabs defaultActiveKey={0}>
                {
                    offer.products.map(
                        (p, idx) =>
                            p && <Tab key={idx} title={getProductI18Name(p.product.name)} eventKey={idx}>
                                <Container fluid className={"border p-3"}>
                                    {
                                        p.attributes.length > 0 &&
                                        <CustomerOfferDetailsAttributes
                                            disabled={isPrevew}
                                            zIndex={props.zIndex}
                                            attributes={p.attributes}
                                            setAttributes={attributes => setOffer(prevState => {
                                                const _offer = {...prevState};
                                                _offer.products[idx].attributes = attributes;
                                                return _offer;
                                            })}
                                            eurExchangeRate={offer.eurExchangeRate}
                                            currency={offer.currency}
                                            discount_price={props.inquiry.client?.discount_price || 0}
                                            qty={p.qty}
                                        />
                                    }

                                    <CustomerOfferDetailsServices
                                        disabled={isPrevew}
                                        zIndex={props.zIndex}
                                        services={p.services}
                                        setServices={services => setOffer(prevState => {
                                            const _offer = {...prevState};
                                            _offer.products[idx].services = services;
                                            return _offer;
                                        })}
                                        currency={offer.currency}
                                        eurExchangeRate={offer.eurExchangeRate}
                                        discount_price={props.inquiry.client?.discount_price || 0}
                                        qty={p.qty}
                                    />
                                </Container>
                            </Tab>
                    )
                }
            </Tabs>

            <CustomerOfferDetailsSummary offer={offer} />
        </>
    );
}

export default CustomerOfferDetailsTabOffer;
