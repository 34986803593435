import {Button, Form, ModalBody, ModalFooter, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCancel, faCircleArrowDown,
    faEdit,
    faPlus,
    faSearch,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {useCollection, useCollectionData} from "react-firebase-hooks/firestore";
import {collection, query, where, doc, deleteDoc, DocumentData, orderBy} from "firebase/firestore";
import {fbDb} from "../../../App";
import {useEffect, useState} from "react";
import SmallBorderedHooveredTable from "../../../common/SmallBorderedHooveredTable";
import I18Label from "../../../i18/i18label";
import useUserRights from "../../../hooks/useUserRights";


export interface RegionsListProps {
    isVisible: boolean;
    onAdd: (country: string) => any;
    onEdit: (item: DocumentData) => any;
    onDelete: (item: DocumentData) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
}

export function RegionsList({isVisible, onAdd, onEdit, onDelete, onClose, onSelect}: RegionsListProps) {
    const {canUserEdit} = useUserRights();
    const [selectedCountry, setSelectedCountry] = useState('')
    const [filter, setFilter] = useState('')
    const [countries, loadingCountries, errorCountries] = useCollectionData(
        query(collection(fbDb, "countries"), orderBy("name", "asc"))
    );

    useEffect(
        () => {
            if (countries && countries.length > 0 && selectedCountry === '') setSelectedCountry(countries[0].name);
        }, [countries]
    )

    const [regions, loadingRegions, errorRegions] = useCollection(
        query(collection(fbDb, "regions"),
            where("country", "==", selectedCountry),
            orderBy("name", "asc")
        )
    )

    const [filteredRegions, setFilteredRegions] = useState<any[]>([])

    useEffect(() => {
        if (regions) {
            setFilteredRegions(regions.docs.filter(
                r => !filter || filter.length === 0 || r.data().name.toLowerCase().indexOf(filter.toLowerCase()) > -1
            ));
        } else setFilteredRegions([]);
    }, [regions, filter]);


    if (errorRegions) console.error(errorRegions);

    return (
        <>
            {loadingCountries && <Spinner animation={"border"}/>}

            {
                !loadingCountries && !errorCountries && countries && countries?.length > 0 && isVisible &&
                <>
                    <ModalBody>
                        <Tabs activeKey={selectedCountry} className={"mb-2"}
                              onSelect={(key) => setSelectedCountry(key || '')}
                        >
                            {
                                countries?.map(
                                    c => <Tab key={c.name}
                                              title={<I18Label label={c.name}/>}
                                              eventKey={c.name}/>
                                )
                            }
                        </Tabs>
                        <div className={"max-h-60vh scrollable"}>
                            <SmallBorderedHooveredTable>
                                <thead className={"bg-white"}>
                                <tr>
                                    <th style={{width: onSelect ? 120 : 90}}>
                                        <br/><FontAwesomeIcon icon={faSearch}/>
                                    </th>
                                    <th><I18Label label={"Регион"}/>
                                        <Form.Control size={"sm"} value={filter}
                                                      onChange={(e) => setFilter(e.target.value)}/></th>
                                </tr>
                                </thead>
                                <tbody>

                                {
                                    loadingRegions &&
                                    <tr>
                                        <td colSpan={3} className={"text-center fw-bold"}>
                                            <Spinner animation={"border"}/>
                                        </td>
                                    </tr>
                                }
                                {
                                    filteredRegions && filteredRegions.length === 0 &&
                                    <tr>
                                        <td colSpan={3} className={"text-center fw-bold"}><I18Label
                                            label={"Няма региони"}/></td>
                                    </tr>
                                }
                                {
                                    filteredRegions && filteredRegions.length > 0 && filteredRegions.map(
                                        r =>
                                            <tr key={r.id} className={"tr-bordered"}>
                                                <td>
                                                    {
                                                        canUserEdit('Номенклатури-Държави и региони') && <>
                                                            <Button size={"sm"} variant={"outline-secondary"}
                                                                    onClick={() => onEdit(r)}
                                                            >
                                                                <FontAwesomeIcon icon={faEdit}/>
                                                            </Button>&nbsp;
                                                            <Button size={"sm"} variant={"outline-danger"}
                                                                    onClick={() => {
                                                                        onDelete(r)
                                                                    }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash}/>
                                                            </Button>&nbsp;
                                                        </>
                                                    }
                                                    {
                                                        onSelect &&
                                                        <Button size={"sm"} variant={"outline-success"}
                                                                onClick={() => {
                                                                    onSelect(r.data())
                                                                }}
                                                        >
                                                            <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                        </Button>
                                                    }
                                                </td>
                                                <td>
                                                    <I18Label label={r.data().name}/>
                                                </td>
                                            </tr>
                                    )
                                }
                                </tbody>

                            </SmallBorderedHooveredTable>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        {
                            canUserEdit('Номенклатури-Държави и региони') &&
                            <Button onClick={() => onAdd(selectedCountry)}>
                                <FontAwesomeIcon icon={faPlus}/> <I18Label label={"Добави нов"}/>
                            </Button>
                        }
                        <Button variant={"outline-secondary"} onClick={() => {
                            onClose(null)
                        }}>
                            <FontAwesomeIcon icon={faCancel}/> <I18Label label={"Затвори"}/>
                        </Button>
                    </ModalFooter>
                </>
            }
        </>
    )
}
