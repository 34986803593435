import {Button, Form, ModalBody, ModalFooter, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCircleArrowDown, faEdit, faPlus, faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useCollection} from "react-firebase-hooks/firestore";
import {
    collection,
    getFirestore,
    DocumentData,
    query,
    where,
    orderBy
} from "firebase/firestore";
import {fbDb, firebaseApp} from "../../../App";
import {useEffect, useState} from "react";
import useUserRights from "../../../hooks/useUserRights";


export interface StandartsListProps {
    isVisible: boolean;
    onAdd: () => any;
    onEdit: (item: DocumentData) => any;
    onDelete: (item: DocumentData) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
}

export function StandartsList({isVisible, onAdd, onEdit, onDelete, onClose, onSelect}: StandartsListProps) {
    const {canUserEdit} = useUserRights();
    const [currentType, setCurrentType] = useState('trade');
    const [filterId, setFilterId] = useState('')
    const [filterName, setFilterName] = useState('')
    const [q, setQ] = useState(buildQuery())

    const [standarts, loading, error] = useCollection(q);
    const [filtered, setFilteredStandarts] = useState<any[]>([]);

    useEffect(() => {
        if (standarts) {
            setFilteredStandarts(standarts.docs.filter(
                r => (!filterName || filterName.length === 0 || r.data().name.toLowerCase().indexOf(filterName.toLowerCase()) > -1) &&
                    (!filterId || filterId.length === 0 || r.data().id.indexOf(filterId) > -1)
            ));
        } else setFilteredStandarts([]);
    }, [standarts, filterName, filterId]);

    useEffect(() => {
        setQ(buildQuery());
    }, [currentType]);


    function isLoading() {
        return loading;
    }

    function buildQuery() {
        return query(collection(fbDb, "standarts"),
            where("type", "==", currentType),
            orderBy("name", "asc")
        )
    }

    return (
        <>
            {
                isLoading() && <Spinner animation={"border"}/>
            }

            {
                !isLoading() && <span>{error?.message}</span>
            }

            {
                !loading && !error && isVisible &&
                <>
                    <ModalBody>
                        <Tabs activeKey={currentType} className={"mb-2"}
                              onSelect={(key) => setCurrentType(key || '')}
                        >
                            <Tab title='Търговия' eventKey='trade'/>
                            <Tab title='Производство' eventKey='production'/>

                        </Tabs>
                        <div className={"max-h-60vh scrollable"}>
                            <Table size={"sm"} borderless hover>
                                <thead style={{backgroundColor: "white"}}>
                                <tr>
                                    <th style={{width: onSelect ? 120 : 90}}></th>
                                    <th>ID</th>
                                    <th>Описание</th>
                                    <th>M/E</th>
                                    <th>Стандарт, min</th>
                                </tr>
                                <tr>
                                    <td className={"text-start"}>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterId}
                                                      onChange={(e) => setFilterId(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterName}
                                                      onChange={(e) => setFilterName(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} disabled={true}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} disabled={true}/>
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    loading &&
                                    <tr>
                                        <td colSpan={7} className={"text-center fw-bold"}>
                                            <Spinner animation={"border"}/>
                                        </td>
                                    </tr>
                                }
                                {
                                    filtered && filtered.length === 0 &&
                                    <tr>
                                        <td colSpan={7} className={"text-center fw-bold"}>Няма стандарти</td>
                                    </tr>
                                }
                                {

                                    filtered && filtered.length > 0 && filtered.map(
                                        r =>
                                            <tr key={r.id} className={"tr-bordered"}>
                                                <td>
                                                    {
                                                        canUserEdit('Номенклатури-Стандарти') && <>
                                                            <Button size={"sm"} variant={"outline-secondary"}
                                                                    onClick={() => onEdit(r)}
                                                            >
                                                                <FontAwesomeIcon icon={faEdit}/>
                                                            </Button>&nbsp;
                                                            <Button size={"sm"} variant={"outline-danger"}
                                                                    onClick={() => {
                                                                        onDelete(r)
                                                                    }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash}/>
                                                            </Button>&nbsp;
                                                        </>
                                                    }
                                                    {
                                                        onSelect &&
                                                        <Button size={"sm"} variant={"outline-success"}
                                                                onClick={() => {
                                                                    onSelect(r.data())
                                                                }}
                                                        >
                                                            <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                        </Button>
                                                    }
                                                </td>
                                                <td>
                                                    {r.data().id}
                                                </td>
                                                <td>
                                                    {r.data().name}
                                                </td>
                                                <td>
                                                    {r.data().me}
                                                </td>
                                                <td>
                                                    {r.data().standart}
                                                </td>
                                            </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        {
                            canUserEdit('Номенклатури-Стандарти') &&
                            <Button onClick={() => onAdd()}>
                                <FontAwesomeIcon icon={faPlus}/> Добави нов
                            </Button>}&nbsp;
                        <Button variant={"outline-secondary"} onClick={() => {
                            onClose(null)
                        }}>
                            <FontAwesomeIcon icon={faCancel}/> Затвори
                        </Button>
                    </ModalFooter>
                </>
            }
        </>
    )
}
