import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {showErrorsListInToast} from "../../../common";
import {useEffect, useState} from "react";
import {CurrencyNameType, CurrencyType, EMPTY_CURRENCY, useCurrency} from "../../../fb-converters/currency-converter";
import ReactDatePicker from "react-datepicker";
import {FirestoreError} from "firebase/firestore";


export interface CurrencyDetailsProps {
    zIndex?: number;
    currency: CurrencyType;
    onDone: () => any;
}

export function CurrenyDetails({
                                   zIndex,
                                   currency,
                                   onDone
                               }: CurrencyDetailsProps) {
    const [saving, setSaving] = useState(false);
    const [forDate, setForDate] = useState(currency.date);
    const [rate, setRate] = useState(currency.rate.toString());

    const {setCurrencyForDate} = useCurrency(null);

    async function save() {
        const newRate = parseFloat(rate);
        if (isNaN(newRate)) {
            showErrorsListInToast("Грешка", ["Моля, въведете курс"]);
            return;
        }

        try {
            setSaving(true);
            await setCurrencyForDate(currency.name, newRate, forDate);
            onDone();
        } catch (e) {
            const fbErr = e as FirestoreError;
            showErrorsListInToast("Грешка", [fbErr.message]);
        } finally {
            setSaving(false);
        }
    }

    return (
        <><Form className={"p-2"}>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    {currency.id ? "Корекция" : "Добавяне"} на валутен курс
                </h5>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                Дата:
                            </Form.Label>
                            <br/>
                            <ReactDatePicker
                                selected={forDate}
                                onChange={(date: Date) => {
                                    setForDate(date ?? new Date());
                                }}
                                dateFormat={"dd.MM.yyyy"}
                                className={"form-control"}
                                showIcon={true}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                Курс към EUR:
                            </Form.Label>
                            <Form.Control type="text"
                                          value={rate}
                                          onChange={(e) => setRate(e.target.value)}
                                          onBlur={() => {
                                              let newRate = parseFloat(rate);
                                              if (isNaN(newRate)) {
                                                  newRate = 0;
                                              }
                                              setRate(newRate.toString());
                                          }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        saving && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !saving &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button onClick={save}>
                                    <FontAwesomeIcon icon={faSave}/> Запиши
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </Form>
        </>
    )
}
