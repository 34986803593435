import {DIALOG_OPTIONS} from "../../../types";
import ModalHeader from "react-bootstrap/ModalHeader";
import {Col, Modal, ModalTitle, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile} from "@fortawesome/free-solid-svg-icons";
import DialogCloseButton from "../../../common/DialogCloseButton";
import React from "react";
import ModalBackground from "../../../common/ModalBackground";
import {ProductionPlanDetails} from "./ProductionPlanDetails";
import {
    CustomerInvoiceItemType,
    CustomerInvoiceType
} from "../../../fb-converters/cutomerInvoice-converter";
import {CustomerInquiryType} from "../../../fb-converters/cutomerInquiry-converter";
import I18Label from "../../../i18/i18label";
import {ProductionCardType} from "../types/ProductionCardType";
import {dateTimeToString, dateToString} from "../../../common";
import ProductionPlanHistory from "./ProductionPlanHistory";
import {CustomerOfferType} from "../../../fb-converters/cutomerOffer-converter";

interface Props extends DIALOG_OPTIONS {
    inquiry: CustomerInquiryType;
    offer: CustomerOfferType;
    invoice: CustomerInvoiceType;
    product: CustomerInvoiceItemType;
}

const ProductionPlanDetailsPopup = ({zIndex, show, onClose, invoice, inquiry, offer, product}: Props) => {
    const [card, setCard] = React.useState<ProductionCardType | undefined>(undefined);
    const [showHistory, setShowHistory] = React.useState(false);

    if(showHistory) {
        return <ProductionPlanHistory inquiryNumber={inquiry.inquiryNumber}
                                      onClose={() => setShowHistory(false)}
                                        zIndex={zIndex}
        />
    }

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} size={"xl"} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faFile}/> <I18Label label={"Карта за възлагане към сервиза"} />
                        {
                            card && card.authName && card.createdAt &&
                            <Row className={"small fst-italic"}>
                                <Col>
                                    <span className={"small text-muted"}><I18Label label={"Автор: "}/> {card.authName} / {dateTimeToString(card.createdAt)}</span>
                                </Col>
                            </Row>
                        }
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(null)}/>
                </ModalHeader>
                {
                    <ProductionPlanDetails invoice={invoice}
                                           inquiry={inquiry}
                                           offer={offer}
                                           product={product}
                                           onDone={() => onClose(null)}
                                           onChange={setCard}
                                           onShowHistoryChange={setShowHistory}
                    />
                }
            </Modal>
        </ModalBackground>
    );
}

export default ProductionPlanDetailsPopup;
